import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { MdAccountCircle } from 'react-icons/md';
import Moment from 'react-moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import moment from 'moment';


const StyledSection3 = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    margin-bottom: 3rem;
    padding: 2rem 1.75rem 1rem;
    background-color: ${({ theme }) => theme.colors.background.elements};
    border-radius: 0.75rem;
    position: relative;
    border: 1px solid #ccc;

    
    
    h3{
        margin: 0px;
        font-size: 1.5rem;
    }
`;

const Section3 = ({ note }) => {

    return (
        <StyledSection3>
            <h2 className='sectionFloatTitle'>ملاحظة الادمن</h2>

            <h3>
                {note}
            </h3>
        </StyledSection3>
    );
}

export default Section3;