import React from 'react';

import styled from 'styled-components';
import UnitDetails from '../../real-estates/units/unit-edit/sections/Section-1';
import UnitFacilities from '../../real-estates/units/unit-edit/sections/Section-2';
import UnitServices from '../../real-estates/units/unit-edit/sections/Section-3';


const StyledUnitsEdit = styled.div`
    & > *:not(:last-child){
        margin-bottom: 3rem;
    }
`;


const UnitsEdit = () => {

    return (
        <StyledUnitsEdit>
            <UnitDetails />
            <UnitFacilities />
            <UnitServices />
        </StyledUnitsEdit>
    );
}

export default UnitsEdit;