import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { FaBed, FaBath } from 'react-icons/fa';
import { MdOutlineLiving } from 'react-icons/md';
import Moment from 'react-moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Select from 'react-select';
import moment from 'moment';
import TextInput from '../../../../pages-parts/inputs/text-input/TextInput';
import CodeInput from './CodeInput';
import PlainText from '../../../../pages-parts/display-info/standard/PlainText';
import { ContractFinanceRecordEditContext } from '../ContractFinanceRecordEditContext';


const StyledSection9 = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    margin-bottom: 0;
    padding: 1.5rem 1.75rem;
    background-color: ${({ theme }) => theme.colors.background.elements};
    border-radius: 0.75rem;
    position: relative;
    border: 1px solid #ccc;

    
    
    
    .infoData{
        width: 12rem;
        position: relative;

        
        input{
            margin: 0;
        }

        &.value::after{
            display: flex;
            content: "SAR";
            position: absolute;
            height: 100%;
            align-items: center;
            right: 0;
            top: 0;
            font-weight: 900;
            width: 50px;
            justify-content: center;
            border-left: 1px solid #ccc;
        }

        .myInput{
            padding-right: 55px!important;
        }

    }
`;
const Section9 = () => {
    const {
        code,
        setCode,
        rightCode,
        checkCode,
        discount,
    } = useContext(ContractFinanceRecordEditContext);

    return (
        <StyledSection9>
            <h2 className='sectionFloatTitle'>الخصم</h2>
            <CodeInput
                titleText="الكود"
                inputName="code-id"
                value={code}
                setValue={setCode}
                checkCode={checkCode}
                rightCode={rightCode}
            />
            {
                rightCode ? (
                    <PlainText
                        title="قيمة الخصم"
                        value={`${discount}SAR`}
                    />
                ) : ''
            }
        </StyledSection9>
    );
}

export default Section9;