import React, { useContext, useEffect, useRef, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';
import Select from 'react-select';
import { Calendar } from 'react-date-range';
import moment from 'moment';
import StaticSelect from '../../../../pages-parts/inputs/static-select-input/StaticSelect';
import TextInput from '../../../../pages-parts/inputs/text-input/TextInput';
import NumberInput from '../../../../pages-parts/inputs/number-input/NumberInput';
import DatePickerInput from '../../../../pages-parts/inputs/date-picker/DatePickerInput';
import { OwnerDetailsEditContext } from '../OwnerDetailsEditContext';
import EmailInput from '../../../../pages-parts/inputs/email-input/EmailInput';
import PhoneNumber from '../../../../pages-parts/inputs/phone-input/PhoneNumber';

const StyledSection4 = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    margin-bottom: 3rem;
    padding: 1rem 1.75rem;
    background-color: ${({ theme }) => theme.colors.background.elements};
    border-radius: 0.75rem;
    position: relative;
    border: 1px solid #ccc;

    
    p{
        margin-bottom: 0;
    }
    .data-line{
        display: flex;
        align-items: center;
        margin-bottom: .5rem;
        &:first-child{
            margin-top: 0!important;
        }
        &:last-child{
            margin-bottom: 0;
        }
    }
    
    .infoData{
        width: 12rem;
        position: relative;
        
        &:has(.phoneNumber){
            width: auto;
        }
        .phoneNumber{
            display: inline;
            width: 12rem;
            margin-left: 0;
            border-left: none;
            border-radius: 0 .375rem .375rem 0;
        }
    }
    span img{
        width: 6rem;
        height: 6rem;
        border-radius: 0.75rem;
    }
`;

const Section4 = () => {
    const {
        phoneNum,
        setPhoneNum,
        phoneCountryCode,
        handleChangePhoneCountryCode,
        email,
        setEmail,
    } = useContext(OwnerDetailsEditContext);

    return (
        <StyledSection4>
            <h2 className='sectionFloatTitle'>معلومات الاتصال</h2>
            <Row className='mt-3'>
                <Col>
                    <PhoneNumber
                        titleText="رقم الهاتف"
                        inputName="phone-number"
                        phoneNum={phoneNum}
                        setPhoneNum={setPhoneNum}
                        handleChangeSelect={handleChangePhoneCountryCode}
                        selectedOption={phoneCountryCode}
                    />
                    <EmailInput
                        titleText='البريد الالكتروني'
                        inputName='email'
                        value={email}
                        setValue={setEmail}
                    />
                </Col>
            </Row>
        </StyledSection4>
    );
}

export default Section4;