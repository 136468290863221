import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { AiOutlineEye } from 'react-icons/ai';
import { BsFillHouseDoorFill, BsUpload } from 'react-icons/bs';
import { IoPricetagOutline } from 'react-icons/io5';
import { FaRegBuilding, FaRegEdit } from 'react-icons/fa';
import Moment from 'react-moment';
import TableComponent from '../../../pages-parts/table/TableComponent';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import DateFilter from '../../../pages-parts/table/DateFilter';
import moment from 'moment';


const StyledSection2 = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    margin-bottom: 3rem;
    padding: 0;
    .scroll-thumb{
        background-color: ${({ theme }) => theme.colors.primary_8};
    }

    td{
        
        font-size: 15px!important;
        p, h6{
            font-size: 15px!important;
            &.gray-time{
                color: ${({ theme }) => theme.colors.text.primary};
            }
        }
    }

    .earning-for-contract{
        padding: 0.3125rem 1rem;
        display: inline-block;
        border: 1px solid #ccc;
        border-radius: 1rem;
    }

    .row>*{
        padding: 0;
    }
`;

const Section2 = ({ userData, userType }) => {
    const location = useLocation();
    const [columns, setColumns] = useState([]);

    const [pageNum, setPageNum] = useState(() => {
        return JSON.parse(localStorage.getItem(location.pathname + '-page-num')) ?? 1;
    });
    const [dateType, setDateType] = useState(() => {
        return JSON.parse(localStorage.getItem('date-type-filter')) ?? 'all';
    });
    const [dateFilter, setDateFilter] = useState(() => {
        return {
            start: moment().subtract(1, 'months').startOf('month').format(),
            end: moment().format()
        };
    });

    const getDateTypeUrlText = type => {
        let dateFilterText = '';
        switch (type) {
            case 'all':
                dateFilterText = '';
                break;
            case 'today':
                dateFilterText = `createDate_gte=${moment().startOf('day').format()}&createDate_lte=${moment().endOf('day').format()}&`;
                break;
            case 'yesterday':
                dateFilterText = `createDate_gte=${moment().subtract(1, 'days').startOf('day').format()}&createDate_lte=${moment().subtract(1, 'days').endOf('day').format()}&`;
                break;
            case 'last-7-days':
                dateFilterText = `createDate_gte=${moment().subtract(7, 'days').startOf('day').format()}&createDate_lte=${moment().endOf('day').format()}&`;
                break;
            case 'last-30-days':
                dateFilterText = `createDate_gte=${moment().subtract(30, 'days').startOf('day').format()}&createDate_lte=${moment().endOf('day').format()}&`;
                break;
            case 'this-month':
                dateFilterText = `createDate_gte=${moment().startOf('month').format()}&createDate_lte=${moment().endOf('month').format()}&`;
                break;
            case 'last-month':
                dateFilterText = `createDate_gte=${moment().subtract(1, 'months').startOf('month').format()}&createDate_lte=${moment().subtract(1, 'months').endOf('month').format()}&`;
                break;
            case 'custom':
                dateFilterText = dateFilter.start !== undefined && dateFilter.end !== undefined ? `createDate_gte=${dateFilter.start}&createDate_lte=${dateFilter.end}&` : "";
                break;
            default:
                dateFilterText = '';
                break;
        }

        return dateFilterText;
    }

    const [entries, setEntries] = useState(10)
    const [tableDataUrl, setTableDataUrl] = useState(() => {
        if (userType === 'broker') {
            return `/brokersFinanceRecords?brokerId=${userData.id}&${getDateTypeUrlText(dateType)}&_page=${pageNum}&_limit=${entries}`;
        } else if (userType === 'customer-services') {
            return `/customerServiceFinanceRecords?customerServiceId=${userData.id}&${getDateTypeUrlText(dateType)}&_page=${pageNum}&_limit=${entries}`;
        } else if (userType === 'promoter') {
            return `/promotersFinanceRecords?promoterId=${userData.id}&${getDateTypeUrlText(dateType)}&_page=${pageNum}&_limit=${entries}`;
        }
    })

    useEffect(() => {
        if (userType === 'broker' || userType === 'customer-services') {
            setColumns([
                {
                    id: 1,
                    Header: "#",
                    Cell: e => {
                        return <span className='numbers-font'>{e.value}</span>
                    },
                    accessor: "number",
                    sortable: false,
                },
                {
                    id: 2,
                    Header: "رقم العقد",
                    accessor: "contractId",
                    sortable: false,
                    Cell: e => {
                        return (
                            <span><span>#</span> {e.value?.replace(/(-.*)/g, "")}...</span>
                        )
                    }
                },
                {
                    id: 3,
                    Header: "النوع",
                    accessor: "contractId",
                    sortable: false,
                    Cell: e => {

                        return Math.random() > .5 ? 'سكني' : 'تجاري';
                    }
                },
                {
                    id: 4,
                    Header: "التاريخ و الوقت",
                    accessor: "contractId",
                    sortable: false,
                    Cell: e => {
                        return (
                            <div className='numbers-font'>
                                <h6 className='m-0'>{moment().format('ll')}</h6>
                                <p className='m-0 gray-time'>{moment().format('hh:mm')}</p>
                            </div>
                        )
                    }
                },
                {
                    id: 5,
                    Header: "القيمة المستحقة",
                    accessor: "number",
                    sortable: false,
                    Cell: e => {
                        return <>50 ريال</>
                    }
                },
            ])
        } else if (userType === 'promoter') {
            setColumns([
                {
                    id: 1,
                    Header: "رقم العقد",
                    accessor: "contractId",
                    sortable: false,
                    Cell: e => {
                        return (
                            <span><span>#</span> {e.value?.replace(/(-.*)/g, "")}...</span>
                        )
                    }
                },
                {
                    id: 2,
                    Header: "التاريخ و الوقت",
                    accessor: "contractId",
                    sortable: false,
                    Cell: e => {
                        return (
                            <div className=' numbers-font'>
                                <h6 className='m-0'>{moment().format('ll')}</h6>
                                <p className='m-0 gray-time'>{moment().format('hh:mm')}</p>
                            </div>
                        )
                    }
                },
                {
                    id: 3,
                    Header: "القيمة المستحقة",
                    accessor: "number",
                    sortable: false,
                    Cell: e => {
                        return <>50 ريال</>
                    }
                },
            ])
        }
    }, [])

    useEffect(() => {
        let url;
        if (userType === 'broker') {
            url = `/brokersFinanceRecords?brokerId=${userData.id}&${getDateTypeUrlText(dateType)}_page=${pageNum}&_limit=${entries}`;
        } else if (userType === 'customer-services') {
            url = `/customerServiceFinanceRecords?customerServiceId=${userData.id}&${getDateTypeUrlText(dateType)}_page=${pageNum}&_limit=${entries}`;
        } else if (userType === 'promoter') {
            url = `/promotersFinanceRecords?promoterId=${userData.id}&${getDateTypeUrlText(dateType)}_page=${pageNum}&_limit=${entries}`;
        }
        setTableDataUrl(prev => {
            console.log(dateFilter);

            // if(dateType !== "all"){
            //     if(prev.search('gte') > -1){
            //         console.log(prev.replace(/(.*)(gte)(.*)(_page)(.*)/g, `$1${
            //             getDateTypeUrlText(dateType).replace(/(.*)(gte)/g, `$2`)
            //         }$4$5`));
            //     }else{
            //         console.log(prev.replace(/(_page)(.*)/g, `${
            //             getDateTypeUrlText(dateType)
            //         }$&`));
            //     }
            // }else{
            //     console.log(prev.replace(/(.*)(&)([^&]*)(_gte)(.*)(_page)(.*)/g, `$1$2${
            //         getDateTypeUrlText(dateType)
            //     }$6$7`));
            // }
            // console.log(url, dateType);
            return url
        });

    }, [pageNum, dateType, dateFilter, userData]);


    return (
        <StyledSection2>
            <Container fluid className='p-0'>
                <Row className='mb-3 flex-lg-row-reverse flex-md-column'>
                    <Col
                        lg={9}
                        md={7}
                        className='d-flex justify-content-end align-items-center mb-2'
                    >
                        {
                            userType === 'promoter' ? '' : (
                                <span className='earning-for-contract'>
                                    1500 ريال /&nbsp;
                                    {
                                        isNaN(
                                            userData.housingUnitsNum + userData.tradingUnitsNum
                                        ) ? "" : (
                                            userData.housingUnitsNum + userData.tradingUnitsNum
                                        )
                                    } عقد
                                </span>
                            )
                        }
                    </Col>
                    <Col lg={3} md={5}>
                        <DateFilter
                            dateFilter={dateFilter}
                            setDateFilter={setDateFilter}
                            dateType={dateType}
                            setDateType={setDateType}
                            setPageNum={setPageNum}
                            onLeft={false}
                        />
                    </Col>
                </Row>
                <TableComponent
                    columns={columns}
                    tableDataUrl={tableDataUrl}
                    setSortTable={() => { }}
                    tableWidth={800}
                    setPageNum={setPageNum}
                    pageNum={pageNum}
                    tableRowsPerPage={entries}
                    hasTotalSection={true}
                />
            </Container>
        </StyledSection2>
    );
}

export default Section2;