import React, { useContext, useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from 'styled-components';
import profileImage1 from '../../../../images/pic1.png';
import profileImage2 from '../../../../images/1.png';
import { UserContext } from '../../../../context/UserContext';
import moment from 'moment';
import PlainText from '../../../pages-parts/display-info/standard/PlainText';

const StyledSection1 = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    padding: 1rem 1.75rem;
    background-color: ${({ theme }) => theme.colors.background.elements};
    border-radius: 0.75rem;
    position: relative;
    border: 1px solid #ccc;

    
    
    


    span img{
        width: 6rem;
        height: 6rem;
        border-radius: 0.75rem;
    }
`;

const Section1 = () => {
    const userContext = useContext(UserContext);

    useEffect(() => {

    }, []);

    const [displayData, setDisplayData] = useState([]);


    useEffect(() => {

        setDisplayData([
            {
                title: 'الاسم بالكامل',
                value: userContext.userData?.firstName + " " + userContext.userData?.lastName,
                hasNumber: false
            },
            {
                title: 'البريد الالكتروني',
                value: userContext.userData?.email,
                hasNumber: true
            },
            {
                title: 'رقم الجوال',
                value: userContext.userData?.phone,
                hasNumber: true
            },
            {
                title: 'الحساب مفعل',
                value: userContext.userData?.active ? 'نعم' : 'لا',
                hasNumber: false
            },
            {
                title: 'نوع الحساب',
                value: getRuleName(),
                hasNumber: false
            },
        ]);
    }, [userContext]);

    const getRuleName = () => {
        if (userContext.userData?.rule === 'admin') {
            return 'مشرف';
        } else if (userContext.userData?.rule === 'assistant') {
            return 'مساعد وسيط';
        } else {
            return 'غير محدد';
        }
    }

    return (
        <StyledSection1 className='mb-4'>
            <h2 className='sectionFloatTitle'>البيانات الاساسية</h2>
            <Row className='flex-row-reverse'>
                <Col
                    xs='12'
                    sm='3'
                    className='
                        d-flex
                        flex-column
                        align-items-center
                        align-items-sm-end
                        justify-content-between
                        mb-3
                        mb-sm-0
                    '
                >
                    <span>
                        <img src={userContext.userData?.rule === 'admin' ? profileImage1 : profileImage2} alt="Profile" />
                    </span>
                </Col>
                <Col xs='12' sm='9' className='pt-2'>
                    {displayData.map((item, i) => (
                        <PlainText
                            key={i}
                            title={item.title}
                            value={item.value}
                            hasNumber={item.hasNumber}
                        />
                    ))}
                </Col>
            </Row>
        </StyledSection1>
    );
}

export default Section1;