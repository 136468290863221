import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { MdAccountCircle } from 'react-icons/md';
import Moment from 'react-moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import moment from 'moment';
import PlainText from '../../../../../pages-parts/display-info/standard/PlainText';


const StyledSection1 = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    margin-bottom: 3rem;
    padding: 1rem 1.75rem;
    background-color: ${({ theme }) => theme.colors.background.elements};
    border-radius: 0.75rem;
    position: relative;
    border: 1px solid #ccc;

    
    
    
    .infoData{
        color: ${({ theme }) => theme.colors.text.primary};
    }
    .scroll-thumb{
        background-color: ${({ theme }) => theme.colors.primary_8};
    }
`;
const Section1 = ({ unitData }) => {
    const [displayData, setDisplayData] = useState([]);

    useEffect(() => {

        setDisplayData([
            {
                title: 'رقم الوحدة',
                value: '15',
                hasNumber: true
            },
            {
                title: 'نوع الوحدة',
                value: 'شقة',
                hasNumber: false
            },
            {
                title: 'استخدام الوحدة',
                value: 'سكني',
                hasNumber: false
            },
            {
                title: 'رقم الطابق',
                value: '4',
                hasNumber: true
            },
            {
                title: 'مساحة الوحدة',
                value: '210م',
                hasNumber: true
            },
            {
                title: 'عدد الغرف',
                value: '3',
                hasNumber: true
            },
            {
                title: 'تاريخ الانشاء',
                value: moment().format('ll'),
                hasNumber: true
            },
            {
                title: 'اتجاة الوحدة',
                value: 'شرق',
                hasNumber: false
            },
            {
                title: 'تشطيب الوحدة',
                value: 'مكتمل',
                hasNumber: false
            },
            {
                title: 'طول الوحدة',
                value: '14م',
                hasNumber: true
            },
            {
                title: 'عرض الوحدة',
                value: '15م',
                hasNumber: true
            },
            {
                title: 'ارتفاع الوحدة',
                value: '4م',
                hasNumber: true
            },
            {
                title: 'تحتوي علي ميزان',
                value: 'لا',
                hasNumber: false
            },
            {
                title: 'مؤثث / مفروش',
                value: 'لا',
                hasNumber: false
            },
            {
                title: 'نوع التأثيث',
                value: '-',
                hasNumber: false
            },
        ]);
    }, [unitData]);

    return (
        <StyledSection1>
            <h2 className='sectionFloatTitle'>تفاصيل الوحدة</h2>

            <Row className='mt-4'>
                <Col md="6" className='mb-2'>
                    {displayData.map((item, i) => (
                        <PlainText
                            key={i}
                            title={item.title}
                            value={item.value}
                            hasNumber={item.hasNumber}
                        />
                    ))}
                </Col>
            </Row>
        </StyledSection1>
    );
}

export default Section1;