import Select from 'react-select';
import { customSelectStyles } from '../../../../styles/Global';

function StaticSelect({ titleText, selectedOption, options, customStylesProp, handleChangeSelect }) {

    return (
        <div className='mt-2 data-line'>
            <span className='infoTitle'>
                {titleText}
            </span>
            <span className='infoData'>
                <Select
                    value={selectedOption}
                    options={options}
                    styles={customStylesProp.has ? customStylesProp.style : customSelectStyles}
                    onChange={handleChangeSelect}
                />
            </span>
        </div>
    );
}

export default StaticSelect;