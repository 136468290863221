import React, { useContext, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useSearchParams, Link, useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';
import ExtraMoneySection from '../../contract-finance-records/edit/section-7/Section-7';
import IBANSection from '../../contract-finance-records/edit/section-8/Section-8';
import CouponSection from '../../contract-finance-records/edit/section-9/Section-9';
import { ContractDetailsEditContext } from '../ContractDetailsEditContext';




const StyledExtraMoneyEdit = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    padding: 0;
    

    & > *:last-child{
        margin-bottom: 0;
    }

`;

const ExtraMoneyEdit = () => {
    const {
        duration,
    } = useContext(ContractDetailsEditContext)

    return (
        <StyledExtraMoneyEdit>
            <ExtraMoneySection />
            <IBANSection />
            <CouponSection />
        </StyledExtraMoneyEdit>
    );
}

export default ExtraMoneyEdit;