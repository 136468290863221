import React, { useState, createContext, useEffect, useContext } from 'react';
import { AlertContext } from "../../../../context/AlertContext";
import api from '../../../../api/areas';
import { useNavigate } from "react-router-dom";
import { TopbarContext } from '../../../../context/TopbarContext';
import moment from 'moment';
export const ContractFinanceRecordEditContext = createContext();

export const ContractFinanceRecordEditContextProvider = ({ children }) => {
    //contract place holder
    const alertContext = useContext(AlertContext);
    // const { toggleTopbarTitle } = useContext(TopbarContext);
    const navigate = useNavigate();

    // contract type section -----------------------
    const [contractTypeHousing, setContractTypeHousing] = useState(true);
    const [contractId, setContractId] = useState(contractTypeHousing ? "913c9d02-7671-44ec-a4e4-a62fce826c54" : "92ce1795-8c95-4eaf-96c8-e326bcc66fb8");
    // rent value section -----------------------
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date(moment().add(3, "years")));
    const [yearlyRent, setYearlyRent] = useState('');
    const [increaseTypePercent, setIncreaseTypePercent] = useState(true);
    const [fixedTypeIncrease, setFixedTypeIncrease] = useState(false);
    const [increaseFixed, setIncreaseFixed] = useState('');
    const [decreaseFixed, setDecreaseFixed] = useState('');
    const [percentTypeIncrease, setPercentTypeIncrease] = useState(false);
    const [increasePercent, setIncreasePercent] = useState([
        '',
        ''
    ]);
    const [decreasePercent, setDecreasePercent] = useState([
        '',
        ''
    ]);
    // services
    const [electricityFee, setElectricityFee] = useState(true);
    const [electricityFeeValue, setElectricityFeeValue] = useState('');
    const [gasFee, setGasFee] = useState(true);
    const [gasFeeValue, setGasFeeValue] = useState('');
    const [waterFee, setWaterFee] = useState(true);
    const [waterFeeValue, setWaterFeeValue] = useState('');

    // paid amount section -----------------------
    const [paidRent, setPaidRent] = useState('');
    const [paidServicesFee, setPaidServicesFee] = useState('');

    // unit annual services fees section -----------------------
    const [parkingFee, setParkingFee] = useState('');
    const [sewageFee, setSewageFee] = useState('');
    const [maintenanceFee, setMaintenanceFee] = useState('');
    const [cleaningFee, setCleaningFee] = useState('');
    const [securityFee, setSecurityFee] = useState('');
    const [additionalFee, setAdditionalFee] = useState([]);

    // payments batchs section ----------------
    const [paymentType, setPaymentType] = useState('flexible');
    const [durationType, setDurationType] = useState('monthly');
    const [firstAppointmentDate, setFirstAppointmentDate] = useState(new Date());
    const [firstValue, setFirstValue] = useState('');
    const [additionalAppointment, setAdditionalAppointment] = useState([]);

    // additional fees section -----------------
    const [fields, setFields] = useState([]);

    // iban number section -------------
    const [creditCardsOptions, setCreditCardsOptions] = useState([
        {
            value: '0',
            label: 'SA03 8000 0000 6080 1016 7519'
        },
        {
            label: 'SA03 8000 7548 7946 4573 0054',
            value: '1'
        },
        {
            label: 'SA03 8052 4517 7841 7835 9419',
            value: '2'
        },
        {
            label: 'SA03 8000 0000 1817 9516 7519',
            value: '3'
        },
    ]);
    const [selectedCreditCard, setSelectedCreditCard] = useState(creditCardsOptions[0]);
    const handleChangeSelectedCreditCard = (selectOption) => {
        setSelectedCreditCard(selectOption)
    }

    // discount coupon section ----------------
    const [codes, setCodes] = useState([
        {
            code: '123ABC',
            discount: '90'
        },
        {
            code: '456QRY',
            discount: '110'
        },
        {
            code: 'HI159S',
            discount: '86'
        },
        {
            code: 'DAS754',
            discount: '205'
        },
        {
            code: '123456',
            discount: '120'
        },
    ]);
    const [code, setCode] = useState('');
    const [discount, setDiscount] = useState('');
    const [rightCode, setRightCode] = useState(false);

    const checkCode = (value) => {
        if (codes.find(item => item.code === value) !== undefined) {
            setRightCode(true);
            setDiscount(codes.find(item => item.code === value).discount)
        } else {
            setRightCode(false);
        }
    }

    const getFinanceRecordData = (contractId) => {
        api.get(`/items?id=${contractId}`).then(result => {
            if (result.data.length === 0) {
                alertContext.toggleAlert(true);
                alertContext.toggleAlertData({
                    heading: 'عقد مجهول',
                    message: 'لم يتم العثور علي العقد المطلوب',
                    variant: 'warning',
                });
                navigate("/");
            } else {
                setContractTypeHousing(result.data[0].contractType === "سكني");
            }
        });
    }


    useEffect(() => {
        if (contractTypeHousing) {
            setFields([
                {
                    titleText: "مبلغ الضمان",
                    inputName: "guarantee-amount",
                    available: true,
                    value: "",
                    inputType: "number",
                    inputText: "الرسوم"
                },
                {
                    titleText: "الغرامة اليومية",
                    inputName: "daily-fine",
                    available: true,
                    value: "",
                    inputType: "number",
                    inputText: "الرسوم"
                },
                {
                    titleText: "العربون",
                    inputName: "deposit",
                    available: false,
                    value: "",
                    inputType: "number",
                    inputText: "الرسوم"
                },
                {
                    titleText: "عمولة مكتب الوساطة",
                    inputName: "brokerage-office-commission",
                    available: true,
                    value: "",
                    inputType: "number",
                    inputText: "الرسوم"
                },
            ])
        } else {
            setFields([
                {
                    titleText: "الاشراف الهندسي",
                    inputName: "eng-superviser",
                    available: true,
                    value: "",
                    inputType: "number",
                    inputText: "الرسوم"
                },
                {
                    titleText: "تجهيز الوحدة",
                    inputName: "unit-processing",
                    available: true,
                    value: "",
                    inputType: "number",
                    inputText: "الرسوم"
                },
                {
                    titleText: "ازالة النفايات",
                    inputName: "waste-processing",
                    available: true,
                    value: "",
                    inputType: "number",
                    inputText: "الرسوم"
                },
                {
                    titleText: "مبلغ الضمان",
                    inputName: "guarantee-amount",
                    available: true,
                    value: "",
                    inputType: "number",
                    inputText: "الرسوم"
                },
                {
                    titleText: "الغرامة اليومية",
                    inputName: "daily-fine",
                    available: true,
                    value: "",
                    inputType: "number",
                    inputText: "الرسوم"
                },
                {
                    titleText: "العربون",
                    inputName: "deposit",
                    available: false,
                    value: "",
                    inputType: "number",
                    inputText: "الرسوم"
                },
            ])
        }
    }, [contractTypeHousing])

    useEffect(() => {
        setRightCode(false)
    }, [code])

    const values = {
        getFinanceRecordData,
        contractTypeHousing,
        setContractTypeHousing,
        contractId,
        setContractId,
        // contract value
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        yearlyRent,
        setYearlyRent,
        increaseTypePercent,
        setIncreaseTypePercent,
        fixedTypeIncrease,
        setFixedTypeIncrease,
        increaseFixed,
        setIncreaseFixed,
        decreaseFixed,
        setDecreaseFixed,
        percentTypeIncrease,
        setPercentTypeIncrease,
        increasePercent,
        setIncreasePercent,
        decreasePercent,
        setDecreasePercent,
        // services
        electricityFee,
        setElectricityFee,
        electricityFeeValue,
        setElectricityFeeValue,
        gasFee,
        setGasFee,
        gasFeeValue,
        setGasFeeValue,
        waterFee,
        setWaterFee,
        waterFeeValue,
        setWaterFeeValue,
        // paid amount
        paidRent,
        setPaidRent,
        paidServicesFee,
        setPaidServicesFee,
        // unit annual services fees
        parkingFee,
        setParkingFee,
        sewageFee,
        setSewageFee,
        maintenanceFee,
        setMaintenanceFee,
        cleaningFee,
        setCleaningFee,
        securityFee,
        setSecurityFee,
        additionalFee,
        setAdditionalFee,
        // payments batchs
        paymentType,
        setPaymentType,
        durationType,
        setDurationType,
        firstAppointmentDate,
        setFirstAppointmentDate,
        firstValue,
        setFirstValue,
        additionalAppointment,
        setAdditionalAppointment,
        // additional fees
        fields,
        setFields,
        // iban number
        creditCardsOptions,
        selectedCreditCard,
        handleChangeSelectedCreditCard,
        // discount coupon
        code,
        setCode,
        rightCode,
        setRightCode,
        checkCode,
        discount,
        setDiscount,
    };

    return (
        <ContractFinanceRecordEditContext.Provider value={values}>
            {children}
        </ContractFinanceRecordEditContext.Provider>
    )
}

