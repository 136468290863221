import moment from 'moment';
import React, { useState, createContext, useEffect, useContext } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { TopbarContext } from '../../../context/TopbarContext';
import { AlertContext } from '../../../context/AlertContext';
import { OwnerDetailsEditContext } from '../owners/edit/OwnerDetailsEditContext';
import { OwnershipDocEditContext } from '../real-estates/ownership-document-edit/OwnershipDocEditContext';
import { RealEstateDetailsEditContext } from '../real-estates/real-estate-details-edit/RealEstateDetailsEditContext';
import { RealEstateSharedFacilitiesEditContext } from '../real-estates/real-estate-shared-facilities-edit/RealEstateSharedFacilitiesEditContext';
import { UnitEditContext } from '../real-estates/units/unit-edit/UnitEditContext';
import { TenantDetailsEditContext } from '../tenant/edit/TenantDetailsEditContext';
import { ContractFinanceRecordEditContext } from '../contract-finance-records/edit/ContractFinanceRecordEditContext';


export const ContractDetailsEditContext = createContext();

export const ContractDetailsEditContextProvider = ({ children }) => {
    const navigate = useNavigate();

    const ownershipDocEditContext = useContext(OwnershipDocEditContext);
    const realEstateDetailsEditContext = useContext(RealEstateDetailsEditContext);
    const realEstateSharedFacilitiesEditContext = useContext(RealEstateSharedFacilitiesEditContext);
    const unitEditContext = useContext(UnitEditContext);
    const ownerDetailsEditContext = useContext(OwnerDetailsEditContext);
    const tenantDetailsEditContext = useContext(TenantDetailsEditContext);
    const contractFinanceRecordEditContext = useContext(ContractFinanceRecordEditContext);
    // current page num
    const [currentPageNum, setCurrentPageNum] = useState(1);

    // contract duration page
    // contract Start Date
    const [contractStartDate, setContractStartDate] = useState(new Date());
    // contract End Date
    const [contractEndDate, setContractEndDate] = useState(new Date(moment().add(3, "years")));

    // terms and conditions
    const [isContractAutoRenew, setIsContractAutoRenew] = useState(false);
    const [cancelAutoRenewDays, setCancelAutoRenewDays] = useState(3);
    const [subLeasing, setSubLeasing] = useState(false);
    const [additionalConditionsAvailable, setAdditionalConditionsAvailable] = useState(true);
    const [firstCustomCondition, setFirstCustomCondition] = useState('');
    const [additionalConditions, setAdditionalConditions] = useState([]);
    const [commercialContractTermsTypeUnified, setCommercialContractTermsTypeUnified] = useState(true);



    const nextPage = () => {
        if (currentPageNum <= 10) {
            if (currentPageNum === 9 && contractFinanceRecordEditContext.contractTypeHousing) {
                setCurrentPageNum(11);
            } else {
                setCurrentPageNum(prev => prev + 1);
            }
        } else {
            setCurrentPageNum(11);
            handleSaveContract();
        }
    }
    const prevPage = () => {
        if (currentPageNum >= 2) {
            if (currentPageNum === 11 && contractFinanceRecordEditContext.contractTypeHousing) {
                setCurrentPageNum(9);
            } else {
                setCurrentPageNum(prev => prev - 1);
            }
        } else {
            setCurrentPageNum(1);
            navigate("/contracts-draft");
        }
    }

    const handleSaveContract = () => {
        console.log('hi');
    }

    const values = {

        currentPageNum,
        setCurrentPageNum,
        nextPage,
        prevPage,
        handleSaveContract,
        duration: {
            contractTypeHousing: contractFinanceRecordEditContext.contractTypeHousing,
            setContractTypeHousing: contractFinanceRecordEditContext.setContractTypeHousing,
            contractStartDate,
            setContractStartDate,
            contractEndDate,
            setContractEndDate,
        },
        realEstate: {
            ...ownershipDocEditContext,
            ...realEstateDetailsEditContext,
            ...realEstateSharedFacilitiesEditContext,
        },
        unit: {
            ...unitEditContext
        },
        owner: {
            ...ownerDetailsEditContext,
        },
        tenant: {
            ...tenantDetailsEditContext,
        },
        financeRecords: {
            ...contractFinanceRecordEditContext,
        },
        // termsAndConditions:
        isContractAutoRenew,
        setIsContractAutoRenew,
        cancelAutoRenewDays,
        setCancelAutoRenewDays,
        subLeasing,
        setSubLeasing,
        additionalConditionsAvailable,
        setAdditionalConditionsAvailable,
        firstCustomCondition,
        setFirstCustomCondition,
        additionalConditions,
        setAdditionalConditions,
        commercialContractTermsTypeUnified,
        setCommercialContractTermsTypeUnified,
    };

    return (
        <ContractDetailsEditContext.Provider value={values}>
            {children}
        </ContractDetailsEditContext.Provider>
    )
}

