import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { SidebarContext } from '../../../../../context/SidebarContext';

const StyledSublistItem = styled.li`
    ${({ theme, sidebarstate, subMenuItemId }) => {
        if (sidebarstate.isOpen) {
            return css`
                width: 100%;
                padding-right: 2rem;
                padding-top: 0.5rem;
                padding-bottom: 0.5rem;
                position: relative;
                font-size: 14px;

                & > a{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    color: ${sidebarstate.activeLink[1] === subMenuItemId ? theme.colors.primary : theme.colors.text.primary};
                    .hover-submenu{
                        display: inline-block;
                        width: .75rem;
                        height: 0.063rem;
                        background-color: ${theme.colors.primary};
                        margin-left: .75rem;
                        transition: all .5s ease-in-out;
                    }
                }

                &:hover{
                    a{
                        color: ${theme.colors.primary};
                    }
                    a .hover-submenu{
                        width: 1.25rem;
                    }
                }
            `
        } else {
            return css`

            `;
        }
    }}
`;

const SubMenuItem = ({ listItemProp, subListItemProp }) => {
    const sidebarState = useContext(SidebarContext);

    return (
        <StyledSublistItem
            key={subListItemProp.id}
            onClick={() => sidebarState.toggleActiveLink(listItemProp, subListItemProp)}
            sidebarstate={sidebarState}
            subMenuItemId={subListItemProp.id}
        >
            <Link
                to={subListItemProp.link}
            >
                <span className='hover-submenu'></span>
                <span className='link-text'>
                    {subListItemProp.translate}
                </span>
            </Link>
        </StyledSublistItem>
    )
}

export default SubMenuItem;