import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import TableComponent from '../../pages-parts/table/TableComponent';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Entries from '../../pages-parts/table/Entries';
import api from '../../../api/areas'
import SearchBarFilter from '../../pages-parts/table/SearchFilter';


const StyledPermissions = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    padding: 2.5rem 1.75rem;
    .scroll-thumb{
        background-color: ${({ theme }) => theme.colors.primary_8};
    }
`;

const PermissionsPage = () => {
    const location = useLocation();
    const [columns, setColumns] = useState([
    ]);
    const [searchTable, setSearchTable] = useState(() => {
        const filterStorage = JSON.parse(localStorage.getItem(location.pathname + '-filters')) ?? null;
        if (filterStorage !== null && filterStorage.search !== undefined) {
            return filterStorage.search;
        } else {
            return '';
        }
    });
    const [sortTable, setSortTable] = useState(() => {
        const filterStorage = JSON.parse(localStorage.getItem(location.pathname + '-filters')) ?? null;
        if (filterStorage !== null && filterStorage.sortFilter !== undefined) {
            return filterStorage.sortFilter;
        } else {
            return {
                column: '',
                direction: ''
            };
        }
    });
    const [pageNum, setPageNum] = useState(() => {
        return JSON.parse(localStorage.getItem(location.pathname + '-page-num')) ?? 1;
    });


    const [entries, setEntries] = useState(() => {
        return JSON.parse(localStorage.getItem('entries-per-page')) ?? 10;
    })
    const [tableDataUrl, setTableDataUrl] = useState(() => {

        return `/permissions?q=${searchTable}&_page=${pageNum}&_limit=${entries}`;
    })


    useEffect(() => {
        let rulesColumn = [{
            id: 1,
            Header: 'الصلاحيات',
            accessor: "permissionNameTrans",
            sortable: false
        }];
        api.get('/rules').then(results => {

            for (let i = 2; i <= results.data.length + 1; i++) {
                rulesColumn.push({
                    id: i,
                    Header: results.data[i - 2].trans,
                    accessor: "rules",
                    sortable: false,
                    ruleId: results.data[i - 2].id,
                    Cell: e => {
                        return e.value.find(item => item.id === e.cell.column.ruleId).isAllowed ? (
                            <Form.Check
                                type="checkbox"
                                id={`rule-${e.cell.column.ruleId}-permision-${results.data[i - 2].id}`}
                                name={`rule-${e.cell.column.ruleId}-permision-${results.data[i - 2].id}`}
                                defaultChecked={true}
                            />
                        ) : (
                            <Form.Check
                                type="checkbox"
                                id={`rule-${e.cell.column.ruleId}-permision-${results.data[i - 2].id}`}
                                name={`rule-${e.cell.column.ruleId}-permision-${results.data[i - 2].id}`}
                                defaultChecked={false}
                            />
                        )
                    }
                })
            }

            setColumns(rulesColumn);
        })

    }, []);

    useEffect(() => {

        const url = `/permissions?q=${searchTable}&_page=${pageNum}&_limit=${entries}`;
        setTableDataUrl(url);
    }, [pageNum, entries, searchTable]);

    useEffect(() => {
        const localFilter = {
            sortFilter: sortTable,
            search: searchTable,
        }
        localStorage.setItem(location.pathname + '-page-num', pageNum);
        localStorage.setItem('entries-per-page', entries);
        localStorage.setItem(location.pathname + '-filters', JSON.stringify(localFilter));
    }, [tableDataUrl]);
    return (
        <StyledPermissions>
            <Container fluid>
                <Row className='mb-3 flex-lg-row-reverse flex-md-row'>
                    <Col
                        className='d-flex justify-content-end align-items-center mb-2'
                    >
                        <Entries
                            entries={entries}
                            setEntries={setEntries}
                        />
                        <SearchBarFilter
                            setSearchTable={setSearchTable}
                            searchTable={searchTable}
                            setPageNum={setPageNum}
                        />
                    </Col>
                </Row>
                <TableComponent
                    columns={columns}
                    tableDataUrl={tableDataUrl}
                    setSortTable={setSortTable}
                    tableWidth={1000}
                    setPageNum={setPageNum}
                    pageNum={pageNum}
                    tableRowsPerPage={entries}
                />
            </Container>
        </StyledPermissions>
    );
}

export default PermissionsPage;