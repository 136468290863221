import React, { useContext, useEffect, useRef, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';
import Select from 'react-select';
import { Calendar } from 'react-date-range';
import moment from 'moment';
import NumberInput from '../../../../pages-parts/inputs/number-input/NumberInput';
import { ContractFinanceRecordEditContext } from '../ContractFinanceRecordEditContext';


const StyledSection2 = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    margin-bottom: 3rem;
    padding: 1rem 1.75rem;
    background-color: ${({ theme }) => theme.colors.background.elements};
    border-radius: 0.75rem;
    position: relative;
    border: 1px solid #ccc;

    
    
    .infoCheckData{
        display: flex;
        width: auto !important;
    }
    .infoData{
        width: 12rem;
        position: relative;
        

    }
    .static{
        .infoData::after{
            display: flex;
            content: "SAR";
            position: absolute;
            height: 100%;
            align-items: center;
            right: 0;
            top: 0;
            font-weight: 900;
            width: 50px;
            justify-content: center;
            border-left: 1px solid #ccc;
        }
        .myInput{
            padding-right: 55px!important;
        }
    }
    .periodBox{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .infoData2{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 3rem;
            padding: 0.5rem 0;
            margin-top: .5rem;
            border-radius: 0.5rem;
            border: 1px solid #ccc;
            background-color: ${({ theme }) => theme.colors.background.elements};
        }
    }
    span img{
        width: 6rem;
        height: 6rem;
        border-radius: 0.75rem;
    }
`;

const Section2 = () => {
    const {
        electricityFee,
        setElectricityFee,
        electricityFeeValue,
        setElectricityFeeValue,
        gasFee,
        setGasFee,
        gasFeeValue,
        setGasFeeValue,
        waterFee,
        setWaterFee,
        waterFeeValue,
        setWaterFeeValue,
    } = useContext(ContractFinanceRecordEditContext);

    const electricityFeeRef = useRef();
    const handleChangeelectricityFee = () => {
        if (electricityFeeRef.current.checked) {
            setElectricityFee(true);
        } else {
            setElectricityFee(false);
        }
    }

    const gasFeeRef = useRef();
    const handleChangeGasFee = () => {
        if (gasFeeRef.current.checked) {
            setGasFee(true);
        } else {
            setGasFee(false);
        }
    }

    const waterFeeRef = useRef();
    const handleChangeWaterFee = () => {
        if (waterFeeRef.current.checked) {
            setWaterFee(true);
        } else {
            setWaterFee(false);
        }
    }

    return (
        <StyledSection2>
            <h2 className='sectionFloatTitle'>الخدمات</h2>
            {/* electricity fee */}
            <div className={electricityFee ? "static" : ""}>
                <div className='data-line mt-4'>
                    <span className='infoTitle'>
                        رسوم الكهرباء
                    </span>
                    <span className='infoCheckData'>
                        <Form.Check
                            className='me-5 mb-0'
                            type='radio'
                            id='yearly-fixed-electricity'
                            defaultChecked={electricityFee}
                            name='electricity-fee'
                            label='ثابت سنويا'
                            ref={electricityFeeRef}
                            onChange={handleChangeelectricityFee}
                        />
                        <Form.Check
                            className='me-5 mb-0'
                            type='radio'
                            id='meter-reading-electricity'
                            defaultChecked={!electricityFee}
                            name='electricity-fee'
                            label='قراءة عداد'
                            onChange={handleChangeelectricityFee}
                        />
                    </span>
                </div>
                <NumberInput
                    titleText={electricityFee ? "رسوم الكهرباء الثابتة" : "قراءة العداد"}
                    inputName='electricity-fee-value'
                    value={electricityFeeValue}
                    setValue={setElectricityFeeValue}
                />
            </div>
            {/* gas fee */}
            <div className={gasFee ? "static" : ""}>
                <div className='data-line mt-4'>
                    <span className='infoTitle'>
                        رسوم الغاز
                    </span>
                    <span className='infoCheckData'>
                        <Form.Check
                            className='me-5 mb-0'
                            type='radio'
                            id='yearly-fixed-gas'
                            defaultChecked={gasFee}
                            name='gas-fee'
                            label='ثابت سنويا'
                            ref={gasFeeRef}
                            onChange={handleChangeGasFee}
                        />
                        <Form.Check
                            className='me-5 mb-0'
                            type='radio'
                            id='meter-reading-gas'
                            defaultChecked={!gasFee}
                            name='gas-fee'
                            label='قراءة عداد'
                            onChange={handleChangeGasFee}
                        />
                    </span>
                </div>
                <NumberInput
                    titleText={gasFee ? "رسوم الغاز الثابتة" : "قراءة العداد"}
                    inputName='gas-fee-value'
                    value={gasFeeValue}
                    setValue={setGasFeeValue}
                />
            </div>
            {/* water fee */}
            <div className={waterFee ? "static" : ""}>
                <div className='data-line mt-4'>
                    <span className='infoTitle'>
                        رسوم المياه
                    </span>
                    <span className='infoCheckData'>
                        <Form.Check
                            className='me-5 mb-0'
                            type='radio'
                            id='yearly-fixed-water'
                            defaultChecked={waterFee}
                            name='water-fee'
                            label='ثابت سنويا'
                            ref={waterFeeRef}
                            onChange={handleChangeWaterFee}
                        />
                        <Form.Check
                            className='me-5 mb-0'
                            type='radio'
                            id='meter-reading-water'
                            defaultChecked={!waterFee}
                            name='water-fee'
                            label='قراءة عداد'
                            onChange={handleChangeWaterFee}
                        />
                    </span>
                </div>
                <NumberInput
                    titleText={waterFee ? "رسوم المياه الثابتة" : "قراءة العداد"}
                    inputName='gas-fee-value'
                    value={waterFeeValue}
                    setValue={setWaterFeeValue}
                />
            </div>
        </StyledSection2>
    );
}

export default Section2;