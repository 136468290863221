import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { AiOutlineEye } from 'react-icons/ai';
import Moment from 'react-moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import moment from 'moment';
import { ContractDetailsEditContext } from '../../ContractDetailsEditContext';


const StyledSection1 = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    margin-bottom: 3rem;
    padding: 2rem 1.75rem 1rem;
    background-color: ${({ theme }) => theme.colors.background.elements};
    border-radius: 0.75rem;
    position: relative;
    border: 1px solid #ccc;

    p{
        margin: 0;
    }

    .displayCard{
        padding: 1rem;
        border-radius: 0.75rem;
        border: 1px solid #ccc;
        background-color: ${({ theme }) => theme.colors.background.body};

        .tableData.last{
            margin-bottom: 1rem;
        }
    }

    .thirdRow{
        width: 75%;
        .periodBox{
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: auto;

            div{
                text-align: center;
            }
            h4, h5{
                margin-bottom: 0;
            }
            .infoData2{
                padding: 0;
                margin-top: 0.5rem;
            }
        }
    }
`;

const Section1 = () => {
    const { duration } = useContext(ContractDetailsEditContext);
    return (
        <StyledSection1>
            <h2 className='sectionFloatTitle'>الشروط و الاحكام</h2>
            <div className='displayCard w-75'>
                <h5>رسوم توثيق العقد</h5>
                <Row className='tableData'>
                    <Col>01 - 10 - 2021</Col>
                    <Col>125 SAR</Col>
                </Row>
                <Row className='tableData'>
                    <Col>07 - 11 - 2021</Col>
                    <Col>125 SAR</Col>
                </Row>
                <Row className='tableData last'>
                    <Col>22 - 11 - 2021</Col>
                    <Col>125 SAR</Col>
                </Row>
                <Row className='tableData total'>
                    <Col>الاجمالي</Col>
                    <Col>375 SAR</Col>
                </Row>
            </div>
        </StyledSection1>
    );
}

export default Section1;