import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTable } from 'react-table';
import styled from 'styled-components';
import { GoTriangleUp, GoTriangleDown } from 'react-icons/go';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Loading from '../loading/Loading';
import api from '../../../api/areas'
import { useLocation } from 'react-router-dom';
import Scrollbars from 'react-custom-scrollbars-2';
import TablePagination from './TablePagination';

const StyledTable = styled.table`
    font-family: 'islamic';
    border-collapse: collapse;
    min-width: ${({ tableWidth }) => tableWidth + 'px'};
    width: 100%;
    background-color: ${({ theme }) => theme.colors.background.elements};
    border-radius: ${({ hasTotalSection }) => hasTotalSection ? '0.75rem 0.75rem 0 0' : '0.75rem'};

    tbody tr:hover{
        box-shadow: 0 0rem 1.875rem rgba(19, 88, 70, 0.1);
    }
    td, th{
        border: none;
        padding: 0.5rem 0.5rem;
        border-bottom: 0.125rem solid #EEEEEE;
        
        .image-heading-text-link{
            img{
                width: 2.5rem;
                height: 2.5rem;
                border-radius: 0.75rem;
                margin-left: .75rem;
            }

            & > span{
                margin-right: 0.25rem;
                h4{
                    color: #000;
                    margin: 0;
                    font-size: 15px;
                }
                span{
                    color: ${({ theme }) => theme.colors.primary};
                    white-space: nowrap;
                    font-size: 13px;
                }
            }
        }
        .icon-header-text-link{
            .icon{
                color: #000;
                font-size: 2rem;
            }

            & > span{
                margin-right: 0.25rem;
                h4{
                    color: #000;
                    margin: 0;
                    font-size: 14px;
                }
                span{
                    color: ${({ theme }) => theme.colors.primary};
                    font-size: 14px;
                    white-space: nowrap;
                }
            }
        }
        .icon-header-link{
            .icon{
                color: #000;
                font-size: 14px;
            }

            span{
                margin-right: 0.25rem;
                h4{
                    color: #000;
                    margin: 0;
                    font-size: 14px;
                }
            }
        }
        &:last-child{
            padding-left: 1.5rem;
        }
        &:first-child{
            padding-right: 1.5rem;
        }

        &.terms-table{
            vertical-align: baseline;
            /* &:first-child span{
                padding: 2px;
                border: 1px solid #000;
                border-radius: 50%;
                font-size: 14px;
            } */
        }
    }
    tbody tr:last-child td{
        border-bottom: 1rem solid transparent;
    }
    tbody tr td:last-child{
        white-space: nowrap;
    }
    th {
        padding-top: 12px;
        padding-bottom: 12px;
        color: ${({ theme }) => theme.colors.text.secondary};
        white-space: nowrap;
        &.sortable{
            cursor: pointer;
        }
        div{
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .sort-icon{
                color: #CECECE;
                display: flex;
                flex-direction: column;
                margin-right: 0.25rem;

                & > svg:first-child{
                    margin-bottom: -3px;
                }
                & > svg:last-child{
                    margin-top: -3px;
                }
                .active{
                    color: ${({ theme }) => theme.colors.text.secondary};
                }
                .not-active{
                    color: transparent;
                }
            }
        }
    }
    a, span.edit-modal{
        margin-left: .25rem;
        cursor: pointer;
    }
    a:hover, span.edit-modal:hover{
        color: ${({ theme }) => theme.colors.secondary};
        &.delete{
            color: ${({ theme }) => theme.colors.delete};
        }
    }
`;

const StyledTotalSection = styled(Row)`
    background-color: ${({ theme }) => theme.colors.background.elements};
    border-radius: 0 0 0.75rem 0.75rem;
    padding: 1rem 1.5rem;
    font-size: 1rem;
    font-weight: bold;
`;

function TableComponent(
    {
        columns,
        tableDataUrl,
        setSortTable,
        tableWidth,
        setPageNum,
        tableRowsPerPage,
        pageNum,
        tableHaveHead = true,
        hasPagination = true,
        isTermsTable = false,
        hasTotalSection = false,
    }
) {
    const location = useLocation();
    const [tableData, setTableData] = useState([]);
    const columnsData = useMemo(() => columns, [columns]);
    const data = useMemo(() => tableData, [tableData]);
    const [isLoading, setIsLoading] = useState(false);
    const [sortColumn, setSortColumn] = useState(() => {
        const filterStorage = JSON.parse(localStorage.getItem(location.pathname + '-filters')) ?? null;
        if (filterStorage !== null && filterStorage.sortFilter !== undefined) {
            return filterStorage.sortFilter;
        } else {
            return {
                column: '',
                direction: ''
            };
        }
    });
    const tableRef = useRef();
    const [tableHeight, setTableHeight] = useState(632);
    const [totalRowsCount, setTotalRowsCount] = useState(0);

    useEffect(() => {
        setIsLoading(true);
        api.get(tableDataUrl).then(result => {
            setTotalRowsCount(result.headers['x-total-count'])
            setTableData(result.data);
            setIsLoading(false);
        });
    }, [tableDataUrl]);

    useEffect(() => {
        setSortTable(sortColumn);
    }, [sortColumn]);

    useEffect(() => {
        setTableHeight(tableRef.current?.clientHeight);
    }, [tableData])

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = useTable({
        columns: columnsData,
        data
    });

    const SortTable = (columnId) => {
        let result = {}
        const columnToSort = columnsData.find(item => item.id === columnId)
        if (columnToSort.sortable) {
            setSortColumn(prev => {
                if (prev.column !== columnToSort.accessor) {
                    result = {
                        column: columnToSort.accessor,
                        direction: 'asc'
                    }
                } else if (prev.column === columnToSort.accessor && prev.direction === 'asc') {
                    result = {
                        column: columnToSort.accessor,
                        direction: 'desc'
                    }
                } else {
                    result = {
                        column: columnToSort.accessor,
                        direction: 'asc'
                    }
                }
                return result;
            })
        }
    }

    const getSortIcon = (columnId) => {
        const currentColumn = columnsData.find(item => item.id === columnId);
        if (currentColumn.sortable) {
            if (currentColumn.accessor !== sortColumn.column) {
                return (
                    <>
                        <GoTriangleUp />
                        <GoTriangleDown />
                    </>
                )
            } else {
                if (sortColumn.direction === "asc") {
                    return (
                        <>
                            <GoTriangleUp className='active' />
                            <GoTriangleDown className='not-active' />
                        </>
                    )
                } else {
                    return (
                        <>
                            <GoTriangleUp className='not-active' />
                            <GoTriangleDown className='active' />
                        </>
                    )
                }
            }
        }
    }

    return (
        <Row className='position-relative'>
            {isLoading ? <Loading /> : null}
            <Col>
                <Scrollbars
                    style={{ width: "100%", height: tableHeight }}
                    className='table-scroll'
                    renderTrackVertical={props => (
                        <div {...props} style={{
                            ...props.style,
                            left: 2,
                            bottom: 20,
                            top: 20,
                            right: 'auto',
                            borderRadius: 3,
                        }} />
                    )}
                    renderView={props => (
                        <div {...props} style={{
                            ...props.style,
                            marginLeft: props.style.marginRight,
                            marginRight: 0,
                        }} />
                    )}
                    renderTrackHorizontal={({ style, ...props }) =>
                        <div {...props} style={{ ...style, bottom: '0', borderRadius: '3px', width: '100%', height: '6px', backgroundColor: 'transparent' }} />
                    }
                    renderThumbHorizontal={({ style, ...props }) =>
                        <div {...props} style={{ ...style, height: '6px', borderRadius: '4px', boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.16)' }} className='scroll-thumb' />
                    }
                >
                    <StyledTable {...getTableProps()} ref={tableRef} tableWidth={tableWidth} hasTotalSection={hasTotalSection}>
                        <thead className={tableHaveHead ? '' : 'd-none'}>
                            {
                                headerGroups.map(group => (
                                    <tr {...group.getHeaderGroupProps()}>
                                        {
                                            group.headers.map(column => (
                                                <th {...column.getHeaderProps()} onClick={() => SortTable(column.id)} className={column.sortable ? 'sortable' : ''}>
                                                    <div>
                                                        {column.render('Header')}
                                                        <span className='sort-icon'>
                                                            {
                                                                getSortIcon(column.id)
                                                            }
                                                        </span>
                                                    </div>
                                                </th>
                                            ))
                                        }
                                    </tr>
                                ))
                            }

                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {
                                rows.map(row => {
                                    prepareRow(row);
                                    return (
                                        <tr {...row.getRowProps()}>
                                            {
                                                row.cells.map(cell => {
                                                    if (isTermsTable) {
                                                        return (
                                                            <td {...cell.getCellProps()} className='terms-table'>
                                                                <span>
                                                                    {cell.render('Cell')}
                                                                </span>
                                                            </td>
                                                        )
                                                    } else {
                                                        return (
                                                            <td {...cell.getCellProps()}>
                                                                {cell.render('Cell')}
                                                            </td>
                                                        )
                                                    }
                                                })
                                            }
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </StyledTable>
                </Scrollbars>
                {
                    hasTotalSection ? (
                        <StyledTotalSection>
                            <Col>
                                الاجمالي(4)
                            </Col>
                            <Col className="text-end">
                                2500SAR
                            </Col>
                        </StyledTotalSection>
                    ) : ""
                }
                {hasPagination ? (
                    <TablePagination
                        setPageNum={setPageNum}
                        pageNum={pageNum}
                        totalRowsCount={totalRowsCount}
                        tableRowsPerPage={tableRowsPerPage}
                    />
                ) : ''}

            </Col>
        </Row>
    );
}

export default TableComponent;