import styled from "styled-components"
import { BsSearch } from 'react-icons/bs';
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const StyledPaginationArea = styled(Row)`
    margin-top: 1rem!important;
    
    .previous-btn,
    .next-btn,
    .pageNum{
        padding: .5rem .75rem;
        color: ${({ theme }) => theme.colors.secondary};
        border: 1px solid ${({ theme }) => theme.colors.secondary};
        border-radius: .75rem;
        margin: 0 .25rem;

        &:hover,
        &.active{
            background-color: ${({ theme }) => theme.colors.secondary};
            color: #fff;
        }
    }

    .previous-btn,
    .next-btn{
        &.disable{
            color: ${({ theme }) => theme.colors.text.primary};
            border-color: ${({ theme }) => theme.colors.text.primary};

            &:hover{
                color: ${({ theme }) => theme.colors.text.primary};
                background-color: transparent;
            }
        }
    }
`

const TablePagination = ({ setPageNum, totalRowsCount, tableRowsPerPage, pageNum }) => {
    const location = useLocation();
    const [pagesCount, setPagesCount] = useState(() => {
        return Math.ceil(totalRowsCount / tableRowsPerPage);
    });

    useEffect(() => {
        setPagesCount(prev => {
            return Math.ceil(totalRowsCount / tableRowsPerPage);
        })
    }, [pageNum, totalRowsCount, tableRowsPerPage])

    const getPagesList = () => {
        let pageList = []
        for (let i = 1; i <= pagesCount; i++) {
            pageList.push(i);
        }
        return (
            <>
                <div
                    className={`previous-btn ${pageNum === 1 ? 'disable' : ''}`}
                    onClick={() => setCurrentPages(pageNum - 1)}
                >
                    السابق
                </div>
                {
                    pageNum > 2 ? <div className="pageNum numbers-font" onClick={() => setCurrentPages(1)}>1</div> : ''
                }
                {
                    pageList.map(pageItem => {
                        if (
                            pageItem === pageNum ||
                            pageItem === (pageNum - 1) ||
                            pageItem === (pageNum + 1)
                        ) {
                            return (
                                <div
                                    className={
                                        `pageNum numbers-font ${pageItem === pageNum ? 'active' : ''}`
                                    }
                                    onClick={() => setCurrentPages(pageItem)}
                                    key={pageItem}
                                >
                                    {pageItem}
                                </div>
                            )
                        }
                    })
                }
                {
                    pageNum < (pagesCount - 1) ? <div className="pageNum numbers-font" onClick={() => setCurrentPages(pagesCount)}>{pagesCount}</div> : ''
                }
                <div
                    className={`next-btn ${pageNum === pagesCount ? 'disable' : ''}`}
                    onClick={() => setCurrentPages(pageNum + 1)}
                >
                    التالي
                </div>
            </>
        )
    }

    const setCurrentPages = (num) => {
        if (!(num > pagesCount || num < 1)) {
            setPageNum(num);
        }
    }

    return (
        <StyledPaginationArea>
            <Col className="d-flex align-items-center flex-row-reverse justify-content-end">
                {getPagesList()}
            </Col>
            <Col className="d-flex align-items-center justify-content-end">
                <p className="mb-0 numbers-font">من {((pageNum - 1) * tableRowsPerPage) + 1} الي {pageNum === pagesCount ? totalRowsCount : pageNum * tableRowsPerPage} - عدد النتائج ({totalRowsCount})</p>
            </Col>
        </StyledPaginationArea>
    )
}

export default TablePagination;