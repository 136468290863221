import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import styled, { css } from "styled-components";
import Section1 from "./sections/Section-1";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { AlertContext } from "../../../../context/AlertContext";
import { useContext, useEffect, useState } from "react";
import Section2 from "./sections/Section-2";
import { RealEstateDetailsEditContext, RealEstateDetailsEditContextProvider } from "./RealEstateDetailsEditContext";

const StyledRealEstateDetailsEdit = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    padding: 2.5rem 1.75rem;

`;

const RealEstateDetailsEditContent = () => {
    const [params, setParams] = useSearchParams();
    const {
        getRealEstateData,
    } = useContext(RealEstateDetailsEditContext);

    useEffect(() => {
        getRealEstateData(params.get('real_id'))
    }, [])

    const handleSave = () => {
        console.log('hi');
    }

    return (
        <StyledRealEstateDetailsEdit>
            <Container fluid>
                <Section1 />
                <Section2 />

                <Row className="mt-3">
                    <Col className='d-flex align-items-center justify-content-end py-2'>
                        <Link to={`/real-estate-view?id=${params.get('real_id')}`} className="py-2 px-3 return-link me-2">
                            رجوع
                        </Link>
                        <span onClick={handleSave} className="py-2 px-3 save-link">
                            حفظ
                        </span>
                    </Col>
                </Row>
            </Container>
        </StyledRealEstateDetailsEdit>
    )
}

const RealEstateDetailsEdit = () => {
    const [params, setParams] = useSearchParams();

    return (
        <RealEstateDetailsEditContextProvider gettingRealEstateId={false} realEstateId={params.get('real_id')}>
            <RealEstateDetailsEditContent />
        </RealEstateDetailsEditContextProvider>
    )
}

export default RealEstateDetailsEdit;