import React, { useContext, useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from 'styled-components';
import { UserContext } from '../../../../context/UserContext';
import AsyncSelect from 'react-select/async';
import api from '../../../../api/areas'
import Select from 'react-select';
import { customSelectStyles } from '../../../../styles/Global';

const StyledSection2 = styled.div`
    width: 100%;
    padding: 2.5rem 1.75rem;
    background-color: ${({ theme }) => theme.colors.background.elements};
    border-radius: .75rem;
`;

const Section2 = () => {
    const userContext = useContext(UserContext);
    const [cities, setCities] = useState([]);
    const [selectedCities, setSelectedCities] = useState([{
        value: 6,
        label: 'Cairo',
    }]);
    const [zones, setZones] = useState([]);
    const [selectedZones, setSelectedZones] = useState([]);

    useEffect(() => {
        getZonesGroupedByCity(selectedCities);

        const respond = api.get(`/areas?_page=1`, {
            'content-type': 'application/json',
            'accept': 'application/json',
            'accept-language': 'en'
        }).then(res => {
            let values = [];
            res.data.forEach(item => {
                const option = {
                    value: item.id,
                    label: item.area,
                }
                values.push(option);
            });
            setCities(values);
        }).catch(err => {
            console.log(err)
        });
    }, []);

    const handleChange = async (selectOption) => {
        setSelectedCities(selectOption);

        getZonesGroupedByCity(selectOption);

        // setZones(zonesGroup);
    }
    const loadOptions = async (searchValue, callback) => {
        try {
            const respond = await api.get(`/areas?q=${searchValue}&_page=1`, {
                'content-type': 'application/json',
                'accept': 'application/json',
                'accept-language': 'en'
            }).then(res => {
                let values = [];
                res.data.forEach(item => {
                    const option = {
                        value: item.id,
                        label: item.area,
                    }
                    values.push(option);
                });
                callback(values)
            }).catch(err => {
                console.log(err)
            });
        } catch (err) {
            console.log(err)
        }

    }

    const getZonesGroupedByCity = async (cities) => {
        let zonesGroup = [];
        for (let i = 0; i < cities.length; i++) {
            const zonesForCity = await api.get(`/cities?area_id=${cities[i].value}&_page=1`).then(res => {
                let values = [];
                const items = res.data;
                for (let x = 0; x < items.length; x++) {
                    const option = {
                        value: items[x].id,
                        label: items[x].city,
                        city_id: items[x].area_id,
                    }
                    values.push(option);
                };
                zonesGroup.push({
                    label: cities[i].label,
                    options: values,
                });
            }).catch(err => {
                console.log(err)
            });
        }
        setZones(zonesGroup);
        return zonesGroup
    }
    return (
        <StyledSection2>
            <Row>
                <Col md='12'>
                    <Row className='mb-2'>
                        <Col xs='6' sm='4' md='3'>منطقة الاشراف</Col>
                        <Col xs='6' sm='8' md='9'>
                            <AsyncSelect
                                value={selectedCities}
                                loadOptions={loadOptions}
                                onChange={handleChange}
                                styles={customSelectStyles}
                                isMulti
                                defaultOptions={cities}
                            />
                        </Col>
                    </Row>
                    <Row className='mb-2'>
                        <Col xs='6' sm='4' md='3'>مدينة الاشراف</Col>
                        <Col xs='6' sm='8' md='9'>
                            <Select
                                isMulti
                                closeMenuOnSelect={false}
                                styles={customSelectStyles}
                                options={zones}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </StyledSection2>
    );
}

export default Section2;