import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import styled, { css } from "styled-components";
import Section1 from "./sections/Section-1";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { AlertContext } from "../../../../context/AlertContext";
import { useContext, useEffect, useState } from "react";
import { RealEstateSharedFacilitiesEditContext, RealEstateSharedFacilitiesEditContextProvider } from "./RealEstateSharedFacilitiesEditContext";

const StyledRealEstateSharedFacilitiesEdit = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    padding: 2.5rem 1.75rem;

`;

const RealEstateSharedFacilitiesEditContent = () => {
    const [params, setParams] = useSearchParams();

    const {
        getRealEstateFacilitiesData
    } = useContext(RealEstateSharedFacilitiesEditContext);

    useEffect(() => {
        getRealEstateFacilitiesData(params.get('real_id'))
    }, [])
    
    const handleSave = () => {
        console.log('hi');
    }

    return (
        <StyledRealEstateSharedFacilitiesEdit>
            <Container fluid>
                <Section1 />

                <Row className="mt-3">
                    <Col className='d-flex align-items-center justify-content-end py-2'>
                        <Link to={`/real-estate-view?id=${params.get('real_id')}`} className="py-2 px-3 return-link me-2">
                            رجوع
                        </Link>
                        <span onClick={handleSave} className="py-2 px-3 save-link">
                            حفظ
                        </span>
                    </Col>
                </Row>
            </Container>
        </StyledRealEstateSharedFacilitiesEdit>
    );
}

const RealEstateSharedFacilitiesEdit = () => {
    
    return (
        <RealEstateSharedFacilitiesEditContextProvider>
            <RealEstateSharedFacilitiesEditContent />
        </RealEstateSharedFacilitiesEditContextProvider>
    )
}

export default RealEstateSharedFacilitiesEdit;