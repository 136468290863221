import React, { useContext, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useSearchParams, Link, useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';
import api from '../../../api/areas';
import { AlertContext } from '../../../context/AlertContext';
import Section2 from './section-2/Section-2';

const StyledOwnersFinancialRecords = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    padding: 2.5rem 1.75rem;
    color: ${({ theme }) => theme.colors.text.secondary};
    
    .row{
        margin: 0;
    }

    .box{
        padding: 0.3125rem 1rem;
        display: inline-block;
        border: 1px solid #ccc;
        border-radius: 1rem;
    }
`;

const OwnersFinancialRecordsPage = ({ userType }) => {
    const [params, setParams] = useSearchParams();
    const [userData, setUserData] = useState({});
    const navigate = useNavigate();
    const alertContext = useContext(AlertContext);

    useEffect(() => {
        api.get(`/owners?id=${params.get('id')}`).then(result => {
            setUserData(result.data[0]);
            if (result.data.length === 0) {
                alertContext.toggleAlert(true);
                alertContext.toggleAlertData({
                    heading: 'مالك / ممثل مجهول',
                    message: 'لم يتم العثور علي اي مالك / ممثل مطابق لطلبك',
                    variant: 'warning',
                });
                navigate("/");
            }

        });
    }, [])

    return (
        <StyledOwnersFinancialRecords>
            <Container fluid className='p-0'>

                <Row>
                    <Col className='text-center mb-3'>
                        <span className='box'>{userData.ownerOrRepresentative}&nbsp;/&nbsp;{userData.userType}</span>
                    </Col>
                </Row>

                {userData === undefined || userData.id === undefined ? '' : (
                    <Section2 userData={userData} />
                )}
            </Container>
        </StyledOwnersFinancialRecords>
    );
}

export default OwnersFinancialRecordsPage;