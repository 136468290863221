import React, { useContext } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from 'styled-components';
import { TenantDetailsEditContext } from '../edit/TenantDetailsEditContext';
import SingleEscort from './SingleEscort';
import { Link, useSearchParams } from 'react-router-dom';
import Scrollbars from 'react-custom-scrollbars-2';

const StyledEscortDetails = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    padding: 2.5rem 1.75rem;

    .container-fluid > *:last-child {
        margin-bottom: 0;
    }

    .saveSection{
        max-width: 1024px;
        margin: 0 auto 3rem;
    }

`;


const SingleEscortDetailsEditContent = () => {
    const [params, setParams] = useSearchParams();
    const {
        escorts,
    } = useContext(TenantDetailsEditContext);

    return (
        <div>
            {escorts.map((item, index) => {
                if (item.id === params.get('escortId')) {
                    return (
                        <SingleEscort key={item.id} item={item} index={index + 1} isTenantInvitation={true} />
                    )
                }
            })}
        </div>
    )
}

const SingleEscortDetailsEdit = () => {
    const [params, setParams] = useSearchParams();

    const handleSave = () => {
        console.log('hi');
    }
    return (
        <Scrollbars
            renderView={props => (
                <div {...props} style={{
                    ...props.style,
                    marginLeft: props.style.marginRight,
                    marginRight: 0,
                }} />
            )}
            renderTrackVertical={props => (
                <div {...props} style={{
                    ...props.style,
                    left: 2,
                    bottom: 20,
                    top: 20,
                    right: 'auto',
                    borderRadius: 3,
                }} />
            )}
            style={{ width: "100%", height: "calc(100vh - 5rem)" }}
        >
            <StyledEscortDetails>
                <Container fluid>
                    <SingleEscortDetailsEditContent />

                    <Row className="mt-3 saveSection">
                        <Col className='d-flex align-items-center justify-content-end p-0'>
                            <Link
                                to={`/tenant/view?id=${params.get('id')}`}
                                className="py-2 px-3 return-link"
                            >
                                رجوع
                            </Link>
                            <span onClick={handleSave} className="py-2 px-3 save-link ms-2">
                                حفظ
                            </span>
                        </Col>
                    </Row>
                </Container>
            </StyledEscortDetails>
        </Scrollbars>
    );
}

export default SingleEscortDetailsEdit;