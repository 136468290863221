import React, { useContext, useEffect, useRef, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';

const StyledSection1 = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    padding: 1rem 1.75rem;
    background-color: ${({ theme }) => theme.colors.background.elements};
    border-radius: 0.75rem;
    position: relative;
    border: 1px solid #ccc;

    
    
    .infoTitle{
        display: inline-block;
        width: 21rem;
        margin-left: .5rem;
        font-size: 14px;
        color: ${({ theme }) => theme.colors.text.secondary};
    }
    .infoData{
        width: 12rem;
    }

    span img{
        width: 6rem;
        height: 6rem;
        border-radius: 0.75rem;
    }
`;

const Section1 = () => {

    return (
        <StyledSection1 className='mb-5'>
            <h2 className='sectionFloatTitle'>رسوم العقد</h2>
            <Row>
                <Col xs='12' sm='9'>
                    <div className='mt-2 data-line'>
                        <span className='infoTitle'>
                            رسوم تسجيل عقد سكني علي منصة ايجار (سنويا)
                        </span>
                        <span className='infoData'>
                            <Form.Control
                                className='py-2 px-3'
                                type='text'
                                name='name'
                                defaultValue="45"
                            />
                        </span>
                    </div>
                    <div className='mt-2 data-line'>
                        <span className='infoTitle'>
                            رسوم تسجيل عقد تجاري علي منصة ايجار (سنويا)
                        </span>
                        <span className='infoData'>
                            <Form.Control
                                className='py-2 px-3'
                                type='text'
                                name='name'
                                defaultValue="75"
                            />
                        </span>
                    </div>
                    <div className='mt-2 data-line'>
                        <span className='infoTitle'>
                            رسوم تسجيل عقد سكني علي منصة عاقد (سنويا)
                        </span>
                        <span className='infoData'>
                            <Form.Control
                                className='py-2 px-3'
                                type='text'
                                name='name'
                                defaultValue="35"
                            />
                        </span>
                    </div>
                    <div className='mt-2 data-line'>
                        <span className='infoTitle'>
                            رسوم تسجيل عقد تجاري علي منصة عاقد (سنويا)
                        </span>
                        <span className='infoData'>
                            <Form.Control
                                className='py-2 px-3'
                                type='text'
                                name='name'
                                defaultValue="65"
                            />
                        </span>
                    </div>
                </Col>
            </Row>
        </StyledSection1>
    );
}

export default Section1;