import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { FaBed, FaBath } from 'react-icons/fa';
import { MdOutlineLiving } from 'react-icons/md';
import Moment from 'react-moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import moment from 'moment';
import AddNewButton from '../../../pages-parts/inputs/add-new-btn/AddNewButton';


const StyledSection2 = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    margin-bottom: 3rem;
    padding: 1rem 1.75rem;
    background-color: ${({ theme }) => theme.colors.background.elements};
    border-radius: 0.75rem;
    position: relative;
    border: 1px solid #ccc;


    .headContainer{
        border-bottom: 1px solid #ccc;
        p{
            color: ${({ theme }) => theme.colors.text.primary};
        }
    }
    p{
        margin: 0;
    }
    
    .infoData{
        padding: .75rem 3rem;
        border-radius: 0.75rem;
        border: 1px solid #ccc;
    }

    
    .secondRow{
        width: 50%;
        .periodBox{
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: auto;

            div{
                text-align: center;
            }
            h4, h5{
                margin-bottom: 0;
            }
            .infoData2{
                padding: 0;
                margin-top: 0.5rem;
            }
        }
    }
    .amenitiesBox{
        display: flex;
        .amenities{
            padding: .25rem .75rem;
            border-radius: 0.5rem;
            border: 1px solid ${({ theme }) => theme.colors.secondary};
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            margin-left: 1rem;
            .icon{
                font-size: 1.5rem;
                color: ${({ theme }) => theme.colors.secondary};
                margin-left: .5rem;
            }
            span{
    
            }
        }
    }
`;

const Section2 = ({ contractData }) => {


    return (
        <StyledSection2>
            <h2 className='sectionFloatTitle'>تفاصيل العقار</h2>

            <Row className='my-3 headContainer'>
                <Col className='mb-2'>
                    <h4 className='mb-0'>اسم العقار</h4>
                    <p>
                        سند الملكية
                    </p>
                </Col>
                <Col className='mb-2 text-end'>
                    <AddNewButton
                        title="تفاصيل"
                        url='/real-estate-view?id=9e0af4be-e398-470a-9efa-834f91117c85'
                        isLink={true}
                        hasIcon={false}
                    />
                </Col>
            </Row>
            <Row className='my-3 secondRow'>
                <Col className='periodBox align-items-start'>
                    <div>
                        <h5>نوع العقار</h5>
                        <p className='infoData2'>
                            عمارة
                        </p>
                    </div>
                </Col>
                <Col className='periodBox align-items-center'>
                    <div>
                        <h5>استخدام العقار</h5>
                        <p className='infoData2'>
                            {contractData.contractType}
                        </p>
                    </div>
                </Col>
                <Col className='periodBox align-items-end'>
                    <div>
                        <h5>تاريخ البناء</h5>
                        <p className='infoData2 numbers-font'>
                            {moment(contractData.createDate).subtract(3, "years").format('ll')}
                        </p>
                    </div>
                </Col>
            </Row>
            <div className='mt-4 mb-3 amenitiesBox'>
                <div className='amenities'>
                    <FaBed className='icon' />
                    <span><span className='numbers-font'>3</span> غرف نوم</span>
                </div>
                <div className='amenities'>
                    <FaBath className='icon' />
                    <span><span className='numbers-font'>2</span> حمامات</span>
                </div>
                <div className='amenities'>
                    <MdOutlineLiving className='icon' />
                    <span><span className='numbers-font'>1</span> غرف معيشة</span>
                </div>
                <div className='amenities'>
                    <FaBed className='icon' />
                    <span><span className='numbers-font'>3</span> غرف نوم</span>
                </div>
                <div className='amenities'>
                    <FaBath className='icon' />
                    <span><span className='numbers-font'>2</span> حمامات</span>
                </div>
            </div>
        </StyledSection2>
    );
}

export default Section2;