import React, { useContext } from 'react';
import styled from 'styled-components';
import { TenantDetailsEditContext } from '../../edit/TenantDetailsEditContext';
import { useNavigate, useSearchParams } from 'react-router-dom';
import EscortCard from './EscortCard';
import AddNewButton from '../../../../pages-parts/inputs/add-new-btn/AddNewButton';

const StyledSection2 = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    margin-top: 3rem;
    padding: 2rem 1.75rem 1rem;
    background-color: ${({ theme }) => theme.colors.background.elements};
    border-radius: 0.75rem;
    position: relative;
    border: 1px solid #ccc;
    max-width: 1024px;
    margin: 0 auto 3rem;

    
    p{
        margin-bottom: 0;
    }
    
    
    .infoData{
        width: 12rem;
        position: relative;
        
    }
    .displayCard{
        padding: 1rem;
        border-radius: 0.75rem;
        border: 1px solid #ccc;
        background-color: ${({ theme }) => theme.colors.background.body};

        &.import{
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            transition: all .125s ease-in-out;

            h4{
                margin-bottom: 0;
            }
            .icon{
                font-size: 1.5rem;
                font-weight: 700;
            }

            &:hover{
                color: ${({ theme }) => theme.colors.secondary};
            }
        }
    }
    span img{
        width: 6rem;
        height: 6rem;
        border-radius: 0.75rem;
    }
`;

const Section2 = () => {
    const [params, setParams] = useSearchParams();
    const navigate = useNavigate();
    const {
        escorts
    } = useContext(TenantDetailsEditContext);

    const addNewEscortLink = () => {
        navigate(`/tenant/escort-new?id=${params.get('id')}`)
    }
    return (
        <StyledSection2>
            <h2 className='sectionFloatTitle'>المرافقين</h2>
            <div className='mb-3'>
                {
                    escorts.map(item => {
                        return (
                            <EscortCard key={item.id} escortData={item} />
                        )
                    })
                }
            </div>
            <AddNewButton
                onClickFunction={addNewEscortLink}
                title="إضافة مرافق أخر"
            />
        </StyledSection2>
    );
}

export default Section2;