import React, { useContext, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { OwnershipDocEditContext } from '../../real-estates/ownership-document-edit/OwnershipDocEditContext';
import OwnershipDoc from '../../real-estates/ownership-document-edit/sections/Section-1';
import RealEstateDetailsEdit from '../../real-estates/real-estate-details-edit/sections/Section-1';
import RealEstateAddressEdit from '../../real-estates/real-estate-details-edit/sections/Section-2';
import RealEstateSharedFacilitiesEdit from '../../real-estates/real-estate-shared-facilities-edit/sections/Section-1';


const StyledRealEstateEdit = styled.div`
    & > *:not(:last-child){
        margin-bottom: 3rem;
    }
`;


const RealEstateEdit = () => {

    return (
        <StyledRealEstateEdit>
            <OwnershipDoc />
            <RealEstateDetailsEdit />
            <RealEstateAddressEdit />
            <RealEstateSharedFacilitiesEdit />
        </StyledRealEstateEdit>
    );
}

export default RealEstateEdit;