import React, { useContext, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from 'styled-components';
import moment from 'moment';
import StaticSelect from '../../../../pages-parts/inputs/static-select-input/StaticSelect';
import DatePickerInput from '../../../../pages-parts/inputs/date-picker/DatePickerInput';
import NumberInput from '../../../../pages-parts/inputs/number-input/NumberInput';
import TextInput from '../../../../pages-parts/inputs/text-input/TextInput';
import { RealEstateDetailsEditContext } from '../RealEstateDetailsEditContext';

const StyledSection1 = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    padding: 1rem 1.75rem;
    background-color: ${({ theme }) => theme.colors.background.elements};
    border-radius: 0.75rem;
    position: relative;
    border: 1px solid #ccc;

    
    
    .infoData{
        width: 12rem;

    }

    span img{
        width: 6rem;
        height: 6rem;
        border-radius: 0.75rem;
    }
`;

const Section1 = () => {

    const {
        realEstateTypeOptions,
        realEstateTypeOption,
        handleChangeRealEstateTypeOption,
        realEstateUseOptions,
        realEstateUseOption,
        handleChangeRealEstateUseOption,
        realEstateNumber,
        setRealEstateNumber,
        constructionDate,
        setConstructionDate,
        floorsNumber,
        setFloorsNumber,
        unitsBerFloorNumber,
        setUnitsBerFloorNumber,
        realEstateName,
        setRealEstateName,
    } = useContext(RealEstateDetailsEditContext);

    return (
        <StyledSection1>
            <h2 className='sectionFloatTitle'>تفاصيل العقار</h2>
            <Row>
                <Col>
                    <StaticSelect
                        titleText='نوع العقار'
                        selectedOption={realEstateTypeOption}
                        options={realEstateTypeOptions}
                        customStylesProp={{
                            has: false,
                            style: {}
                        }}
                        handleChangeSelect={handleChangeRealEstateTypeOption}
                    />
                    <StaticSelect
                        titleText='استخدام العقار'
                        selectedOption={realEstateUseOption}
                        options={realEstateUseOptions}
                        customStylesProp={{
                            has: false,
                            style: {}
                        }}
                        handleChangeSelect={handleChangeRealEstateUseOption}
                    />
                    <NumberInput
                        titleText='رقم العقار'
                        inputName='real-estate-number'
                        value={realEstateNumber}
                        setValue={setRealEstateNumber}
                    />
                    <DatePickerInput
                        titleText='تاريخ الانشاء'
                        date={constructionDate}
                        setDate={setConstructionDate}
                    />
                    <NumberInput
                        titleText='عدد الادوار'
                        inputName='floors-number'
                        value={floorsNumber}
                        setValue={setFloorsNumber}
                    />
                    <NumberInput
                        titleText='عدد الوحدات لكل دور'
                        inputName='units-ber-floor-number'
                        value={unitsBerFloorNumber}
                        setValue={setUnitsBerFloorNumber}
                    />
                    <TextInput
                        titleText='الاسم'
                        inputName='name'
                        value={realEstateName}
                        setValue={setRealEstateName}
                    />
                </Col>
            </Row>
        </StyledSection1>
    );
}

export default Section1;