import React, { useContext, useEffect, useRef, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';
import Select from 'react-select';
import { Calendar } from 'react-date-range';
import { TfiAngleUp } from 'react-icons/tfi';
import moment from 'moment';
import StaticSelect from '../../../../pages-parts/inputs/static-select-input/StaticSelect';
import TextInput from '../../../../pages-parts/inputs/text-input/TextInput';
import NumberInput from '../../../../pages-parts/inputs/number-input/NumberInput';
import DatePickerInput from '../../../../pages-parts/inputs/date-picker/DatePickerInput';
import { OwnerDetailsEditContext } from '../OwnerDetailsEditContext';
import EmailInput from '../../../../pages-parts/inputs/email-input/EmailInput';
import PhoneNumber from '../../../../pages-parts/inputs/phone-input/PhoneNumber';
import CustomFieldModal from '../../../../pages-parts/inputs/custom-field/CustomFieldModal';

const StyledSection5 = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    margin-bottom: 0;
    padding: 2rem 1.75rem 1rem;
    background-color: ${({ theme }) => theme.colors.background.elements};
    border-radius: 0.75rem;
    position: relative;
    border: 1px solid #ccc;

    
    p{
        margin-bottom: 0;
    }
    .data-line{
        display: flex;
        align-items: center;
        margin-bottom: .5rem;
        &:first-child{
            margin-top: 0!important;
        }
        &:last-child{
            margin-bottom: 0;
        }
    }
    .infoTitle{
        display: inline-block;
        min-width: 12rem;
        margin-left: .5rem;
        font-size: 14px;
        color: ${({ theme }) => theme.colors.text.secondary};
    }
    .infoData{
        width: auto;
        position: relative;
        
        .phoneNumber{
            display: inline;
            width: 12rem;
            margin-left: 0;
            border-left: none;
            border-radius: 0 .375rem .375rem 0;
        }
    }
    .displayCard{
        padding: 1rem;
        border-radius: 0.75rem;
        border: 1px solid #ccc;
        background-color: ${({ theme }) => theme.colors.background.body};
        margin-bottom: 1rem;

        .header{
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            transition: all .5s linear, color .125s;
            font-size: 1.5rem;
            border-bottom: 1px solid #ccc;
            margin-bottom: 1rem;
            padding-bottom: 0.75rem;            
            &.closed{
                margin-bottom: 0;
                padding-bottom: 0;
                border: none;
                
                .icon{
                    transform: rotate(180deg);
                }
            }
            h4{
                margin-bottom: 0;
            }
            .icon{
                font-weight: 700;
                transition: all .5s linear, color .125s;
            }

            &:hover{
                color: ${({ theme }) => theme.colors.secondary};
            }
        }

        .body{
            transition: all .5s linear;
            overflow: hidden;
            max-height: 15rem;

            &.closed{
                max-height: 0;
            }
        }
    }
    span img{
        width: 6rem;
        height: 6rem;
        border-radius: 0.75rem;
    }
`;

const Section5 = () => {
    const {
        creditCards,
        addNewCreditCard,
    } = useContext(OwnerDetailsEditContext);

    const openCard = (e, id) => {
        if (e.currentTarget.classList.contains('closed')) {
            e.currentTarget.classList.remove('closed');
            document.getElementById(id).classList.remove('closed');
        } else {
            e.currentTarget.classList.add('closed');
            document.getElementById(id).classList.add('closed');
        }
    }
    return (
        <StyledSection5>
            <h2 className='sectionFloatTitle'>معلومات البطاقة الائتمانية</h2>
            {
                creditCards.map(item => (
                    <div key={item.id} className='displayCard'>
                        <div className='header' onClick={(e) => openCard(e, item.id)}>
                            {item.cardName} <TfiAngleUp className='icon' />
                        </div>
                        <div className='body' id={item.id}>
                            <div className="data-line">
                                <span className='infoTitle'>
                                    رقم حساب الايبان
                                </span>
                                <span className='infoData'>
                                    {item.ibanNumber}
                                </span>
                            </div>
                            <div className="data-line">
                                <span className='infoTitle'>
                                    اسم صاحب الحساب البنكي
                                </span>
                                <span className='infoData'>
                                    {item.cardOwnerName}
                                </span>
                            </div>
                            <div className="data-line">
                                <span className='infoTitle'>
                                    اسم البنك
                                </span>
                                <span className='infoData'>
                                    {item.bankName}
                                </span>
                            </div>
                            <div className="data-line">
                                <span className='infoTitle'>
                                    كود السويفت
                                </span>
                                <span className='infoData'>
                                    {item.swiftCode}
                                </span>
                            </div>
                            <div className="data-line">
                                <span className='infoTitle'>
                                    عنوان البنك
                                </span>
                                <span className='infoData'>
                                    {item.bankAddress}
                                </span>
                            </div>
                        </div>
                    </div>
                ))
            }
            <CustomFieldModal
                text='إضافة بطاقة جديدة'
                modleContent={{
                    title: 'إضافة بطاقة جديدة',
                    inputs: [
                        {
                            inputType: 'text',
                            inputName: 'card-name',
                            inputPlaceholder: 'اسم البطاقة'
                        },
                        {
                            inputType: 'number',
                            inputName: 'iban-number',
                            inputPlaceholder: 'رقم حساب الايبان'
                        },
                        {
                            inputType: 'text',
                            inputName: 'card-owner-name',
                            inputPlaceholder: 'اسم صاحب الحساب البنكي'
                        },
                        {
                            inputType: 'text',
                            inputName: 'bank-name',
                            inputPlaceholder: 'اسم البنك'
                        },
                        {
                            inputType: 'text',
                            inputName: 'swift-code',
                            inputPlaceholder: 'كود السويفت'
                        },
                        {
                            inputType: 'text',
                            inputName: 'bank-address',
                            inputPlaceholder: 'عنوان البنك'
                        },
                    ]
                }}
                handleSave={addNewCreditCard}
            />
        </StyledSection5>
    );
}

export default Section5;