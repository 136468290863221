import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { AiOutlineEye } from 'react-icons/ai';
import { BsFillHouseDoorFill, BsUpload } from 'react-icons/bs';
import { FaRegBuilding, FaRegEdit } from 'react-icons/fa';
import TableComponent from '../../pages-parts/table/TableComponent';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import TabsFilter from '../../pages-parts/table/TabsFilter';
import SearchBarFilter from '../../pages-parts/table/SearchFilter';
import Entries from '../../pages-parts/table/Entries';
import SelectFilter from '../../pages-parts/table/SelectFilter';
import moment from 'moment';


const StyledRealEstates = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    padding: 2.5rem 1.75rem;
    .scroll-thumb{
        background-color: ${({ theme }) => theme.colors.primary_8};
    }
`;

const RealEstatesPage = () => {
    const location = useLocation();
    const [columns, setColumns] = useState([
        {
            id: 1,
            Header: "#",
            Cell: e => {
                return <span className='numbers-font'>{e.value}</span>
            },
            accessor: "number",
            sortable: true,
        },
        {
            id: 2,
            Header: "اسم العقار",
            accessor: "realEstatesName",
            sortable: true,
            Cell: e => {
                if (e.row.cells.find(cell => cell.column.Header === "نوع العقار").value === "سكني") {
                    return (
                        <Link to={`/contracts-view?id=${e.row.cells.find(cell => cell.column.Header === "الاجراءات").value}`} className='d-flex align-items-center icon-header-link'>
                            <BsFillHouseDoorFill className='icon' />
                            <span>
                                <h4>
                                    {e.value}
                                </h4>
                            </span>
                        </Link>
                    )
                } else {
                    return (
                        <Link to={`/contracts-view?id=${e.row.cells.find(cell => cell.column.Header === "الاجراءات").value}`} className='d-flex align-items-center icon-header-link'>
                            <FaRegBuilding className='icon' />
                            <span>
                                <h4>
                                    {e.value}
                                </h4>
                            </span>
                        </Link>
                    )
                }
            }
        },
        {
            id: 3,
            Header: "سند الملكية",
            accessor: "titleDeed",
            sortable: true,
            Cell: e => {
                return (
                    <span className='numbers-font'>
                        {e.value}#
                    </span>
                )
            }
        },
        {
            id: 4,
            Header: "نوع العقار",
            accessor: "realEstatesType",
            sortable: true
        },
        {
            id: 5,
            Header: "تاريخ الانشاء",
            accessor: "realEstatesType",
            sortable: true,
            Cell: e => {
                return (
                    <span className='numbers-font'>
                        {moment().subtract(32, "years").format('ll')}
                    </span>
                )
            }
        },
        {
            id: 6,
            Header: "المالك",
            accessor: "owner",
            sortable: true
        },
        {
            id: 7,
            Header: "الاجراءات",
            accessor: "id",
            sortable: false,
            Cell: e => {
                return (
                    <>
                        <OverlayTrigger
                            key="view"
                            placement="bottom"
                            overlay={
                                <Tooltip id={`tooltip-view`}>
                                    عرض بيانات العقار
                                </Tooltip>
                            }
                        >
                            <Link to={`/real-estate-view?id=${e.value}`}><AiOutlineEye /></Link>
                        </OverlayTrigger>
                        <OverlayTrigger
                            key="units"
                            placement="bottom"
                            overlay={
                                <Tooltip id={`tooltip-units`}>
                                    وحدات العقار
                                </Tooltip>
                            }
                        >
                            <Link to={`/real-estate-units?id=${e.value}`}><FaRegBuilding /></Link>
                        </OverlayTrigger>
                    </>
                )
            }
        },
    ]);
    const [searchTable, setSearchTable] = useState(() => {
        const filterStorage = JSON.parse(localStorage.getItem(location.pathname + '-filters')) ?? null;
        if (filterStorage !== null && filterStorage.search !== undefined) {
            return filterStorage.search;
        } else {
            return '';
        }
    });
    const [sortTable, setSortTable] = useState(() => {
        const filterStorage = JSON.parse(localStorage.getItem(location.pathname + '-filters')) ?? null;
        if (filterStorage !== null && filterStorage.sortFilter !== undefined) {
            return filterStorage.sortFilter;
        } else {
            return {
                column: '',
                direction: ''
            };
        }
    });
    const [tabFilter, setTabFilter] = useState(() => {
        const filterStorage = JSON.parse(localStorage.getItem(location.pathname + '-filters')) ?? null;
        if (filterStorage !== null && filterStorage.tabFilter !== undefined) {
            return filterStorage.tabFilter;
        } else {
            return {
                column: '',
                value: ''
            };
        }
    });
    const [pageNum, setPageNum] = useState(() => {
        return JSON.parse(localStorage.getItem(location.pathname + '-page-num')) ?? 1;
    });
    const [realEstateTypeSelectFilter, setRealEstateTypeSelectFilter] = useState(() => {
        const filterStorage = JSON.parse(localStorage.getItem(location.pathname + '-filters')) ?? null;
        if (
            filterStorage !== null &&
            filterStorage.realEstateTypeSelectFilter !== undefined &&
            filterStorage.realEstateTypeSelectFilter?.value !== undefined
        ) {
            return {
                type: 'static',
                url: '',
                value: filterStorage.realEstateTypeSelectFilter,
                column: 'realEstatesType',
                name: 'realEstateTypeSelectFilter',
                options: [
                    {
                        value: 1,
                        label: 'جميع العقارات',
                    },
                    {
                        value: 2,
                        label: 'سكني',
                    },
                    {
                        value: 3,
                        label: 'تجاري',
                    },
                ],
            };
        } else {
            return {
                type: 'static',
                url: '',
                value: {
                    value: 1,
                    label: 'جميع العقارات',
                },
                column: 'realEstatesType',
                name: 'realEstateTypeSelectFilter',
                options: [
                    {
                        value: 1,
                        label: 'جميع العقارات',
                    },
                    {
                        value: 2,
                        label: 'سكني',
                    },
                    {
                        value: 3,
                        label: 'تجاري',
                    },
                ],
            };
        }
    });
    const [ownerSelectFilter, setOwnerSelectFilter] = useState(() => {
        const filterStorage = JSON.parse(localStorage.getItem(location.pathname + '-filters')) ?? null;
        if (
            filterStorage !== null &&
            filterStorage.ownerSelectFilter !== undefined &&
            filterStorage.ownerSelectFilter?.value !== undefined
        ) {
            return {
                type: 'api',
                url: '/realEstates?',
                value: filterStorage.ownerSelectFilter,
                column: 'owner',
                name: 'ownerSelectFilter',
            };
        } else {
            return {
                type: 'api',
                url: '/realEstates?',
                value: null,
                column: 'owner',
                name: 'ownerSelectFilter',
            };
        }
    });
    const [entries, setEntries] = useState(() => {
        return JSON.parse(localStorage.getItem('entries-per-page')) ?? 10;
    })
    const [tableDataUrl, setTableDataUrl] = useState(() => {
        // tab filter url part
        let tabFilterText;
        if (tabFilter.value !== "" && tabFilter.value !== undefined) {
            tabFilterText = `${tabFilter.column}=${tabFilter.value}&`;
        } else {
            tabFilterText = "";
        }

        // sort filter url part
        let sortFilter;
        if (sortTable.column !== "" && sortTable.column !== undefined) {
            sortFilter = `_sort=${sortTable.column}&_order=${sortTable.direction}&`;
        } else {
            sortFilter = "";
        }

        // select filter url part
        // realEstateType
        let realEstateTypeSelectFilterText;
        if (
            realEstateTypeSelectFilter.value !== {} &&
            realEstateTypeSelectFilter.value?.label !== '' &&
            realEstateTypeSelectFilter.value?.label !== 'الكل' &&
            realEstateTypeSelectFilter.value?.value !== 1
        ) {
            realEstateTypeSelectFilterText = `${realEstateTypeSelectFilter.column}=${realEstateTypeSelectFilter.value?.label}&`;
        } else {
            realEstateTypeSelectFilterText = "";
        }
        // owner
        let ownerSelectFilterText;
        if (
            ownerSelectFilter.value !== {} &&
            ownerSelectFilter.value?.label !== '' &&
            ownerSelectFilter.value?.label !== 'الكل' &&
            ownerSelectFilter.value?.label !== undefined
        ) {
            ownerSelectFilterText = `${ownerSelectFilter.column}=${ownerSelectFilter.value?.label}&`;
        } else {
            ownerSelectFilterText = "";
        }
        let selectFilterText = realEstateTypeSelectFilterText + ownerSelectFilterText;

        const url = `/realEstates?${selectFilterText}${tabFilterText}${sortFilter}q=${searchTable}&_page=${pageNum}&_limit=${entries}`;

        return url;
    })

    useEffect(() => {
        // tab filter url part
        let tabFilterText;
        if (tabFilter.value !== "" && tabFilter.value !== undefined) {
            tabFilterText = `${tabFilter.column}=${tabFilter.value}&`;
        } else {
            tabFilterText = "";
        }

        // sort filter url part
        let sortFilter;
        if (sortTable.column !== "" && sortTable.column !== undefined) {
            sortFilter = `_sort=${sortTable.column}&_order=${sortTable.direction}&`;
        } else {
            sortFilter = "";
        }

        // select filter url part
        // realEstateType
        let realEstateTypeSelectFilterText;
        if (
            realEstateTypeSelectFilter.value !== {} &&
            realEstateTypeSelectFilter.value?.label !== '' &&
            realEstateTypeSelectFilter.value?.label !== 'الكل' &&
            realEstateTypeSelectFilter.value?.value !== 1
        ) {
            realEstateTypeSelectFilterText = `${realEstateTypeSelectFilter.column}=${realEstateTypeSelectFilter.value?.label}&`;
        } else {
            realEstateTypeSelectFilterText = "";
        }
        // owner
        let ownerSelectFilterText;
        if (
            ownerSelectFilter.value !== {} &&
            ownerSelectFilter.value?.label !== '' &&
            ownerSelectFilter.value?.label !== 'الكل' &&
            ownerSelectFilter.value?.label !== undefined
        ) {
            ownerSelectFilterText = `${ownerSelectFilter.column}=${ownerSelectFilter.value?.label}&`;
        } else {
            ownerSelectFilterText = "";
        }
        let selectFilterText = realEstateTypeSelectFilterText + ownerSelectFilterText;

        const url = `/realEstates?${selectFilterText}${tabFilterText}${sortFilter}q=${searchTable}&_page=${pageNum}&_limit=${entries}`;
        setTableDataUrl(url);
    },
        [
            searchTable,
            sortTable,
            tabFilter,
            pageNum,
            entries,
            realEstateTypeSelectFilter.value,
            ownerSelectFilter.value
        ]
    );

    useEffect(() => {
        const localFilter = {
            tabFilter: tabFilter,
            sortFilter: sortTable,
            search: searchTable,
            realEstateTypeSelectFilter: realEstateTypeSelectFilter.value
        }
        localStorage.setItem(location.pathname + '-page-num', pageNum);
        localStorage.setItem('entries-per-page', entries);
        localStorage.setItem(location.pathname + '-filters', JSON.stringify(localFilter));

    }, [tableDataUrl]);

    return (
        <StyledRealEstates>
            <Container fluid>
                <Row className='mb-3 flex-lg-row-reverse flex-md-row'>
                    <Col
                        lg={7}
                        md={12}
                        className='d-flex justify-content-end align-items-center mb-2'
                    >
                        <Entries
                            entries={entries}
                            setEntries={setEntries}
                        />
                        <SelectFilter
                            label='نوع العقار'
                            selectFilter={realEstateTypeSelectFilter}
                            setSelectFilter={setRealEstateTypeSelectFilter}
                        />
                        <SelectFilter
                            label='المالك'
                            selectFilter={ownerSelectFilter}
                            setSelectFilter={setOwnerSelectFilter}
                        />
                        <SearchBarFilter
                            setSearchTable={setSearchTable}
                            searchTable={searchTable}
                            setPageNum={setPageNum}
                        />
                    </Col>
                    <Col lg={5} md={12}>
                        <TabsFilter
                            tabFilters={
                                {
                                    columnName: "realEstatesType",
                                    tabs: [
                                        {
                                            id: 1,
                                            name: 'جميع العقارات',
                                            value: '',
                                        },
                                        {
                                            id: 2,
                                            name: 'سكني',
                                            value: 'سكني',
                                        },
                                        {
                                            id: 3,
                                            name: 'تجاري',
                                            value: 'تجاري',
                                        },
                                    ]
                                }
                            }
                            setTabFilter={setTabFilter}
                            setPageNum={setPageNum}
                        />
                    </Col>
                </Row>
                <TableComponent
                    columns={columns}
                    tableDataUrl={tableDataUrl}
                    setSortTable={setSortTable}
                    tableWidth={800}
                    setPageNum={setPageNum}
                    pageNum={pageNum}
                    tableRowsPerPage={entries}
                />
            </Container>
        </StyledRealEstates>
    );
}

export default RealEstatesPage;