import React, { useContext, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import styled from 'styled-components';
import { FaFileContract } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import EmailInput from './inputs/EmailInput';
import PasswordInput from './inputs/PasswordInput';
import Loading from '../../pages-parts/loading/Loading';
import FlashMessage from '../../pages-parts/flash-message/FlashMessage';
import { AlertContext } from '../../../context/AlertContext';
import { UserContext } from '../../../context/UserContext';
import Logo from '../../../images/logo.png';
import LogoText from '../../../images/logo-text.png';

const StyledLogin = styled(Row)`
    position: relative;
    --bs-gutter-x: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    align-items: center;
    justify-content: center;

    .content-container{
        margin-top: -2.5rem;

        .logoContainer{
            text-align: center;
            color: ${({ theme }) => theme.colors.primary};
            margin-bottom: 1rem;

            .logo, .logoText{
                width: 7rem;
            }
        }
        .login-form-card{
            border: none;

            h2{
                text-align: center;
                color: ${({ theme }) => theme.colors.text.secondary};
                font-size: 1.5rem;
                font-weight: 700;
            }

            form{
                .input-group-text{
                    background-color: ${({ theme }) => theme.colors.primary_2};
                    color: ${({ theme }) => theme.colors.primary};
                    font-size: 1.5rem;
                    border-color: ${({ theme }) => theme.colors.primary_2};
                }
                input{
                    background-color: ${({ theme }) => theme.colors.background.body};
                    border-color: ${({ theme }) => theme.colors.primary_2};
                    transition: none;
                    text-align: right;
                    &:focus{
                        box-shadow: 0 0 0 0.25rem ${({ theme }) => theme.colors.primary_2};
                        border: none;
                    }
                    &:checked{
                        box-shadow: 0 0 0 0.25rem ${({ theme }) => theme.colors.primary_2};
                        background-color: ${({ theme }) => theme.colors.primary};
                        border: none;
                    }
                }
                button{
                    background-color: ${({ theme }) => theme.colors.primary};
                    color: #fff;
                    font-size: 14px;
                }
                a{
                    font-size: 1.1875rem;
                    color: ${({ theme }) => theme.colors.text.secondary};
                    &:hover{
                        color: ${({ theme }) => theme.colors.primary};
                    }
                }
            }
        }
    }
`;

const Login = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const alertContext = useContext(AlertContext);
    const userContext = useContext(UserContext);

    const handelSubmint = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const users = [
            {
                email: 'a@a.com',
                password: '123456',
                userName: 'ahemd',
                firstName: 'احمد',
                lastName: 'علاء',
                rule: 'admin',
                active: true,
                phone: '02154657546',
                aria: 'الرياض',
                city: 'الرياض'
            },
            {
                email: 'assistants@a.com',
                password: '123456',
                userName: 'mohamed',
                firstName: 'محمد',
                lastName: 'حسين',
                rule: 'assistant',
                active: false,
                phone: '56464168421',
                aria: 'مكة المكرمة',
                city: 'جدة'
            },
        ]
        setIsLoading(true);

        const checkEmail = users.find(item => item.email === formData.get('email'));

        if (checkEmail === undefined) {
            alertContext.toggleAlert(true);
            alertContext.toggleAlertData({
                heading: 'فشل تسجيل الدخول',
                message: 'من فضلك تأكد من البريد الإلكتروني و كلمة المرور',
                variant: 'danger',
            });
        } else if (checkEmail.password !== formData.get('password')) {
            alertContext.toggleAlert(true);
            alertContext.toggleAlertData({
                heading: 'فشل تسجيل الدخول',
                message: 'من فضلك تأكد من كلمة المرور',
                variant: 'danger',
            });
        } else {
            handelLogin(checkEmail).then(() => {
                navigate('/');
            });
        }
        setTimeout(() => setIsLoading(false), 1000);
    }

    const handelLogin = async function (data) {
        userContext.toggleUserData(true, data)
    }

    return (
        <StyledLogin>
            {isLoading ? <Loading className="loading" /> : ''}
            {alertContext.showAlert ? <FlashMessage /> : ''}

            <Col xs='12' sm='9' md='6' lg='4' className='content-container'>
                <div className='logoContainer'>
                    <img src={Logo} className="logo" alt="logo" />
                    <img src={LogoText} className="logoText" alt="logo" />
                </div>
                <Card className='login-form-card'>
                    <Card.Body>
                        <h2>تسجيل الدخول</h2>
                        <Form onSubmit={handelSubmint}>
                            <EmailInput />
                            <PasswordInput />
                            <Row className='mt-4'>
                                <Col md='6'>
                                    <Button variant="primary" type="submit" className='border-0'>
                                        تسجيل الدخول
                                    </Button>
                                </Col>
                                <Col md='6' className='d-flex align-items-center justify-content-end'>
                                    <Form.Check
                                        type='checkbox'
                                        id='remember-me'
                                        label='تذكرني'
                                        reverse
                                    />
                                </Col>
                                <Col md='12' className='text-center mt-3'>
                                    <Link to='/forgot-password'>
                                        هل نسيت كلمة السر
                                    </Link>
                                </Col>
                                <Col md='12' className='text-center my-0'>
                                    <Link to='/send-broker-assistant-request'>
                                        طلب الانضمام كمساعد وسيط
                                    </Link>
                                </Col>
                            </Row>
                        </Form>
                    </Card.Body>
                </Card>
            </Col>
        </StyledLogin>
    );
}

export default Login;