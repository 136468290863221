import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { SidebarContext } from '../../../../context/SidebarContext';
import MenuHead from './MenuHead';
import SubMenuBody from './sub-menu-body/SubMenuBody';
import SubMenuHead from './SubMenuHead';

const StyledSidebarListItem = styled.li`
    width: 100%;
    padding: 0 0.9375rem;
    margin-bottom: .25rem;
    & > a, & > span{
        transition: all 0.5s ease-in-out;
    }
    ${({ theme, sidebarstate, listItemId }) => {
        if (sidebarstate.isOpen) {
            return css`
                &:hover{
                    & > a, & > span{
                        color: ${theme.colors.primary};
                    }
                }
            `;
        } else {
            return css`
                &:hover{
                    & > a, & > span{
                        & > span{
                            color: ${theme.colors.primary};
                        }
                        color: ${theme.colors.primary};
                        background-color: ${theme.colors.primary_1};
                    }
                }
            `;
        }

    }}
`;


const ListItem = ({ listItemProp }) => {
    const sidebarState = useContext(SidebarContext);

    const listItemHaveSubMenu = (listItem) => {

        if (listItem !== undefined) {
            if (listItem.haveSubMenu) {
                return (
                    <>
                        <SubMenuHead
                            listItemProp={listItem}
                        />
                        <SubMenuBody
                            listItemProp={listItem}
                        />
                    </>
                );
            } else {
                return (
                    <MenuHead listItemProp={listItem} />
                );
            }
        } else {
            return (<p className='mb-0'>loading...</p>)
        }

    }

    return (
        <StyledSidebarListItem
            sidebarstate={sidebarState}
            listItemId={listItemProp.id}
        >
            {listItemHaveSubMenu(listItemProp)}
        </StyledSidebarListItem>
    )
}

export default ListItem;