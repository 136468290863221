import React, { useContext, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from 'styled-components';
import moment from 'moment';
import { TenantDetailsEditContext } from '../../edit/TenantDetailsEditContext';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import Scrollbars from 'react-custom-scrollbars-2';
import NewEscort from './NewEscort';

const StyledEscortDetails = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    padding: 2.5rem 1.75rem;

    .container-fluid > *:last-child {
        margin-bottom: 0;
    }

    .saveSection{
        max-width: 1024px;
        margin: 0 auto 3rem;
    }

`;


const NewEscortDetailsContent = () => {
    const [params, setParams] = useSearchParams();
    const navigate = useNavigate();
    const {
        escorts,
        setEscorts,
    } = useContext(TenantDetailsEditContext);
    const [newEscort, setNewEscorts] = useState({
        id: `escort-${moment().valueOf()}`,
        name: '',
        relativeType: "wife-husband",
        escortIdType: "national-id",
        escortIdNumber: "",
        escortBirthDate: new Date(moment()),
        escortNationality: "saudi-arabia",
        escortCountryOfIssue: "saudi-arabia",
        escortEmail: "",
        escortPhoneNumber: "",
    });


    const setEscortField = (value, field) => {
        setNewEscorts(prev => {
            prev[field] = value;
            return { ...prev };
        })
    };

    const handleSave = () => {
        setEscorts(prev => [...prev, newEscort]);
        navigate(`/tenant/view?id=${params.get('id')}`)
    }
    return (
        <>
            <div>
                <NewEscort escort={newEscort} index={escorts.length + 1} isTenantInvitation={true} setEscortField={setEscortField} />
            </div>

            <Row className="mt-3 saveSection">
                <Col className='d-flex align-items-center justify-content-end p-0'>
                    <Link
                        to={`/tenant/view?id=${params.get('id')}`}
                        className="py-2 px-3 return-link"
                    >
                        رجوع
                    </Link>
                    <span onClick={handleSave} className="py-2 px-3 save-link ms-2">
                        حفظ
                    </span>
                </Col>
            </Row>
        </>
    )
}

const NewEscortDetails = () => {


    return (
        <Scrollbars
            renderView={props => (
                <div {...props} style={{
                    ...props.style,
                    marginLeft: props.style.marginRight,
                    marginRight: 0,
                }} />
            )}
            renderTrackVertical={props => (
                <div {...props} style={{
                    ...props.style,
                    left: 2,
                    bottom: 20,
                    top: 20,
                    right: 'auto',
                    borderRadius: 3,
                }} />
            )}
            style={{ width: "100%", height: "calc(100vh - 5rem)" }}
        >
            <StyledEscortDetails>
                <Container fluid>
                    <NewEscortDetailsContent />


                </Container>
            </StyledEscortDetails>
        </Scrollbars>
    );
}

export default NewEscortDetails;