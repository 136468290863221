import React, { useContext } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';
import { FaRegTrashAlt } from 'react-icons/fa';
import moment from 'moment';
import { ContractDetailsEditContext } from '../../ContractDetailsEditContext';
import AddNewButton from '../../../../pages-parts/inputs/add-new-btn/AddNewButton';

const StyledSection4 = styled.div`
    position: relative;
    width: 100%;
    margin-top: 3rem;
    transition: all .5s ease-in-out;
    padding: 1rem 1.75rem;
    background-color: ${({ theme }) => theme.colors.background.elements};
    border-radius: 0.75rem;
    position: relative;
    border: 1px solid #ccc;

    .form-check-input{
        margin-right: 0;
    }
    
    .infoData{
        width: 12rem;
        
        .icon{
            cursor: pointer;
            &:hover{
                color: ${({ theme }) => theme.colors.delete};
            }
        }
    }

    span img{
        width: 6rem;
        height: 6rem;
        border-radius: 0.75rem;
    }
`;

const Section4 = () => {
    const {
        additionalConditionsAvailable,
        setAdditionalConditionsAvailable,
        firstCustomCondition,
        setFirstCustomCondition,
        additionalConditions,
        setAdditionalConditions,
    } = useContext(ContractDetailsEditContext);

    const addNewCondition = () => {
        setAdditionalConditions(prev => {
            return [
                ...prev,
                {
                    inputName: `new-condition-${moment().format()}-${prev.length}`,
                    value: '',
                }
            ]
        })
    }
    const removeField = (input) => {
        setAdditionalConditions(prev => {
            return prev.filter(item => item.inputName !== input)
        })
    }
    const handleChangeValue = (e) => {

        setAdditionalConditions(prev => {
            return prev.filter(item => {
                if (item.inputName === e.target.name) {
                    item.value = e.target.value;
                }
                return item;
            })
        })

    }
    return (
        <StyledSection4>
            <h2 className='sectionFloatTitle'>بنود وشروط أضافية</h2>
            <Row>
                <Col className='text-end'>
                    <Form.Check
                        className='px-0'
                        type="switch"
                        checked={additionalConditionsAvailable}
                        name='has-facilities'
                        onChange={() => setAdditionalConditionsAvailable(prev => !prev)}
                    />
                </Col>
            </Row>
            <Row className={additionalConditionsAvailable ? '' : 'd-none'}>
                <Col>
                    <div className='data-line'>
                        <div className='infoTitle'>
                            <Form.Control
                                className='px-0 mb-2'
                                type="text"
                                value={firstCustomCondition}
                                name='has-facilities'
                                onChange={setFirstCustomCondition}
                            />
                        </div>
                    </div>
                    {
                        additionalConditions.map(item => (
                            <div className='data-line' key={item.inputName}>
                                <div className='infoTitle'>
                                    <Form.Control
                                        className='px-0 '
                                        type="text"
                                        value={item.value}
                                        name={item.inputName}
                                        onChange={handleChangeValue}
                                    />
                                </div>
                                <div className='infoData'>
                                    <FaRegTrashAlt className='icon' onClick={() => removeField(item.inputName)} />
                                </div>
                            </div>
                        ))
                    }
                    <AddNewButton
                        onClickFunction={addNewCondition}
                        title="إضافة بند أخر"
                    />
                </Col>
            </Row>
        </StyledSection4>
    );
}

export default Section4;