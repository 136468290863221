import React, { useState, createContext, useEffect, useContext } from 'react';
import { AlertContext } from "../../../../../context/AlertContext";
import api from '../../../../../api/areas';
import { useNavigate } from "react-router-dom";
export const UnitEditContext = createContext();

export const UnitEditContextProvider = ({ children }) => {
    //unitData place holder
    const [unitData, setUnitData] = useState({});
    const alertContext = useContext(AlertContext);
    const navigate = useNavigate();

    // section 1 unit details --------------------------
    // رقم الوحدة
    const [unitNumber, setUnitNumber] = useState('');
    // نوع الوحدة
    const [unitTypeOptions, setUnitTypeOptions] = useState([
        {
            value: 'building',
            label: 'مبني',
        },
        {
            value: 'villa',
            label: 'فيلا',
        },
        {
            value: 'open-commercial-complex',
            label: 'مجمع تجاري مفتوح',
        },
        {
            value: 'ground',
            label: 'ارض',
        },
        {
            value: 'closed-commercial-complex',
            label: 'مجمع تجاري مغلق',
        },
        {
            value: 'tower',
            label: 'برج',
        },
        {
            value: 'another',
            label: 'اخر',
        },
    ]);
    const [unitTypeOption, setUnitTypeOption] = useState({
        value: 'building',
        label: 'مبني',
    });
    const handleChangeUnitTypeOption = (selectOption) => {
        setUnitTypeOption(selectOption)
    }
    // استخدام الوحدة
    const [unitUseOptions, setUnitUseOptions] = useState([
        {
            value: 'family-housing',
            label: 'سكن عائلي',
        },
        {
            value: 'individual-housing',
            label: 'سكن فردي',
        },
        {
            value: 'commercial',
            label: 'تجاري',
        },
        {
            value: 'housing-commercial',
            label: 'سكني / تجاري',
        },
    ]);
    const [unitUseOption, setUnitUseOption] = useState({
        value: 'family-housing',
        label: 'سكن عائلي',
    });
    const handleChangeUnitUseOption = (selectOption) => {
        setUnitUseOption(selectOption)
    }
    // رقم الطابق
    const [floorNumber, setFloorNumber] = useState('');
    // مساحة الوحدة
    const [unitSpace, setUnitSpace] = useState('');
    // عدد الغرف
    const [roomsNumber, setRoomsNumber] = useState('');
    // تاريخ الانشاء
    const [constructionDate, setConstructionDate] = useState(new Date());
    // اتجاة الوحدة
    const [unitDirections, setUnitDirections] = useState([
        {
            value: 'north',
            label: 'شمال',
        },
        {
            value: 'east',
            label: 'شرق',
        },
        {
            value: 'south',
            label: 'جنوب',
        },
        {
            value: 'west',
            label: 'غرب',
        },
    ]);
    const [unitSelectedDirection, setUnitSelectedDirection] = useState({
        value: 'north',
        label: 'شمال',
    });
    const handleChangeUnitSelectedDirection = (selectOption) => {
        setUnitSelectedDirection(selectOption)
    }
    // تشطيب الوحدة
    const [unitFinishingTypes, setUnitFinishingTypes] = useState([
        {
            value: 'great',
            label: 'ممتاز',
        },
        {
            value: 'completed',
            label: 'مكتمل',
        },
        {
            value: 'non-completed',
            label: 'غير مكتمل',
        }
    ]);
    const [unitSelectedFinishingType, setUnitSelectedFinishingType] = useState({
        value: 'great',
        label: 'ممتاز',
    });
    const handleChangeUnitSelectedFinishingType = (selectOption) => {
        setUnitSelectedFinishingType(selectOption)
    }
    // طول الوحدة
    const [unitLength, setUnitLength] = useState('');
    // عرض الوحدة
    const [unitWidth, setUnitWidth] = useState('');
    // ارتفاع الوحدة
    const [unitHeight, setUnitHeight] = useState('');
    // تحتوي علي ميزانين
    const [hasMezzanine, setHasMezzanine] = useState(false);
    // مفروشة
    const [isFurnished, setIsFurnished] = useState(false);
    // نوع الفرش
    const [furnishTypes, setFurnishTypes] = useState([
        {
            value: 'new',
            label: 'جديد',
        },
        {
            value: 'used',
            label: 'مستعمل',
        },
    ]);
    const [unitSelectedFurnishType, setUnitSelectedFurnishType] = useState({
        value: 'new',
        label: 'جديد',
    });
    const handleChangeUnitSelectedFurnishType = (selectOption) => {
        setUnitSelectedFurnishType(selectOption)
    }

    // section 2 unit facilities --------------------------
    // تحتوي علي مرفقات
    const [hasFacilities, setHasFacilities] = useState(true);
    // غرف النوم
    const [bedroomsNumber, setBedroomsNumber] = useState('');
    // دورات المياة
    const [WC, setWC] = useState('');
    // صالات
    const [halls, setHalls] = useState('');
    // غرفة الخدم
    const [servantRoom, setServantRoom] = useState('');
    // المطبخ
    const [kitchen, setKitchen] = useState('');
    // مخزن
    const [storage, setStorage] = useState('');
    // الفناء الخلفي
    const [backyard, setBackyard] = useState('');
    // مجلس
    const [board, setBoard] = useState('');
    // مكيف هواء مركزي
    const [centralAirConditioner, setCentralAirConditioner] = useState('');
    // مكيف هواء منفصل
    const [splitAirConditioner, setSplitAirConditioner] = useState('');
    // مكيف هواء صحراوي
    const [desertAirConditioner, setDesertAirConditioner] = useState('');
    // مطبخ راكب
    const [hangingKitchen, setHangingKitchen] = useState(false);
    // خزانة راكبة
    const [hangingWardrobe, setHangingWardrobe] = useState(false);

    // section 2 unit services --------------------------
    // تحتوي علي خدمات
    const [hasServices, setHasServices] = useState(true);
    // عداد مياة
    const [waterMeter, setWaterMeter] = useState('');
    // عداد غاز
    const [gasMeter, setGasMeter] = useState('');
    // عداد كهرباء
    const [electricityMeter, setElectricityMeter] = useState('');

    const getUnitData = (unitId) => {
        api.get(`/units?id=${unitId}`).then(result => {
            if (result.data.length === 0) {
                alertContext.toggleAlert(true);
                alertContext.toggleAlertData({
                    heading: 'وحدة مجهول',
                    message: 'لم يتم العثور علي الوحدة المطلوب',
                    variant: 'warning',
                });
                navigate("/");
            } else {
                setUnitData(result.data[0]);
            }
        });
    }

    const values = {
        getUnitData,
        // unit details
        unitNumber,
        setUnitNumber,
        unitTypeOptions,
        unitTypeOption,
        handleChangeUnitTypeOption,
        unitUseOptions,
        unitUseOption,
        handleChangeUnitUseOption,
        floorNumber,
        setFloorNumber,
        unitSpace,
        setUnitSpace,
        roomsNumber,
        setRoomsNumber,
        constructionDate,
        setConstructionDate,
        unitDirections,
        unitSelectedDirection,
        handleChangeUnitSelectedDirection,
        unitFinishingTypes,
        unitSelectedFinishingType,
        handleChangeUnitSelectedFinishingType,
        unitLength,
        setUnitLength,
        unitWidth,
        setUnitWidth,
        unitHeight,
        setUnitHeight,
        hasMezzanine,
        setHasMezzanine,
        isFurnished,
        setIsFurnished,
        furnishTypes,
        unitSelectedFurnishType,
        handleChangeUnitSelectedFurnishType,
        // unit facilities
        hasFacilities,
        setHasFacilities,
        bedroomsNumber,
        setBedroomsNumber,
        WC,
        setWC,
        halls,
        setHalls,
        servantRoom,
        setServantRoom,
        kitchen,
        setKitchen,
        storage,
        setStorage,
        backyard,
        setBackyard,
        board,
        setBoard,
        centralAirConditioner,
        setCentralAirConditioner,
        splitAirConditioner,
        setSplitAirConditioner,
        desertAirConditioner,
        setDesertAirConditioner,
        hangingKitchen,
        setHangingKitchen,
        hangingWardrobe,
        setHangingWardrobe,
        // unit services
        hasServices,
        setHasServices,
        waterMeter,
        setWaterMeter,
        gasMeter,
        setGasMeter,
        electricityMeter,
        setElectricityMeter,
    };

    return (
        <UnitEditContext.Provider value={values}>
            {children}
        </UnitEditContext.Provider>
    )
}

