import 'bootstrap/dist/css/bootstrap.rtl.min.css';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { ThemeProvider } from "styled-components";
import ContentContainer from "./components/layout/content-container/ContentContainer";
import BrokerAssestantsRequestsPage from './components/pages/broker-assistants-requests/BrokerAssistantsRequests';
import BrokerAssestantsPage from './components/pages/broker-assistants/BrokerAssistants';
import ContractFinanceRecord from './components/pages/contract-finance-records/ContractFinanceRecord';
import ContractsCompletedPage from './components/pages/contracts-completed/ContractsCompleted';
import ContractsDraftPage from './components/pages/contracts-draft/ContractsDraft';
import ContractsExpireSoonPage from './components/pages/contracts-expire-soon/ContractsExpireSoon';
import CustomerServicePage from './components/pages/customer-service/CustomerService';
import DashboardPage from './components/pages/dashboard/Dashboard';
import GeneralSettingsPage from './components/pages/general-settings/GeneralSettings';
import ForgotPassword from './components/pages/login/ForgetPassword';
import Login from './components/pages/login/Login';
import CreateNewNotification from './components/pages/notifications/CreateNew';
import OwnerContractsPage from './components/pages/owner-contracts/OwnerContracts';
import OwnersPage from './components/pages/owners/Owners';
import PermissionsPage from './components/pages/permissions/Permissions';
import ProfilePage from './components/pages/profile/Profile';
import ProfileEditPage from './components/pages/profile/ProfileEdite';
import PromotersPage from './components/pages/promoters/Promoters';
import RealEstatesPage from './components/pages/real-estates/RealEstatesPage';
import RealEstateUnitsPage from './components/pages/real-estates/units/RealEstateUnitsList';
import RealEstateView from './components/pages/real-estates/view-real-estate/ViewRealEstate';
import RulesPage from './components/pages/rulers/Rules';
import TermsAndConditionsPage from './components/pages/terms-conditions/TermsAndConditions';
import EditUser from './components/pages/users/edit/EditeUser';
import UsersPage from './components/pages/users/Users';
import ViewUser from './components/pages/users/view/ViewUser';
import ViewContractPage from './components/pages/view-contract/ViewContract';
import ViewOwnerPage from './components/pages/view-owner/ViewOwner';
import { AlertContextProvider } from './context/AlertContext';
import { SidebarContextProvider } from "./context/SidebarContext";
import { UserContext, UserContextProvider } from './context/UserContext';
import GlobalStyles from "./styles/Global";
import theme from "./styles/Theme.styled";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import OwnershipDocEdit from './components/pages/real-estates/ownership-document-edit/OwnershipDocEdit';
import RealEstateDetailsEdit from './components/pages/real-estates/real-estate-details-edit/RealEstateDetailsEdit';
import RealEstateSharedFacilitiesEdit from './components/pages/real-estates/real-estate-shared-facilities-edit/RealEstateSharedFacilitiesEdit';
import UnitEditPage from './components/pages/real-estates/units/unit-edit/UnitEdit';
import UnitViewPage from './components/pages/real-estates/units/unit-view/UnitView';
import ContractFinanceRecordEdit from './components/pages/contract-finance-records/edit/ContractFinanceRecordEdit';
import SendBrokerAssistantRequest from './components/pages/send-broker-assistant-request/SendBrokerAssistantRequest';
import BrokerAssistantRequestViewPage from './components/pages/broker-assistants-requests/request-view/RequestView';
import UsersFinancialRecordsPage from './components/pages/users-financial-records/UsersFinancialRecords';
import OwnersFinancialRecordsPage from './components/pages/owners-financial-records/OwnersFinancialRecords';
import UsersUsersPayoutPage from './components/pages/users-payouts/UsersPayout';
import { TopbarContextProvider } from './context/TopbarContext';
import OwnerDetailsEdit from './components/pages/owners/edit/OwnerDetailsEdit';
import OwnerRealEstates from './components/pages/owners/real-estates/OwnerRealEstates';
import ZonesPage from './components/pages/zones/Zones';
import ZoneViewPage from './components/pages/zones/ZoneView';
import TicketPage from './components/pages/ticket/Ticket';
import ContractDetailsEditContainer from './components/pages/contract-edit/ContractDetailsEditContainer';
import TenantDetailsEdit from './components/pages/tenant/edit/TenantDetailsEdit';
import EscortDetails from './components/pages/tenant/escort/EscortDetails';
import TenantDetailsView from './components/pages/tenant/view/TenantDetailsView';
import TenantPagesContainer from './components/pages/tenant/TenantPages';
import SingleEscortDetailsEdit from './components/pages/tenant/escort/SingleEscortDetailsEdit';
import TenantInvitation from './components/pages/tenant/invitation/TenantInvitation';
import NewEscortDetails from './components/pages/tenant/escort/new/NewEscortDetails';
import { useContext, useEffect } from 'react';

const Logout = () => {
  const navigate = useNavigate();
  const {toggleUserData} = useContext(UserContext);

  useEffect(() => {
    toggleUserData(false)
    document.cookie = "isSameDay=true; expires=Thu, 01 Jan 1970 00:00:01 GMT";
    navigate('/login')
  });
}

function App() {
  return (
    <AlertContextProvider>
      <UserContextProvider>
        <TopbarContextProvider>
          <SidebarContextProvider>
            <ThemeProvider theme={theme}>
              <GlobalStyles />
              <Routes>
                  <Route path='/' element={<ContentContainer />} >
                    <Route index element={<DashboardPage />} />
                    <Route path='contracts-draft' element={<ContractsDraftPage />} />
                    <Route path='contracts-completed' element={<ContractsCompletedPage />} />
                    <Route path='contracts-expire-soon' element={<ContractsExpireSoonPage />} />
                    <Route path='contracts-view' element={<ViewContractPage />} />
                    <Route path='contracts-edit' element={<ContractDetailsEditContainer />} />
                    <Route path='contract-finance-record' element={<ContractFinanceRecord />} />
                    <Route path='contract-finance-record-edit' element={<ContractFinanceRecordEdit />} />
                    <Route path='real-estate' element={<RealEstatesPage />} />
                    <Route path='real-estate-view' element={<RealEstateView />} />
                    <Route path='real-estate-ownership-document-edit' element={<OwnershipDocEdit />} />
                    <Route path='real-estate-details-edit' element={<RealEstateDetailsEdit />} />
                    <Route path='real-estate-shared-facilities-edit' element={<RealEstateSharedFacilitiesEdit />} />
                    <Route path='real-estate-units' element={<RealEstateUnitsPage />} />
                    <Route path='unit-view' element={<UnitViewPage />} />
                    <Route path='unit-edit' element={<UnitEditPage />} />
                    <Route path='owner' element={<OwnersPage />} />
                    <Route path='owner-view' element={<ViewOwnerPage />} />
                    <Route path='owner-edit' element={<OwnerDetailsEdit />} />
                    <Route path='owner-new' element={<OwnerDetailsEdit />} />
                    <Route path='owner-contracts' element={<OwnerContractsPage />} />
                    <Route path='owner-financial-records' element={<OwnersFinancialRecordsPage />} />
                    <Route path='owner-real-estates' element={<OwnerRealEstates />} />
                    <Route path='customer-service' element={<CustomerServicePage />} />
                    <Route path='customer-service-financial-records' element={<UsersFinancialRecordsPage userType='customer-services' />} />
                    <Route path='customer-service-payouts' element={<UsersUsersPayoutPage userType='customer-services' />} />
                    <Route path='broker-assistants-request' element={<BrokerAssestantsRequestsPage />} />
                    <Route path='broker-assistant-request-view' element={<BrokerAssistantRequestViewPage />} />
                    <Route path='broker-assistants' element={<BrokerAssestantsPage />} />
                    <Route path='broker-assistant-financial-records' element={<UsersFinancialRecordsPage userType='broker' />} />
                    <Route path='broker-assistant-payouts' element={<UsersUsersPayoutPage userType='broker' />} />
                    <Route path='promoters' element={<PromotersPage />} />
                    <Route path='promoter-financial-records' element={<UsersFinancialRecordsPage userType='promoter' />} />
                    <Route path='promoter-payouts' element={<UsersUsersPayoutPage userType='promoter' />} />
                    <Route path='notifications' element={<CreateNewNotification />} />
                    <Route path='users'>
                      <Route index element={<UsersPage />} />
                      <Route path='view' element={<ViewUser />} />
                      <Route path='edit' element={<EditUser />} />
                      <Route path='create' element={<EditUser />} />
                    </Route>
                    <Route path='terms-conditions' element={<TermsAndConditionsPage />} />
                    <Route path='general-settings' element={<GeneralSettingsPage />} />
                    <Route path='roles' element={<RulesPage />} />
                    <Route path='permissions' element={<PermissionsPage />} />
                    <Route path='zones' element={<ZonesPage />} />
                    <Route path='zone-view' element={<ZoneViewPage />} />
                    <Route path='profile' element={<ProfilePage />} />
                    <Route path='profile/edit' element={<ProfileEditPage />} />
                    <Route path='ticket' element={<TicketPage />} />
                  </Route>
                  <Route path='/login' element={<Login />} />
                  <Route path='/log-out' element={<Logout />} />
                  <Route path='/forgot-password' element={<ForgotPassword />} />
                  <Route path='/send-broker-assistant-request' element={<SendBrokerAssistantRequest />} />
                  <Route path='/tenant'>
                    <Route index element={<TenantInvitation />} />
                    <Route element={<TenantPagesContainer />} >
                      <Route path='view' element={<TenantDetailsView />} />
                      <Route path='edit' element={<TenantDetailsEdit />} />
                      <Route path='escort-edit' element={<SingleEscortDetailsEdit />} />
                      <Route path='escort-new' element={<NewEscortDetails />} />
                    </Route>
                  </Route>
              </Routes>
            </ThemeProvider>
          </SidebarContextProvider>
        </TopbarContextProvider>
      </UserContextProvider>
    </AlertContextProvider>
  );
}

export default App;
