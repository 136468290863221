import React from 'react';
import styled from 'styled-components';
import TenantDetailsEdit from '../../tenant/edit/section-1/Section-1';
import EstablishmentDetailsEdit from '../../tenant/edit/section-2/Section-2';
import ContactDetailsEdit from '../../tenant/edit/section-4/Section-4';
import RepresentativeDetailsEdit from '../../tenant/edit/section-6/Section-6';



const StyledTenantEdit = styled.div`
    & > *:not(:last-child){
        margin-bottom: 3rem;
    }

    & > *:last-child{
        margin-bottom: 0;
    }
`;


const TenantEdit = () => {


    return (
        <StyledTenantEdit>
            <TenantDetailsEdit />
            <EstablishmentDetailsEdit />
            <ContactDetailsEdit />
            <RepresentativeDetailsEdit />
        </StyledTenantEdit>
    );
}

export default TenantEdit;