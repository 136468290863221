import React, { useContext } from 'react';
import styled from 'styled-components';
import { SidebarContext } from '../../../context/SidebarContext';
import Topbar from '../topbar/Topbar';
import MainContent from './MainContent';

const StyledBodyContent = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: calc( 100vw - ${(props) => props.sidebarstate.isOpen ? props.theme.sidebar_width.open : props.theme.sidebar_width.close});
    transition: all .5s ease-in-out;
    height: 100%;
    overflow: hidden;
`;

const BodyContent = () => {
    const sidebarState = useContext(SidebarContext);

    return (
        <StyledBodyContent sidebarstate={sidebarState}>
            <Topbar />
            <MainContent />
        </StyledBodyContent>
    );
}

export default BodyContent;