import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { AiOutlineEye } from 'react-icons/ai';
import { FaRegBuilding, FaClipboardList, FaFileContract } from 'react-icons/fa';
import { IoPricetagOutline } from 'react-icons/io5';
import { BsFillHouseDoorFill, BsUpload } from 'react-icons/bs';
import { FaRegEdit } from 'react-icons/fa';
import TableComponent from '../../pages-parts/table/TableComponent';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import SearchBarFilter from '../../pages-parts/table/SearchFilter';
import Entries from '../../pages-parts/table/Entries';
import profileImage from '../../../images/1.png';
import SelectFilter from '../../pages-parts/table/SelectFilter';


const StyledOwners = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    padding: 2.5rem 1.75rem;
    .scroll-thumb{
        background-color: ${({ theme }) => theme.colors.primary_8};
    }

    .unitsNum{
        display: flex;
        align-items: center;
        justify-content: center;

        span{
            width: 2.5rem;
            text-align: center;
            padding: 0.125rem 0;
            border: 1px solid #ccc;
        }
        .filled{
            color: ${({ theme }) => theme.colors.primary};
            border-right: none;
        }
    }
`;

const OwnersPage = () => {
    const location = useLocation();
    const [showTooltip, setShowTooltip] = useState(false);
    const [columns, setColumns] = useState([
        {
            id: 1,
            Header: "#",
            Cell: e => {
                return <span className='numbers-font'>{e.value}</span>
            },
            accessor: "number",
            sortable: true,
        },
        {
            id: 2,
            Header: "اسم المالك / الممثل",
            accessor: "ownerOrRepresentative",
            sortable: true,
            Cell: e => {
                return (
                    <>
                        <Link to={`/users/view?id=${e.row.cells.find(cell => cell.column.Header === "الاجراءات").value}`} className='d-flex align-items-center image-heading-text-link'>
                            <img src={profileImage} alt="Profile" />
                            <span>
                                <h4>
                                    {e.value}
                                </h4>
                                <span className="numbers-font"><span>#</span> {e.row.cells.find(cell => cell.column.Header === "الاجراءات").value.replace(/(-.*)/g, "")}...</span>
                            </span>
                        </Link>
                    </>
                )
            }
        },
        {
            id: 3,
            Header: "الصفة",
            accessor: "userType",
            sortable: true,

        },
        {
            id: 4,
            Header: "عدد العقارات",
            accessor: "realEstatesNum",
            Cell: e => {
                return <span className='numbers-font'>{e.value}</span>
            },
            sortable: true
        },
        {
            id: 5,
            Header: "عدد الوحدات السكنية",
            accessor: "housingUnitsNum",
            sortable: true,
            Cell: e => {
                return (
                    <div className='unitsNum numbers-font'>
                        <span className='all'>{e.value.units}</span>
                        <span className='filled'>{e.value.filled}</span>
                    </div>
                )
            }
        },
        {
            id: 6,
            Header: "عدد العقارات التجارية",
            accessor: "tradingUnitsNum",
            sortable: true,
            Cell: e => {
                return (
                    <div className='unitsNum numbers-font'>
                        <span className='all'>{e.value.units}</span>
                        <span className='filled'>{e.value.filled}</span>
                    </div>
                )
            }
        },
        {
            id: 7,
            Header: "المنطقة",
            accessor: "tradingUnitsNum",
            sortable: true,
            Cell: e => {
                return (
                    <span>
                        {
                            e.value.units < 17 ? "مكة" :
                                e.value.units < 19 ?
                                "الرياض" :
                                "المدينة المنورة"
                        }
                    </span>
                )
            }
        },
        {
            id: 8,
            Header: "المدينة",
            accessor: "tradingUnitsNum",
            sortable: true,
            Cell: e => {
                return (
                    <span>
                        {
                            e.value.units < 17 ? "جدة" :
                                e.value.units < 19 ?
                                "الرياض" :
                                "العلا"
                        }
                    </span>
                )
            }
        },
        {
            id: 9,
            Header: "الحي",
            accessor: "tradingUnitsNum",
            sortable: true,
            Cell: e => {
                return (
                    <span>
                        {
                            e.value.units < 17 ? "ضاحية الميار" :
                                e.value.units < 19 ?
                                "لبن" :
                                "الخميسية"
                        }
                    </span>
                )
            }
        },
        {
            id: 10,
            Header: "الاجراءات",
            accessor: "id",
            sortable: false,
            Cell: e => {
                return (
                    <>
                        <OverlayTrigger
                            key="view"
                            placement="bottom"
                            overlay={
                                <Tooltip id={`tooltip-view`}>
                                    عرض بيانات {
                                        e.row.cells.find(cell => cell.column.Header === "الصفة").value === "ممثل" ? "الممثل" : "المالك"
                                    }
                                </Tooltip>
                            }
                        >
                            <Link
                                to={`/owner-view?id=${e.value}`}
                            >
                                <AiOutlineEye />
                            </Link>
                        </OverlayTrigger>
                        <OverlayTrigger
                            key="edit"
                            placement="bottom"
                            overlay={
                                <Tooltip id={`tooltip-edit`}>
                                    تعديل بيانات {
                                        e.row.cells.find(cell => cell.column.Header === "الصفة").value === "ممثل" ? "الممثل" : "المالك"
                                    }
                                </Tooltip>
                            }
                        >
                            <Link to={`/owner-edit?id=${e.value}`}><FaRegEdit /></Link>
                        </OverlayTrigger>
                        <OverlayTrigger
                            key="ownerRealEstate"
                            placement="bottom"
                            overlay={
                                <Tooltip id={`tooltip-ownerRealEstate`}>
                                    عقارات {
                                        e.row.cells.find(cell => cell.column.Header === "الصفة").value === "ممثل" ? "الممثل" : "المالك"
                                    }
                                </Tooltip>
                            }
                        >
                            <Link to={`/owner-real-estates?id=${e.value}`}><FaRegBuilding /></Link>
                        </OverlayTrigger>
                        <OverlayTrigger
                            key="financeRecord"
                            placement="bottom"
                            overlay={
                                <Tooltip id={`tooltip-financeRecord`}>
                                    السجل المالي الخاص ب{
                                        e.row.cells.find(cell => cell.column.Header === "الصفة").value === "ممثل" ? "الممثل" : "المالك"
                                    }
                                </Tooltip>
                            }
                        >
                            <Link to={`/owner-financial-records?id=${e.value}`}><FaClipboardList /></Link>
                        </OverlayTrigger>
                        <OverlayTrigger
                            key="contracts"
                            placement="bottom"
                            overlay={
                                <Tooltip id={`tooltip-contracts`}>
                                    عقود {
                                        e.row.cells.find(cell => cell.column.Header === "الصفة").value === "ممثل" ? "الممثل" : "المالك"
                                    }
                                </Tooltip>
                            }
                        >
                            <Link to={`/owner-contracts?id=${e.value}`}><FaFileContract /></Link>
                        </OverlayTrigger>
                    </>
                )
            }
        },
    ]);
    const [searchTable, setSearchTable] = useState(() => {
        const filterStorage = JSON.parse(localStorage.getItem(location.pathname + '-filters')) ?? null;
        if (filterStorage !== null && filterStorage.search !== undefined) {
            return filterStorage.search;
        } else {
            return '';
        }
    });
    const [sortTable, setSortTable] = useState(() => {
        const filterStorage = JSON.parse(localStorage.getItem(location.pathname + '-filters')) ?? null;
        if (filterStorage !== null && filterStorage.sortFilter !== undefined) {
            return filterStorage.sortFilter;
        } else {
            return {
                column: '',
                direction: ''
            };
        }
    });
    const [pageNum, setPageNum] = useState(() => {
        return JSON.parse(localStorage.getItem(location.pathname + '-page-num')) ?? 1;
    });
    const [areaSelectFilter, setAreaSelectFilter] = useState(() => {
        const filterStorage = JSON.parse(localStorage.getItem(location.pathname + '-filters')) ?? null;
        if (filterStorage !== null && filterStorage.areaSelectFilter !== undefined) {
            return {
                type: 'api',
                url: '/areas?',
                value: filterStorage.areaSelectFilter,
                column: 'area',
                name: 'areaSelectFilter',
            };
        } else {
            return {
                type: 'api',
                url: '/areas?',
                value: null,
                column: 'area',
                name: 'areaSelectFilter',
            };
        }
    });
    const [citiesSelectFilter, setCitiesSelectFilter] = useState(() => {
        const filterStorage = JSON.parse(localStorage.getItem(location.pathname + '-filters')) ?? null;
        if (filterStorage !== null && filterStorage.citiesSelectFilter !== undefined) {
            return {
                type: 'api',
                url: '/cities?area_id=' + (areaSelectFilter.value?.value ?? '') + '&',
                value: filterStorage.citiesSelectFilter,
                column: 'city',
                name: 'citiesSelectFilter',
            };
        } else {
            return {
                type: 'api',
                url: '/cities',
                value: null,
                column: 'city',
                name: 'citiesSelectFilter',
            };
        }
    });
    const [neighborhoodSelectFilter, setNeighborhoodSelectFilter] = useState(() => {
        const filterStorage = JSON.parse(localStorage.getItem(location.pathname + '-filters')) ?? null;
        if (filterStorage !== null && filterStorage.neighborhoodSelectFilter !== undefined) {
            return {
                type: 'static',
                url: '',
                value: filterStorage.neighborhoodSelectFilter,
                column: 'neighborhood',
                options: [
                    {
                        value: 'no-filter',
                        label: 'الكل',
                    },
                    {
                        value: 1,
                        label: 'لبن',
                    },
                    {
                        value: 2,
                        label: 'درّة الرياض',
                    },
                    {
                        value: 3,
                        label: 'حي الموسى',
                    },
                    {
                        value: 4,
                        label: 'النظيم',
                    },
                    {
                        value: 5,
                        label: 'تلال الرياض',
                    },
                ],
                name: 'neighborhoodSelectFilter',
            };
        } else {
            return {
                type: 'static',
                url: '',
                value: null,
                column: 'neighborhood',
                options: [
                    {
                        value: 'no-filter',
                        label: 'الكل',
                    },
                    {
                        value: 1,
                        label: 'لبن',
                    },
                    {
                        value: 2,
                        label: 'درّة الرياض',
                    },
                    {
                        value: 3,
                        label: 'حي الموسى',
                    },
                    {
                        value: 4,
                        label: 'النظيم',
                    },
                    {
                        value: 5,
                        label: 'تلال الرياض',
                    },
                ],
                name: 'neighborhoodSelectFilter',
            };
        }
    });
    const [entries, setEntries] = useState(() => {
        return JSON.parse(localStorage.getItem('entries-per-page')) ?? 10;
    })
    const [tableDataUrl, setTableDataUrl] = useState(() => {
        // sort filter url part
        let sortFilter;
        if (sortTable.column !== "" && sortTable.column !== undefined) {
            sortFilter = `_sort=${sortTable.column}&_order=${sortTable.direction}&`;
        } else {
            sortFilter = "";
        }

        const url = `/owners?${sortFilter}q=${searchTable}&_page=${pageNum}&_limit=${entries}`;

        return url;
    })

    useEffect(() => {
        // sort filter url part
        let sortFilter;
        if (sortTable.column !== "" && sortTable.column !== undefined) {
            sortFilter = `_sort=${sortTable.column}&_order=${sortTable.direction}&`;
        } else {
            sortFilter = "";
        }

        const url = `/owners?${sortFilter}q=${searchTable}&_page=${pageNum}&_limit=${entries}`;
        setTableDataUrl(url);
    }, [searchTable, sortTable, pageNum, entries]);

    useEffect(() => {
        setCitiesSelectFilter(prev => {
            return {
                type: 'api',
                url: '/cities?area_id=' + areaSelectFilter.value?.value + '&',
                value: null,
                column: 'city',
                name: 'citiesSelectFilter'
            };
        })
    }, [areaSelectFilter.value])

    useEffect(() => {
        const localFilter = {
            search: searchTable,
            sortFilter: sortTable,
            areaSelectFilter: areaSelectFilter.value,
            citiesSelectFilter: citiesSelectFilter.value,
            neighborhoodSelectFilter: neighborhoodSelectFilter.value,
        }
        localStorage.setItem(location.pathname + '-filters', JSON.stringify(localFilter));
        localStorage.setItem(location.pathname + '-page-num', pageNum);
        localStorage.setItem('entries-per-page', entries);
    }, [tableDataUrl]);

    return (
        <StyledOwners>
            <Container fluid>
                <Row className='mb-3 flex-lg-row-reverse flex-md-row justify-content-between'>
                    <Col
                        lg={5}
                        md={12}
                        className='d-flex justify-content-end align-items-center mb-2'
                    >
                        <Entries
                            entries={entries}
                            setEntries={setEntries}
                        />
                        <SearchBarFilter
                            setSearchTable={setSearchTable}
                            searchTable={searchTable}
                            setPageNum={setPageNum}
                        />
                    </Col>
                    <Col lg={5} md={12} className='d-flex'>
                        <SelectFilter
                            label="المنطقة"
                            selectFilter={areaSelectFilter}
                            setSelectFilter={setAreaSelectFilter}
                        />
                        <SelectFilter
                            label="المدينة"
                            selectFilter={citiesSelectFilter}
                            setSelectFilter={setCitiesSelectFilter}
                        />
                        <SelectFilter
                            label="الحي"
                            selectFilter={neighborhoodSelectFilter}
                            setSelectFilter={setNeighborhoodSelectFilter}
                        />
                    </Col>
                </Row>
                <TableComponent
                    columns={columns}
                    tableDataUrl={tableDataUrl}
                    setSortTable={setSortTable}
                    tableWidth={800}
                    setPageNum={setPageNum}
                    pageNum={pageNum}
                    tableRowsPerPage={entries}
                />
            </Container>
        </StyledOwners>
    );
}

export default OwnersPage;