import Form from 'react-bootstrap/Form';

function EmailInput({ titleText, inputName, value, setValue }) {

    const handleChange = e => {
        setValue(prev => {
            return (e.target.value);
        })
    }

    return (
        <div className='data-line'>
            <span className='infoTitle'>
                {titleText}
            </span>
            <span className='infoData'>
                <Form.Control
                    className='py-1 px-3'
                    type='text'
                    defaultValue={value}
                    name={inputName}
                    onChange={handleChange}
                />
            </span>
        </div>
    );
}

export default EmailInput;