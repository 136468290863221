import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import styled from "styled-components";
import Section1 from "./sections/Section-1";
import { Link, useSearchParams } from "react-router-dom";
import { useContext, useEffect } from "react";
import { OwnershipDocEditContext, OwnershipDocEditContextProvider } from "./OwnershipDocEditContext";

const StyledOwnershipDocEdit = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    padding: 2.5rem 1.75rem;

`;

const OwnershipDocEditContent = () => {
    const [params, setParams] = useSearchParams();
    const {
        getOwnershipDocData,
    } = useContext(OwnershipDocEditContext);

    useEffect(() => {
        getOwnershipDocData(params.get('real_id'))
    }, [])

    const handleSave = () => {
        console.log('hi');
    }

    return(
        <Container fluid>
            <Section1 />

            <Row className="mt-3">
                <Col className='d-flex align-items-center justify-content-end py-2'>
                    <Link to={`/real-estate-view?id=${params.get('real_id')}`} className="py-2 px-3 return-link me-2">
                        رجوع
                    </Link>
                    <span onClick={handleSave} className="py-2 px-3 save-link">
                        حفظ
                    </span>
                </Col>
            </Row>
        </Container>
    )
}

const OwnershipDocEdit = () => {

    return (
        <OwnershipDocEditContextProvider>
            <StyledOwnershipDocEdit>
                <OwnershipDocEditContent />
            </StyledOwnershipDocEdit>
        </OwnershipDocEditContextProvider>
    )
}

export default OwnershipDocEdit;