import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { FaBed, FaBath } from 'react-icons/fa';
import { MdOutlineLiving } from 'react-icons/md';
import { TfiAngleUp } from 'react-icons/tfi';
import Moment from 'react-moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import moment from 'moment';
import AddNewButton from '../../../pages-parts/inputs/add-new-btn/AddNewButton';


const StyledSection2 = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    margin-bottom: 1rem;
    padding: 1rem 1.75rem;
    background-color: ${({ theme }) => theme.colors.background.elements};
    border-radius: 0.75rem;
    position: relative;
    border: 1px solid #ccc;

    &:last-child{
        margin-bottom: 0;
    }
    .headContainer{
        cursor: pointer;

        &.closed{
            margin-bottom: 0;
            padding-bottom: 0;
            border: none;
            p.unit-id{
                display: none;
            }
            .icon{
                transform: rotate(180deg);
            }
        }
        .icon{
            font-size: 1.5rem;
            transition: all .5s ease-in-out;
        }
        p{
            color: ${({ theme }) => theme.colors.text.primary};
        }
        h4 {
            display: flex;
            align-items: center;
            p{
                color: ${({ theme }) => theme.colors.text.secondary};
                margin-left: .75rem;
            }
            .status{
                display: inline-block;
                padding: 0.25rem 1rem;
                font-size: 14px;
                border-radius: .75rem;
                background-color: ${({ theme }) => theme.colors.secondary_2};
            }
        }

        &:hover{
            color: ${({ theme }) => theme.colors.secondary};
        }
    }
    p{
        margin: 0;
    }
    
    .infoData{
        padding: .75rem 3rem;
        border-radius: 0.75rem;
        border: 1px solid #ccc;
    }

    
    .secondRow{
        justify-content: right;
        .periodBox{
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: auto;

            div{
                text-align: center;
            }
            h4, h5{
                margin-bottom: 0;
            }
            .infoData2{
                padding: 0;
                margin-top: 0.5rem;
            }
        }
    }
    .amenitiesBox{
        display: flex;
        .amenities{
            padding: .25rem .75rem;
            border-radius: 0.5rem;
            border: 1px solid ${({ theme }) => theme.colors.secondary};
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            margin-left: 1rem;
            .icon{
                font-size: 1.5rem;
                color: ${({ theme }) => theme.colors.secondary};
                margin-left: .5rem;
            }
            span{
    
            }
        }
    }

    .contentContainer{
        transition: all .5s linear;
        overflow: hidden;
        max-height: 20rem;

        &.closed{
            max-height: 0;
        }
    }
`;

const UnitCard = ({ data }) => {

    const openCard = (e, id) => {
        if (e.currentTarget.classList.contains('closed')) {
            e.currentTarget.classList.remove('closed');
            document.getElementById(id).classList.remove('closed');
        } else {
            e.currentTarget.classList.add('closed');
            document.getElementById(id).classList.add('closed');
        }
    }

    return (
        <StyledSection2>

            <Row className='m-0 headContainer' onClick={(e) => openCard(e, data.id)}>
                <Col className='mb-2'>
                    <h4 className='mb-0'><p>وحدة <span className="numbers-font">#1</span> </p><span className='status'>شاغر</span></h4>
                    <p className='mt-2 unit-id'>
                        رقم الوحدة <span className="numbers-font">#{123456}</span>
                    </p>
                </Col>
                <Col className='mb-2 d-flex justify-content-end align-items-center'>
                    <AddNewButton
                        title="تفاصيل"
                        url={`/unit-view?unit_id=42766813-b6c2-4697-8372-67101f76b20d`}
                        isLink={true}
                        hasIcon={false}
                    />
                    <TfiAngleUp className='icon ms-3' />
                </Col>
            </Row>
            <div className='contentContainer' id={data.id}>
                <Row className='my-3 secondRow' >
                    <div className='periodBox align-items-start'>
                        <div>
                            <h5>رقم الطابق</h5>
                            <p className='infoData2 numbers-font'>
                                5
                            </p>
                        </div>
                    </div>
                    <div className='periodBox align-items-center'>
                        <div>
                            <h5>نوع الوحدة</h5>
                            <p className='infoData2'>
                                شقة
                            </p>
                        </div>
                    </div>
                    <div className='periodBox align-items-end'>
                        <div>
                            <h5>تاريخ الانشاء</h5>
                            <p className='infoData2 numbers-font'>
                                {moment().subtract(6, "years").format('ll')}
                            </p>
                        </div>
                    </div>
                    <div className='periodBox align-items-end'>
                        <div>
                            <h5>استخدام الوحدة</h5>
                            <p className='infoData2'>
                                سكني
                            </p>
                        </div>
                    </div>
                </Row>
                <div className='m-0 amenitiesBox'>
                    <div className='amenities'>
                        <FaBed className='icon' />
                        <span><span className='numbers-font'>3</span> غرف نوم</span>
                    </div>
                    <div className='amenities'>
                        <FaBath className='icon' />
                        <span><span className='numbers-font'>2</span> حمامات</span>
                    </div>
                    <div className='amenities'>
                        <MdOutlineLiving className='icon' />
                        <span><span className='numbers-font'>1</span> غرف معيشة</span>
                    </div>
                    <div className='amenities'>
                        <FaBed className='icon' />
                        <span><span className='numbers-font'>3</span> غرف نوم</span>
                    </div>
                    <div className='amenities'>
                        <FaBath className='icon' />
                        <span><span className='numbers-font'>2</span> حمامات</span>
                    </div>
                </div>
            </div>

        </StyledSection2>
    );
}

export default UnitCard;