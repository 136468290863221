import React, { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { AlertContext } from '../../../context/AlertContext';
import BodyContent from '../main-content/BodyContent';
import Sidebar from '../sidebar/Sidebar';

const StyledContentContainer = styled.div`
    position: relative;
    width: 100vw;
    height: 100vh;
    background-color: ${({ theme }) => theme.colors.background.body};
`;

const ContentContainer = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const alertContext = useContext(AlertContext);

    useEffect(() => {
        const userStorage = JSON.parse(localStorage.getItem('user-data'));

        if (userStorage === undefined || userStorage === null) {
            if(location.pathname !== "/"){
                alertContext.toggleAlert(true);
                alertContext.toggleAlertData({
                    heading: 'لم يتم تسجيل الدخول',
                    message: 'من فضلك قم بتسجيل الدخول أولا',
                    variant: 'warning',
                });
            }
            navigate('/login');
        }
    }, []);

    return (
        <StyledContentContainer>
            <Sidebar />
            <BodyContent />
        </StyledContentContainer>
    );
}

export default ContentContainer;