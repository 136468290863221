import React, { useContext, useEffect, useRef, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';
import Select from 'react-select';
import { Calendar } from 'react-date-range';
import moment from 'moment';
import StaticSelect from '../../../../pages-parts/inputs/static-select-input/StaticSelect';
import DatePickerInput from '../../../../pages-parts/inputs/date-picker/DatePickerInput';
import NumberInput from '../../../../pages-parts/inputs/number-input/NumberInput';
import TextInput from '../../../../pages-parts/inputs/text-input/TextInput';
import SectionCommercialPart from './increasing-rent';
import { ContractFinanceRecordEditContext } from '../ContractFinanceRecordEditContext';
import { useLocation } from 'react-router-dom';

const StyledSection1 = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    margin-bottom: 3rem;
    padding: 1rem 1.75rem;
    background-color: ${({ theme }) => theme.colors.background.elements};
    border-radius: 0.75rem;
    position: relative;
    border: 1px solid #ccc;

    
    p{
        margin-bottom: 0;
    }
    
    
    .infoData{
        width: 12rem;
        position: relative;
        
        &:has(input[name=yearly-rent])::after{
            display: flex;
            content: "SAR";
            position: absolute;
            height: 100%;
            align-items: center;
            right: 0;
            top: 0;
            font-weight: 900;
            width: 50px;
            justify-content: center;
            border-left: 1px solid #ccc;
        }

        input[name=yearly-rent].myInput{
            padding-right: 55px!important;
        }
    }
    .periodBox{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h3{
            font-size: 14px;
            color: ${({ theme }) => theme.colors.text.secondary};
            margin-bottom: 0;
            margin-top: .25rem;
        }
        .infoData2{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 3rem;
            padding: 0.5rem 0;
            margin-top: .5rem;
            border-radius: 0.5rem;
            border: 1px solid #ccc;
            background-color: ${({ theme }) => theme.colors.background.elements};
        }
    }
    span img{
        width: 6rem;
        height: 6rem;
        border-radius: 0.75rem;
    }
`;

const Section1 = () => {
    const {
        contractTypeHousing,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        yearlyRent,
        setYearlyRent,
    } = useContext(ContractFinanceRecordEditContext);
    const location = useLocation();

    const yearsDuration = moment(endDate).diff(startDate, "Y");
    const monthsTotal = moment(endDate).diff(startDate, "M");
    const monthsDuration = monthsTotal - (yearsDuration * 12);
    let daysDuration = moment(endDate).format('D') - moment(startDate).format("D");

    if (daysDuration < 0) {
        daysDuration *= -1;
    }

    return (
        <StyledSection1>
            <h2 className='sectionFloatTitle'>قيمة الايجار</h2>
            <Row className='mt-4'>
                <Col md="6" className='mb-2'>
                    <DatePickerInput
                        titleText='تاريخ البدء'
                        date={startDate}
                        setDate={setStartDate}
                    />
                </Col>
                <Col md="6" className='mb-2'>
                    <DatePickerInput
                        titleText='تاريخ الانتهاء'
                        date={endDate}
                        setDate={setEndDate}
                    />
                </Col>
            </Row>

            <Row>
                <Col className='infoTitle'>
                    فترة الايجار
                </Col>
            </Row>
            <Row>
                <Col className='ps-5 d-flex'>
                    <div className='periodBox me-3'>
                        <h3>سنوات</h3>
                        <p className='infoData2'>
                            {yearsDuration}
                        </p>
                    </div>
                    <div className='periodBox me-3'>
                        <h3>شهور</h3>
                        <p className='infoData2'>
                            {monthsDuration}
                        </p>
                    </div>
                    <div className='periodBox me-3'>
                        <h3>أيام</h3>
                        <p className='infoData2'>
                            {daysDuration}
                        </p>
                    </div>
                </Col>
            </Row>
            <Row className='mt-3'>
                <Col md="6">
                    <NumberInput
                        titleText='الايجار السنوي'
                        inputName='yearly-rent'
                        value={yearlyRent}
                        setValue={setYearlyRent}
                    />
                </Col>
            </Row>
            {!contractTypeHousing && location.pathname !== "/contracts-edit" ? <SectionCommercialPart /> : ""}
        </StyledSection1>
    );
}

export default Section1;