import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import styled, { css } from "styled-components";
import Section1 from "./sections/Section-1";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { AlertContext } from "../../../../context/AlertContext";
import { useContext, useEffect, useState } from "react";
import api from '../../../../api/areas'
import SupervisorSection2 from "./sections/supervisor/SupervisorSection-2";
import PromoterSection2 from "./sections/promoter/PromoterSection-2";
import BrokerAssistantSection2 from "./sections/broker-assistant/BrokerAssistantSection-2";
import { TopbarContext } from "../../../../context/TopbarContext";

const StyledViewUser = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    padding: 2.5rem 1.75rem;

    ${({ theme }) => {
        return css`
            .edit-link{
                color: ${theme.colors.secondary};
                background-color: ${theme.colors.secondary_1};
                border: 1px solid ${theme.colors.secondary_2};
                border-radius: 0.5rem;
            }
        `;
    }}
    
`;

const ViewUser = () => {
    const [params, setParams] = useSearchParams();
    const [userData, setUserData] = useState({});
    const navigate = useNavigate();
    const alertContext = useContext(AlertContext);
    const { toggleTopbarTitle } = useContext(TopbarContext);

    useEffect(() => {
        api.get(`/users?id=${params.get('id')}`).then(result => {
            setUserData(result.data[0]);
            if (result.data.length === 0) {
                alertContext.toggleAlert(true);
                alertContext.toggleAlertData({
                    heading: 'مستخدم مجهول',
                    message: 'لم يتم العثور علي المستخدم المطلوب',
                    variant: 'warning',
                });
                navigate("/");
            } else {
                toggleTopbarTitle(`عرض بيانات ${result.data[0].rule}`)
            }
        });
    }, [])

    const getSection2 = () => {
        switch (userData.rule) {
            case null:
                return '';
                break;
            case undefined:
                return '';
                break;
            case "مشرف":
                return <SupervisorSection2 />
                break;
            case "مساعد وسيط":
                return <BrokerAssistantSection2 />;
                break;
            case "مروج":
                return <PromoterSection2 />;
                break;
            case "خدمة عملاء":
                return '';
                break;
            default:
                return '';
        }
    }

    return (
        <StyledViewUser>
            <Container fluid>
                <Row className="mb-3">
                    <Col className='d-flex align-items-center justify-content-end py-2'>
                        <Link to={`/users/edit?id=${params.get('id')}`} className="py-2 px-3 edit-link">
                            تعديل الملف الشخصي
                        </Link>
                    </Col>
                </Row>
                <Section1 userData={userData} />

                {getSection2()}
            </Container>
        </StyledViewUser>
    )
}

export default ViewUser;