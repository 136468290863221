import React, { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useSearchParams, Link } from 'react-router-dom';
import styled from 'styled-components';
import Section1 from './Section-1';
import { TenantDetailsEditContextProvider } from '../edit/TenantDetailsEditContext';



const StyledEscortDetails = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    padding: 2.5rem 1.75rem;

`;
const EscortDetails = () => {
    const [params, setParams] = useSearchParams();

    useEffect(() => {

    }, [])

    const handleSave = () => {
        console.log('hi');
    }
    return (
        <TenantDetailsEditContextProvider tenantId={params.get('id')} gettingTenantId={false}>
            <StyledEscortDetails>
                <Container fluid>
                    <Section1 />

                    <Row className="mt-3">
                        <Col className='d-flex align-items-center justify-content-end py-0'>
                            <Link
                                to={`/tenant-view?id=${params.get('id')}`}
                                className="py-2 px-3 return-link me-2"
                            >
                                رجوع
                            </Link>
                            <span onClick={handleSave} className="py-2 px-3 save-link">
                                حفظ
                            </span>
                        </Col>
                    </Row>
                </Container>
            </StyledEscortDetails>
        </TenantDetailsEditContextProvider>
    );
}

export default EscortDetails;