import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { MdAccountCircle } from 'react-icons/md';
import Moment from 'react-moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import moment from 'moment';
import PlainText from '../../../pages-parts/display-info/standard/PlainText';


const StyledSection2 = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    margin-bottom: 3rem;
    padding: 1rem 1.75rem;
    background-color: ${({ theme }) => theme.colors.background.elements};
    border-radius: 0.75rem;
    position: relative;
    border: 1px solid #ccc;

    
    
    
    .infoData{
        color: ${({ theme }) => theme.colors.text.primary};
    }
    .scroll-thumb{
        background-color: ${({ theme }) => theme.colors.primary_8};
    }
`;

const Section2 = ({ ownerData }) => {

    const [displayData, setDisplayData] = useState([]);

    useEffect(() => {

        setDisplayData([
            {
                title: 'المنطقة',
                value: 'الرياض',
                hasNumber: false
            },
            {
                title: 'المدينة',
                value: 'الرياض',
                hasNumber: false
            },
            {
                title: 'الحي',
                value: 'جلاجل',
                hasNumber: false
            },
            {
                title: 'الرمز البريدي',
                value: "1295",
                hasNumber: true
            },
            {
                title: 'اسم الشارع',
                value: '-',
                hasNumber: false
            },
            {
                title: 'رقم المبنى',
                value: "74",
                hasNumber: true
            },
            {
                title: 'رقم اضافي',
                value: "0017",
                hasNumber: true
            },
        ]);
    }, [ownerData]);

    return (
        <StyledSection2>
            <h2 className='sectionFloatTitle'>العنوان في المملكة العربية السعودية</h2>

            <Row className='mt-2'>
                <Col md="6" className='mb-2'>
                    {displayData.map((item, i) => (
                        <PlainText
                            key={i}
                            title={item.title}
                            value={item.value}
                            hasNumber={item.hasNumber}
                        />
                    ))}
                </Col>
            </Row>
        </StyledSection2>
    );
}

export default Section2;