import React, { useContext, useEffect, useRef, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';
import Select from 'react-select';
import { Calendar } from 'react-date-range';
import moment from 'moment';
import StaticSelect from '../../../../pages-parts/inputs/static-select-input/StaticSelect';
import TextInput from '../../../../pages-parts/inputs/text-input/TextInput';
import NumberInput from '../../../../pages-parts/inputs/number-input/NumberInput';
import DatePickerInput from '../../../../pages-parts/inputs/date-picker/DatePickerInput';
import { TenantDetailsEditContext } from '../../edit/TenantDetailsEditContext';
import { useSearchParams, Link } from 'react-router-dom';
import AddNewButton from '../../../../pages-parts/inputs/add-new-btn/AddNewButton';

const StyledSection1 = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    margin-bottom: 3rem;
    padding: 1rem 1.75rem;
    background-color: ${({ theme }) => theme.colors.background.elements};
    border-radius: 0.75rem;
    position: relative;
    border: 1px solid #ccc;
    max-width: 1024px;
    margin: 0 auto 3rem;

    

    &:last-child{
        margin-bottom: 0;
    }
    .headContainer{
        h3{
            font-size: 1.5rem;
            color: ${({ theme }) => theme.colors.text.secondary};
            margin-bottom: 0;
        }
        p{
            color: ${({ theme }) => theme.colors.text.primary};
            
        }

        h4 {
            display: flex;
            align-items: center;
            p{
                color: ${({ theme }) => theme.colors.text.secondary};
                margin-left: .75rem;
            }
            .status{
                display: inline-block;
                padding: 0.25rem 1rem;
                font-size: 14px;
                border-radius: .75rem;
                background-color: ${({ theme }) => theme.colors.secondary_2};
            }
        }
    }
    p{
        margin: 0;
    }
    
    .infoData{
        padding: .75rem 3rem;
        border-radius: 0.75rem;
        border: 1px solid #ccc;
    }

    
`;

const Section1 = () => {
    const [params, setParams] = useSearchParams();
    const {
        fullName,
        selectedIdTypeOption,
    } = useContext(TenantDetailsEditContext);
    return (
        <StyledSection1>
            <Row className='my-0 headContainer'>
                <Col className='mb-2'>
                    <h3>
                        {fullName}
                    </h3>
                    <p className='m-0'>
                        <span>{selectedIdTypeOption.label}:</span> #{params.get('id').replace(/(-.*)/g, "")}
                    </p>
                </Col>
                <Col className='mb-2 text-end'>
                    <AddNewButton
                        title="تعديل"
                        url={`/tenant/edit?id=${params.get('id')}`}
                        isLink={true}
                        hasIcon={false}
                    />
                </Col>
            </Row>
        </StyledSection1>
    );
}

export default Section1;