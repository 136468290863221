import React, { useContext, useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';
import profileImage1 from '../../../../../images/pic1.png';
import profileImage2 from '../../../../../images/1.png';
import { UserContext } from '../../../../../context/UserContext';
import PlainText from '../../../../pages-parts/display-info/standard/PlainText';

const StyledSection1 = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    padding: 1rem 1.75rem;
    background-color: ${({ theme }) => theme.colors.background.elements};
    border-radius: 0.75rem;
    position: relative;
    border: 1px solid #ccc;

    
    
    


    span img{
        width: 6rem;
        height: 6rem;
        border-radius: 0.75rem;
    }
`;

const Section1 = ({ userData }) => {
    const [displayData, setDisplayData] = useState([]);

    useEffect(() => {

        setDisplayData([
            {
                title: 'الاسم بالكامل',
                value: userData.name,
                hasNumber: false
            },
            {
                title: 'البريد الالكتروني',
                value: userData.email,
                hasNumber: true
            },
            {
                title: 'رقم الجوال',
                value: userData.phoneNum,
                hasNumber: true
            },
            {
                title: 'الحساب مفعل',
                value: (
                    <Form.Check
                        type="switch"
                        id="custom-switch"
                        label=""
                        defaultChecked={true}
                    />
                ),
                hasNumber: false
            },
            {
                title: 'نوع الحساب',
                value: userData.rule,
                hasNumber: false
            },
        ]);
    }, [userData]);

    return (
        <StyledSection1 className='mb-5'>
            <h2 className='sectionFloatTitle'>البيانات الاساسية</h2>
            <Row className='flex-row-reverse'>
                <Col
                    xs='12'
                    sm='3'
                    className='
                        d-flex
                        flex-column
                        align-items-center
                        align-items-sm-end
                        justify-content-between
                        mb-3
                        mb-sm-0
                    '
                >
                    <span>
                        <img src={profileImage2} alt="Profile" />
                    </span>
                    <Button variant="outline-danger">ايقاف الحساب</Button>
                </Col>
                <Col xs='12' sm='9' className='pt-2'>
                    {displayData.map((item, i) => (
                        <PlainText
                            key={i}
                            title={item.title}
                            value={item.value}
                            hasNumber={item.hasNumber}
                        />
                    ))}
                </Col>
            </Row>
        </StyledSection1>
    );
}

export default Section1;