import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import profileImage from '../../../../images/1.png';
import TableComponent from '../../../pages-parts/table/TableComponent';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import Scrollbars from 'react-custom-scrollbars-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const StyledSection4 = styled.div`
    margin-bottom: 3rem;
`
const StyledSection4Box = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    padding: 1.5rem 0;
    background-color:${({ theme }) => theme.colors.background.elements};
    border-radius: 0.75rem;
    position: relative;
    border: 1px solid #ccc;
    height: 100%;
    min-height: 450px;
    max-height: 450px;

    &.chart{
        padding: 1.5rem;
    }
    
    .scroll-thumb{
        background-color: ${({ theme }) => theme.colors.primary_8};
    }
`;
const Section4 = () => {
    const location = useLocation();
    const [columns, setColumns] = useState([
        {
            id: 1,
            Header: "#",
            Cell: e => {
                return <span className='numbers-font'>{e.value}</span>
            },
            accessor: "number",
            sortable: false,
        },
        {
            id: 2,
            Header: "اسم الموظف",
            accessor: "name",
            sortable: false,
            Cell: e => {
                return (
                    <Link to={`/users/view?id=${e.data.find(item => item.number === e.row.cells.find(cell => cell.column.Header === "#").value).id}`} className='d-flex align-items-center image-heading-text-link'>
                        <img src={profileImage} alt="Profile" />
                        <span>
                            <h4>
                                {e.value}
                            </h4>
                            <span className='numbers-font'><span>#</span> {e.data.find(item => item.number === e.row.cells.find(cell => cell.column.Header === "#").value).id.replace(/(-.*)/g, "")}...</span>
                        </span>
                    </Link>
                )
            }
        },
        {
            id: 3,
            Header: "الانتاج",
            accessor: "monthAchevment",
            sortable: false,
            Cell: e => {
                return <span className='numbers-font'>{e.value}</span>
            }
        },
    ]);

    const [tableDataUrl, setTableDataUrl] = useState(() => {
        return `/users?_sort=monthAchevment&_order=desc&_page=1&_limit=10`;
    })

    const labels = [
        "يناير",
        "فبراير",
        "مارس",
        "ابريل",
        "مايو",
        "يونيو",
        "يوليو",
        "اغسطس",
        "سبتمبر",
        "اكتوبر",
        "ديسمبر",
    ];

    const chartDate = [186, 202, 155, 97, 300, 269, 45, 159, 78, 277, 79, 83];
    const getChartBG = () => {
        let x = []
        chartDate.forEach(d => {
            if (d < 150) {
                x.push('rgba(226, 52, 40, 1)')
            } else {
                x.push('rgba(19, 88, 70, 1)')
            }
        })

        return x;
    }
    const data = {
        labels,
        datasets: [
            {
                label: 'عقود الشهر',
                data: chartDate,
                backgroundColor: getChartBG(),
            }
        ]
    }

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        barThickness: 10,
        borderRadius: 5,
        scales: {
            xAxis: {
                grid: {
                    display: false
                }
            },
            yAxis: {
                grid: {
                    display: false
                }
            },
        },
        plugins: {
            legend: {
                position: 'none',
            },
            title: {
                display: false,
                text: '',
            },
        },
    };

    return (
        <StyledSection4>

            <Row>
                <Col md="4" sm="6" xs="12">
                    <StyledSection4Box>
                        <h2 className='sectionFloatTitle'>الاكثر انتاجية هذا الشهر</h2>
                        <Scrollbars
                            renderView={props => (
                                <div {...props} style={{
                                    ...props.style,
                                    marginLeft: props.style.marginRight,
                                    marginRight: 0,
                                }} />
                            )}
                            renderTrackVertical={props => (
                                <div {...props} style={{
                                    ...props.style,
                                    left: 2,
                                    bottom: 20,
                                    top: 20,
                                    right: 'auto',
                                    borderRadius: 3,
                                }} />
                            )}
                            renderTrackHorizontal={
                                props => (<div {...props} style={{ display: 'none' }} className="track-horizontal" />)
                            }
                            style={{ width: "100%", height: "100%" }}
                        >
                            <TableComponent
                                columns={columns}
                                tableDataUrl={tableDataUrl}
                                setSortTable={() => { }}
                                tableWidth={1}
                                setPageNum={() => { }}
                                pageNum={() => { }}
                                tableRowsPerPage={() => { }}
                                hasPagination={false}
                            />
                        </Scrollbars>
                    </StyledSection4Box>
                </Col>
                <Col md="8" sm="6" xs="12">
                    <StyledSection4Box className='chart'>
                        <h2 className='sectionFloatTitle'>جدول عقود <span className='numbers-font'>2022</span></h2>
                        <Bar options={options} data={data} />
                    </StyledSection4Box>
                </Col>
            </Row>

        </StyledSection4>
    );
}

export default Section4;