import moment from 'moment';
import React, { useState, createContext, useEffect, useContext } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { TopbarContext } from '../../../../context/TopbarContext';
import api from '../../../../api/areas';
import { AlertContext } from '../../../../context/AlertContext';


export const OwnerDetailsEditContext = createContext();

export const OwnerDetailsEditContextProvider = ({ children }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { toggleTopbarTitle } = useContext(TopbarContext);
    const alertContext = useContext(AlertContext);
    // inputs
    // الاسم
    const [fullName, setFullName] = useState('');
    // نوع الهوية
    const [idTypeOptions, setIdTypeOptions] = useState([
        {
            value: 'national-id',
            label: 'هوية وطنية',
        },
        {
            value: 'special-stay',
            label: 'اقامة مميزة',
        },
        {
            value: 'resident-id',
            label: 'اقامة',
        },
        {
            value: 'gcc',
            label: 'مجلس التعاون الخليجي',
        },
        {
            value: 'passport',
            label: 'جواز سفر',
        },
        {
            value: 'another',
            label: 'اخر',
        },
    ]);
    const [selectedIdTypeOption, setSelectedIdTypeOption] = useState(idTypeOptions[0]);
    const handleChangeIdType = (selectOption) => {
        setSelectedIdTypeOption(selectOption)
    }
    // id number
    const [idNumber, setIdNumber] = useState('')
    // تاريخ الميلاد
    const [birthDate, setBirthDate] = useState(new Date(moment().add(3, "years")));
    // الصفة objective
    const [ownerTypeOptions, setOwnerTypeOptions] = useState([
        {
            value: 'single-owner',
            label: 'مستأجر كفرد',
        },
        {
            value: 'single-representative',
            label: 'ممثل مستأجر كفرد',
        },
        {
            value: 'establishment-owner',
            label: 'مستأجر كمؤسسة',
        },
        {
            value: 'establishment-representative',
            label: 'ممثل مستأجر كمؤسسة',
        },
    ]);
    const [selectedOwnerTypeOption, setSelectedOwnerTypeOption] = useState({
        value: 'single-owner',
        label: 'مستأجر كفرد',
    });
    const handleChangeOwnerType = (selectOption) => {
        setSelectedOwnerTypeOption(selectOption)
    }
    // البريد الالكتروني
    const [email, setEmail] = useState('');
    // nationality
    const [nationalityOptions, setNationalityOptions] = useState([
        {
            value: 'oman',
            label: 'عمان',
        },
        {
            value: 'emirates',
            label: 'الإمارات',
        },
        {
            value: 'kuwait',
            label: 'الكويت',
        },
        {
            value: 'qatar',
            label: 'قطر',
        },
    ]);
    const [selectedNationalityOption, setSelectedNationalityOption] = useState(nationalityOptions[0]);
    const handleChangeNationality = (selectOption) => {
        setSelectedNationalityOption(selectOption)
    }
    // Country of Issue بلد الاصدار
    const [countryOfIssueOptions, setCountryOfIssueOptions] = useState([
        {
            value: 'oman',
            label: 'عمان',
        },
        {
            value: 'emirates',
            label: 'الإمارات',
        },
        {
            value: 'kuwait',
            label: 'الكويت',
        },
        {
            value: 'qatar',
            label: 'قطر',
        },
    ]);
    const [selectedCountryOfIssueOption, setSelectedCountryOfIssueOption] = useState(countryOfIssueOptions[0]);
    const handleChangeCountryOfIssue = (selectOption) => {
        setSelectedCountryOfIssueOption(selectOption)
    }
    // نوع المؤسسة
    const [establishmentTypeOptions, setEstablishmentTypeOptions] = useState([
        {
            value: 'commercial',
            label: 'تجارية',
        },
        {
            value: 'non-prophet',
            label: 'غير ربحية',
        },
        {
            value: 'license-number',
            label: 'رقم رخصة',
        }
    ]);
    const [selectedEstablishmentTypeOption, setSelectedEstablishmentTypeOption] = useState(establishmentTypeOptions[0]);
    const handleChangeEstablishmentType = (selectOption) => {
        setSelectedEstablishmentTypeOption(selectOption)
    }
    // نوع المؤسسة
    const [establishmentId, setEstablishmentId] = useState('');
    // الرقم الضريبي
    const [taxNumber, setTaxNumber] = useState('');
    // المنطقة
    const [areaDBUrl, setAreaDBUrl] = useState('areas?');
    const [selectedArea, setSelectedArea] = useState({
        value: 'no-value',
        label: 'غير محدد',
    });
    const handleChangeSelectedAreaOption = (selectOption) => {
        setSelectedArea(selectOption)
    }
    // المدينة
    const [cityDBUrl, setCityDBUrl] = useState(`cities?area_id=${selectedArea.value}&`);
    const [selectedCity, setSelectedCity] = useState({
        value: 'no-value',
        label: 'غير محدد',
    });
    const handleChangeSelectedCityOption = (selectOption) => {
        setSelectedCity(selectOption)
    }
    // الحي
    const [neighborhoodOptions, setNeighborhoodOptions] = useState([
        {
            value: 'no-value',
            label: 'غير محدد',
        },
        {
            value: 1,
            label: 'لبن',
        },
        {
            value: 2,
            label: 'درّة الرياض',
        },
        {
            value: 3,
            label: 'حي الموسى',
        },
        {
            value: 4,
            label: 'النظيم',
        },
        {
            value: 5,
            label: 'تلال الرياض',
        },
    ]);
    const [selectedNeighborhood, setSelectedNeighborhood] = useState(neighborhoodOptions[0]);
    const handleChangeSelectedNeighborhood = (selectOption) => {
        setSelectedNeighborhood(selectOption)
    }
    // الرمز البريدى
    const [zipCode, setZipCode] = useState('');
    // اسم الشارع
    const [streetName, setStreetName] = useState('');
    // رقم المبني
    const [buildingNumber, setBuildingNumber] = useState('');
    // رقم اضافي
    const [additionalNumber, setAdditionalNumber] = useState('');
    // رقم الهاتف
    const [phoneNum, setPhoneNum] = useState('');
    const [phoneCountryCode, setPhoneCountryCode] = useState('');
    const handleChangePhoneCountryCode = (value) => {
        setPhoneCountryCode(value);
    }
    // نوع المنشأة الممثل
    const [representativeTypeOptions, setRepresentativeTypeOptions] = useState([
        {
            value: 'electronic-legal-agency',
            label: 'وكالة شرعية الكترونية',
        },
        {
            value: 'legal-agency',
            label: 'وكالة شرعية',
        },
        {
            value: 'legitimate-mandate',
            label: 'ولاية شرعية',
        },
        {
            value: 'injunction',
            label: 'أمر قضائي',
        },
        {
            value: 'waqf-monjez',
            label: 'وقف منجز',
        },
        {
            value: 'partners-decision',
            label: 'قرار الشركاء',
        },
        {
            value: 'establishment-contract',
            label: 'عقد تأسيس الشركة',
        },
        {
            value: 'others',
            label: 'أخر',
        },
    ]);
    const [selectedRepresentativeTypeOption, setSelectedRepresentativeTypeOption] = useState(representativeTypeOptions[0]);
    const handleChangeRepresentativeType = (selectOption) => {
        setSelectedRepresentativeTypeOption(selectOption)
    }
    // رقم وثيقة الممثل
    const [representativeDocumentNumber, setRepresentativeDocumentNumber] = useState('');
    // تاريخ انشاء وثيقة الممثل
    const [representativeDocumentReleaseDate, setRepresentativeDocumentReleaseDate] = useState(new Date(moment().subtract(3, "years")));
    // تاريخ انتهاء وثيقة الممثل
    const [representativeDocumentExpireDate, setRepresentativeDocumentExpireDate] = useState(new Date(moment().add(1, "years")));
    // تم الانشاء بواسطة
    const [representativeDocumentReleasedBy, setRepresentativeDocumentReleasedBy] = useState('');
    // تم الانشاء في
    const [representativeDocumentReleasedIn, setRepresentativeDocumentReleasedIn] = useState('');
    // اسم نوع الوثيقة القانونية
    const [representativeLegalDocumentName, setRepresentativeLegalDocumentName] = useState('');
    // البطاقات الائتمانية
    const [creditCards, setCreditCards] = useState([
        {
            id: "card-1",
            cardName: "بطاقة بنك ساب",
            ibanNumber: "6542 8455 7812 4654",
            cardOwnerName: "عبدالعزيز خالد محمود محسن",
            bankName: "SABB",
            swiftCode: "SABBSARIXXX",
            bankAddress: "شارع خالد بن الوليد، مع, King Abdullah Rd with Khalid Ibn Al Walid St تقاطع طريق الملك عبدالله, الرياض 13217، المملكة العربية السعودية",
        },
    ]);

    const addNewCreditCard = () => {
        setCreditCards(prev => {
            return [
                ...prev,
                {
                    id: `card-${moment().format()}-${prev.length + 1}`,
                    cardName: document.querySelector('input[name=card-name]').value,
                    ibanNumber: document.querySelector('input[name=iban-number]').value,
                    cardOwnerName: document.querySelector('input[name=card-owner-name]').value,
                    bankName: document.querySelector('input[name=bank-name]').value,
                    swiftCode: document.querySelector('input[name=swift-code]').value,
                    bankAddress: document.querySelector('input[name=bank-address]').value
                }
            ]
        })
    }

    useEffect(() => {
        setCityDBUrl(`cities?area_id=${selectedArea.value}&`)
        setSelectedCity({
            value: 'no-value',
            label: 'غير محدد',
        })
    }, [selectedArea])
    useEffect(() => {
        setSelectedNeighborhood({
            value: 'no-value',
            label: 'غير محدد',
        })
    }, [selectedCity])

    const getOwnerData = ownerId => {
        api.get(`/owners?id=${ownerId}`).then(result => {
            if (result.data.length === 0) {
                alertContext.toggleAlert(true);
                alertContext.toggleAlertData({
                    heading: 'مالك / ممثل مجهول',
                    message: 'لم يتم العثور علي المالك / الممثل المطلوب',
                    variant: 'warning',
                });
                navigate("/");
            } else {
                if (location.pathname === "/owner-edit") {
                    toggleTopbarTitle(`تعديل بيانات ${result.data[0].userType}`)
                }
                setFullName(result.data[0].ownerOrRepresentative);
                setSelectedOwnerTypeOption(prev => {
                    if (result.data[0].userType === "ممثل") {
                        return {
                            value: 'single-representative',
                            label: 'ممثل مستأجر كفرد',
                        };
                    } else {
                        return {
                            value: 'single-owner',
                            label: 'مستأجر كفرد',
                        };
                    }
                })
            }
        });
    };

    useEffect(() => {
        const another = [
            {
                value: 'egypt',
                label: 'مصر',
            },
            {
                value: 'tunisia',
                label: 'تونس',
            },
            {
                value: 'algeria',
                label: 'الجزائر',
            },
            {
                value: 'lebanon',
                label: 'لبنان',
            },
        ];

        const gcc = [
            {
                value: 'oman',
                label: 'عمان',
            },
            {
                value: 'emirates',
                label: 'الإمارات',
            },
            {
                value: 'kuwait',
                label: 'الكويت',
            },
            {
                value: 'qatar',
                label: 'قطر',
            },
        ];

        if (
            selectedIdTypeOption.value === "special-stay" ||
            selectedIdTypeOption.value === "resident-id" ||
            selectedIdTypeOption.value === "passport" ||
            selectedIdTypeOption.value === "another"
        ) {
            setNationalityOptions(another);
            setCountryOfIssueOptions(another);
        } else if (selectedIdTypeOption.value === "gcc") {
            setNationalityOptions(gcc)
            setCountryOfIssueOptions(gcc)
        }
    }, [selectedIdTypeOption])

    useEffect(() => {
        setSelectedNationalityOption(nationalityOptions[0]);
    }, [nationalityOptions])
    useEffect(() => {
        setSelectedCountryOfIssueOption(countryOfIssueOptions[0]);
    }, [countryOfIssueOptions])

    const handleSave = () => {
        console.log('hi');
    }

    const values = {
        getOwnerData,
        handleSave,
        // الاسم
        fullName,
        setFullName,
        // نوع الهوية
        idTypeOptions,
        handleChangeIdType,
        selectedIdTypeOption,
        // id number
        idNumber,
        setIdNumber,
        // تاريخ الميلاد
        birthDate,
        setBirthDate,
        // الصفة objective
        ownerTypeOptions,
        handleChangeOwnerType,
        selectedOwnerTypeOption,
        // nationality
        nationalityOptions,
        handleChangeNationality,
        selectedNationalityOption,
        // Country of Issue بلد الاصدار
        countryOfIssueOptions,
        handleChangeCountryOfIssue,
        selectedCountryOfIssueOption,
        // نوع المؤسسة
        establishmentTypeOptions,
        handleChangeEstablishmentType,
        selectedEstablishmentTypeOption,
        // نوع المؤسسة
        establishmentId,
        setEstablishmentId,
        // الرقم الضريبي
        taxNumber,
        setTaxNumber,
        // المنطقة
        areaDBUrl,
        handleChangeSelectedAreaOption,
        selectedArea,
        // المدينة
        cityDBUrl,
        handleChangeSelectedCityOption,
        selectedCity,
        // الحي
        neighborhoodOptions,
        handleChangeSelectedNeighborhood,
        selectedNeighborhood,
        // الرمز البريدى
        zipCode,
        setZipCode,
        // اسم الشارع
        streetName,
        setStreetName,
        // رقم المبني
        buildingNumber,
        setBuildingNumber,
        // رقم اضافي
        additionalNumber,
        setAdditionalNumber,
        // رقم الهاتف
        phoneNum,
        setPhoneNum,
        phoneCountryCode,
        handleChangePhoneCountryCode,
        // البريد الالكتروني
        email,
        setEmail,
        // نوع المنشأة
        representativeTypeOptions,
        handleChangeRepresentativeType,
        selectedRepresentativeTypeOption,
        // رقم الوثيقة
        representativeDocumentNumber,
        setRepresentativeDocumentNumber,
        // تاريخ انشاء وثيقة الممثل
        representativeDocumentReleaseDate,
        setRepresentativeDocumentReleaseDate,
        // تاريخ انتهاء وثيقة الممثل
        representativeDocumentExpireDate,
        setRepresentativeDocumentExpireDate,
        // تم الانشاء بواسطة
        representativeDocumentReleasedBy,
        setRepresentativeDocumentReleasedBy,
        // تم الانشاء في
        representativeDocumentReleasedIn,
        setRepresentativeDocumentReleasedIn,
        // اسم نوع الوثيقة القانونية
        representativeLegalDocumentName,
        setRepresentativeLegalDocumentName,
        // البطاقات الائتمانية
        creditCards,
        addNewCreditCard,
    };

    return (
        <OwnerDetailsEditContext.Provider value={values}>
            {children}
        </OwnerDetailsEditContext.Provider>
    )
}

