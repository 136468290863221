import React, { useContext, useEffect, useRef, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';
import Select from 'react-select';
import { Calendar } from 'react-date-range';
import moment from 'moment';
import StaticSelect from '../../../pages-parts/inputs/static-select-input/StaticSelect';
import TextInput from '../../../pages-parts/inputs/text-input/TextInput';
import NumberInput from '../../../pages-parts/inputs/number-input/NumberInput';
import DatePickerInput from '../../../pages-parts/inputs/date-picker/DatePickerInput';
import { TenantDetailsEditContext } from '../edit/TenantDetailsEditContext';
import EmailInput from '../../../pages-parts/inputs/email-input/EmailInput';
import SingleEscort from './SingleEscort';

const StyledSection1 = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    margin-bottom: 3rem;
    padding: 2rem 1.75rem 1rem;
    background-color: ${({ theme }) => theme.colors.background.elements};
    border-radius: 0.75rem;
    position: relative;
    border: 1px solid #ccc;
    &:last-child{
        margin-bottom: 0;
    }
    
    p{
        margin-bottom: 0;
    }
    
    
    .infoData{
        width: 12rem;
        position: relative;
        
        
    }
    .periodBox{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h3{
            font-size: 14px;
            color: ${({ theme }) => theme.colors.text.secondary};
            margin-bottom: 0;
            margin-top: .25rem;
        }
        .infoData2{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 3rem;
            padding: 0.5rem 0;
            margin-top: .5rem;
            border-radius: 0.5rem;
            border: 1px solid #ccc;
            background-color: ${({ theme }) => theme.colors.background.elements};
        }
    }
    span img{
        width: 6rem;
        height: 6rem;
        border-radius: 0.75rem;
    }
`;

const Section1 = () => {
    const {
        escorts,
    } = useContext(TenantDetailsEditContext);

    return (
        <>
            {escorts.map((item, index) => {
                return (
                    <SingleEscort key={item.id} item={item} index={index + 1} />
                )
            })}
        </>
    );
}

export default Section1;