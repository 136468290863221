import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { FaBed, FaBath } from 'react-icons/fa';
import { MdOutlineLiving } from 'react-icons/md';
import Moment from 'react-moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import api from '../../../api/areas';
import AsyncSelect from 'react-select/async';
import moment from 'moment';
import { customSelectStyles } from '../../../styles/Global';


const StyledCreateNewNotification = styled.div`
    position: relative;
    transition: all .5s ease-in-out;
    margin: 3rem 1.75rem;
    padding: 1rem 1.75rem;
    background-color: ${({ theme }) => theme.colors.background.elements};
    border-radius: 0.75rem;
    border: 1px solid #ccc;


    input[name=send-to]{
        background-color: ${({ theme }) => theme.colors.background.body};
        border-color: ${({ theme }) => theme.colors.primary_2};
        transition: none;
        text-align: right;
        &:focus{
            box-shadow: 0 0 0 0.25rem ${({ theme }) => theme.colors.primary_2};
            border: none;
            background-color: ${({ theme }) => theme.colors.primary};
        }
        &:checked{
            box-shadow: 0 0 0 0.25rem ${({ theme }) => theme.colors.primary_2};
            background-color: ${({ theme }) => theme.colors.primary};
            border: none;
        }
    }

    textarea[name=notification-text]{
        background-color: ${({ theme }) => theme.colors.background.body};
        transition: none;
        text-align: right;
        &:focus{
            box-shadow: 0 0 0 0.25rem ${({ theme }) => theme.colors.primary_2};
            border: none;
        }
    }

    
    
    .infoData{
        width: 12rem;
    }

`;

const CreateNewNotification = () => {
    const toArea = useRef();
    const [zones, setZones] = useState([]);
    const [isArea, setIsArea] = useState(false);

    useEffect(() => {

        const respond = api.get(`/areas?_page=1`).then(res => {
            let values = [];
            res.data.forEach(item => {
                const option = {
                    value: item.id,
                    label: item.area,
                }
                values.push(option);
            });
            setZones(values);
        }).catch(err => {
            console.log(err)
        });
    }, []);

    const loadOptionsAreas = async (searchValue, callback) => {
        try {
            const respond = await api.get(`/areas?q=${searchValue}&_page=1`).then(res => {
                let values = [];
                res.data.forEach(item => {
                    const option = {
                        value: item.id,
                        label: item.area,
                    }
                    values.push(option);
                });
                callback(values)
            }).catch(err => {
                console.log(err)
            });
        } catch (err) {
            console.log(err)
        }

    }

    const handleChange = () => {
        if (toArea.current.checked) {
            setIsArea(true);
        } else {
            setIsArea(false);
        }
    }
    return (
        <StyledCreateNewNotification>

            <Row className='my-3 '>
                <Col>
                    <Form.Check
                        type='radio'
                        name='send-to'
                        id="all"
                        defaultChecked={true}
                        label='الكل'
                        onChange={handleChange}
                        value='0'
                    />
                </Col>
                <Col>
                    <Form.Check
                        type='radio'
                        name='send-to'
                        id="area"
                        label='منطقة معينة'
                        ref={toArea}
                        onChange={handleChange}
                        value='1'
                    />
                </Col>
                <Col>
                    <Form.Check
                        type='radio'
                        name='send-to'
                        id="expire-soon"
                        label='عقود تنتهي قريبا'
                        onChange={handleChange}
                        value='2'
                    />
                </Col>
            </Row>
            <Row className='my-3'>
                <Col>
                    {isArea ? (
                        <div className='data-line'>
                            <span className='infoTitle'>
                                أختر المنطقة
                            </span>
                            <span className='infoData'>
                                <AsyncSelect
                                    loadOptions={loadOptionsAreas}
                                    isMulti
                                    styles={customSelectStyles}
                                    defaultOptions={zones}
                                />
                            </span>
                        </div>
                    ) : ""}
                </Col>
            </Row>
            <Row className='my-3 '>
                <Col md="9">
                    <Form.Control as="textarea" rows={3} name="notification-text" />
                    <Row className='mt-2'>
                        <Col className='d-flex align-items-center justify-content-end py-2'>
                            <Link to={`/`} className="py-2 px-3 return-link me-2">
                                رجوع
                            </Link>
                            <span className="py-2 px-3 save-link">
                                حفظ
                            </span>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </StyledCreateNewNotification>
    );
}

export default CreateNewNotification;