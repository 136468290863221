import { BiLockAlt } from 'react-icons/bi';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';

const PasswordInput = () => {
    return (
        <InputGroup className="mb-3">
            <InputGroup.Text id="password" className='py-2 px-3 border-end-0'>
                <BiLockAlt />
            </InputGroup.Text>
            <Form.Control
                placeholder="كلمة المرور"
                aria-label="password"
                aria-describedby="password"
                className='py-2 px-3 border-start-0'
                type='password'
                name='password'
            />
        </InputGroup>
    )
}

export default PasswordInput;