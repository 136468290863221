import { useEffect, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import { FaRegTrashAlt } from 'react-icons/fa';
import moment from 'moment';
import { Calendar } from 'react-date-range';

function NewFlexibleField({ inputName, value, setValue, date, setDate, removeField }) {
    const calenderBoxRef = useRef();

    const handleClickWindow = () => {
        calenderBoxRef.current.classList.add("d-none");
    }
    const toggleThisCalender = e => {
        e.stopPropagation();
        const eles = document.getElementsByClassName('datepicker-container')
        Array.prototype.forEach.call(eles, ele => {
            ele.classList.add("d-none");
        })
        calenderBoxRef.current.classList.toggle("d-none");
    }
    const handleChangingDate = e => {
        e.stopPropagation();
    }
    useEffect(() => {
        calenderBoxRef.current.classList.add("d-none");
    }, [date]);
    useEffect(() => {
        window.addEventListener('click', handleClickWindow);

        return function () {
            window.removeEventListener('click', handleClickWindow)
        }
    }, []);

    const handleChangeAppointmentValue = e => {
        setValue(e.target.value.replace(/([^0-9])/g, ''));
    }

    return (
        <div className='data-line'>
            <span className='infoTitle calender-input numbers-font'>
                <p className="mb-0" onClick={toggleThisCalender}>{moment(date).format('ll')}</p>
                <div
                    className='datepicker-container'
                    onClick={handleChangingDate}
                    ref={calenderBoxRef}
                >
                    <Calendar onChange={item => setDate(item)} date={date} />
                </div>
            </span>
            <span className='infoData'>
                <Form.Control
                    className='py-1 px-3 myInput'
                    type='text'
                    value={value}
                    name={inputName}
                    onChange={handleChangeAppointmentValue}
                />
            </span>
            <span className='customFieldRemoveIcon'>
                <FaRegTrashAlt className='icon' onClick={() => removeField(inputName)} />
            </span>
        </div>
    );
}

export default NewFlexibleField;