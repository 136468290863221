import React, { useContext, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useSearchParams, Link, useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';
import StartingBalanceSection from '../../contract-finance-records/edit/section-3/Section-3';
import { ContractDetailsEditContext } from '../ContractDetailsEditContext';




const StyledStartingBalanceEdit = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    padding: 0;
    

    .infoTitle{
        width: 14rem!important;
    }

    & > *:last-child{
        margin-bottom: 0;
    }

`;

const StartingBalanceEdit = () => {
    const {
        duration,
    } = useContext(ContractDetailsEditContext)

    return (
        <StyledStartingBalanceEdit>
            <StartingBalanceSection />
        </StyledStartingBalanceEdit>
    );
}

export default StartingBalanceEdit;