import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";


const StyledTabs = styled.div`
    display: flex;

    & > .tab-filter{
        padding: 0 1rem .75rem;
        color: ${({ theme }) => theme.colors.text.secondary};
        border-bottom: 2px solid transparent;
        cursor: pointer;

        &:hover{
            border-bottom: 2px solid ${({ theme }) => theme.colors.secondary};
        }

        &.active{
            color: ${({ theme }) => theme.colors.secondary};
            border-bottom: 2px solid ${({ theme }) => theme.colors.secondary};
        }
    }
`;

const TabsFilter = ({ activeTab, setActiveTab, tabs }) => {

    const changePage = (e) => {
        const tabValue = parseInt(e.target.dataset['value']);
        setActiveTab(tabValue);
    }

    return (
        <StyledTabs>
            {tabs.map(tab => {
                return (
                    <div
                        className={`tab-filter ${tab.value === activeTab ? 'active' : ''}`}
                        onClick={changePage}
                        data-value={tab.value}
                        key={tab.id}
                    >
                        {tab.name}
                    </div>
                )
            })}
        </StyledTabs>
    )
}

export default TabsFilter