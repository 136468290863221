
function PlainText({ title, value, hasNumber=false }) {

    return (
        <div className={`data-line`}>
            <span className='infoTitle'>
                {title}
            </span>
            <span className={`infoData ${hasNumber ? 'numbers-font' : ''}`}>
                {value}
            </span>
        </div>
    );
}

export default PlainText;