import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { AiOutlineEye } from 'react-icons/ai';
import { FaRegEdit } from 'react-icons/fa';
import TableComponent from '../../pages-parts/table/TableComponent';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Entries from '../../pages-parts/table/Entries';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import CreateNewModal from '../../pages-parts/table/CreateNewModal';
import SearchBarFilter from '../../pages-parts/table/SearchFilter';


const StyledZones = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    padding: 2.5rem 1.75rem;
    .scroll-thumb{
        background-color: ${({ theme }) => theme.colors.primary_8};
    }
`;

const ZonesPage = () => {
    const location = useLocation();
    const [showEditModal, setShowEditModal] = useState(false);
    const [editModalValue, setEditModalValue] = useState({
        id: "",
        zoneName: ""
    });
    const [columns, setColumns] = useState([
        {
            id: 1,
            Header: "المناطق",
            accessor: "area",
            sortable: true,
            Cell: e => {
                return (
                    <div id={e.row.cells.find(cell => cell.column.Header === "الاجراءات").value}>
                        {e.value}
                    </div>
                )
            }
        },
        {
            id: 2,
            Header: "عدد المدن",
            accessor: "id",
            sortable: true,
            Cell: e => {
                return (
                    <div className='numbers-font'>
                        5
                    </div>
                )
            }
        },
        {
            id: 3,
            Header: "الاجراءات",
            accessor: "id",
            sortable: false,
            Cell: e => {
                return (
                    <>
                        <OverlayTrigger
                            key="view"
                            placement="bottom"
                            overlay={
                                <Tooltip id={`tooltip-view`}>
                                    عرض بيانات المنطقة
                                </Tooltip>
                            }
                        >
                            <Link to={`/zone-view?id=${e.value}`}><AiOutlineEye /></Link>
                        </OverlayTrigger>
                        <OverlayTrigger
                            key="edit"
                            placement="bottom"
                            overlay={
                                <Tooltip id={`tooltip-edit`}>
                                    تعديل المنطقة
                                </Tooltip>
                            }
                        >
                            <span className='edit-modal' onClick={() => handleEditZone(e.value)}><FaRegEdit /></span>
                        </OverlayTrigger>
                    </>
                )
            }
        },
    ]);
    const [searchTable, setSearchTable] = useState(() => {
        const filterStorage = JSON.parse(localStorage.getItem(location.pathname + '-filters')) ?? null;
        if (filterStorage !== null && filterStorage.search !== undefined) {
            return filterStorage.search;
        } else {
            return '';
        }
    });
    const [sortTable, setSortTable] = useState(() => {
        const filterStorage = JSON.parse(localStorage.getItem(location.pathname + '-filters')) ?? null;
        if (filterStorage !== null && filterStorage.sortFilter !== undefined) {
            return filterStorage.sortFilter;
        } else {
            return {
                column: '',
                direction: ''
            };
        }
    });
    const [pageNum, setPageNum] = useState(() => {
        return JSON.parse(localStorage.getItem(location.pathname + '-page-num')) ?? 1;
    });
    const [entries, setEntries] = useState(() => {
        return JSON.parse(localStorage.getItem('entries-per-page')) ?? 10;
    })
    const [tableDataUrl, setTableDataUrl] = useState(() => {

        // sort filter url part
        let sortFilter;
        if (sortTable.column !== "" && sortTable.column !== undefined) {
            sortFilter = `_sort=${sortTable.column}&_order=${sortTable.direction}&`;
        } else {
            sortFilter = "";
        }

        const url = `/areas?${sortFilter}q=${searchTable}&_page=${pageNum}&_limit=${entries}`;

        return url;
    })

    useEffect(() => {

        // sort filter url part
        let sortFilter;
        if (sortTable.column !== "" && sortTable.column !== undefined) {
            sortFilter = `_sort=${sortTable.column}&_order=${sortTable.direction}&`;
        } else {
            sortFilter = "";
        }

        const url = `/areas?${sortFilter}q=${searchTable}&_page=${pageNum}&_limit=${entries}`;
        setTableDataUrl(url);
    },
        [
            searchTable,
            sortTable,
            pageNum,
            entries,
        ]
    );

    useEffect(() => {
        const localFilter = {
            sortFilter: sortTable,
            search: searchTable,
        }
        localStorage.setItem(location.pathname + '-page-num', pageNum);
        localStorage.setItem('entries-per-page', entries);
        localStorage.setItem(location.pathname + '-filters', JSON.stringify(localFilter));

    }, [tableDataUrl]);

    const handleEditZone = (id) => {
        setShowEditModal(true);
        const zone = document.getElementById(id).innerText;
        setEditModalValue({
            id,
            zoneName: zone
        })
    }
    const handleClose = () => {
        setShowEditModal(false);
    }
    const handleSave = () => {
        const zone = document.getElementById(editModalValue.id);
        zone.innerText = editModalValue.zoneName;
        setShowEditModal(false);
    }
    return (
        <StyledZones>
            <Container fluid>
                <Row className='mb-3 flex-lg-row-reverse flex-md-row justify-content-between'>
                    <Col
                        md={5}
                        sm={12}
                        className='d-flex justify-content-end align-items-center mb-2'
                    >
                        <Entries
                            entries={entries}
                            setEntries={setEntries}
                        />
                        <SearchBarFilter
                            setSearchTable={setSearchTable}
                            searchTable={searchTable}
                            setPageNum={setPageNum}
                        />
                    </Col>
                    <Col md={5} sm={12}>
                        <CreateNewModal
                            text='إضافة منطقة'
                            modleContent={{
                                title: 'إضافة منطقة',
                                inputName: 'zone',
                                inputPlaceholder: 'اسم المنطقة'
                            }}
                        />
                    </Col>
                </Row>
                <TableComponent
                    columns={columns}
                    tableDataUrl={tableDataUrl}
                    setSortTable={setSortTable}
                    tableWidth={800}
                    setPageNum={setPageNum}
                    pageNum={pageNum}
                    tableRowsPerPage={entries}
                />
                <Modal show={showEditModal} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>تعديل المنطقة</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Control
                            className='py-2 px-3'
                            type='text'
                            name="edit-zone"
                            placeholder="اسم المنطقة"
                            value={editModalValue.zoneName}
                            onChange={(e) => setEditModalValue(prev => {
                                return {
                                    ...prev,
                                    zoneName: e.target.value
                                }
                            })}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            اغلاق
                        </Button>
                        <Button variant="primary" onClick={handleSave}>
                            حفظ
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Container>
        </StyledZones>
    );
}

export default ZonesPage;