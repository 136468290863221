import React, { useContext, useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from 'styled-components';
import { UserContext } from '../../../../context/UserContext';
import PlainText from '../../../pages-parts/display-info/standard/PlainText';

const StyledSection2 = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    padding: 1rem 1.75rem;
    background-color: ${({ theme }) => theme.colors.background.elements};
    border-radius: 0.75rem;
    position: relative;
    border: 1px solid #ccc;

    
    
    

`;

const Section2 = () => {
    const userContext = useContext(UserContext);

    useEffect(() => {

    }, []);

    
    const [displayData, setDisplayData] = useState([]);

    useEffect(() => {

        setDisplayData([
            {
                title: 'منطقة الاشراف',
                value: userContext.userData?.aria,
                hasNumber: false
            },
            {
                title: 'مدينة الاشراف',
                value: userContext.userData?.city,
                hasNumber: false
            },
        ]);
    }, [userContext]);


    return (
        <StyledSection2>
            <h2 className='sectionFloatTitle'>معلومات المشرف</h2>
            <Row>
                <Col md='12' className='pt-2'>
                    {displayData.map((item, i) => (
                        <PlainText
                            key={i}
                            title={item.title}
                            value={item.value}
                            hasNumber={item.hasNumber}
                        />
                    ))}
                </Col>
            </Row>
        </StyledSection2>
    );
}

export default Section2;