import React, { useState, createContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { availablePages, linksList } from '../components/pages/pages-list/PagesList';

export const SidebarContext = createContext(true);

export const SidebarContextProvider = ({ children }) => {
    const [isOpen, setIsOpen] = useState(true);
    const [activeLink, setActiveLink] = useState([]);
    const [menuList, setMenuList] = useState([]);
    const [hoverOnClosedSubMenuHead, setHoverOnClosedSubMenuHead] = useState({});
    const [activeLinkClosedMenu, setActiveLinkClosedMenu] = useState({});
    const [hoverClosedMenu, setHoverClosedMenu] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {

        

        let currentPage = availablePages.find(item => item.url === window.location.pathname) ?? null;
        if(currentPage === null){
            navigate('/');
        }

        linksList.map(item => {
            if(item.haveSubMenu){
                item.subMenu.map(subItem => {
                    if(subItem.link === currentPage.url){
                        toggleActiveLink(item, subItem);
                    }
                })
            }else{
                if(item.link === currentPage.url){
                    toggleActiveLink(item);
                }
            }
        });

        setMenuList(linksList);
        setIsOpen(() => {
            const isOpenLocal = JSON.parse(localStorage.getItem('menu-is-open')) ?? true;

            return isOpenLocal;
        });
    }, []);

    const toggleActiveLink = (listItem, subMenuItem = undefined, activeSubMenuLink = undefined) => {
        if(listItem.haveSubMenu && subMenuItem !== undefined){
            setActiveLink([listItem.id, subMenuItem.id]);
        }else if(listItem.haveSubMenu && subMenuItem === undefined){
            setActiveLink((prevLink) => {
                let finalResult = [];
                if(prevLink[0] != listItem.id){
                    finalResult = [listItem.id];
                }
                return finalResult;
            })
            
        }else{
            setActiveLink([listItem.id]);
        }

        setActiveLinkClosedMenu(activeSubMenuLink);
    }


    const toggleSidebar = () => {
        localStorage.setItem('menu-is-open', JSON.stringify(!isOpen));
        setIsOpen((currentState) => !currentState);
    }

    const toggleSubMenuClosed = (isMouseEnter, subMenuHeadId = null, subMenuHeadPosition = null) => {
        if(isMouseEnter && subMenuHeadId !== null && subMenuHeadPosition !== null){
            setHoverOnClosedSubMenuHead({
                id: subMenuHeadId,
                postion: subMenuHeadPosition
            });
        }else{
            setHoverOnClosedSubMenuHead({});
        }
    }

    const toggleHoverClosedMenu = (isEnter) => {
        setHoverClosedMenu(isEnter);
    }

    const values = {
        isOpen,
        toggleSidebar,
        activeLink,
        toggleActiveLink,
        menuList,
        hoverOnClosedSubMenuHead,
        toggleSubMenuClosed,
        activeLinkClosedMenu,
        toggleHoverClosedMenu,
        hoverClosedMenu
    }

    return (
        <SidebarContext.Provider value={values}>
            {children}
        </SidebarContext.Provider>
    )
}