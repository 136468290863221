import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { FaBed, FaBath } from 'react-icons/fa';
import { IoIosSend } from 'react-icons/io';
import Moment from 'react-moment';
import Container from 'react-bootstrap/Row';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import api from '../../../api/areas';
import AsyncSelect from 'react-select/async';
import moment from 'moment';
import Scrollbars from 'react-custom-scrollbars-2';
import { AlertContext } from '../../../context/AlertContext';
import profileImage from '../../../images/pic1.png';
import profileImage2 from '../../../images/1.png';
import UserMsg from './UserMsg';
import OwnerMsg from './OwnerMsg';

const StyledTicketPage = styled.div`
    position: relative;
    padding: 2.5rem;
    width: 100%;
    height: 100%;
    .section{
        position: relative;
        transition: all .5s ease-in-out;
        padding: 1rem 0;
        background-color: ${({ theme }) => theme.colors.background.elements};
        border-radius: 0.75rem;
        border: 1px solid #ccc;
        height: calc(100% - 2rem);
        color: ${({ theme }) => theme.colors.text.secondary};
    
        .ticketHeader{
            display: flex;
            justify-content: space-between;
            margin-bottom: 0;
            padding: 0 1rem .5rem;
            border-bottom: 1px solid #ccc;
        }
        a{
            color: ${({ theme }) => theme.colors.text.secondary};
            &:hover{
                color: ${({ theme }) => theme.colors.primary};
            }
        }
    
        .massagesContainer{
            min-height: 100%;
            padding: 2rem 1rem;
        }
    
        .inputContainer{
            align-items: stretch;
            .iconContainer{
                display: flex;
                align-items: center;
                margin-left: .5rem;
    
                .icon{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-style: 1rem;
                    width: 2rem;
                    height: 2rem;
                    background-color: ${({ theme }) => theme.colors.primary};
                    border-radius: 50%;
                    color: ${({ theme }) => theme.colors.background.elements};
                    cursor: pointer;
    
                    &:hover{
                        background-color: ${({ theme }) => theme.colors.primary_8};
                    }
                }
            }
        }
    }
`;

const TicketPage = () => {
    const [params, setParams] = useSearchParams();
    const navigate = useNavigate();
    const alertContext = useContext(AlertContext);
    const [contractData, setContractData] = useState({});
    const [massages, setMassages] = useState([
        {
            id: 1,
            type: 'employee',
            msg: 'كيف اقوم بمساعدتك ؟',
            time: moment().subtract(3, 'M').format('hh:mm A, YYYY-MM-DD'),
            userImg: profileImage
        },
        {
            id: 2,
            type: 'owner',
            msg: 'اريد تفعيل عقد',
            time: moment().subtract(3, 'M').add(1, 'minutes').format('hh:mm A, YYYY-MM-DD'),
            userImg: profileImage2
        },
        {
            id: 3,
            type: 'employee',
            msg: 'من فضلك ارسل البيانت التالية',
            time: moment().subtract(3, 'M').add(2, 'minutes').format('hh:mm A, YYYY-MM-DD'),
            userImg: profileImage
        },
        {
            id: 4,
            type: 'employee',
            msg: `
                1. بيانات المستأجر :- 
                اسم المستأجر / نوع الهوية(هوية وطنية - جواز سفر - مجلس التعاون الخليجي - اقامة ...) / صورة الهوية
            `,
            time: moment().subtract(3, 'M').add(3, 'minutes').format('hh:mm A, YYYY-MM-DD'),
            userImg: profileImage
        },
        {
            id: 5,
            type: 'employee',
            msg: `
                2. بيانات العقار و الوحدة :- 
                عنوان العقار / استخدام العقار / حجم الوحدة / مرافق الوحدة
            `,
            time: moment().subtract(3, 'M').add(3, 'minutes').format('hh:mm A, YYYY-MM-DD'),
            userImg: profileImage
        },
        {
            id: 6,
            type: 'employee',
            msg: `
                3. تفاصيل العقد :- 
                نوع العقد / مدة العقد / قيمة العقد
            `,
            time: moment().subtract(3, 'M').add(3, 'minutes').format('hh:mm A, YYYY-MM-DD'),
            userImg: profileImage
        },
    ]);
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        api.get(`/items?id=${params.get('id')}`).then(result => {
            if (result.data.length === 0) {
                alertContext.toggleAlert(true);
                alertContext.toggleAlertData({
                    heading: 'عقد مجهول',
                    message: 'لم يتم العثور علي اي عقد مطابق لطلبك',
                    variant: 'warning',
                });
                navigate("/");
            } else {
                setContractData(result.data[0])
            }
        });
    }, []);

    const handleChange = e => {
        setInputValue(e.target.value)
    }

    const handleSend = () => {

        setMassages(prev => {
            return [
                ...prev,
                {
                    id: prev.length + 1,
                    type: 'employee',
                    msg: inputValue,
                    time: moment().format('hh:mm A, YYYY-MM-DD'),
                    userImg: profileImage
                }
            ]
        })
        setInputValue('');
    }
    return (
        <StyledTicketPage>
            <Container fluid className='h-100'>
                <div className='section'>
                    <div className='ticketHeader'>
                        <Link to={`/contracts-view?id=${params.get('id')}`}><span>#</span> {contractData.id?.replace(/(-.*)/g, "")}...</Link>
                        <span>{contractData.owner}</span>
                        <span>{contractData.Tenant}</span>
                    </div>
                    <Scrollbars
                        renderView={props => (
                            <div {...props} style={{
                                ...props.style,
                                marginLeft: props.style.marginRight,
                                marginRight: 0,
                            }} />
                        )}
                        renderTrackVertical={props => (
                            <div {...props} style={{
                                ...props.style,
                                left: 2,
                                bottom: 20,
                                top: 20,
                                right: 'auto',
                                borderRadius: 3,
                            }} />
                        )}
                        style={{ width: "100%", height: "calc(100% - 66px)" }}
                    >
                        <div className='massagesContainer'>
                            {
                                massages.map(msg => {
                                    if (msg.type === "employee") {
                                        return <UserMsg msg={msg} key={msg.id} />
                                    } else {
                                        return <OwnerMsg msg={msg} key={msg.id} />
                                    }
                                })
                            }

                        </div>
                    </Scrollbars>

                    <div className='mt-1 d-flex inputContainer px-3'>
                        <div className='iconContainer'>
                            <span className='icon' onClick={handleSend}>
                                <IoIosSend />
                            </span>
                        </div>
                        <Form.Control
                            className='py-1 px-3 input'
                            type='text'
                            value={inputValue}
                            name='text-bar'
                            onChange={handleChange}
                        />
                    </div>
                </div>

                <Row className="mt-3 p-0 mx-0">
                    <Col className='d-flex align-items-center justify-content-end p-0'>
                        <Link
                            to={`/contracts-draft`}
                            className="py-2 px-3 return-link"
                        >
                            رجوع
                        </Link>
                    </Col>
                </Row>
            </Container>
        </StyledTicketPage>
    );
}

export default TicketPage;