import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { MdAccountCircle } from 'react-icons/md';
import Moment from 'react-moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import moment from 'moment';


const StyledSection2 = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    margin-bottom: 3rem;
    padding: 2rem 1.75rem;
    background-color: ${({ theme }) => theme.colors.background.elements};
    border-radius: 0.75rem;
    position: relative;
    border: 1px solid #ccc;

    
    
    .slide-box{
        position: relative;
        display: flex;
        justify-content: space-between;
        width: 80%;
        margin: 0 auto;
        z-index: 1;

        &::after{
            display: block;
            position: absolute;
            content: '';
            right: .5rem;
            top: .5rem;
            background-color: ${({ theme }) => theme.colors.primary};
            width: 0;
            height: .5rem;
            z-index: -1;
            transition: all .5s linear;
        }
        &.under-revision::after{
            width: 0;
        }
        &.candidate::after{
            width: calc(50% - .5rem);
        }
        &.ready::after{
            width: calc(100% - 1rem);
        }

        &::before{
            display: block;
            position: absolute;
            content: '';
            right: .5rem;
            top: calc(50% - 1.5px);
            background-color: #ccc;
            width: calc(100% - 1rem);
            height: 3px;
            z-index: -2;
            transition: all .5s linear;
        }

        .form-check-input[type=checkbox]{
            border-radius: 50%;
            position: relative;

            &:checked{
                background-color: ${({ theme }) => theme.colors.primary};
                border-color: ${({ theme }) => theme.colors.primary};
            }
            &:focus{
                border-color: ${({ theme }) => theme.colors.primary_8};
                box-shadow: 0 0 0 0.25rem ${({ theme }) => theme.colors.primary_2};
            }
            &::after{
                display: block;
                position: absolute;
                top: 15px;
                left: 50%;
                transform: translateX(-50%);
                font-size: 14px;
                width: auto;
                white-space: nowrap;
            }
            &[name=under-revision]::after{
                content: "تحت المراجعة";
            }
            &[name=candidate]::after{
                content: "مرشح";
            }
            &[name=ready]::after{
                content: "جاهز";
            }
        }
    }
`;

const Section2 = ({ requestData }) => {
    const [requestStatus, setRequestStatus] = useState('under-revision');

    const handleChangeStatus = (value) => {
        setRequestStatus(value)
    }

    return (
        <StyledSection2>
            <h2 className='sectionFloatTitle'>حالة الطلب</h2>

            <div className={`slide-box ${requestStatus}`}>
                <Form.Check
                    className='m-0'
                    type='checkbox'
                    id='under-revision'
                    value='under-revision'
                    checked={true}
                    name='under-revision'
                    onChange={() => handleChangeStatus("under-revision")}
                    inline
                />
                <Form.Check
                    className='m-0'
                    type='checkbox'
                    id='candidate'
                    value='candidate'
                    checked={requestStatus === 'candidate' || requestStatus === 'ready'}
                    name='candidate'
                    onChange={() => handleChangeStatus("candidate")}
                    inline
                />
                <Form.Check
                    className='m-0'
                    type='checkbox'
                    id='ready'
                    value='ready'
                    checked={requestStatus === 'ready'}
                    name='ready'
                    onChange={() => handleChangeStatus("ready")}
                    inline
                />
            </div>
        </StyledSection2>
    );
}

export default Section2;