import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import { useSearchParams, Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import api from '../../../../api/areas';
import { AlertContext } from '../../../../context/AlertContext';
import UnitCard from './UnitCard';

const StyledViewContract = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    padding: 2.5rem 1.75rem;
    color: ${({ theme }) => theme.colors.text.secondary};
    
    .row{
        margin: 0;
    }
`;

const RealEstateUnitsPage = () => {
    const [params, setParams] = useSearchParams();
    const [unitsNumber, setUnitsNumber] = useState({});
    const navigate = useNavigate();
    const alertContext = useContext(AlertContext);

    useEffect(() => {
        api.get(`/realEstates?id=${params.get('id')}`).then(result => {

            if (result.data.length === 0) {
                alertContext.toggleAlert(true);
                alertContext.toggleAlertData({
                    heading: 'عقار مجهول',
                    message: 'لم يتم العثور علي العقار المطلوب',
                    variant: 'warning',
                });
                navigate("/");
            } else {
                setUnitsNumber(result.data[0].units);
            }
        });
    }, [])
    return (
        <StyledViewContract>
            <Container fluid>
                {
                    [...Array(unitsNumber)].map((e, i) => (
                        <UnitCard key={i} data={{
                            id: `unit-${moment().format()}-${i}`
                        }} />
                    ))
                }
            </Container>
        </StyledViewContract>
    );
}

export default RealEstateUnitsPage;