import moment from 'moment';
import React, { useEffect, useRef } from 'react';
import { Calendar } from 'react-date-range';

function DatePickerInput({ titleText, date, setDate }) {
    const calenderBoxRef = useRef();

    const handleClickWindow = () => {
        calenderBoxRef.current.classList.add("d-none");
    }
    const toggleCalender = e => {
        e.stopPropagation();
        calenderBoxRef.current.classList.toggle("d-none");
    }
    const handleChangingDate = e => {
        e.stopPropagation();
    }
    useEffect(() => {
        calenderBoxRef.current.classList.add("d-none");
    }, [date]);
    useEffect(() => {
        window.addEventListener('click', handleClickWindow);

        return function () {
            window.removeEventListener('click', handleClickWindow)
        }
    }, []);

    return (
        <div className='data-line'>
            <span className='infoTitle'>
                {titleText}
            </span>
            <span className='infoData calender-input numbers-font'>
                <p className='mb-0' onClick={toggleCalender}>{moment(date).format('ll')}</p>
                <div
                    className='datepicker-container'
                    onClick={handleChangingDate}
                    ref={calenderBoxRef}
                >
                    <Calendar onChange={item => setDate(item)} date={date} />
                </div>
            </span>
        </div>
    );
}

export default DatePickerInput;