import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { MdAccountCircle } from 'react-icons/md';
import Moment from 'react-moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import moment from 'moment';
import PlainText from '../../../pages-parts/display-info/standard/PlainText';
import StaticSelect from '../../../pages-parts/inputs/static-select-input/StaticSelect';


const StyledSection3 = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    padding: 2rem 1.75rem 1rem;
    background-color: ${({ theme }) => theme.colors.background.elements};
    border-radius: 0.75rem;
    position: relative;
    border: 1px solid #ccc;

    
    
    .data-line{
        margin: 0!important;
    }
    .infoTitle{
        display: inline-block;
        width: 6rem;
        margin-left: .5rem;
        font-size: 14px;
        color: ${({ theme }) => theme.colors.text.secondary};
    }
    .infoData{
        color: ${({ theme }) => theme.colors.text.primary};
        display: inline-block;
        width: 9rem;
        
    }
    input{
        
    }

`;

const Section3 = ({ userData }) => {
    const [options, setOptions] = useState([
        {
            value: 'bank',
            label: 'بنك',
        },
        {
            value: 'cash',
            label: 'كاش',
        },
        {
            value: 'mada',
            label: 'مدي',
        }
    ]);
    const [selectedOption, setSelectedOption] = useState({
        value: 'bank',
        label: 'بنك',
    });

    const handleChangeSelect = (selectOption) => {
        setSelectedOption(selectOption)
    }

    const handleSave = () => {
        console.log('hi');
    }
    return (
        <StyledSection3>
            <h2 className='sectionFloatTitle'>التسوية</h2>

            <Row>
                <Col>
                    <div>
                        <PlainText
                            title='الرصيد'
                            value='(2500)'
                            hasNumber={true}
                        />
                    </div>
                    <Row className='mt-2'>
                        <Col md="auto">
                            <StaticSelect
                                titleText='طريقة الدفع'
                                selectedOption={selectedOption}
                                options={options}
                                customStylesProp={{
                                    has: false,
                                    style: {}
                                }}
                                handleChangeSelect={handleChangeSelect}
                            />
                        </Col>
                        <Col md='3'>
                            <Form.Control
                                className='py-1 px-2 text-center h-100'
                                type='text'
                                name='value'
                                onChange={e => e.target.value = e.target.value.replace(/([^0-9])/g, '')}
                                placeholder='القيمة'
                            />
                        </Col>
                        <Col md='3'>
                            <Form.Control
                                className='py-1 px-3 text-center h-100'
                                type='text'
                                name='payment-doc'
                                placeholder='مستند الدفع'
                            />
                        </Col>
                    </Row>
                    <div className='mt-3 w-75 text-center'>
                        <span onClick={handleSave} className="py-2 px-3 d-inline-block save-link me-2">
                            تأكيد
                        </span>
                    </div>
                </Col>
            </Row>
        </StyledSection3>
    );
}

export default Section3;