import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useState } from "react";
import AddNewButton from "../inputs/add-new-btn/AddNewButton";


const CreateNewModal = ({ text, modleContent }) => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <AddNewButton
                onClickFunction={handleShow}
                title={text}
            />
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{modleContent.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control
                        className='py-2 px-3'
                        type='text'
                        name={modleContent.inputName}
                        placeholder={modleContent.inputPlaceholder}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        اغلاق
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        حفظ
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default CreateNewModal;