import React, { useContext, useEffect, useRef, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';
import Select from 'react-select';
import { Calendar } from 'react-date-range';
import moment from 'moment';
import Percent from './Percent';
import Fixed from './Fixed';
import { ContractFinanceRecordEditContext } from '../../ContractFinanceRecordEditContext';

const StyledSectionCommercialPart = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    padding: 1rem 0;
    
    
    
    .infoDataSwitch{
        display: flex;
        width: auto !important;
    }
    span img{
        width: 6rem;
        height: 6rem;
        border-radius: 0.75rem;
    }
`;

const SectionCommercialPart = () => {
    const {
        increaseTypePercent,
        setIncreaseTypePercent,
    } = useContext(ContractFinanceRecordEditContext);
    const increaseTypePercentRef = useRef();

    const handleChangeIncreaseType = () => {
        if (increaseTypePercentRef.current.checked) {
            setIncreaseTypePercent(true);
        } else {
            setIncreaseTypePercent(false);
        }
    }

    return (
        <StyledSectionCommercialPart>
            <div className='data-line'>
                <span className='infoTitle'>
                    الايجار المتزايد
                </span>
                <span className='infoDataSwitch'>
                    <Form.Check
                        className='me-5 mb-0'
                        type='radio'
                        id='by-percent'
                        defaultChecked={increaseTypePercent}
                        name='increase-type'
                        label='بالنسبة %'
                        ref={increaseTypePercentRef}
                        onChange={handleChangeIncreaseType}
                    />
                    <Form.Check
                        className='me-5 mb-0'
                        type='radio'
                        id='by-fixed-value'
                        defaultChecked={!increaseTypePercent}
                        name='increase-type'
                        label='مبلغ ثابت'
                        onChange={handleChangeIncreaseType}
                    />
                </span>
            </div>
            <Row>
                <Col md="9">
                    {increaseTypePercent ? (
                        <Percent />
                    ) : (
                        <Fixed />
                    )}
                </Col>
            </Row>
        </StyledSectionCommercialPart>
    );
}

export default SectionCommercialPart;