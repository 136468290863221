import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";


const StyledTabs = styled.div`
    display: flex;

    & > .tab-filter{
        padding: 0 1rem .75rem;
        color: ${({ theme }) => theme.colors.text.secondary};
        border-bottom: 2px solid transparent;
        cursor: pointer;
        font-size: 14px;

        &:hover{
            border-bottom: 2px solid ${({ theme }) => theme.colors.secondary};
        }

        &.active{
            color: ${({ theme }) => theme.colors.secondary};
            border-bottom: 2px solid ${({ theme }) => theme.colors.secondary};
        }
    }
`;

const TabsFilter = ({ setTabFilter, tabFilters, setPageNum }) => {
    const location = useLocation();
    const [activeTab, setActiveTab] = useState(() => {
        return JSON.parse(localStorage.getItem(location.pathname + '-tab-filter')) ?? '';
    });

    const filterTableByTab = (e) => {
        const tabValue = e.target.dataset['value'];
        localStorage.setItem(location.pathname + '-tab-filter', JSON.stringify(tabValue));
        setActiveTab(tabValue);
        setTabFilter(prev => {
            setPageNum(1);
            return {
                column: tabFilters.columnName,
                value: tabValue
            }
        })
    }

    return (
        <StyledTabs>
            {tabFilters.tabs.map(tab => {
                return (
                    <div
                        className={`tab-filter ${tab.value === activeTab ? 'active' : ''}`}
                        onClick={filterTableByTab}
                        data-value={tab.value}
                        key={tab.id}
                    >
                        {tab.name}
                    </div>
                )
            })}
        </StyledTabs>
    )
}

export default TabsFilter