import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import api from '../../../api/areas'
import { useLocation } from 'react-router-dom';

const StyledSelectFilter = styled.div`
    width: 100%;
    max-width: 7.5rem;
    padding: 0 .25rem;
    border-radius: .75rem;
    display: flex;
    align-items: center;
    
    &:first-child{
        padding-right: 0;
    }
    &:last-child{
        padding-left: 0;
    }
`;

const SelectFilter = ({ selectFilter, setSelectFilter, label="hello world" }) => {
    const location = useLocation();
    const [defaultValues, setDefaultValues] = useState([]);

    useEffect(() => {
        if (selectFilter.type === 'api') {
            api.get(selectFilter.url + '_page=1').then(res => {
                let values = [{
                    value: 'no-filter',
                    label: 'الكل',
                }];
                res.data.forEach(item => {
                    const option = {
                        value: item.id,
                        label: item[selectFilter.column],
                    }
                    values.push(option);
                });
                setDefaultValues(values)
            }).catch(err => {
                console.log(err)
            });
        } else {
            setDefaultValues(selectFilter.options)
        }

        const filterStorage = JSON.parse(localStorage.getItem(location.pathname + '-filters')) ?? {};
        const newFilterStorage = {
            ...filterStorage
        }
        newFilterStorage[selectFilter.name] = selectFilter.value;
        localStorage.setItem(location.pathname + '-filters', JSON.stringify(newFilterStorage))

    }, [selectFilter]);

    const handleChange = async (selectOption) => {

        setSelectFilter(prev => {
            return {
                ...prev,
                value: selectOption
            }
        })
    }

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            minHeight: "2rem",
        }),
        container: (provided, state) => ({
            ...provided,
            width: "100%",
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            padding: "0px 2px",
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            padding: 0,
        }),
    }

    const loadOptions = async (searchValue, callback) => {
        try {
            const respond = await api.get(`${selectFilter.url}q=${searchValue}&_page=1`).then(res => {
                let values = [{
                    value: 'no-filter',
                    label: 'الكل',
                }];
                res.data.forEach(item => {
                    const option = {
                        value: item.id,
                        label: item[selectFilter.column],
                    }
                    values.push(option);
                });
                callback(values)
            }).catch(err => {
                console.log(err)
            });
        } catch (err) {
            console.log(err)
        }

    }

    const getSelectFilter = () => {
        if (selectFilter.type === 'api') {
            return (
                <StyledSelectFilter>
                    <AsyncSelect
                        value={selectFilter.value}
                        loadOptions={loadOptions}
                        onChange={handleChange}
                        defaultOptions={defaultValues}
                        styles={customStyles}
                        placeholder={label}
                    />
                </StyledSelectFilter>
            )
        } else {
            return (
                <StyledSelectFilter>
                    <Select
                        onChange={handleChange}
                        value={selectFilter.value}
                        options={defaultValues}
                        styles={customStyles}
                        placeholder={label}
                    />
                </StyledSelectFilter>
            )
        }
    }

    return (
        <>
            {getSelectFilter()}
        </>
    );
}

export default SelectFilter;