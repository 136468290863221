import React, { useContext, useEffect, useRef, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from 'styled-components';
import profileImage1 from '../../../../images/pic1.png';
import profileImage2 from '../../../../images/1.png';
import { UserContext } from '../../../../context/UserContext';

const StyledSection1 = styled.div`
    width: 100%;
    padding: 2.5rem 1.75rem;
    background-color: ${({ theme }) => theme.colors.background.elements};
    border-radius: .75rem;

    span img{
        width: 6rem;
        height: 6rem;
        border-radius: 0.75rem;
    }
`;

const Section1 = () => {
    const userContext = useContext(UserContext);
    const phoneInput = useRef();

    useEffect(() => {

    }, []);

    const handleChange = () => {
        phoneInput.current.value = phoneInput.current.value.replace(/([^0-9])/g, '');
    }

    const getRuleName = () => {
        if (userContext.userData?.rule === 'admin') {
            return 'مشرف';
        } else if (userContext.userData?.rule === 'assistant') {
            return 'مساعد وسيط';
        } else {
            return 'غير محدد';
        }
    }

    return (
        <StyledSection1 className='mb-4'>
            <Row className='flex-row-reverse'>
                <Col xs='12' sm='3' className='d-flex align-items-center justify-content-center justify-content-sm-end mb-3 mb-sm-0'>
                    <span>
                        <img src={userContext.userData?.rule === 'admin' ? profileImage1 : profileImage2} alt="Profile" />
                    </span>
                </Col>
                <Col xs='12' sm='9'>
                    <Row className='mb-2' >
                        <Col xs='6' sm='5' md='4'>الاسم بالكامل</Col>
                        <Col xs='6' sm='7' md='8'>
                            <Form.Control
                                className='py-2 px-3'
                                type='text'
                                name='name'
                            />
                        </Col>
                    </Row>
                    <Row className='mb-2' >
                        <Col xs='6' sm='5' md='4'>البريد الالكتروني</Col>
                        <Col xs='6' sm='7' md='8'>
                            <Form.Control
                                aria-label="email"
                                aria-describedby="email"
                                className='py-2 px-3'
                                type='email'
                                name='email'
                            />
                        </Col>
                    </Row>
                    <Row className='mb-2' >
                        <Col xs='6' sm='5' md='4'>رقم الهاتف</Col>
                        <Col xs='6' sm='7' md='8'>
                            <Form.Control
                                aria-label="phone"
                                aria-describedby="phone"
                                className='py-2 px-3'
                                type='text'
                                onChange={handleChange}
                                ref={phoneInput}
                                name='phone'
                            />
                        </Col>
                    </Row>
                    <Row className='mb-2' >
                        <Col xs='6' sm='5' md='4'>نوع الحساب</Col>
                        <Col xs='6' sm='7' md='8'>{getRuleName()}</Col>
                    </Row>
                </Col>
            </Row>
        </StyledSection1>
    );
}

export default Section1;