import React, { useContext, useRef } from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { SidebarContext } from '../../../../context/SidebarContext';
import MenuIcons from '../SidebarMenuIcons';

const StyledMenuHead = styled(Link)`
    ${({ theme, sidebarstate, listitemid }) => {
        if (sidebarstate.isOpen) {
            return css`
                position: relative;
                padding: 0.75rem 1rem;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                font-weight: ${listitemid === sidebarstate.activeLink[0] ? "700" : "400"};
                font-size: 14px;
                text-decoration: none;
                color: ${listitemid === sidebarstate.activeLink[0] ? theme.colors.primary : theme.colors.light_gray};
                transition: all .5s ease-in-out;
                cursor: pointer;

                &::after{
                    display: block;
                    content: "";
                    position: absolute;
                    right: ${listitemid === sidebarstate.activeLink[0] ? "-1rem" : "-1.5rem"};
                    top: 0;
                    width: .375rem;
                    height: 100%;
                    background-color: ${theme.colors.primary};
                    border-top-left-radius: 0.5rem;
                    border-bottom-left-radius: 0.5rem;
                    transition: all .5s ease-in-out;
                }
            `;
        } else {
            return css`
                margin: 0 auto;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 3.5rem;
                height: 3.5rem;
                border-radius: 50%;
                background-color: ${listitemid === sidebarstate.activeLink[0] ? theme.colors.primary_1 : "transparent"};
                & > span.link-text{
                    display: none;
                }
            `;
        }
    }}
`;

const MenuHead = ({ listItemProp }) => {
    const sidebarState = useContext(SidebarContext);
    const headRef = useRef();

    const hendleMouseEnter = (listItem) => {
        const headPosition = headRef.current.getBoundingClientRect();
        sidebarState.toggleSubMenuClosed(true, listItem.id, headPosition);
    }

    return (
        <StyledMenuHead
            to={listItemProp.link}
            onClick={() => sidebarState.toggleActiveLink(listItemProp)}
            sidebarstate={sidebarState}
            listitemid={listItemProp.id}
            onMouseEnter={() => hendleMouseEnter(listItemProp)}
            ref={headRef}
        >
            <MenuIcons listItemId={listItemProp.id} listItem={listItemProp} />
            <span className='link-text'>
                {listItemProp.translate}
            </span>
        </StyledMenuHead>
    )
}

export default MenuHead;