import React, { useContext, useEffect, useRef, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import styled, { css } from 'styled-components';
import moment from 'moment';
import TextInput from '../../../pages-parts/inputs/text-input/TextInput';
import Logo from '../../../../images/logo.png';
import LogoText from '../../../../images/logo-text.png';
import Scrollbars from 'react-custom-scrollbars-2';
import api from '../../../../api/areas';
import { AlertContext } from '../../../../context/AlertContext';
import { useNavigate, useSearchParams } from 'react-router-dom';
import FlashMessage from '../../../pages-parts/flash-message/FlashMessage';
import Loading from '../../../pages-parts/loading/Loading';

const StyledTenantInvitation = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
    align-items: center;
    padding: 1rem 2rem;

    .logoContainer{
        text-align: center;
        color: ${({ theme }) => theme.colors.primary};
        margin-bottom: 1rem;

        .logo, .logoText{
            width: 7rem;
        }
    }
    .content{
        position: relative;
        padding: 1rem;
        width: 100%;
        transition: all .5s ease-in-out;
        background-color: ${({ theme }) => theme.colors.background.elements};
        border-radius: 0.75rem;
        position: relative;
        border: 1px solid #ccc;
        max-width: 1024px;
        margin: 0 auto;
        
        p{
            margin-bottom: 0;
            line-height: 1.5;
        }
        .data-line{
            display: flex;
            align-items: center;
            margin: 1rem 0;
        }
        .infoTitle{
            display: inline-block;
            width: 6rem;
            margin-left: .5rem;
            font-size: 14px;
            color: ${({ theme }) => theme.colors.text.secondary};
        }
        .infoData{
            width: calc(100% - 6rem);
            position: relative;
        }

        .sendCode{
            margin-top: .5rem;
            color: ${({ theme }) => theme.colors.text.primary};
            cursor: pointer;
    
            &.sendCode:hover{
                color: ${({ theme }) => theme.colors.secondary};
            }
        }
        .waitingToSendCode{
            margin-top: .5rem;
            color: ${({ theme }) => theme.colors.text.primary};
        }
    }
    
`;

const TenantInvitation = () => {
    const navigate = useNavigate();
    const alertContext = useContext(AlertContext);
    const [ownerName, setOwnerName] = useState('');
    const [tenantId, setTenantId] = useState('');
    const [contractType, setContractType] = useState('');
    const [params, setParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState(false);
    const [code, setCode] = useState('');
    const [newCodeRequestActive, setNewCodeRequestActive] = useState(false);
    const [countDown, setCountDown] = useState(30);
    const timerId = useRef();

    useEffect(() => {
        setIsLoading(true);
        timerId.current = setInterval(() => setCountDown(prev => prev - 1), 1000);
        api.get(`/items?id=${params.get('id')}`).then(result => {
            setIsLoading(false);
            if (result.data.length === 0) {
                alertContext.toggleAlert(true);
                alertContext.toggleAlertData({
                    heading: 'دعوة مجهولة',
                    message: 'لم يتم العثور علي الدعوة المطلوبة',
                    variant: 'warning',
                });
            } else {
                setOwnerName(result.data[0].owner);
                setTenantId(result.data[0].tenantId);
                setContractType(result.data[0].contractType);
            }
        });
        setTimeout(() => {
            setNewCodeRequestActive(true);
        }, 30000);

        return () => clearInterval(timerId.current)
    }, []);


    const handleSave = () => {
        setIsLoading(true);
        // api check code ----------
        setTimeout(() => {
            setIsLoading(false);
            if (code === "abc123") {
                sessionStorage.setItem('tenant-access', JSON.stringify(true));
                navigate(`/tenant/edit?id=${tenantId}&contractId=${params.get('id')}&first_time=true`);
            } else {
                setNewCodeRequestActive(true);
                alertContext.toggleAlert(true);
                alertContext.toggleAlertData({
                    heading: 'كود خاطئ',
                    message: 'من فضلك تأكد من كتابة الكود بشكل صحيح كما تم أرسالة الي جوالك ,أو اطلب ارسال كود جديد',
                    variant: 'danger',
                });
            }
        }, 1500);
    }

    const sendCode = () => {
        setNewCodeRequestActive(false);
        setCountDown(30);
        setTimeout(() => {
            setNewCodeRequestActive(true);
        }, 30000);
    }
    return (

        <Scrollbars
            renderView={props => (
                <div {...props} style={{
                    ...props.style,
                    marginLeft: props.style.marginRight,
                    marginRight: 0,
                }} />
            )}
            renderTrackVertical={props => (
                <div {...props} style={{
                    ...props.style,
                    left: 2,
                    bottom: 20,
                    top: 20,
                    right: 'auto',
                    borderRadius: 3,
                }} />
            )}
            style={{ width: "100%", height: "100vh" }}
        >
            <div style={{ position: "relative" }}>
                {isLoading ? <Loading className="loading" /> : ''}
                {alertContext.showAlert ? <FlashMessage /> : ''}
                <Row className='justify-content-center m-0'>
                    <Col xs='12' sm='9' md='6' lg='4'>
                        <StyledTenantInvitation>
                            <div className='logoContainer'>
                                <img src={Logo} className="logo" alt="logo" />
                                <img src={LogoText} className="logoText" alt="logo" />
                            </div>
                            <div className='content'>

                                <p>
                                    تم دعوتك من طرف / {ownerName} ( المؤجر ) وذلك لكتابة عقد ايجار {contractType}
                                </p>
                                <TextInput
                                    titleText='كود التعريف'
                                    inputName='code'
                                    value={code}
                                    setValue={setCode}
                                />
                                <div className='text-start'>
                                    <span onClick={handleSave} className="py-2 px-3 save-link d-inline-block">
                                        تأكيد الكود
                                    </span>
                                    {newCodeRequestActive ? (
                                        <p className='sendCode mb-0 mt-2' onClick={sendCode}>
                                            ارسال كود جديد
                                        </p>
                                    ) : (
                                        <p className='waitingToSendCode mb-0 mt-1'>
                                            انتظر {countDown} ثانية لطلب كود جديد
                                        </p>
                                    )}
                                </div>

                            </div>
                        </StyledTenantInvitation>
                    </Col>
                </Row>
            </div>

        </Scrollbars>
    );
}

export default TenantInvitation;