import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import styled, { css } from "styled-components";
import Section1 from "./edit-sections/Section-1";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import Loading from "../../pages-parts/loading/Loading";
import Section2 from "./edit-sections/Section-2";

const StyledProfileEditPage = styled(Form)`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    padding: 2.5rem 1.75rem;

`;

const ProfileEditPage = () => {
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const handleSave = () => {
        setIsLoading(true);
        setTimeout(() => navigate('/profile'), 1500);
    }
    return (
        <StyledProfileEditPage>
            {isLoading ? <Loading className="loading" /> : ''}
            <Container fluid>
                <Section1 />
                <Section2 />
                <Row className="mt-3">
                    <Col className='d-flex align-items-center justify-content-start py-2'>
                        <Link to='/profile' className="py-2 px-3 return-link me-2">
                            رجوع
                        </Link>
                        <span onClick={handleSave} className="py-2 px-3 save-link">
                            حفظ
                        </span>
                    </Col>
                </Row>
            </Container>
        </StyledProfileEditPage>
    )
}

export default ProfileEditPage;