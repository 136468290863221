import React, { useContext, useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';
import { UserContext } from '../../../../../../context/UserContext';

const StyledSection2 = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    padding: 1rem 1.75rem;
    background-color: ${({ theme }) => theme.colors.background.elements};
    border-radius: 0.75rem;
    position: relative;
    border: 1px solid #ccc;

    
    
    

`;

const PromoterSection2 = ({ userData }) => {

    return (
        <StyledSection2>
            <h2 className='sectionFloatTitle'>معلومات المروج</h2>
            <Row>
                <Col md='12'>
                    <div className='mt-2 data-line'>
                        <span className='infoTitle'>
                            كود الخصم
                        </span>
                        <span className='infoData'>
                            <Form.Control
                                className='py-2 px-3'
                                type='text'
                                name='promoter-discount-code'
                                defaultValue="4252875"
                            />
                        </span>
                    </div>
                    <div className='mt-2 data-line'>
                        <span className='infoTitle'>
                            تفعيل الخصم
                        </span>
                        <span className='infoData'>
                            <Form.Check
                                type="switch"
                                id="custom-switch"
                                label=""
                            />
                        </span>
                    </div>
                </Col>
            </Row>
        </StyledSection2>
    );
}

export default PromoterSection2;