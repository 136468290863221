import React, { useContext, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useSearchParams, Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Section1 from './section-1/Section-1';
import Section2 from './section-2/Section-2';
import Section4 from './section-4/Section-4';
import Section6 from './section-6/Section-6';
import Scrollbars from 'react-custom-scrollbars-2';
import { TopbarContext } from '../../../../context/TopbarContext';

const StyledTenantDetailsEdit = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    padding: 2.5rem 1.75rem;
    .saveSection{
        max-width: 1024px;
        margin: 0 auto 3rem;
    }

    .container-fluid > .sectionContainer > *:last-child {
        margin-bottom: 0;
    }
`;

const TenantDetailsEdit = ({ tenantPages = false }) => {
    const navigate = useNavigate();
    const [params, setParams] = useSearchParams();
    const { toggleTopbarTitle } = useContext(TopbarContext);

    useEffect(() => {
        if(params.get("first_time") === "true"){
            toggleTopbarTitle("أضافة بيانات المستأجر")
        }
    }, [])

    const handleSave = () => {
        navigate(`/tenant/view?id=${params.get('id')}`);
    }
    return (
        <Scrollbars
            renderView={props => (
                <div {...props} style={{
                    ...props.style,
                    marginLeft: props.style.marginRight,
                    marginRight: 0,
                }} />
            )}
            renderTrackVertical={props => (
                <div {...props} style={{
                    ...props.style,
                    left: 2,
                    bottom: 20,
                    top: 20,
                    right: 'auto',
                    borderRadius: 3,
                }} />
            )}
            style={{ width: "100%", height: "calc(100vh - 5rem)" }}
        >
            <StyledTenantDetailsEdit>
                <Container fluid>
                    <div className='sectionContainer'>
                        <Section1 />
                        <Section2 />
                        <Section4 />
                        <Section6 />
                    </div>

                    <Row className="mt-3 mb-0 saveSection">
                        <Col className='d-flex align-items-center justify-content-end p-0'>
                            <Link
                                to={
                                    params.get("first_time") === "true" ?
                                    `/tenant?id=${params.get('contractId')}` :
                                    `/tenant/view?id=${params.get('id')}`
                                }
                                className="py-2 px-3 return-link"
                            >
                                {params.get("first_time") === "true" ? "الغاء" : "رجوع"}
                            </Link>
                            <span onClick={handleSave} className="py-2 px-3 save-link ms-2">
                                حفظ
                            </span>
                        </Col>
                    </Row>
                </Container>
            </StyledTenantDetailsEdit>
        </Scrollbars>
    );
}

export default TenantDetailsEdit;