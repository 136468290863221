import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { AiOutlineEye } from 'react-icons/ai';
import { BsFillHouseDoorFill, BsUpload } from 'react-icons/bs';
import { IoPricetagOutline } from 'react-icons/io5';
import { FaRegBuilding, FaRegEdit } from 'react-icons/fa';
import Moment from 'react-moment';
import TableComponent from '../../../pages-parts/table/TableComponent';
import Container from 'react-bootstrap/Container';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import CreateNewLink from '../../../pages-parts/table/CreateNewLink';


const StyledSection3 = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    margin-bottom: 3rem;
    padding: 1.5rem 1.75rem;
    background-color: ${({ theme }) => theme.colors.background.elements};
    border-radius: 0.75rem;
    position: relative;
    border: 1px solid #ccc;

    
    .scroll-thumb{
        background-color: ${({ theme }) => theme.colors.primary_8};
    }
`;

const Section3 = ({ contractId }) => {
    const location = useLocation();
    const [columns, setColumns] = useState([
        {
            id: 1,
            Header: "#",
            Cell: e => {
                return <span className='numbers-font'>{e.value}</span>
            },
            accessor: "number",
            sortable: false,
        },
        {
            id: 2,
            Header: "تاريخ الاصدار",
            accessor: "createDate",
            sortable: false,
            Cell: e => {
                const date = new Date(e.value);
                return (
                    <span className='numbers-font'>
                        <Moment date={date} format="YYYY/MM/DD" />
                    </span>
                )
            }
        },
        {
            id: 3,
            Header: "تاريخ الاستحقاق",
            accessor: "claimingDate",
            sortable: false,
            Cell: e => {
                const date = new Date(e.value);
                return (
                    <span className='numbers-font'>
                        <Moment date={date} format="YYYY/MM/DD" />
                    </span>
                )
            }
        },
        {
            id: 4,
            Header: "الايجار",
            accessor: "payment",
            sortable: false,
            Cell: e => {
                return `${e.value.rent} SAR`
            }
        },
        {
            id: 5,
            Header: "الخدمات",
            accessor: "payment",
            sortable: false,
            Cell: e => {
                return `${e.value.service} SAR`
            }
        },
        {
            id: 6,
            Header: "اجمالي قيمة الفاتورة",
            accessor: "payment",
            sortable: false,
            Cell: e => {
                return `${e.value.rent + e.value.service} SAR`
            }
        }
    ]);

    const [pageNum, setPageNum] = useState(1);
    const [tableDataUrl, setTableDataUrl] = useState(() => {
        return `/contractFinanceRecords?contract_id=${contractId}&_page=${pageNum}&_limit=5`;
    })

    useEffect(() => {
        setTableDataUrl(`/contractFinanceRecords?contract_id=${contractId}&_page=${pageNum}&_limit=5`);
    }, [contractId, pageNum]);

    return (
        <StyledSection3>
            <h2 className='sectionFloatTitle'>جدول فواتير السداد</h2>
            <TableComponent
                columns={columns}
                tableDataUrl={tableDataUrl}
                setSortTable={() => { }}
                tableWidth={800}
                setPageNum={setPageNum}
                pageNum={pageNum}
                tableRowsPerPage={5}
            />
        </StyledSection3>
    );
}

export default Section3;