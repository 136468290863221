import React, { useContext, useEffect, useRef, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';
import Select from 'react-select';
import { Calendar } from 'react-date-range';
import moment from 'moment';
import StaticSelect from '../../../../pages-parts/inputs/static-select-input/StaticSelect';
import DatePickerInput from '../../../../pages-parts/inputs/date-picker/DatePickerInput';
import NumberInput from '../../../../pages-parts/inputs/number-input/NumberInput';
import TextInput from '../../../../pages-parts/inputs/text-input/TextInput';
import { OwnershipDocEditContext } from '../OwnershipDocEditContext';
import { useSearchParams } from 'react-router-dom';

const StyledSection1 = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    padding: 1rem 1.75rem;
    background-color: ${({ theme }) => theme.colors.background.elements};
    border-radius: 0.75rem;
    position: relative;
    border: 1px solid #ccc;

    
    
    .infoData{
        width: 12rem;

    }

    span img{
        width: 6rem;
        height: 6rem;
        border-radius: 0.75rem;
    }
`;

const Section1 = () => {
    const {
        options,
        selectedOption,
        date,
        setDate,
        docNumber,
        setDocNumber,
        ownerIdNumber,
        setOwnerIdNumber,
        customDocType,
        setCustomDocType,
        handleChangeSelect
    } = useContext(OwnershipDocEditContext);

    return (
        <StyledSection1>
            <h2 className='sectionFloatTitle'>تعديل وثيقة الملكية</h2>
            <Row>
                <Col>
                    <StaticSelect
                        titleText='نوع الوثيقة'
                        selectedOption={selectedOption}
                        options={options}
                        customStylesProp={{
                            has: false,
                            style: {}
                        }}
                        handleChangeSelect={handleChangeSelect}
                    />
                    <DatePickerInput
                        titleText='تاريخ الاصدار'
                        date={date}
                        setDate={setDate}
                    />
                    <NumberInput
                        titleText='رقم وثيقة الملكية'
                        inputName='doc-number'
                        value={docNumber}
                        setValue={setDocNumber}
                    />
                    {
                        selectedOption.value === "electronic-document" ||
                            selectedOption.value === "hoggat-estekhdam" ? (
                            <NumberInput
                                titleText='رقم هوية المالك'
                                inputName='owner-id-number'
                                value={ownerIdNumber}
                                setValue={setOwnerIdNumber}
                            />
                        ) : ""
                    }

                    {
                        selectedOption.value === "another" ? (
                            <TextInput
                                titleText='اسم نوع الوثيقة القانونية'
                                inputName='custom-doc-type'
                                value={customDocType}
                                setValue={setCustomDocType}
                            />
                        ) : ""
                    }

                </Col>
            </Row>
        </StyledSection1>
    );
}

export default Section1;