import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from 'styled-components';
import { RiDraftLine } from 'react-icons/ri';
import { AiOutlineFileDone } from 'react-icons/ai';
import { IoEnterOutline } from 'react-icons/io5';
import Section1Card from './Cards';
import moment from 'moment';

const StyledSection1 = styled.div`
    width: 100%;
    margin-bottom: 3rem;
`;

const Section1 = ({ userData }) => {
    const [cardList, setCardList] = useState([]);

    useEffect(() => {
        setCardList([
            {
                id: 1,
                title: 'عدد العقود الاجمالي',
                value: isNaN(userData.housingUnitsNum + userData.tradingUnitsNum) ? "" : userData.housingUnitsNum + userData.tradingUnitsNum,
            },
            {
                id: 2,
                title: 'عقود تنتهي قريبا',
                value: userData.housingUnitsNum,
            },
            {
                id: 3,
                title: 'عدد مسودة العقود',
                value: userData.tradingUnitsNum,
            },
        ]);
    }, [userData]);

    return (
        <StyledSection1>
            <Row className='justify-content-between'>
                {
                    cardList.map(item => (
                        <Col md="3" sm="6" xs="12" className='mb-0 p-0' key={item.id}>
                            <Section1Card carddata={item} />
                        </Col>
                    ))
                }
            </Row>
        </StyledSection1>
    );
}

export default Section1;