import React, { useState, createContext, useEffect, useContext } from 'react';
import { AlertContext } from "../../../../context/AlertContext";
import api from '../../../../api/areas';
import { useNavigate } from "react-router-dom";
export const OwnershipDocEditContext = createContext();

export const OwnershipDocEditContextProvider = ({ children }) => {
    //realEstateData place holder
    const [realEstateData, setRealEstateData] = useState({});
    const alertContext = useContext(AlertContext);
    const navigate = useNavigate();
    const [options, setOptions] = useState([
        {
            value: 'electronic-document',
            label: 'صك ملكية الكتروني',
        },
        {
            value: 'paper-document',
            label: 'صك ملكية ورقي',
        },
        {
            value: 'hoggat-estekhdam',
            label: 'حجة استخدام',
        },
        {
            value: 'another',
            label: 'اخر',
        },
    ]);
    const [selectedOption, setSelectedOption] = useState({
        value: 'paper-document',
        label: 'صك ملكية ورقي',
    });
    const [date, setDate] = useState(new Date());
    const [docNumber, setDocNumber] = useState('');
    const [ownerIdNumber, setOwnerIdNumber] = useState('');
    const [customDocType, setCustomDocType] = useState('');

    const handleChangeSelect = (selectOption) => {
        setSelectedOption(selectOption)
    }

    const getOwnershipDocData = (realEstateId) => {
        api.get(`/realEstates?id=${realEstateId}`).then(result => {
            if (result.data.length === 0) {
                alertContext.toggleAlert(true);
                alertContext.toggleAlertData({
                    heading: 'عقار مجهول',
                    message: 'لم يتم العثور علي العقار المطلوب',
                    variant: 'warning',
                });
                navigate("/");
            } else {
                setRealEstateData(result.data[0]);
            }
        });
    }

    const values = {
        options,
        selectedOption,
        date,
        setDate,
        docNumber,
        setDocNumber,
        ownerIdNumber,
        setOwnerIdNumber,
        customDocType,
        setCustomDocType,
        handleChangeSelect,
        getOwnershipDocData,
    };

    return (
        <OwnershipDocEditContext.Provider value={values}>
            {children}
        </OwnershipDocEditContext.Provider>
    )
}

