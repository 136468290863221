import Form from 'react-bootstrap/Form';
import { FaRegTrashAlt } from 'react-icons/fa';

function CustomFieldInput({ titleText, inputName, value, setValue, inputType, removeField }) {

    const handleChange = e => {
        if (inputType === 'text') {
            setValue(e.target.value);
        } else {
            e.target.value = e.target.value.replace(/([^0-9])/g, '');
            setValue(e.target.value.replace(/([^0-9])/g, ''));
        }
    }

    return (
        <div className='data-line'>
            <span className='infoTitle'>
                {titleText}
            </span>
            <span className='infoData'>
                <Form.Control
                    className='py-1 px-3 myInput'
                    type='text'
                    defaultValue={value}
                    name={inputName}
                    onChange={handleChange}
                />
            </span>
            <span className='customFieldRemoveIcon'>
                <FaRegTrashAlt className='icon' onClick={removeField} />
            </span>
        </div>
    );
}

export default CustomFieldInput;