import React, { useContext } from 'react';
import Container from 'react-bootstrap/Container';
import styled from 'styled-components';
import { AlertContext } from '../../../context/AlertContext';
import FlashMessage from '../../pages-parts/flash-message/FlashMessage';
import Section1 from './section-1/Section-1';
import Section2 from './section-2/Section-2';
import Section3 from './section-3/Section-3';
import Section4 from './section-4/Section-4';

const StyledDashboard = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    padding: 2.5rem 1.75rem;
`;

const DashboardPage = () => {
    const alertContext = useContext(AlertContext);

    return (
        <StyledDashboard>
            {alertContext.showAlert ? <FlashMessage /> : ''}
            <Container fluid>
                <Section1 />
                <Section2 />
                <Section3 />
                <Section4 />
            </Container>
        </StyledDashboard>
    );
}

export default DashboardPage;