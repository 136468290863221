import React from 'react';
import styled from 'styled-components';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';

const StyledAddNewButton = styled.span`
    padding: 0.25rem .5rem;
    border-radius: 0.5rem;
    white-space: nowrap;
    display: inline-flex;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.secondary};
    color: #fff;
    cursor: pointer;

    .icon{
        margin-left: .25rem;
    }
    span{
        white-space: nowrap;
    }
    &:hover{
        background-color: ${({ theme }) => theme.colors.secondary};
        filter: brightness(1.2);
    }
`

function AddNewButton({ onClickFunction = () => {}, title, isLink = false, url = "", hasIcon = true }) {
    const navigate = useNavigate();

    return (
        <StyledAddNewButton onClick={isLink ?  () => navigate(url) : onClickFunction}>
            {
                hasIcon ? <AiOutlinePlusCircle className="icon" /> : ""
            }
            <span>
                {title}
            </span>
        </StyledAddNewButton>
    );
}

export default AddNewButton;