import React, { useContext, useEffect, useRef, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';
import Select from 'react-select';
import { Calendar } from 'react-date-range';
import moment from 'moment';
import NumberInput from '../../../../pages-parts/inputs/number-input/NumberInput';
import { ContractFinanceRecordEditContext } from '../ContractFinanceRecordEditContext';
import { useLocation } from 'react-router-dom';


const StyledSection3 = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    margin-bottom: 3rem;
    padding: 1rem 1.75rem;
    background-color: ${({ theme }) => theme.colors.background.elements};
    border-radius: 0.75rem;
    position: relative;
    border: 1px solid #ccc;

    
    .data-line{
        align-items: center;
        justify-content: flex-start;
    }
    .infoTitle{
        width: auto;
        margin-bottom: .5rem;
    }
    .infoCheckData{
        display: flex;
        width: auto !important;
    }
    .infoData{
        width: 12rem;
        position: relative;
        
        &::after{
            display: flex;
            content: "SAR";
            position: absolute;
            height: 100%;
            align-items: center;
            right: 0;
            top: 0;
            font-weight: 900;
            width: 50px;
            justify-content: center;
            border-left: 1px solid #ccc;
        }

        .myInput{
            padding-right: 55px!important;
        }

    }

    .periodBox{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .infoData2{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 3rem;
            padding: 0.5rem 0;
            margin-top: .5rem;
            border-radius: 0.5rem;
            border: 1px solid #ccc;
            background-color: ${({ theme }) => theme.colors.background.elements};
        }
    }
    span img{
        width: 6rem;
        height: 6rem;
        border-radius: 0.75rem;
    }
`;

const Section3 = () => {
    const {
        paidRent,
        setPaidRent,
        paidServicesFee,
        setPaidServicesFee,
    } = useContext(ContractFinanceRecordEditContext);
    const location = useLocation();


    return (
        <StyledSection3>
            <h2 className='sectionFloatTitle'>المبالغ المدفوعة</h2>
            <Row className='mt-4'>
                <Col md={location.pathname === "/contracts-edit" ? "12" : "6"} className='d-flex align-items-center mb-2'>
                    <NumberInput
                        titleText={
                            <>قيمة مبالغ الايجار المدفوعة <br />(غير شامل ضريبة القيمة المضافة)</>
                        }
                        inputName='paid-rent'
                        value={paidRent}
                        setValue={setPaidRent}
                    />
                </Col>
                <Col md={location.pathname === "/contracts-edit" ? "12" : "6"} className='d-flex align-items-center mb-2'>
                    <NumberInput
                        titleText="قيمة بدل الخدمات المدفوعة"
                        inputName='paid-services-fee'
                        value={paidServicesFee}
                        setValue={setPaidServicesFee}
                    />
                </Col>
            </Row>
        </StyledSection3>
    );
}

export default Section3;