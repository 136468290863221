import moment from 'moment';
import React, { useState, createContext, useEffect } from 'react';

export const UserContext = createContext();

export const UserContextProvider = ({ children }) => {
    const [userData, setUserData] = useState(null);

    useEffect(() => {
        if(document.cookie.split("; ").find(item => item === "isSameDay=true") === "isSameDay=true"){
            const userStorage = JSON.parse(localStorage.getItem('user-data'));
    
            setUserData(userStorage);
        }else{
            localStorage.removeItem('user-data')
        }
    }, []);

    const toggleUserData = (isActionLogin, data = null) => {
        if(isActionLogin){
            setUserData(data);
            const dataString = JSON.stringify(data);
            localStorage.setItem('user-data', dataString);
            const dateString = new Date(moment().add('1', 'days').startOf('day')).toUTCString();
            document.cookie = "isSameDay=true; expires=" + dateString;
        }else{
            setUserData(null);
            localStorage.removeItem('user-data');
        }
    }

    const values = {
        userData,
        toggleUserData
    };

    return (
        <UserContext.Provider value={values}>
            {children}
        </UserContext.Provider>
    )
}

