import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { MdAccountCircle } from 'react-icons/md';
import Moment from 'react-moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import moment from 'moment';


const StyledSection1_1 = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    margin-bottom: 3rem;
    padding: 1rem 1.75rem;
    background-color: ${({ theme }) => theme.colors.background.elements};
    border-radius: 0.75rem;
    position: relative;
    border: 1px solid #ccc;

    
    .infoTitle{
        width: 8rem;
    }
    .infoData{
        color: ${({ theme }) => theme.colors.text.primary};
    }
    .scroll-thumb{
        background-color: ${({ theme }) => theme.colors.primary_8};
    }
`;

const Section1_1 = ({ contractData }) => {

    return (
        <StyledSection1_1>
            <h2 className='sectionFloatTitle'>الايجار المتزايد</h2>

            <Row className='mt-2'>
                <Col className='mb-2'>
                    <div>
                        <span className='infoTitle'>
                            مبلغ الزيادة :
                        </span>
                        <span className='infoData'>
                            3200 ريال
                        </span>
                    </div>
                </Col>
            </Row>
        </StyledSection1_1>
    );
}

export default Section1_1;