import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { MdAccountCircle } from 'react-icons/md';
import Moment from 'react-moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import moment from 'moment';
import PlainText from '../../../../pages-parts/display-info/standard/PlainText';


const StyledSection1 = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    margin-bottom: 3rem;
    padding: 1rem 1.75rem;
    background-color: ${({ theme }) => theme.colors.background.elements};
    border-radius: 0.75rem;
    position: relative;
    border: 1px solid #ccc;

    
    
    .infoData{
        color: ${({ theme }) => theme.colors.text.primary};
    }
    .scroll-thumb{
        background-color: ${({ theme }) => theme.colors.primary_8};
    }
`;

const Section1 = ({ requestData }) => {
    const [displayData, setDisplayData] = useState([]);


    useEffect(() => {

        setDisplayData([
            {
                title: 'الاسم بالكامل',
                value: requestData.name,
                hasNumber: false
            },
            {
                title: 'البريد الالكتروني',
                value: 'assistant@assistant.com',
                hasNumber: true
            },
            {
                title: 'رقم الجوال',
                value: '01024563178',
                hasNumber: true
            },
            {
                title: 'تاريخ الميلاد',
                value: moment().subtract(29, "years").format('ll'),
                hasNumber: true
            },
            {
                title: 'المنطقة',
                value: requestData.area,
                hasNumber: false
            },
            {
                title: 'المدينة',
                value: requestData.city,
                hasNumber: false
            },
            {
                title: 'تاريخ الأنشاء',
                value: moment(requestData.sentAt).format('ll'),
                hasNumber: true
            },
            {
                title: 'تاريخ العرض',
                value: moment(requestData.sentAt).add(3, "days").format('ll'),
                hasNumber: true
            },
        ]);
    }, [requestData]);

    return (
        <StyledSection1>
            <h2 className='sectionFloatTitle'>البيانات الاساسية</h2>

            <Row className='mt-4'>
                <Col md="6" className='mb-2'>
                    {displayData.map((item, i) => (
                        <PlainText
                            key={i}
                            title={item.title}
                            value={item.value}
                            hasNumber={item.hasNumber}
                        />
                    ))}
                </Col>
            </Row>
        </StyledSection1>
    );
}

export default Section1;