import React, { useContext, useEffect, useRef, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';
import profileImage1 from '../../../../../images/pic1.png';
import profileImage2 from '../../../../../images/1.png';
import { UserContext } from '../../../../../context/UserContext';
import Select from 'react-select';
import { customSelectStyles } from '../../../../../styles/Global';

const StyledSection1 = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    padding: 1rem 1.75rem;
    background-color: ${({ theme }) => theme.colors.background.elements};
    border-radius: 0.75rem;
    position: relative;
    border: 1px solid #ccc;

    
    
    
    .infoData{
        width: 12rem;
    }

    span img{
        width: 6rem;
        height: 6rem;
        border-radius: 0.75rem;
    }
`;

const Section1 = ({ userData, userType, setUserType }) => {
    const phoneInput = useRef();
    const [options, setOptions] = useState([
        {
            value: 'admin',
            label: 'ادمن',
        },
        {
            value: 'supervisor',
            label: 'مشرف',
        },
        {
            value: 'accountant',
            label: 'محاسب',
        },
        {
            value: 'broker-assistant',
            label: 'مساعد وسيط',
        },
        {
            value: 'promoter',
            label: 'مروج',
        },
        {
            value: 'customer-service',
            label: 'خدمة عملاء',
        },
    ]);


    const handleChangeSelect = (selectOption) => {
        setUserType(selectOption)
    }
    const handleChange = () => {
        if (phoneInput.current.value.length > 12 && phoneInput.current.value.search('-') > 0) {
            phoneInput.current.value = phoneInput.current.value.slice(0, -1);
        } else if (phoneInput.current.value.length === 10) {
            phoneInput.current.value = phoneInput.current.value.replace(/([0-9]{3})([0-9]{3})([0-9]{4})/g, '$1-$2-$3');
        } else {
            phoneInput.current.value = phoneInput.current.value.replace(/([^0-9])/g, '');
        }
    }

    return (
        <StyledSection1 className='mb-5'>
            <h2 className='sectionFloatTitle'>البيانات الاساسية</h2>
            <Row className='flex-row-reverse'>
                <Col
                    xs='12'
                    sm='3'
                    className='
                        d-flex
                        flex-column
                        align-items-center
                        align-items-sm-end
                        justify-content-between
                        mb-3
                        mb-sm-0
                    '
                >
                    <span>
                        <img src={profileImage2} alt="Profile" />
                    </span>
                </Col>
                <Col xs='12' sm='9'>
                    <div className='mt-2 data-line'>
                        <span className='infoTitle'>
                            الاسم بالكامل
                        </span>
                        <span className='infoData'>
                            <Form.Control
                                className='py-2 px-3'
                                type='text'
                                name='name'
                                defaultValue={userData.name}
                            />
                        </span>
                    </div>
                    <div className='data-line'>
                        <span className='infoTitle'>
                            البريد الالكتروني
                        </span>
                        <span className='infoData'>
                            <Form.Control
                                className='py-2 px-3'
                                type='email'
                                name='email'
                                defaultValue={userData.email}
                            />
                        </span>
                    </div>
                    <div className='data-line'>
                        <span className='infoTitle'>
                            رقم الهاتف
                        </span>
                        <span className='infoData'>
                            <Form.Control
                                className='py-2 px-3'
                                type='text'
                                name='phone'
                                defaultValue={userData.phoneNum}
                                onChange={handleChange}
                                ref={phoneInput}
                            />
                        </span>
                    </div>
                    <div className='data-line'>
                        <span className='infoTitle'>
                            الحساب مفعل
                        </span>
                        <span className='infoData'>
                            <Form.Check
                                className='px-0'
                                type="switch"
                                id="custom-switch"
                                label=""
                                defaultChecked={true}
                            />
                        </span>
                    </div>
                    <div className='data-line'>
                        <span className='infoTitle'>
                            نوع الحساب
                        </span>
                        <span className='infoData'>
                            <Select
                                value={userType}
                                options={options}
                                styles={customSelectStyles}
                                onChange={handleChangeSelect}
                            />
                        </span>
                    </div>
                </Col>
            </Row>
        </StyledSection1>
    );
}

export default Section1;