import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { FaBed, FaBath } from 'react-icons/fa';
import { MdOutlineLiving } from 'react-icons/md';
import Moment from 'react-moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Select from 'react-select';
import moment from 'moment';
import { ContractFinanceRecordEditContext } from '../ContractFinanceRecordEditContext';
import { customSelectStyles } from '../../../../../styles/Global';


const StyledSection8 = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    margin-bottom: 3rem;
    padding: 1rem 1.75rem;
    background-color: ${({ theme }) => theme.colors.background.elements};
    border-radius: 0.75rem;
    position: relative;
    border: 1px solid #ccc;

    

    &:last-child{
        margin-bottom: 0;
    }
    .headContainer{
        p{
            color: ${({ theme }) => theme.colors.text.primary};
        }

        h4 {
            display: flex;
            align-items: center;
            p{
                color: ${({ theme }) => theme.colors.text.secondary};
                margin-left: .75rem;
            }
            .status{
                display: inline-block;
                padding: 0.25rem 1rem;
                font-size: 14px;
                border-radius: .75rem;
                background-color: ${({ theme }) => theme.colors.secondary_2};
            }
        }
    }
    p{
        margin: 0;
    }
    .infoData{
        padding: .75rem 3rem;
        border-radius: 0.75rem;
        border: 1px solid #ccc;
    }

    
    .secondRow{
        justify-content: right;
        .periodBox{
            display: flex;
            margin-left: 2rem;
            flex-direction: column;
            justify-content: center;
            width: auto;

            div{
                text-align: center;
            }
            h4, h6{
                margin-bottom: 0;
                color: ${({ theme }) => theme.colors.text.secondary};
            }
            .infoData2{
                padding: 0;
                margin-top: 0.5rem;
            }
        }
    }
    .amenitiesBox{
        display: flex;
        .amenities{
            padding: .25rem .75rem;
            border-radius: 0.5rem;
            border: 1px solid ${({ theme }) => theme.colors.secondary};
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            margin-left: 1rem;
            .icon{
                font-size: 1.5rem;
                color: ${({ theme }) => theme.colors.secondary};
                margin-left: .5rem;
            }
            span{
    
            }
        }
    }
`;

const Section8 = () => {
    const {
        creditCardsOptions,
        selectedCreditCard,
        handleChangeSelectedCreditCard,
    } = useContext(ContractFinanceRecordEditContext);

    return (
        <StyledSection8>
            <h2 className='sectionFloatTitle'>رقم حساب الايبان</h2>
            <Row className='my-3 secondRow numbers-font'>
                <Col md="6">
                    <Select
                        value={selectedCreditCard}
                        options={creditCardsOptions}
                        styles={customSelectStyles}
                        onChange={handleChangeSelectedCreditCard}
                    />
                </Col>
            </Row>
        </StyledSection8>
    );
}

export default Section8;