import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { AiOutlineEye } from 'react-icons/ai';
import Moment from 'react-moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import moment from 'moment';


const StyledSection6 = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    padding: 1rem 1.75rem;
    background-color: ${({ theme }) => theme.colors.background.elements};
    border-radius: 0.75rem;
    position: relative;
    border: 1px solid #ccc;

    p{
        margin: 0;
    }

    .displayCard{
        padding: 1rem;
        border-radius: 0.75rem;
        border: 1px solid #ccc;
        background-color: ${({ theme }) => theme.colors.background.body};

        .tableData.last{
            margin-bottom: 1rem;
        }
    }

    .thirdRow{
        width: 75%;
        .periodBox{
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: auto;

            div{
                text-align: center;
            }
            h4, h5{
                margin-bottom: 0;
            }
            .infoData2{
                padding: 0;
                margin-top: 0.5rem;
            }
        }
    }
`;

const Section6 = ({ contractData }) => {

    return (
        <StyledSection6>
            <h2 className='sectionFloatTitle'>الشروط و الاحكام</h2>
            <Row className='my-3 headContainer'>
                <Col className='mb-2'>
                    <h5 className='mb-0'>معلومات عن شروط و احكام الايجار</h5>
                </Col>
            </Row>
            <div className='displayCard w-50'>
                <h5>رسوم توثيق العقد</h5>
                <Row className='tableData'>
                    <Col>01 - 10 - 2021</Col>
                    <Col>125 SAR</Col>
                </Row>
                <Row className='tableData'>
                    <Col>07 - 11 - 2021</Col>
                    <Col>125 SAR</Col>
                </Row>
                <Row className='tableData last'>
                    <Col>22 - 11 - 2021</Col>
                    <Col>125 SAR</Col>
                </Row>
                <Row className='tableData total'>
                    <Col>الاجمالي</Col>
                    <Col>375 SAR</Col>
                </Row>
            </div>

            <Row className='my-3 thirdRow'>
                <Col className='periodBox align-items-start'>
                    <div>
                        <h5>مدة العقد</h5>
                        <p className='infoData2'>
                            3 سنوات
                        </p>
                    </div>
                </Col>
                <Col className='periodBox align-items-center'>
                    <div>
                        <h5>تجديد العقد تلقائيا</h5>
                        <p className='infoData2'>
                            60 يوم
                        </p>
                    </div>
                </Col>
                <Col className='periodBox align-items-center'>
                    <div>
                        <h5>التأجير من الباطن</h5>
                        <p className='infoData2'>
                            بدون
                        </p>
                    </div>
                </Col>
                <Col className='periodBox align-items-end'>
                    <div>
                        <h5>بند أضافي (1)</h5>
                        <p className='infoData2'>
                            -
                        </p>
                    </div>
                </Col>
            </Row>
        </StyledSection6>
    );
}

export default Section6;