import moment from 'moment';
import React, { useState, createContext, useEffect, useContext } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { TopbarContext } from '../../../../context/TopbarContext';
import api from '../../../../api/areas';
import { AlertContext } from '../../../../context/AlertContext';


export const TenantDetailsEditContext = createContext();

export const TenantDetailsEditContextProvider = ({ children }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { toggleTopbarTitle } = useContext(TopbarContext);
    const alertContext = useContext(AlertContext);

    // tenant personal info section ------------------------
    // الاسم
    const [fullName, setFullName] = useState('');
    // نوع الهوية

    const anotherCountries = [
        {
            value: 'egypt',
            label: 'مصر',
        },
        {
            value: 'tunisia',
            label: 'تونس',
        },
        {
            value: 'algeria',
            label: 'الجزائر',
        },
        {
            value: 'lebanon',
            label: 'لبنان',
        },
    ];

    const gccCountries = [
        {
            value: 'oman',
            label: 'عمان',
        },
        {
            value: 'emirates',
            label: 'الإمارات',
        },
        {
            value: 'kuwait',
            label: 'الكويت',
        },
        {
            value: 'qatar',
            label: 'قطر',
        },
    ];
    const [idTypeOptions, setIdTypeOptions] = useState([
        {
            value: 'national-id',
            label: 'هوية وطنية',
        },
        {
            value: 'special-stay',
            label: 'اقامة مميزة',
        },
        {
            value: 'resident-id',
            label: 'اقامة',
        },
        {
            value: 'gcc',
            label: 'مجلس التعاون الخليجي',
        },
        {
            value: 'passport',
            label: 'جواز سفر',
        },
        {
            value: 'another',
            label: 'اخر',
        },
    ]);
    const [selectedIdTypeOption, setSelectedIdTypeOption] = useState(idTypeOptions[0]);
    const handleChangeIdType = (selectOption) => {
        setSelectedIdTypeOption(selectOption)
    }
    // id number
    const [idNumber, setIdNumber] = useState('')
    // تاريخ الميلاد
    const [birthDate, setBirthDate] = useState(new Date(moment().add(3, "years")));
    // الصفة objective
    const [tenantTypeOptions, setTenantTypeOptions] = useState([
        {
            value: 'single-tenant',
            label: 'مستأجر كفرد',
        },
        {
            value: 'single-representative',
            label: 'ممثل مستأجر كفرد',
        },
        {
            value: 'establishment-tenant',
            label: 'مستأجر كمؤسسة',
        },
        {
            value: 'establishment-representative',
            label: 'ممثل مستأجر كمؤسسة',
        },
    ]);
    const [selectedTenantTypeOption, setSelectedTenantTypeOption] = useState({
        value: 'single-tenant',
        label: 'مستأجر كفرد',
    });
    const handleChangeTenantType = (selectOption) => {
        setSelectedTenantTypeOption(selectOption)
    }
    // nationality
    const [nationalityOptions, setNationalityOptions] = useState([
        {
            value: 'oman',
            label: 'عمان',
        },
        {
            value: 'emirates',
            label: 'الإمارات',
        },
        {
            value: 'kuwait',
            label: 'الكويت',
        },
        {
            value: 'qatar',
            label: 'قطر',
        },
    ]);
    const [selectedNationalityOption, setSelectedNationalityOption] = useState(nationalityOptions[0]);
    const handleChangeNationality = (selectOption) => {
        setSelectedNationalityOption(selectOption)
    }
    // Country of Issue بلد الاصدار
    const [countryOfIssueOptions, setCountryOfIssueOptions] = useState([
        {
            value: 'oman',
            label: 'عمان',
        },
        {
            value: 'emirates',
            label: 'الإمارات',
        },
        {
            value: 'kuwait',
            label: 'الكويت',
        },
        {
            value: 'qatar',
            label: 'قطر',
        },
    ]);
    const [selectedCountryOfIssueOption, setSelectedCountryOfIssueOption] = useState(countryOfIssueOptions[0]);
    const handleChangeCountryOfIssue = (selectOption) => {
        setSelectedCountryOfIssueOption(selectOption)
    }

    // establishment info section -----------------
    // نوع المؤسسة
    const [establishmentTypeOptions, setEstablishmentTypeOptions] = useState([
        {
            value: 'commercial',
            label: 'تجارية',
        },
        {
            value: 'non-prophet',
            label: 'غير ربحية',
        },
        {
            value: 'license-number',
            label: 'رقم رخصة',
        }
    ]);
    const [selectedEstablishmentTypeOption, setSelectedEstablishmentTypeOption] = useState(establishmentTypeOptions[0]);
    const handleChangeEstablishmentType = (selectOption) => {
        setSelectedEstablishmentTypeOption(selectOption)
    }
    // رقم المؤسسة
    const [establishmentId, setEstablishmentId] = useState('');
    // الرقم الضريبي
    const [taxNumber, setTaxNumber] = useState('');

    // contact info section -------------------------
    // رقم الهاتف
    const [phoneNum, setPhoneNum] = useState('');
    const [phoneCountryCode, setPhoneCountryCode] = useState('');
    const handleChangePhoneCountryCode = (value) => {
        setPhoneCountryCode(value);
    }
    // البريد الالكتروني
    const [email, setEmail] = useState('');

    // representative info section -------------------------
    // نوع وثيقة الممثل
    const [representativeTypeOptions, setRepresentativeTypeOptions] = useState([
        {
            value: 'electronic-legal-agency',
            label: 'وكالة شرعية الكترونية',
        },
        {
            value: 'legal-agency',
            label: 'وكالة شرعية',
        },
        {
            value: 'legitimate-mandate',
            label: 'ولاية شرعية',
        },
        {
            value: 'injunction',
            label: 'أمر قضائي',
        },
        {
            value: 'waqf-monjez',
            label: 'وقف منجز',
        },
        {
            value: 'partners-decision',
            label: 'قرار الشركاء',
        },
        {
            value: 'establishment-contract',
            label: 'عقد تأسيس الشركة',
        },
        {
            value: 'others',
            label: 'أخر',
        },
    ]);
    const [selectedRepresentativeTypeOption, setSelectedRepresentativeTypeOption] = useState(representativeTypeOptions[0]);
    const handleChangeRepresentativeType = (selectOption) => {
        setSelectedRepresentativeTypeOption(selectOption)
    }
    // رقم وثيقة الممثل
    const [representativeDocumentNumber, setRepresentativeDocumentNumber] = useState('');
    // تاريخ انشاء وثيقة الممثل
    const [representativeDocumentReleaseDate, setRepresentativeDocumentReleaseDate] = useState(new Date(moment().subtract(3, "years")));
    // تاريخ انتهاء وثيقة الممثل
    const [representativeDocumentExpireDate, setRepresentativeDocumentExpireDate] = useState(new Date(moment().add(1, "years")));
    // تم الانشاء بواسطة
    const [representativeDocumentReleasedBy, setRepresentativeDocumentReleasedBy] = useState('');
    // تم الانشاء في
    const [representativeDocumentReleasedIn, setRepresentativeDocumentReleasedIn] = useState('');
    // اسم نوع الوثيقة القانونية
    const [representativeLegalDocumentName, setRepresentativeLegalDocumentName] = useState('');

    // escort personal info section ------------------------
    const [escorts, setEscorts] = useState([]);
    const setEscortField = (value, escortId, field) => {
        setEscorts(prev => {
            return prev.filter(item => {
                if (item.id === escortId) {
                    item[field] = value;
                }
                return item
            })
        })

    };
    // نوع القرابة
    const [escortTypeOptions, setEscortTypeOptions] = useState([
        {
            value: 'wife-husband',
            label: 'زوج / زوجة',
        },
        {
            value: 'son-daughter',
            label: 'ابن / ابنة',
        },
        {
            value: 'father',
            label: 'والد',
        },
        {
            value: 'mother',
            label: 'والدة',
        },
        {
            value: 'brother',
            label: 'اخ',
        },
        {
            value: 'sister',
            label: 'اخت',
        },
        {
            value: 'makfol',
            label: 'مكفول',
        },
        {
            value: 'uncle',
            label: 'عم / خال',
        },
        {
            value: 'cousin',
            label: 'ابن عم / خال',
        },
        {
            value: 'another',
            label: 'اخر',
        },
    ]);
    // نوع الهوية
    const [escortIdTypeOptions, setEscortIdTypeOptions] = useState([
        {
            value: 'national-id',
            label: 'هوية وطنية',
        },
        {
            value: 'special-stay',
            label: 'اقامة مميزة',
        },
        {
            value: 'resident-id',
            label: 'اقامة',
        },
        {
            value: 'gcc',
            label: 'مجلس التعاون الخليجي',
        },
        {
            value: 'passport',
            label: 'جواز سفر',
        },
        {
            value: 'another',
            label: 'اخر',
        },
    ]);
    // nationality
    const [escortNationalityOptions, setEscortNationalityOptions] = useState([
        {
            value: 'oman',
            label: 'عمان',
        },
        {
            value: 'emirates',
            label: 'الإمارات',
        },
        {
            value: 'kuwait',
            label: 'الكويت',
        },
        {
            value: 'qatar',
            label: 'قطر',
        },
    ]);
    // Country of Issue بلد الاصدار
    const [escortCountryOfIssueOptions, setEscortCountryOfIssueOptions] = useState([
        {
            value: 'oman',
            label: 'عمان',
        },
        {
            value: 'emirates',
            label: 'الإمارات',
        },
        {
            value: 'kuwait',
            label: 'الكويت',
        },
        {
            value: 'qatar',
            label: 'قطر',
        },
    ]);
    // email
    const [escortEmail, setEscortEmail] = useState('');
    // email
    const [escortPhoneNumber, setEscortPhoneNumber] = useState('');


    const getTenantData = (tenantId) => {
        api.get(`/tenants?id=${tenantId}`).then(result => {
            if (result.data.length === 0) {
                alertContext.toggleAlert(true);
                alertContext.toggleAlertData({
                    heading: 'مستأجر / ممثل مجهول',
                    message: 'لم يتم العثور علي المستأجر / الممثل المطلوب',
                    variant: 'warning',
                });
                navigate("/");
            } else {
                if (location.pathname === "/tenant-edit") {
                    toggleTopbarTitle(`تعديل بيانات ${result.data[0].userType}`)
                }
                setFullName(result.data[0].tenantOrRepresentative);
                setEscorts(prev => {
                    let values = [];
                    result.data[0].escorts.map(item => {
                        let value = {
                            id: item.id,
                            name: item.name,
                            relativeType: "wife-husband",
                            escortIdType: "national-id",
                            escortIdNumber: "45487756414",
                            escortBirthDate: new Date(moment().subtract(24, "years")),
                            escortNationality: "saudi-arabia",
                            escortCountryOfIssue: "saudi-arabia",
                            escortEmail: "a@a.com",
                            escortPhoneNumber: "584684254584",
                        }
                        values.push(value);
                    })

                    return values
                });
                setSelectedTenantTypeOption(prev => {
                    if (result.data[0].userType === "ممثل") {
                        return {
                            value: 'single-representative',
                            label: 'ممثل مستأجر كفرد',
                        };
                    } else {
                        return {
                            value: 'single-tenant',
                            label: 'مستأجر كفرد',
                        };
                    }
                })
            }
        });
    }

    useEffect(() => {
        const another = [
            {
                value: 'egypt',
                label: 'مصر',
            },
            {
                value: 'tunisia',
                label: 'تونس',
            },
            {
                value: 'algeria',
                label: 'الجزائر',
            },
            {
                value: 'lebanon',
                label: 'لبنان',
            },
        ];

        const gcc = [
            {
                value: 'oman',
                label: 'عمان',
            },
            {
                value: 'emirates',
                label: 'الإمارات',
            },
            {
                value: 'kuwait',
                label: 'الكويت',
            },
            {
                value: 'qatar',
                label: 'قطر',
            },
        ];

        if (
            selectedIdTypeOption.value === "special-stay" ||
            selectedIdTypeOption.value === "resident-id" ||
            selectedIdTypeOption.value === "passport" ||
            selectedIdTypeOption.value === "another"
        ) {
            setNationalityOptions(another);
            setCountryOfIssueOptions(another);
        } else if (selectedIdTypeOption.value === "gcc") {
            setNationalityOptions(gcc)
            setCountryOfIssueOptions(gcc)
        }
    }, [selectedIdTypeOption])

    useEffect(() => {
        setSelectedNationalityOption(nationalityOptions[0]);
    }, [nationalityOptions])
    useEffect(() => {
        setSelectedCountryOfIssueOption(countryOfIssueOptions[0]);
    }, [countryOfIssueOptions])



    const handleSave = () => {
        console.log('hi');
    }

    const values = {
        getTenantData,
        handleSave,
        // tenant personal info
        fullName,
        setFullName,
        idTypeOptions,
        handleChangeIdType,
        selectedIdTypeOption,
        // id number
        idNumber,
        setIdNumber,
        birthDate,
        setBirthDate,
        tenantTypeOptions,
        handleChangeTenantType,
        selectedTenantTypeOption,
        nationalityOptions,
        handleChangeNationality,
        selectedNationalityOption,
        countryOfIssueOptions,
        handleChangeCountryOfIssue,
        selectedCountryOfIssueOption,
        // establishment info
        establishmentTypeOptions,
        handleChangeEstablishmentType,
        selectedEstablishmentTypeOption,
        establishmentId,
        setEstablishmentId,
        taxNumber,
        setTaxNumber,
        // contact info
        phoneNum,
        setPhoneNum,
        phoneCountryCode,
        handleChangePhoneCountryCode,
        email,
        setEmail,
        // representative info
        representativeTypeOptions,
        handleChangeRepresentativeType,
        selectedRepresentativeTypeOption,
        representativeDocumentNumber,
        setRepresentativeDocumentNumber,
        representativeDocumentReleaseDate,
        setRepresentativeDocumentReleaseDate,
        representativeDocumentExpireDate,
        setRepresentativeDocumentExpireDate,
        representativeDocumentReleasedBy,
        setRepresentativeDocumentReleasedBy,
        representativeDocumentReleasedIn,
        setRepresentativeDocumentReleasedIn,
        representativeLegalDocumentName,
        setRepresentativeLegalDocumentName,
        // escort personal info
        escorts,
        setEscorts,
        setEscortField,
        // ---------------------------------
        escortTypeOptions,
        escortIdTypeOptions,
        escortNationalityOptions,
        escortCountryOfIssueOptions,
        anotherCountries,
        gccCountries,
    };

    return (
        <TenantDetailsEditContext.Provider value={values}>
            {children}
        </TenantDetailsEditContext.Provider>
    )
}

