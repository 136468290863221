import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { AiOutlineEye } from 'react-icons/ai';
import { BsFillHouseDoorFill, BsUpload } from 'react-icons/bs';
import { IoPricetagOutline } from 'react-icons/io5';
import { FaRegBuilding, FaRegEdit } from 'react-icons/fa';
import Moment from 'react-moment';
import TableComponent from '../../../pages-parts/table/TableComponent';
import Container from 'react-bootstrap/Container';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import CreateNewLink from '../../../pages-parts/table/CreateNewLink';
import AddNewButton from '../../../pages-parts/inputs/add-new-btn/AddNewButton';


const StyledSection2 = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    margin-bottom: 3rem;
    padding: 1rem 1.75rem;
    background-color: ${({ theme }) => theme.colors.background.elements};
    border-radius: 0.75rem;
    position: relative;
    border: 1px solid #ccc;

    
    .tableHead{
        padding-bottom: 0;
    }
    .scroll-thumb{
        background-color: ${({ theme }) => theme.colors.primary_8};
    }
`;

const Section2 = () => {
    const location = useLocation();
    const [columns, setColumns] = useState([
        {
            id: 1,
            Header: "#",
            Cell: e => {
                return <span className='numbers-font'>{e.value}</span>
            },
            accessor: "number",
            sortable: false,
        },
        {
            id: 2,
            Header: "العقود",
            accessor: "contractName",
            sortable: false,
            Cell: e => {
                if (e.row.cells.find(cell => cell.column.Header === "نوع العقد").value === "سكني") {
                    return (
                        <Link to={`/contracts-view?id=${e.row.cells.find(cell => cell.column.Header === "الاجراءات").value}`} className='d-flex align-items-center icon-header-text-link'>
                            <BsFillHouseDoorFill className='icon' />
                            <span>
                                <h4>
                                    {e.value}
                                </h4>
                                <span className="numbers-font"><span>#</span> {e.row.cells.find(cell => cell.column.Header === "الاجراءات").value.replace(/(-.*)/g, "")}...</span>
                            </span>
                        </Link>
                    )
                } else {
                    return (
                        <Link to={`/contracts-view?id=${e.row.cells.find(cell => cell.column.Header === "الاجراءات").value}`} className='d-flex align-items-center icon-header-text-link'>
                            <FaRegBuilding className='icon' />
                            <span>
                                <h4>
                                    {e.value}
                                </h4>
                                <span className="numbers-font"><span>#</span> {e.row.cells.find(cell => cell.column.Header === "الاجراءات").value.replace(/(-.*)/g, "")}...</span>
                            </span>
                        </Link>
                    )
                }
            }
        },
        {
            id: 3,
            Header: "تاريخ الانشاء",
            accessor: "createDate",
            sortable: false,
            Cell: e => {
                const date = new Date(e.value);
                return (
                    <span className='numbers-font'>
                        <Moment date={date} format="YYYY/MM/DD" />
                    </span>
                )
            }
        },
        {
            id: 4,
            Header: "المالك",
            accessor: "owner",
            sortable: false
        },
        {
            id: 5,
            Header: "المستأجر",
            accessor: "Tenant",
            sortable: false
        },
        {
            id: 6,
            Header: "نوع العقد",
            accessor: "contractType",
            sortable: false
        },
        {
            id: 7,
            Header: "حالة العقد",
            accessor: "contractState",
            sortable: false
        },
        {
            id: 8,
            Header: "حالة الدفع",
            accessor: "paymentState",
            sortable: false
        },
        {
            id: 9,
            Header: "الاجراءات",
            accessor: "id",
            sortable: false,
            Cell: e => {
                return (
                    <>
                        <OverlayTrigger
                            key="view"
                            placement="bottom"
                            overlay={
                                <Tooltip id={`tooltip-view`}>
                                    عرض بيانات العقد
                                </Tooltip>
                            }
                        >
                            <Link to={`/contracts-view?id=${e.value}`}><AiOutlineEye /></Link>
                        </OverlayTrigger>
                        <OverlayTrigger
                            key="edit"
                            placement="bottom"
                            overlay={
                                <Tooltip id={`tooltip-edit`}>
                                    تعديل بيانات العقد
                                </Tooltip>
                            }
                        >
                            <Link to={`/contracts-edit?id=${e.value}`}><FaRegEdit /></Link>
                        </OverlayTrigger>
                        <OverlayTrigger
                            key="ticket"
                            placement="bottom"
                            overlay={
                                <Tooltip id={`tooltip-ticket`}>
                                    محادثة العقد
                                </Tooltip>
                            }
                        >
                            <Link to={`/ticket?id=${e.value}`}><IoPricetagOutline /></Link>
                        </OverlayTrigger>
                        <OverlayTrigger
                            key="export"
                            placement="bottom"
                            overlay={
                                <Tooltip id={`tooltip-export`}>
                                    تصدير بيانات العقد
                                </Tooltip>
                            }
                        >
                            <Link to={`/contracts-export?id=${e.value}`}><BsUpload /></Link>
                        </OverlayTrigger>
                    </>
                )
            }
        },
    ]);

    const [tableDataUrl, setTableDataUrl] = useState(() => {
        return `/items?_page=1&_limit=5`;
    })





    return (
        <StyledSection2>
            <h2 className='sectionFloatTitle'>قائمة مسودة العقود</h2>
            <div className='tableHead'>
                <AddNewButton
                    title="عرض الكل"
                    url='/contracts-draft'
                    isLink={true}
                    hasIcon={false}
                />
            </div>
            <TableComponent
                columns={columns}
                tableDataUrl={tableDataUrl}
                setSortTable={() => { }}
                tableWidth={1000}
                setPageNum={() => { }}
                pageNum={() => { }}
                tableRowsPerPage={() => { }}
                hasPagination={false}
            />
        </StyledSection2>
    );
}

export default Section2;