import styled from "styled-components"
import { BsSearch, BsXLg } from 'react-icons/bs';

const StyledSearchArea = styled.div`
    width: 100%;
    max-width: 15rem;
    min-width: 9rem;
    display: flex;
    align-items: center;
    justify-content: left;
    flex-wrap: wrap;
    border-radius: 0.75rem;
    background-color: #FCFCFC;
    overflow: hidden;
    border: 2px solid #CCCCCC;
    position: relative;

    &:has(input:focus){
        border: 2px solid ${({ theme }) => theme.colors.secondary};
    }
    input{
        border: 0.0625rem solid #f5f5f5;
        border-left: 0;
        padding: 0.3125rem .5rem;
        position: relative;
        flex: auto 1;
        width: 1%;
        min-width: 0;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1;
        outline: none;
    }

    span{
        font-size: 1.4rem;
        font-weight: 700;
        line-height: 1;
        color: #6E6E6E;
        text-align: center;
        display: flex;
        padding-left: .5rem;
        height: 100%;
        align-items: center;
        cursor: pointer;
    }
`

const SearchBarFilter = ({ searchTable, setSearchTable, setPageNum }) => {
    const handleSearch = e => {
        setPageNum(1);
        setSearchTable(e.target.value);
    }
    const handleClick = () => {
        setPageNum(1);
        setSearchTable('');
    }
    return (
        <StyledSearchArea>
            <input value={searchTable} onChange={handleSearch} type="text" />
            <span>{searchTable === "" ? <BsSearch /> : <BsXLg onClick={handleClick} />}</span>
        </StyledSearchArea>
    )
}

export default SearchBarFilter;