import React, { useContext, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import { useSearchParams, Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import api from '../../../../../api/areas';
import { AlertContext } from '../../../../../context/AlertContext';
import Section1 from './sections/Section-1';
import Section2 from './sections/Section-2';
import Section3 from './sections/Section-3';
import AddNewButton from '../../../../pages-parts/inputs/add-new-btn/AddNewButton';

const StyledUnitView = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    padding: 2.5rem 1.75rem;
    color: ${({ theme }) => theme.colors.text.secondary};
    
    .row{
        margin: 0;
    }
`;

const UnitViewPage = () => {
    const [params, setParams] = useSearchParams();
    const [unitData, setUnitData] = useState({});
    const navigate = useNavigate();
    const alertContext = useContext(AlertContext);

    useEffect(() => {
        api.get(`/units?id=${params.get('unit_id')}`).then(result => {
            if (result.data.length === 0) {
                alertContext.toggleAlert(true);
                alertContext.toggleAlertData({
                    heading: 'وحدة مجهول',
                    message: 'لم يتم العثور علي الوحدة المطلوب',
                    variant: 'warning',
                });
                navigate("/");
            } else {
                setUnitData(result.data[0]);
            }
        });
    }, [])
    return (
        <StyledUnitView>
            <Container fluid>
                <div className='tableHead'>
                    <AddNewButton
                        title="تعديل"
                        url={`/unit-edit?unit_id=${params.get('unit_id')}`}
                        isLink={true}
                        hasIcon={false}
                    />
                </div>
                <Section1 unitData={unitData} />
                <Section2 unitData={unitData} />
                <Section3 unitData={unitData} />
            </Container>
        </StyledUnitView>
    );
}

export default UnitViewPage;