import React, { useContext, useEffect, useRef, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';
import Select from 'react-select';
import { Calendar } from 'react-date-range';
import moment from 'moment';
import NumberInput from '../../../../../pages-parts/inputs/number-input/NumberInput';
import { ContractFinanceRecordEditContext } from '../../ContractFinanceRecordEditContext';


const StyledFixed = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    padding: 1rem 1.75rem;
    background-color: ${({ theme }) => theme.colors.background.body};
    border-radius: 0.75rem;
    position: relative;
    border: 1px solid #ccc;

    .box{
        padding: .5rem;
        border: 1px solid #ccc;
        margin-bottom: 1rem;

        &:last-child{
            margin-bottom: 0;
        }

        &.checked{
            border: 2px solid #787878;
            background-color: ${({ theme }) => theme.colors.background.elements};
        }
    }
    
    
    .infoData{
        width: 12rem !important;
        position: relative;

        &::after{
            display: flex;
            content: "SAR";
            position: absolute;
            height: 100%;
            align-items: center;
            right: 0;
            top: 0;
            font-weight: 900;
            width: 50px;
            justify-content: center;
            border-left: 1px solid #ccc;
        }
        .myInput{
            padding-right: 55px!important;
        }

    }
    span img{
        width: 6rem;
        height: 6rem;
        border-radius: 0.75rem;
    }
`;

const Fixed = () => {
    const {
        fixedTypeIncrease,
        setFixedTypeIncrease,
        increaseFixed,
        setIncreaseFixed,
        decreaseFixed,
        setDecreaseFixed,
    } = useContext(ContractFinanceRecordEditContext);
    const increaseTypeFixedRef = useRef();

    const handleChange = (type) => {
        if (type === "increase") {
            setFixedTypeIncrease(true);
        } else {
            setFixedTypeIncrease(false);
        }
    }
    return (
        <StyledFixed>
            <div
                className={`box ${fixedTypeIncrease ? 'checked' : ''}`}
                onClick={() => handleChange('increase')}
            >
                <Form.Check
                    className='me-5 mb-0'
                    type='radio'
                    id='increase'
                    checked={fixedTypeIncrease}
                    name='increase-decrease'
                    label='مبلغ الزيادة'
                    onChange={() => { }}
                    ref={increaseTypeFixedRef}
                />

                <NumberInput
                    titleText='المبلغ'
                    inputName='yearly-rent'
                    value={increaseFixed}
                    setValue={setIncreaseFixed}
                />
            </div>

            <div
                className={`box ${!fixedTypeIncrease ? 'checked' : ''}`}
                onClick={() => handleChange('decrease')}
            >
                <Form.Check
                    className='me-5 mb-0'
                    type='radio'
                    id='decrease'
                    checked={!fixedTypeIncrease}
                    onChange={() => { }}
                    name='increase-decrease'
                    label='مبلغ النقصان'
                />

                <NumberInput
                    titleText='المبلغ'
                    inputName='yearly-rent'
                    value={decreaseFixed}
                    setValue={setDecreaseFixed}
                />
            </div>
        </StyledFixed>
    );
}

export default Fixed;