import { useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import { BsCheckLg, BsXLg } from 'react-icons/bs';
import styled from 'styled-components';

const StyledCode = styled.div`
    margin-bottom: 0;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    
    &:last-child{
        margin-bottom: 0;
    }

    

    .codeData{
        width: 12rem;
        position: relative;

        .ckeck-btn{
            position: absolute;
            display: flex;
            left: 10px;
            top: 10%;
            height: 80%;
            align-items: center;
            padding: 0 10px;
            background-color: ${({ theme }) => theme.colors.secondary};
            color: ${({ theme }) => theme.colors.background.elements};
            border-radius: 50%;
            cursor: pointer;

            &:hover{
                background-color: ${({ theme }) => theme.colors.secondary_8};
            }
        }
    }

    #icon-container{
        margin-right: 1rem;

        .true{
            color: ${({ theme }) => theme.colors.secondary};
        }
        .false{
            color: ${({ theme }) => theme.colors.primary};
        }
    }
`;

function CodeInput({ titleText, inputName, value, setValue, checkCode, rightCode }) {

    useEffect(() => {
        document.getElementById('icon-container').classList.add('d-none');
    }, [value])
    const handleChange = e => {
        setValue(prev => {
            return (e.target.value);
        })
    }

    const handleCheckCode = () => {
        document.getElementById('icon-container').classList.remove('d-none');
        checkCode(value);
    }
    return (
        <StyledCode>
            <span className='infoTitle'>
                {titleText}
            </span>
            <span className='codeData'>
                <Form.Control
                    className='py-1 px-3'
                    type='text'
                    defaultValue={value}
                    name={inputName}
                    onChange={handleChange}
                />
                <span className='ckeck-btn' onClick={handleCheckCode}>Ok</span>
            </span>
            <span id='icon-container' className='d-none'>
                {rightCode ? <BsCheckLg className='true' /> : <BsXLg className='false' />}
            </span>
        </StyledCode>
    );
}

export default CodeInput;