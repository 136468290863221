import React, { useContext, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useSearchParams, Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import Section1 from './section-1/Section-1';
import { OwnerDetailsEditContext, OwnerDetailsEditContextProvider } from './OwnerDetailsEditContext';
import Section2 from './section-2/Section-2';
import Section3 from './section-3/Section-3';
import Section4 from './section-4/Section-4';
import Section6 from './section-6/Section-6';
import Section5 from './section-5/Section-5';




const StyledOwnerDetailsEdit = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    padding: 2.5rem 1.75rem;
    
`;

const OwnerDetailsEditContent = () => {
    const [params, setParams] = useSearchParams();
    const location = useLocation();
    const { getOwnerData } = useContext(OwnerDetailsEditContext);

    useEffect(() => {
        if(location.pathname === "/owner-edit"){
            getOwnerData(params.get('id'));
        }
    }, [])

    const handleSave = () => {
        console.log('hi');
    }

    return (
        <StyledOwnerDetailsEdit>
            <Container fluid>
                <Section1 />
                <Section2 />
                <Section3 />
                <Section4 />
                <Section5 />
                <Section6 />

                <Row className="mt-3">
                    <Col className='d-flex align-items-center justify-content-end py-0'>
                        <Link
                            to={
                                location.pathname === "/owner-edit" ?
                                `/owner-view?id=${params.get('id')}` :
                                `/real-estate-view?id=${params.get('real_estate_id')}`
                            }
                            className="py-2 px-3 return-link me-2"
                        >
                            {location.pathname === "/owner-edit" ? "رجوع" : "الغاء"}
                        </Link>
                        <span onClick={handleSave} className="py-2 px-3 save-link">
                            حفظ
                        </span>
                    </Col>
                </Row>
            </Container>
        </StyledOwnerDetailsEdit>
    )
}

const OwnerDetailsEdit = () => {
    
    return (
        <OwnerDetailsEditContextProvider>
            <OwnerDetailsEditContent />
        </OwnerDetailsEditContextProvider>
    );
}

export default OwnerDetailsEdit;