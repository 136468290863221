import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import styled, { css } from "styled-components";
import Section1 from "./sections/Section-1";
import { Link } from "react-router-dom";
import Section2 from "./sections/Section-2";

const StyledProfilePage = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    padding: 2.5rem 1.75rem;

    ${({ theme }) => {
        return css`
            .edit-link{
                color: ${theme.colors.secondary};
                background-color: ${theme.colors.secondary_1};
                border: 1px solid ${theme.colors.secondary_2};
                border-radius: 0.5rem;
            }
        `;
    }}
    
`;

const ProfilePage = () => {
    return (
        <StyledProfilePage>
            <Container fluid>
                {/* <Row className="mb-3">
                    <Col className='d-flex align-items-center justify-content-end py-2'>
                        <Link to='/profile/edit' className="py-2 px-3 edit-link">
                            تعديل الملف الشخصي
                        </Link>
                    </Col>
                </Row> */}
                <Section1 />
                <Section2 />
            </Container>
        </StyledProfilePage>
    )
}

export default ProfilePage;