import React, { useContext, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';
import moment from 'moment';
import { ContractDetailsEditContext } from '../../ContractDetailsEditContext';
import NumberInput from '../../../../pages-parts/inputs/number-input/NumberInput';

const StyledSection3 = styled.div`
    position: relative;
    width: 100%;
    margin-top: 3rem;
    transition: all .5s ease-in-out;
    padding: 1rem 1.75rem;
    background-color: ${({ theme }) => theme.colors.background.elements};
    border-radius: 0.75rem;
    position: relative;
    border: 1px solid #ccc;

    .form-check-input{
        margin-right: 0;
    }

    .infoData{
        width: 12rem;

    }

    span img{
        width: 6rem;
        height: 6rem;
        border-radius: 0.75rem;
    }
`;

const Section3 = () => {
    const {
        subLeasing,
        setSubLeasing,
    } = useContext(ContractDetailsEditContext);

    return (
        <StyledSection3>
            <h2 className='sectionFloatTitle'>التأجير من الباطن</h2>
            <Row>
                <Col className='text-end'>
                    <Form.Check
                        className='px-0'
                        type="switch"
                        checked={subLeasing}
                        name='has-facilities'
                        onChange={() => setSubLeasing(prev => !prev)}
                    />
                </Col>
            </Row>
            <Row className={subLeasing ? '' : 'd-none'}>
                <Col>
                    <p>
                        يسمح للمستأجر بتأجير هذا العقار بعقد ايجار جديد من الباطن
                    </p>
                </Col>
            </Row>
        </StyledSection3>
    );
}

export default Section3;