import React, { useContext, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';
import moment from 'moment';
import StaticSelect from '../../../../../pages-parts/inputs/static-select-input/StaticSelect';
import DatePickerInput from '../../../../../pages-parts/inputs/date-picker/DatePickerInput';
import NumberInput from '../../../../../pages-parts/inputs/number-input/NumberInput';
import TextInput from '../../../../../pages-parts/inputs/text-input/TextInput';
import SwitchInput from '../../../../../pages-parts/inputs/switch-input/SwitchInput';
import { UnitEditContext } from '../UnitEditContext';

const StyledSection2 = styled.div`
    position: relative;
    width: 100%;
    margin-top: 3rem;
    transition: all .5s ease-in-out;
    padding: 1rem 1.75rem;
    background-color: ${({ theme }) => theme.colors.background.elements};
    border-radius: 0.75rem;
    position: relative;
    border: 1px solid #ccc;

    .form-check-input{
        margin-right: 0;
    }
    
    
    .infoData{
        width: 12rem;

    }

    span img{
        width: 6rem;
        height: 6rem;
        border-radius: 0.75rem;
    }
`;

const Section2 = () => {
    const {
        hasFacilities,
        setHasFacilities,
        bedroomsNumber,
        setBedroomsNumber,
        WC,
        setWC,
        halls,
        setHalls,
        servantRoom,
        setServantRoom,
        kitchen,
        setKitchen,
        storage,
        setStorage,
        backyard,
        setBackyard,
        board,
        setBoard,
        centralAirConditioner,
        setCentralAirConditioner,
        splitAirConditioner,
        setSplitAirConditioner,
        desertAirConditioner,
        setDesertAirConditioner,
        hangingKitchen,
        setHangingKitchen,
        hangingWardrobe,
        setHangingWardrobe,
    } = useContext(UnitEditContext);

    return (
        <StyledSection2>
            <h2 className='sectionFloatTitle'>مرافق الوحدة</h2>
            <Row>
                <Col className='text-end'>
                    <Form.Check
                        className='px-0'
                        type="switch"
                        defaultChecked={hasFacilities}
                        name='has-facilities'
                        onChange={() => setHasFacilities(prev => !prev)}
                    />
                </Col>
            </Row>
            <Row className={hasFacilities ? '' : 'd-none'}>
                <Col>
                    <NumberInput
                        titleText='غرف النوم'
                        inputName='parking-number'
                        value={bedroomsNumber}
                        setValue={setBedroomsNumber}
                    />
                    <NumberInput
                        titleText='دورات المياة'
                        inputName='wc'
                        value={WC}
                        setValue={setWC}
                    />
                    <NumberInput
                        titleText='صالات'
                        inputName='halls'
                        value={halls}
                        setValue={setHalls}
                    />
                    <NumberInput
                        titleText='غرفة الخدم'
                        inputName='servant-room'
                        value={servantRoom}
                        setValue={setServantRoom}
                    />
                    <NumberInput
                        titleText='المطبخ'
                        inputName='kitchen'
                        value={kitchen}
                        setValue={setKitchen}
                    />
                    <NumberInput
                        titleText='مخزن'
                        inputName='storage'
                        value={storage}
                        setValue={setStorage}
                    />
                    <NumberInput
                        titleText='الفناء الخلفي'
                        inputName='backyard'
                        value={backyard}
                        setValue={setBackyard}
                    />
                    <NumberInput
                        titleText='مجلس'
                        inputName='board'
                        value={board}
                        setValue={setBoard}
                    />
                    <NumberInput
                        titleText='مكيف هواء مركزي'
                        inputName='central-air-conditioner'
                        value={centralAirConditioner}
                        setValue={setCentralAirConditioner}
                    />
                    <NumberInput
                        titleText='مكيف هواء منفصل'
                        inputName='split-air-conditioner'
                        value={splitAirConditioner}
                        setValue={setSplitAirConditioner}
                    />
                    <NumberInput
                        titleText='مكيف صحراوي'
                        inputName='desert-air-conditioner'
                        value={desertAirConditioner}
                        setValue={setDesertAirConditioner}
                    />
                    <SwitchInput
                        titleText='مطبخ راكب'
                        inputName='hanging-kitchen'
                        value={hangingKitchen}
                        setValue={setHangingKitchen}
                    />
                    <SwitchInput
                        titleText='خزانة راكبة'
                        inputName='hanging-wardrobe'
                        value={hangingWardrobe}
                        setValue={setHangingWardrobe}
                    />
                </Col>
            </Row>
        </StyledSection2>
    );
}

export default Section2;