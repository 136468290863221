import React, { useContext, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useSearchParams, Link, useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';
import Section1 from '../../owners/edit/section-1/Section-1';
import Section2 from '../../owners/edit/section-2/Section-2';
import Section3 from '../../owners/edit/section-3/Section-3';
import Section4 from '../../owners/edit/section-4/Section-4';
import Section5 from '../../owners/edit/section-5/Section-5';
import Section6 from '../../owners/edit/section-6/Section-6';




const StyledOwnerDetailsEdit = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    padding: 0;
    
`;

const OwnerDetailsEdit = () => {

    return (
        <StyledOwnerDetailsEdit>
            <Section1 />
            <Section2 />
            <Section3 />
            <Section4 />
            <Section5 />
            <Section6 />
        </StyledOwnerDetailsEdit>
    );
}

export default OwnerDetailsEdit;