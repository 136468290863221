import React, { useContext, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from 'styled-components';
import moment from 'moment';
import StaticSelect from '../../../../../pages-parts/inputs/static-select-input/StaticSelect';
import DatePickerInput from '../../../../../pages-parts/inputs/date-picker/DatePickerInput';
import NumberInput from '../../../../../pages-parts/inputs/number-input/NumberInput';
import TextInput from '../../../../../pages-parts/inputs/text-input/TextInput';
import SwitchInput from '../../../../../pages-parts/inputs/switch-input/SwitchInput';
import { UnitEditContext } from '../UnitEditContext';

const StyledSection1 = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    padding: 2rem 1.75rem 1rem;
    background-color: ${({ theme }) => theme.colors.background.elements};
    border-radius: 0.75rem;
    position: relative;
    border: 1px solid #ccc;

    .form-check-input{
        margin-right: 0;
    }
    
    
    .infoData{
        width: 12rem;

    }

    span img{
        width: 6rem;
        height: 6rem;
        border-radius: 0.75rem;
    }
`;

const Section1 = () => {
    const {
        unitNumber,
        setUnitNumber,
        unitTypeOptions,
        unitTypeOption,
        handleChangeUnitTypeOption,
        unitUseOptions,
        unitUseOption,
        handleChangeUnitUseOption,
        floorNumber,
        setFloorNumber,
        unitSpace,
        setUnitSpace,
        roomsNumber,
        setRoomsNumber,
        constructionDate,
        setConstructionDate,
        unitDirections,
        unitSelectedDirection,
        handleChangeUnitSelectedDirection,
        unitFinishingTypes,
        unitSelectedFinishingType,
        handleChangeUnitSelectedFinishingType,
        unitLength,
        setUnitLength,
        unitWidth,
        setUnitWidth,
        unitHeight,
        setUnitHeight,
        hasMezzanine,
        setHasMezzanine,
        isFurnished,
        setIsFurnished,
        furnishTypes,
        unitSelectedFurnishType,
        handleChangeUnitSelectedFurnishType,
    } = useContext(UnitEditContext);

    return (
        <StyledSection1>
            <h2 className='sectionFloatTitle'>تفاصيل الوحدة</h2>
            <Row>
                <Col>
                    <NumberInput
                        titleText='رقم الوحدة'
                        inputName='parking-number'
                        value={unitNumber}
                        setValue={setUnitNumber}
                    />
                    <StaticSelect
                        titleText='نوع الوحدة'
                        selectedOption={unitTypeOption}
                        options={unitTypeOptions}
                        customStylesProp={{
                            has: false,
                            style: {}
                        }}
                        handleChangeSelect={handleChangeUnitTypeOption}
                    />
                    <StaticSelect
                        titleText='استخدام الوحدة'
                        selectedOption={unitUseOption}
                        options={unitUseOptions}
                        customStylesProp={{
                            has: false,
                            style: {}
                        }}
                        handleChangeSelect={handleChangeUnitUseOption}
                    />
                    <NumberInput
                        titleText='رقم الطابق'
                        inputName='floor-number'
                        value={floorNumber}
                        setValue={setFloorNumber}
                    />
                    <NumberInput
                        titleText='مساحة الوحدة'
                        inputName='security-service'
                        value={unitSpace}
                        setValue={setUnitSpace}
                    />
                    <TextInput
                        titleText='عدد الغرف'
                        inputName='transfer-service'
                        value={roomsNumber}
                        setValue={setRoomsNumber}
                    />
                    <DatePickerInput
                        titleText='تاريخ الانشاء'
                        date={constructionDate}
                        setDate={setConstructionDate}
                    />
                    <StaticSelect
                        titleText='اتجاة الوحدة'
                        selectedOption={unitSelectedDirection}
                        options={unitDirections}
                        customStylesProp={{
                            has: false,
                            style: {}
                        }}
                        handleChangeSelect={handleChangeUnitSelectedDirection}
                    />
                    <StaticSelect
                        titleText='تشطيب الوحدة'
                        selectedOption={unitSelectedFinishingType}
                        options={unitFinishingTypes}
                        customStylesProp={{
                            has: false,
                            style: {}
                        }}
                        handleChangeSelect={handleChangeUnitSelectedFinishingType}
                    />
                    <NumberInput
                        titleText='طول الوحدة'
                        inputName='unit-length'
                        value={unitLength}
                        setValue={setUnitLength}
                    />
                    <NumberInput
                        titleText='عرض الوحدة'
                        inputName='unit-width'
                        value={unitWidth}
                        setValue={setUnitWidth}
                    />
                    <NumberInput
                        titleText='عرض الوحدة'
                        inputName='unit-height'
                        value={unitHeight}
                        setValue={setUnitHeight}
                    />
                    <SwitchInput
                        titleText='تحتوي علي ميزانين'
                        inputName='has-mezzanine'
                        value={hasMezzanine}
                        setValue={setHasMezzanine}
                    />
                    <SwitchInput
                        titleText='مؤثث / مفروش'
                        inputName='is-furnished'
                        value={isFurnished}
                        setValue={setIsFurnished}
                    />
                    {
                        isFurnished ? (
                            <StaticSelect
                                titleText='نوع الوحدة'
                                selectedOption={unitSelectedFurnishType}
                                options={furnishTypes}
                                customStylesProp={{
                                    has: false,
                                    style: {}
                                }}
                                handleChangeSelect={handleChangeUnitSelectedFurnishType}
                            />
                        ) : ""
                    }
                </Col>
            </Row>
        </StyledSection1>
    );
}

export default Section1;