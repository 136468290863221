import React, { useContext, useEffect, useRef, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';
import Select from 'react-select';
import { Calendar } from 'react-date-range';
import moment from 'moment';
import { ContractDetailsEditContext } from '../../ContractDetailsEditContext';


const StyledTerms = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    padding: 2rem 1.75rem 1rem;
    background-color: ${({ theme }) => theme.colors.background.body};
    border-radius: 0.75rem;
    position: relative;
    border: 1px solid #ccc;


    .box{
        padding: 1rem;
        width: 75%;
        border: 1px solid #ccc;
        border-radius: .75rem;
        margin-bottom: 1rem;

        &:last-child{
            margin-bottom: 0;
        }
        .form-check{
            color: ${({ theme }) => theme.colors.text.secondary};
        }
        &.checked{
            border: 2px solid #787878;
            background-color: ${({ theme }) => theme.colors.background.elements};
        }
    }
    
    
    .infoData{
        width: 12rem !important;
        position: relative;

        &::after{
            display: flex;
            content: "%";
            position: absolute;
            height: 100%;
            align-items: center;
            right: 0;
            top: 0;
            font-weight: 900;
            width: 50px;
            justify-content: center;
            border-left: 1px solid #ccc;
        }
        .myInput{
            padding-right: 55px!important;
        }

    }
    span img{
        width: 6rem;
        height: 6rem;
        border-radius: 0.75rem;
    }
`;

const Terms = () => {
    const {
        commercialContractTermsTypeUnified,
        setCommercialContractTermsTypeUnified,
    } = useContext(ContractDetailsEditContext);

    const commercialContractTermsTypeRef = useRef();

    const handleChange = (type) => {
        if (type === "increase") {
            setCommercialContractTermsTypeUnified(true);
        } else {
            setCommercialContractTermsTypeUnified(false);
        }
    }
    return (
        <StyledTerms>
            <h2 className='sectionFloatTitle'>الشروط و الاحكام</h2>

            <div
                className={`box ${commercialContractTermsTypeUnified ? 'checked' : ''}`}
                onClick={() => handleChange('increase')}
            >
                <Form.Check
                    className='me-5 mb-0'
                    type='radio'
                    id='increase'
                    checked={commercialContractTermsTypeUnified}
                    name='commercial-terms-type'
                    label='عقد موحد'
                    onChange={() => { }}
                    ref={commercialContractTermsTypeRef}
                />
                <p>
                    عقد موحد يتيح لأطرافة عرض واختيار أنواع متعددة من الالتزامات بسهولة مع الحفاظ الطابع التنفيذي للمستند
                </p>
            </div>

            <div
                className={`box ${!commercialContractTermsTypeUnified ? 'checked' : ''}`}
                onClick={() => handleChange('decrease')}
            >
                <Form.Check
                    className='me-5 mb-0'
                    type='radio'
                    id='decrease'
                    checked={!commercialContractTermsTypeUnified}
                    onChange={() => { }}
                    name='commercial-terms-type'
                    label='عقد غير موحد'
                />
                <p>
                    عقد غير موحد يسمح لأطرافة صياغة بنودة وفقا لاتفاقية أثناء العقد. بما في ذلك تحديد المدد الودية لحل النزاع وتحديد المحكمة. ولا يحمل طابع تنفيذيا
                </p>
            </div>
        </StyledTerms>
    );
}

export default Terms;