import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { AiOutlineEye } from 'react-icons/ai';
import { BsFillHouseDoorFill, BsUpload } from 'react-icons/bs';
import { IoPricetagOutline } from 'react-icons/io5';
import { FaRegBuilding, FaRegEdit } from 'react-icons/fa';
import Moment from 'react-moment';
import SwitchValueInput from '../../../../pages-parts/inputs/switch-value-input/SwitchValueInput';
import { ContractFinanceRecordEditContext } from '../ContractFinanceRecordEditContext';



const StyledSection7 = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    margin-bottom: 3rem;
    padding: 1.5rem 1.75rem;
    background-color: ${({ theme }) => theme.colors.background.elements};
    border-radius: 0.75rem;
    position: relative;
    border: 1px solid #ccc;

    
    
    
    .infoData{
        width: 12rem;
        position: relative;
        input{
            margin: 0;
        }

        &.value::after{
            display: flex;
            content: "SAR";
            position: absolute;
            height: 100%;
            align-items: center;
            right: 0;
            top: 0;
            font-weight: 900;
            width: 50px;
            justify-content: center;
            border-left: 1px solid #ccc;
        }

        .myInput{
            padding-right: 55px!important;
        }

    }
`;

const Section7 = () => {
    const {
        fields,
        setFields,
    } = useContext(ContractFinanceRecordEditContext);

    const setIfFieldAvailable = (value, inputName) => {
        setFields(prev => {
            return prev.filter(item => {
                if (item.inputName === inputName) {
                    item.available = value
                    return item;
                } else {
                    return item;
                }
            })
        })
    }

    const setFieldValue = (value, inputName) => {
        setFields(prev => {
            return prev.filter(item => {
                if (item.inputName === inputName) {
                    item.value = value
                    return item;
                } else {
                    return item;
                }
            })
        })
    }

    return (
        <StyledSection7>
            <h2 className='sectionFloatTitle'>مبالغ أضافية</h2>
            {fields.map(item => (
                <SwitchValueInput
                    key={item.inputName}
                    titleText={item.titleText}
                    inputName={item.inputName}
                    available={item.available}
                    setAvailable={(value) => setIfFieldAvailable(value, item.inputName)}
                    value={item.value}
                    setValue={(value) => setFieldValue(value, item.inputName)}
                    inputType={item.inputType}
                    inputText={item.inputText}
                />
            ))}
        </StyledSection7>
    );
}

export default Section7;