import React, { useState, createContext, useEffect } from 'react';

export const AlertContext = createContext();

export const AlertContextProvider = ({ children }) => {
    const [showAlert, setShowAlert] = useState(false);
    const [alertData, setAlertData] = useState({
        heading: '',
        message: '',
        variant: '',
        typeConfirm: false,
    });

    useEffect(() => {
        
    }, []);

    const toggleAlert = (show) => {
        setShowAlert(show);
    }

    const toggleAlertData = (data) => {
        setAlertData(data);
    }

    const values = {
        showAlert,
        toggleAlert,
        alertData,
        toggleAlertData,
    };

    return (
        <AlertContext.Provider value={values}>
            {children}
        </AlertContext.Provider>
    )
}

