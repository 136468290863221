import React, { useContext, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useSearchParams, Link, useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';
import api from '../../../../api/areas';
import { AlertContext } from '../../../../context/AlertContext';
import Section1 from './section-1/Section-1';
import Section2 from './section-2/Section-2';
import Section3 from './section-3/Section-3';

const StyledBrokerAssistantRequestView = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    padding: 2.5rem 1.75rem;
    color: ${({ theme }) => theme.colors.text.secondary};

    .row{
        margin: 0;
    }

`;

const BrokerAssistantRequestViewPage = () => {
    const [params, setParams] = useSearchParams();
    const [requestData, setRequestData] = useState({});
    const [showNoteModal, setShowNoteModal] = useState(false);
    const [note, setNote] = useState('');
    const navigate = useNavigate();
    const alertContext = useContext(AlertContext);

    useEffect(() => {
        api.get(`/brokersRequests?id=${params.get('id')}`).then(result => {
            setRequestData(result.data[0]);
            if (result.data.length === 0) {
                alertContext.toggleAlert(true);
                alertContext.toggleAlertData({
                    heading: 'طلب مجهول',
                    message: 'لم يتم العثور علي اي طلب مساعد وسيط مطابق لطلبك',
                    variant: 'warning',
                });
                navigate("/");
            }

        });
    }, [])

    const handleSave = () => {
        console.log('hi');
    }
    const addNote = () => {
        setShowNoteModal(true);
    }
    const closeNote = () => {
        setShowNoteModal(false);
    }
    const handleSaveNote = () => {
        setNote(document.querySelector('input[name=note]').value);
        setShowNoteModal(false);
    }

    return (
        <StyledBrokerAssistantRequestView>
            <Container fluid>
                <Section1 requestData={requestData} />
                <Section2 requestData={requestData} />
                {
                    note !== "" ? <Section3 note={note} /> : ""
                }

                <Row className="mt-3">
                    <Col className='d-flex align-items-center justify-content-between py-0'>
                        <div>
                            <span onClick={handleSave} className="py-2 px-3 d-inline-block save-link me-2">
                                قبول
                            </span>
                            <span onClick={addNote} className="py-2 px-3 d-inline-block save-link me-2">
                                إضافة ملاحظة
                            </span>
                            <Link to={`/broker-assistants-request`} className="py-2 px-3 return-link me-2">
                                الغاء الطلب
                            </Link>
                        </div>
                        <Link
                            to={`/broker-assistants-request`}
                            className="py-2 px-3 return-link"
                        >
                            رجوع
                        </Link>
                    </Col>
                </Row>

                <Modal show={showNoteModal} onHide={closeNote}>
                    <Modal.Header closeButton>
                        <Modal.Title>ملاحظة الادمن علي الطلب</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Control
                            className='py-2 px-3 mb-2 text-start'
                            type='text'
                            name='note'
                            placeholder='ادخل الملاحظة'
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={closeNote}>
                            اغلاق
                        </Button>
                        <Button variant="primary" onClick={handleSaveNote}>
                            حفظ
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Container>
        </StyledBrokerAssistantRequestView>
    );
}

export default BrokerAssistantRequestViewPage;