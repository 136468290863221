import { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import api from '../../../../api/areas'
import { customSelectStyles } from '../../../../styles/Global';

const DynamicSelect = (
    {
        titleText,
        selectedOption,
        url,
        column,
        customStylesProp,
        handleChangeSelect
    }
) => {
    const [defaultOptions, setDefaultValues] = useState([]);

    useEffect(() => {
        getDefaultOptions();
    }, [url])

    const getDefaultOptions = async () => {
        try {
            const respond = await api.get(`${url}_page=1`).then(res => {
                let values = [{
                    value: 'no-value',
                    label: 'غير محدد',
                }];
                res.data.forEach(item => {
                    const option = {
                        value: item.id,
                        label: item[column],
                    }
                    values.push(option);
                });
                setDefaultValues(values)
            }).catch(err => {
                console.log(err)
            });
        } catch (err) {
            console.log(err)
        }

    }

    const loadOptions = async (searchValue, callback) => {
        try {
            const respond = await api.get(`${url}q=${searchValue}&_page=1`).then(res => {
                let values = [{
                    value: 'no-value',
                    label: 'غير محدد',
                }];
                res.data.forEach(item => {
                    const option = {
                        value: item.id,
                        label: item[column],
                    }
                    values.push(option);
                });
                callback(values)
            }).catch(err => {
                console.log(err)
            });
        } catch (err) {
            console.log(err)
        }

    }

    return (
        <div className='mt-2 data-line'>
            <span className='infoTitle'>
                {titleText}
            </span>
            <span className='infoData'>
                <AsyncSelect
                    value={selectedOption}
                    loadOptions={loadOptions}
                    defaultOptions={defaultOptions}
                    styles={customStylesProp.has ? customStylesProp.style : customSelectStyles}
                    onChange={handleChangeSelect}
                />
            </span>
        </div>
    );
}

export default DynamicSelect;