import React, { useContext, useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';
import AsyncSelect from 'react-select/async';
import api from '../../../../../../api/areas';
import Select from 'react-select';
import { customSelectStyles } from '../../../../../../styles/Global';

const StyledSection2 = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    padding: 1rem 1.75rem;
    background-color: ${({ theme }) => theme.colors.background.elements};
    border-radius: 0.75rem;
    position: relative;
    border: 1px solid #ccc;

    
    
    

    .infoData{
        min-width: 12rem;
    }

`;

const BrokerAssistantSection2 = ({ userData }) => {
    const [zones, setZones] = useState([]);
    const [cities, setCities] = useState([]);
    const [neighborhoods, setNeighborhoods] = useState([
        {
            value: 1,
            label: 'لبن',
        },
        {
            value: 2,
            label: 'درّة الرياض',
        },
        {
            value: 3,
            label: 'حي الموسى',
        },
        {
            value: 4,
            label: 'النظيم',
        },
        {
            value: 5,
            label: 'تلال الرياض',
        },
    ]);
    const [selectedZones, setSelectedZones] = useState([{
        value: 1,
        label: "الرياض",
    }]);
    const [selectedCities, setSelectedCities] = useState([]);
    const [selectedNeighborhood, setSelectedNeighborhood] = useState(neighborhoods[0]);

    useEffect(() => {
        getCitiesGroupedByZones(selectedZones);

        const respond = api.get(`/areas?_page=1`).then(res => {
            let values = [];
            res.data.forEach(item => {
                const option = {
                    value: item.id,
                    label: item.area,
                }
                values.push(option);
            });
            setZones(values);
        }).catch(err => {
            console.log(err)
        });
    }, []);

    const handleChange = async (selectOption) => {
        setSelectedZones(selectOption);

        getCitiesGroupedByZones(selectOption);

    }
    const loadOptionsAreas = async (searchValue, callback) => {
        try {
            const respond = await api.get(`/areas?q=${searchValue}&_page=1`).then(res => {
                let values = [];
                res.data.forEach(item => {
                    const option = {
                        value: item.id,
                        label: item.area,
                    }
                    values.push(option);
                });
                callback(values)
            }).catch(err => {
                console.log(err)
            });
        } catch (err) {
            console.log(err)
        }

    }

    const getCitiesGroupedByZones = async (zones) => {
        let citiesGroup = [];
        for (let i = 0; i < zones.length; i++) {
            const citiesForZone = await api.get(`/cities?area_id=${zones[i].value}&_page=1`).then(res => {
                let values = [];
                const items = res.data;
                for (let x = 0; x < items.length; x++) {
                    const option = {
                        value: items[x].id,
                        label: items[x].city,
                        area_id: items[x].area_id,
                    }
                    values.push(option);
                };
                citiesGroup.push({
                    label: zones[i].label,
                    options: values,
                });
            }).catch(err => {
                console.log(err)
            });
        }
        setCities(citiesGroup);
        return citiesGroup
    }


    return (
        <StyledSection2>
            <h2 className='sectionFloatTitle'>معلومات المساعد الوسيط</h2>
            <Row>
                <Col md='12'>
                    <div className='mt-2 data-line'>
                        <span className='infoTitle'>
                            منطقة العمل
                        </span>
                        <span className='infoData'>
                            <AsyncSelect
                                value={selectedZones}
                                loadOptions={loadOptionsAreas}
                                styles={customSelectStyles}
                                onChange={handleChange}
                                isMulti
                                defaultOptions={zones}
                            />
                        </span>
                    </div>
                    <div className='mt-2 data-line'>
                        <span className='infoTitle'>
                            المدينة
                        </span>
                        <span className='infoData'>
                            <Select
                                value={selectedCities}
                                isMulti
                                closeMenuOnSelect={false}
                                options={cities}
                                styles={customSelectStyles}
                                onChange={e => setSelectedCities(e)}
                            />
                        </span>
                    </div>
                    <div className='mt-2 data-line'>
                        <span className='infoTitle'>
                            الحي (أختياري)
                        </span>
                        <span className='infoData'>
                            <Select
                                value={selectedNeighborhood}
                                isMulti
                                closeMenuOnSelect={false}
                                options={neighborhoods}
                                styles={customSelectStyles}
                                onChange={e => setSelectedNeighborhood(e)}
                            />
                        </span>
                    </div>
                    <div className='mt-2 data-line'>
                        <span className='infoTitle'>
                            رقم مساعد الوسيط (منصة ايجار)
                        </span>
                        <span className='infoData'>
                            <Form.Control
                                className='py-2 px-3'
                                type='text'
                                name='assistant-num'
                                defaultValue="48753"
                            />
                        </span>
                    </div>
                </Col>
            </Row>
        </StyledSection2>
    );
}

export default BrokerAssistantSection2;