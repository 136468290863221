import { MdSpaceDashboard, MdNotificationsNone } from 'react-icons/md';
import { RiDraftLine, RiCustomerService2Fill, RiCustomerService2Line } from 'react-icons/ri';
import { AiOutlineFileDone, AiOutlineUserAdd, AiOutlineFileProtect } from 'react-icons/ai';
import { FaUserTie, FaRegBuilding, FaUsers } from 'react-icons/fa';
import { BsGear, BsMegaphone } from 'react-icons/bs';
import { BiLogOut } from 'react-icons/bi';


export const availablePages = [
    // login
    {
        url: "/login",
        title: "login",
        translate: "تسجيل الدخول"
    },
    {
        url: "/forgot-password",
        title: "forget password",
        translate: "استرجاع كلمة المرور"
    },


    // menu links
    {
        url: "/",
        title: "dashboard",
        translate: "لوحة التحكم"
    },
    {
        url: "/contracts-draft",
        title: "contracts draft",
        translate: "مسودات العقود"
    },
    {
        url: "/contracts-completed",
        title: "completed contracts history",
        translate: "سجل العقود المكتملة"
    },
    {
        url: "/owner",
        title: "Owner / Representative",
        translate: "الملاك / ممثلي الملاك"
    },
    {
        url: "/real-estate",
        title: "Properties / Units",
        translate: "العقارات / الوحدات"
    },
    {
        url: "/customer-service",
        title: "customer service",
        translate: "خدمة العملاء"
    },
    {
        url: "/broker-assistants-request",
        title: "Broker Assistants Request",
        translate: "طلبات مساعد الوسيط"
    },
    {
        url: "/broker-assistants",
        title: "broker assistants",
        translate: "مساعد الوسيط"
    },
    {
        url: "/promoters",
        title: "promoters",
        translate: "المروجين"
    },
    {
        url: "/notifications",
        title: "notifications",
        translate: "الاشعارات"
    },
    {
        url: "/users",
        title: "users",
        translate: "المستخدمين"
    },
    {
        url: "/terms-conditions",
        title: "Terms & Condition Agreement",
        translate: "الشروط و الاحكام"
    },
    {
        url: "/log-out",
        title: "log out",
        translate: "تسجيل الخروج"
    },
    {
        url: "/general-settings",
        title: "general settings",
        translate: "اعدادات عامة"
    },
    {
        url: "/permissions",
        title: "permissions",
        translate: "الصلاحيات"
    },
    {
        url: "/zones",
        title: "zones",
        translate: "قائمة المناطق"
    },
    {
        url: "/roles",
        title: "roles",
        translate: "الأدوار"
    },
    // profile links
    {
        url: "/profile",
        title: "profile",
        translate: "الصفحة الشخصية"
    },
    {
        url: "/profile/edit",
        title: "edit profile",
        translate: "تعديل الصفحة الشخصية"
    },
    {
        url: "/contracts-view",
        title: "view contracts",
        translate: "تفاصيل العقد"
    },
    {
        url: "/owner-view",
        title: "view owner",
        translate: "عرض بيانات المالك"
    },
    {
        url: "/owner-edit",
        title: "edit owner",
        translate: "تعديل بيانات المالك"
    },
    {
        url: "/owner-new",
        title: "new owner",
        translate: "مالك جديد"
    },
    {
        url: "/tenant",
        title: "tenant invitation",
        translate: "دعوة مستأجر"
    },
    {
        url: "/tenant/view",
        title: "view tenant",
        translate: "عرض بيانات المستأجر"
    },
    {
        url: "/tenant/edit",
        title: "edit tenant",
        translate: "تعديل بيانات المستأجر"
    },
    {
        url: "/tenant/escort-edit",
        title: "edit escort",
        translate: "بيانات المرافق"
    },
    {
        url: "/tenant/escort-new",
        title: "new escort",
        translate: "مرافق جديد"
    },
    {
        url: "/users/view",
        title: "view user",
        translate: "بيانات"
    },
    {
        url: "/users/edit",
        title: "edit user",
        translate: "تعديل بيانات"
    },
    {
        url: "/users/create",
        title: "create user",
        translate: "انشاء"
    },
    {
        url: "/contracts-expire-soon",
        title: "expire soon contracts",
        translate: "عقود تنتهي قريبا"
    },
    {
        url: "/owner-contracts",
        title: "owner / representative contracts",
        translate: "عقود المالك / الممثل"
    },
    {
        url: "/real-estate-units",
        title: "real estate units",
        translate: "وحدات العقار"
    },
    {
        url: "/unit-view",
        title: "view unit",
        translate: "عرض بيانات الوحدة"
    },
    {
        url: "/unit-edit",
        title: "edit unit",
        translate: "تعديل بيانات الوحدة"
    },
    {
        url: "/real-estate-view",
        title: "view real estate",
        translate: "عرض بيانات العقار"
    },
    {
        url: "/real-estate-ownership-document-edit",
        title: "real estate ownership document edit",
        translate: "تعديل وثيقة ملكية العقار"
    },
    {
        url: "/real-estate-details-edit",
        title: "real estate details edit",
        translate: "تعديل تفاصيل العقار"
    },
    {
        url: "/real-estate-shared-facilities-edit",
        title: "real estate shared facilities edit",
        translate: "تعديل المرافق المشتركة للعقار"
    },
    {
        url: "/contracts-edit",
        title: "edit contract",
        translate: "تعديل بيانات العقد"
    },
    {
        url: "/contract-finance-record",
        title: "contract finance record",
        translate: "البيانات المالية لعقد"
    },
    {
        url: "/contract-finance-record-edit",
        title: "edit contract finance record",
        translate: "تعديل البيانات المالية لعقد"
    },
    {
        url: "/send-broker-assistant-request",
        title: "send broker assistant request",
        translate: "ارسال طلب مساعد وسيط"
    },
    {
        url: "/broker-assistant-request-view",
        title: "view broker assistant request",
        translate: "عرض طلب مساعد وسيط"
    },
    {
        url: "/broker-assistant-financial-records",
        title: "broker assistant financial records",
        translate: "السجل المالي لمساعد الوسيط"
    },
    {
        url: "/customer-service-financial-records",
        title: "customer service financial records",
        translate: "السجل المالي لموظف خدمة العملاء"
    },
    {
        url: "/promoter-financial-records",
        title: "promoter financial records",
        translate: "السجل المالي للمروج"
    },
    {
        url: "/owner-financial-records",
        title: "owner financial records",
        translate: "السجل المالي للمالك / ممثل"
    },
    {
        url: "/broker-assistant-payouts",
        title: "broker assistant payouts",
        translate: "كشف حساب المساعد الوسيط"
    },
    {
        url: "/customer-service-payouts",
        title: "customer service payouts",
        translate: "كشف حساب لموظف خدمة العملاء"
    },
    {
        url: "/promoter-payouts",
        title: "promoter payouts",
        translate: "كشف حساب المروج"
    },
    {
        url: "/owner-real-estates",
        title: "owner real estates",
        translate: "عرض عقارات المالك"
    },
    {
        url: "/zone-view",
        title: "zone view",
        translate: "بيانات المناطق"
    },
    {
        url: "/ticket",
        title: "ticket",
        translate: "محادثة عقد"
    },
    // {
    //     url: "/create-notification",
    //     title: "create-notification",
    //     translate: "الاشعارات"
    // },

];

export const linksList = [
    {
        id: 1,
        pageName: "Dashboard",
        translate: "لوحة التحكم",
        link: "/",
        hasIcon: true,
        haveSubMenu: false,
        icon: <MdSpaceDashboard />,
        subMenu: []
    },
    {
        id: 2,
        pageName: "Contracts Draft",
        translate: "مسودات العقود",
        link: "/contracts-draft",
        hasIcon: true,
        haveSubMenu: false,
        icon: <RiDraftLine />,
        subMenu: []
    },
    {
        id: 3,
        pageName: "Completed Contracts History",
        translate: "سجل العقود المكتملة",
        link: "/contracts-completed",
        hasIcon: true,
        haveSubMenu: false,
        icon: <AiOutlineFileDone />,
        subMenu: []
    },
    {
        id: 4,
        pageName: "Owner / Representative",
        translate: "الملاك / ممثلي الملاك",
        link: "/owner",
        hasIcon: true,
        haveSubMenu: false,
        icon: <FaUserTie />,
        subMenu: []
    },
    {
        id: 5,
        pageName: "Properties / Units",
        translate: "العقارات / الوحدات",
        link: "/real-estate",
        hasIcon: true,
        haveSubMenu: false,
        icon: <FaRegBuilding />,
        subMenu: []
    },
    {
        id: 6,
        pageName: "Customer Service",
        translate: "خدمة العملاء",
        link: "/customer-service",
        hasIcon: true,
        haveSubMenu: false,
        icon: <RiCustomerService2Fill />,
        subMenu: []
    },
    {
        id: 7,
        pageName: "Broker Assistants Request",
        translate: "طلبات مساعد الوسيط",
        link: "/broker-assistants-request",
        hasIcon: true,
        haveSubMenu: false,
        icon: <AiOutlineUserAdd />,
        subMenu: []
    },
    {
        id: 8,
        pageName: "Broker Assistants",
        translate: "مساعد الوسيط",
        link: "/broker-assistants",
        hasIcon: true,
        haveSubMenu: false,
        icon: <RiCustomerService2Line />,
        subMenu: []
    },
    {
        id: 9,
        pageName: "Promoters",
        translate: "المروجين",
        link: "/promoters",
        hasIcon: true,
        haveSubMenu: false,
        icon: <BsMegaphone />,
        subMenu: []
    },
    {
        id: 10,
        pageName: "Notifications",
        translate: "الأشعارات",
        link: "/notifications",
        hasIcon: true,
        haveSubMenu: false,
        icon: <MdNotificationsNone />,
        subMenu: []
    },
    {
        id: 11,
        pageName: "Users",
        translate: "المستخدمين",
        link: "/users",
        hasIcon: true,
        haveSubMenu: false,
        icon: <FaUsers />,
        subMenu: []
    },
    {
        id: 12,
        pageName: "Terms & Condition Agreement",
        translate: "الشروط و الاحكام",
        link: "/terms-conditions",
        hasIcon: true,
        haveSubMenu: false,
        icon: <AiOutlineFileProtect />,
        subMenu: []
    },
    {
        id: 13,
        pageName: "Settings",
        translate: "الأعدادات",
        link: "/settings",
        hasIcon: true,
        haveSubMenu: true,
        icon: <BsGear />,
        subMenu: [
            {
                id: 1,
                pageName: "General Settings",
                translate: "الأعدادات العامة",
                link: "/general-settings",
            },
            {
                id: 2,
                pageName: "Permissions",
                translate: "الصلاحيات",
                link: "/permissions",
            },
            {
                id: 3,
                pageName: "Roles",
                translate: "الأدوار",
                link: "/roles",
            },
            {
                id: 4,
                pageName: "Zones",
                translate: "المناطق",
                link: "/zones",
            },
        ]
    },
    {
        id: 14,
        pageName: "Log Out",
        translate: "تسجيل الخروج",
        link: "/log-out",
        hasIcon: true,
        haveSubMenu: false,
        icon: <BiLogOut />,
        subMenu: []
    },
];