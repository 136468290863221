import styled from "styled-components";

const StyledOwnerMsg = styled.div`
    display: flex;
    justify-content: flex-start;
    margin-bottom: .5rem;

    img{
        display: inline-block;
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
    }
    .msgContainer{
        background-color: #ccc;
        padding: .35rem .5rem;
        border-radius: .5rem 0 .5rem .5rem;
        margin-right: 0.75rem;
        color: ${({ theme }) => theme.colors.text.secondary};
        position: relative;
        font-size: 14px;

        &::before{
            content: "";
            position: absolute;
            right: -0.625rem;
            border-left: 0.625rem solid #ccc;
            border-bottom: 0.625rem solid transparent;
            border-top: 0rem solid;
            top: 0;
        }

        span{
            
            opacity: .5;
            font-size: 12px;
            direction: ltr;
            display: block;
        }
    }
`
function OwnerMsg({ msg }) {


    return (
        <StyledOwnerMsg>
            <img src={msg.userImg} alt="Profile" />
            <div className="msgContainer">
                <p className="mb-0">{msg.msg}</p>
                <span>{msg.time}</span>
            </div>
        </StyledOwnerMsg>
    );
}

export default OwnerMsg;