import React, { useContext, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useSearchParams, Link, useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';
import TabsFilter from '../TabsFilter';
import { ContractDetailsEditContext } from '../ContractDetailsEditContext';
import RentValueEdit from './RentValueEdit';
import PayoutsScheduleTableEdit from './PayoutsEdit';
import ExtraMoneyEdit from './ExtraMoneyEdit';
import StartingBalanceEdit from './StartingBalanceEdit';




const StyledContractFinanceRecords = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    padding: 0;
    
`;

const ContractFinanceRecords = () => {
    const {
        duration,
        currentPageNum,
        setCurrentPageNum
    } = useContext(ContractDetailsEditContext);
    const housingContractTabs = [
        {
            id: 1,
            value: 7,
            name: "قيمة الايجار",
        },
        {
            id: 2,
            value: 8,
            name: "دفعات السداد",
        },
        {
            id: 3,
            value: 9,
            name: "مبالغ اضافية",
        },
    ];
    const commercialContractTabs = [
        {
            id: 1,
            value: 7,
            name: "قيمة الايجار",
        },
        {
            id: 2,
            value: 8,
            name: "المبالغ المدفوعة",
        },
        {
            id: 3,
            value: 9,
            name: "دفعات السداد",
        },
        {
            id: 4,
            value: 10,
            name: "مبالغ اضافية",
        },
    ];
    const [tabs, setTabs] = useState(() => {
        return duration.contractTypeHousing ? housingContractTabs : commercialContractTabs
    });

    useEffect(() => {
        setTabs(() => duration.contractTypeHousing ? housingContractTabs : commercialContractTabs)
    }, [duration.contractTypeHousing])

    const getCurrentPage = () => {
        if (duration.contractTypeHousing) {
            switch (currentPageNum) {
                case 7:
                    return <RentValueEdit />;
                case 8:
                    return <PayoutsScheduleTableEdit />;
                case 9:
                    return <ExtraMoneyEdit />;
                case 10:
                    return "loading!";
                default:
                    return "Error";
            }
        } else {
            switch (currentPageNum) {
                case 7:
                    return <RentValueEdit />;
                case 8:
                    return <StartingBalanceEdit />;
                case 9:
                    return <PayoutsScheduleTableEdit />;
                case 10:
                    return <ExtraMoneyEdit />;
                default:
                    return "Error";
            }
        }
    }

    return (
        <StyledContractFinanceRecords>
            <Container fluid>
                <Row className='mb-4'>
                    <Col md="8">
                        <TabsFilter
                            activeTab={currentPageNum}
                            setActiveTab={setCurrentPageNum}
                            tabs={tabs}
                        />
                    </Col>
                </Row>
                {
                    getCurrentPage()
                }

            </Container>
        </StyledContractFinanceRecords>
    );
}

export default ContractFinanceRecords;