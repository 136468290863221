import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { MdSpaceDashboard, MdErrorOutline } from 'react-icons/md';
import { RiDraftLine } from 'react-icons/ri';
import { AiOutlineFileDone } from 'react-icons/ai';
import { SidebarContext } from '../../../context/SidebarContext';

const StyledMenuIcons = styled.span`
    display: flex;
    margin-left: ${({ sidebarstate }) => sidebarstate.isOpen ? "0.5rem" : "0"};
    font-size: 2rem;
    height: 100%;
    align-items: center;
    justify-content: flex-start;
    color: ${({ theme, sidebarstate, listItemId }) => (
        listItemId == sidebarstate.activeLink[0] ?
            theme.colors.primary :
            theme.colors.light_gray
    )};
    transition: all .25s ease-in-out;
`;

const MenuIcons = ({ listItem }) => {
    const sidebarState = useContext(SidebarContext);
    const [icons, setIcons] = useState([
        {
            id: 1,
            icon: <MdSpaceDashboard key={listItem.id} />
        },
        {
            id: 2,
            icon: <RiDraftLine key={listItem.id} />
        },
        {
            id: 3,
            icon: <AiOutlineFileDone key={listItem.id} />
        },
    ]);

    const getIcon = () => {
        if (listItem.hasIcon) {
            return listItem.icon;
        } else {
            return <MdErrorOutline key={listItem.id} />
        }
    }

    return (
        <StyledMenuIcons sidebarstate={sidebarState} listItemId={listItem.id}>
            {getIcon()}
        </StyledMenuIcons>
    );
}

export default MenuIcons;