import React, { useContext, useEffect, useRef, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';
import Select from 'react-select';
import { Calendar } from 'react-date-range';
import moment from 'moment';
import NumberInput from '../../../../../pages-parts/inputs/number-input/NumberInput';
import { ContractFinanceRecordEditContext } from '../../ContractFinanceRecordEditContext';


const StyledConsistant = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    padding: 1rem 1.75rem;
    background-color: ${({ theme }) => theme.colors.background.body};
    border-radius: 0.75rem;
    position: relative;
    border: 1px solid #ccc;

    .box{
        padding: .5rem;
        border: 1px solid #ccc;
        margin-bottom: 1rem;

        &:last-child{
            margin-bottom: 0;
        }

        &.checked{
            border: 2px solid #787878;
            background-color: ${({ theme }) => theme.colors.background.elements};
        }
    }
    
    
    
    .infoData{
        width: 12rem !important;
        position: relative;

    }
    .infoDataSwitch{
    }
    span img{
        width: 6rem;
        height: 6rem;
        border-radius: 0.75rem;
    }
`;

const Consistant = () => {
    const {
        durationType,
        setDurationType,
    } = useContext(ContractFinanceRecordEditContext);


    const handleChange = (e) => {
        setDurationType(e.target.value)
    }
    return (
        <StyledConsistant>
            <Form.Check
                className='mb-2'
                type='radio'
                id='monthly'
                value='monthly'
                checked={durationType === 'monthly'}
                name='duration-type'
                label='شهري'
                onChange={handleChange}
            />
            <Form.Check
                className='mb-2'
                type='radio'
                id='quarterly'
                value='quarterly'
                checked={durationType === 'quarterly'}
                name='duration-type'
                label='ربع سنوي'
                onChange={handleChange}
            />
            <Form.Check
                className='mb-2'
                type='radio'
                id='midterm'
                value='midterm'
                checked={durationType === 'midterm'}
                name='duration-type'
                label='نصف سنوي'
                onChange={handleChange}
            />
            <Form.Check
                className='mb-2'
                type='radio'
                id='annual'
                value='annual'
                checked={durationType === 'annual'}
                name='duration-type'
                label='سنوي'
                onChange={handleChange}
            />
        </StyledConsistant>
    );
}

export default Consistant;