import { IoMdRefresh } from 'react-icons/io';
import styled from 'styled-components';

const StyledLoading = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .1);
    z-index: 4;
    display: flex;
    align-items: center;
    justify-content: center;

    span{
        color: ${({ theme }) => theme.colors.primary};
        font-size: 2rem;
        animation: rotate-center 2s linear infinite;
    }

    @keyframes rotate-center {
        0% {
            -webkit-transform: rotate(0);
                    transform: rotate(0);
        }
        100% {
            -webkit-transform: rotate(360deg);
                    transform: rotate(360deg);
        }
    }
`;

const Loading = () => {
    return (
        <StyledLoading>
            <span>
                <IoMdRefresh />
            </span>
        </StyledLoading>
    )
}

export default Loading;