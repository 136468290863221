import React, { useContext, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { useSearchParams, Link, useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';
import api from '../../../api/areas';
import { AlertContext } from '../../../context/AlertContext';
import { ContractDetailsEditContext } from './ContractDetailsEditContext';
import ContractDurationPage from './contract-duration/ContractDuration';
import RealEstatesAndUnits from './real-estates-and-units/RealEstatesAndUnits';
import ContractParties from './contract-parties/ContractParties';
import ContractFinanceRecords from './finance-records/ContractFinanceRecords';
import TermsAndConditionsPage from './terms-conditions/TermsAndConditions';


const StyledContractDetailsEdit = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    padding: 2.5rem 1.75rem;

    .container-fluid{
        padding-left: 0 !important;
    }

    .contentContainer{
        display: flex;
        .slide-box-container{
            position: sticky;
            top: 1rem;
            height: fit-content;
            .slide-box{
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                margin: 0 auto; 
                z-index: 1;
                width: 14rem;
                height: 12rem;
                padding: .5rem 1rem;
                background-color: ${({ theme }) => theme.colors.background.elements};
                border-radius: 0.75rem;
                position: relative;
                border: 1px solid #ccc;

            
                &::after{
                    display: block;
                    position: absolute;
                    content: '';
                    right: 19px;
                    top: 16px;
                    background-color: ${({ theme }) => theme.colors.secondary};
                    width: .5rem;
                    height: 0;
                    z-index: -1;
                    transition: all .5s linear;
                }
                &.stage-1::after{
                    height: 0;
                }
                &.stage-2::after{
                    height: calc(11rem / 4);
                }
                &.stage-3::after{
                    height: calc(50% - 1rem);
                }
                &.stage-4::after{
                    height: calc(75% - 1.5rem);
                }
                &.stage-5::after{
                    height: calc(100% - 2rem);
                }
    
                &::before{
                    display: block;
                    position: absolute;
                    content: '';
                    right: 22px;
                    top: 1rem;
                    background-color: #ccc;
                    width: 3px;
                    height: calc(100% - 2rem);
                    z-index: -2;
                    transition: all .5s linear;
                }
    
                .form-check-input[type=checkbox]{
                    border-radius: 50%;
                    position: relative;
                    opacity: 1;
                    
                    &:checked{
                        background-color: ${({ theme }) => theme.colors.secondary};
                        border-color: ${({ theme }) => theme.colors.secondary};
                    }
                    &:focus{
                        border-color: ${({ theme }) => theme.colors.secondary_8};
                        box-shadow: 0 0 0 0.25rem ${({ theme }) => theme.colors.secondary_2};
                    }
                }
                label{
                    opacity: 1;
                }
            }
        }
    }
`;

const ContractDetailsEdit = () => {
    const alertContext = useContext(AlertContext);
    const {
        // duration,
        currentPageNum,
        nextPage,
        prevPage,

        // get data
        realEstate,
        unit,
        owner,
        tenant,
        financeRecords,
    } = useContext(ContractDetailsEditContext);
    const [params, setParams] = useSearchParams();
    const navigate = useNavigate();
    // contract type
    const [contractType, setContractType] = useState('');

    useEffect(() => {
        api.get(`/items?id=${params.get('id')}`).then(result => {
            if (result.data.length === 0) {
                alertContext.toggleAlert(true);
                alertContext.toggleAlertData({
                    heading: 'عقد مجهول',
                    message: 'لم يتم العثور علي العقد المطلوب',
                    variant: 'warning',
                });
                navigate("/");
            } else {
                realEstate.getOwnershipDocData(result.data[0].realEstateId);
                realEstate.getRealEstateData(result.data[0].realEstateId);
                realEstate.getRealEstateFacilitiesData(result.data[0].realEstateId);
                unit.getUnitData(result.data[0].unitId);
                owner.getOwnerData(result.data[0].ownerId);
                tenant.getTenantData(result.data[0].tenantId);
                financeRecords.getFinanceRecordData(params.get('id'));
            }
        });
    }, []);

    const getPage = () => {
        if (currentPageNum === 1) {
            return <ContractDurationPage />
        } else if (currentPageNum > 1 && currentPageNum < 4) {
            return <RealEstatesAndUnits />
        } else if (currentPageNum >= 4 && currentPageNum < 7) {
            return <ContractParties />
        } else if (currentPageNum >= 7 && currentPageNum < 11) {
            return <ContractFinanceRecords />
        } else {
            return <TermsAndConditionsPage />
        }
    }

    return (
        <StyledContractDetailsEdit>
            <Container fluid>
                <div className='contentContainer'>
                    <div className='slide-box-container'>
                        <div className={`slide-box stage-${currentPageNum > 10 ?
                                "5" : (
                                    currentPageNum > 6 ?
                                        "4" : (
                                            currentPageNum > 3 ?
                                                "3" : (
                                                    currentPageNum > 1 ? "2" : "1"
                                                )
                                        )
                                )
                            }`}>
                            <Form.Check
                                disabled
                                className='m-0'
                                type='checkbox'
                                id='contract-duration'
                                value='contract-duration'
                                checked={true}
                                name='contract-duration'
                                label='مدة العقد'
                            />
                            <Form.Check
                                disabled
                                className='m-0'
                                type='checkbox'
                                id='real-estate-and-units'
                                value='real-estate-and-units'
                                checked={currentPageNum > 1}
                                name='real-estate-and-units'
                                label='العقارات والوحدات'
                            />
                            <Form.Check
                                disabled
                                className='m-0'
                                type='checkbox'
                                id='parties'
                                value='parties'
                                checked={currentPageNum > 3}
                                name='parties'
                                label='أطراف العقد'
                            />
                            <Form.Check
                                disabled
                                className='m-0'
                                type='checkbox'
                                id='ready'
                                value='ready'
                                checked={currentPageNum > 6}
                                name='ready'
                                label='البيانات المالية'
                            />
                            <Form.Check
                                disabled
                                className='m-0'
                                type='checkbox'
                                id='ready'
                                value='ready'
                                checked={currentPageNum > 10}
                                name='ready'
                                label='الشروط والاحكام'
                            />
                        </div>
                    </div>

                    {
                        getPage()
                    }
                </div>

                <Row className="mt-3">
                    <Col className='d-flex align-items-center justify-content-end py-0'>
                        <span
                            onClick={prevPage}
                            className={`py-2 px-3 ${currentPageNum > 1 ? "save-link" : "return-link"} me-2`}
                        >
                            {currentPageNum > 1 ? "السابق" : "الغاء"}
                        </span>
                        <span onClick={nextPage} className="py-2 px-3 save-link">
                            {currentPageNum > 10 ? "حفظ" : "التالي"}
                        </span>
                    </Col>
                </Row>
            </Container>
        </StyledContractDetailsEdit>
    );
}

export default ContractDetailsEdit;