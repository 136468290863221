import React, { useContext, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useSearchParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import api from '../../../api/areas';
import { AlertContext } from '../../../context/AlertContext';
import Section2 from './section-2/Section-2';

const StyledUsersUsersPayout = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    padding: 2.5rem 1.75rem;
    color: ${({ theme }) => theme.colors.text.secondary};

    .row{
        margin: 0;
    }

    .box{
        padding: 0.3125rem 1rem;
        display: inline-block;
        border: 1px solid #ccc;
        border-radius: 1rem;
    }
`;

const UsersUsersPayoutPage = ({ userType }) => {
    const [params, setParams] = useSearchParams();
    const [userData, setUserData] = useState({});
    const navigate = useNavigate();
    const alertContext = useContext(AlertContext);

    useEffect(() => {
        if (userType === 'broker') {
            api.get(`/brokers?id=${params.get('id')}`).then(result => {
                setUserData(result.data[0]);
                if (result.data.length === 0) {
                    alertContext.toggleAlert(true);
                    alertContext.toggleAlertData({
                        heading: 'مساعد وسيط مجهول',
                        message: 'لم يتم العثور علي اي مساعد وسيط مطابق لطلبك',
                        variant: 'warning',
                    });
                    navigate("/");
                }

            });
        } else if (userType === 'customer-services') {
            api.get(`/customerServiceUser?id=${params.get('id')}`).then(result => {
                setUserData(result.data[0]);
                if (result.data.length === 0) {
                    alertContext.toggleAlert(true);
                    alertContext.toggleAlertData({
                        heading: 'موظف خدمة عملاء مجهول',
                        message: 'لم يتم العثور علي اي موظف خدمة عملاء مطابق لطلبك',
                        variant: 'warning',
                    });
                    navigate("/");
                }

            });
        } else if (userType === 'promoter') {
            api.get(`/promoters?id=${params.get('id')}`).then(result => {
                setUserData(result.data[0]);
                if (result.data.length === 0) {
                    alertContext.toggleAlert(true);
                    alertContext.toggleAlertData({
                        heading: 'مروج مجهول',
                        message: 'لم يتم العثور علي اي مروج مطابق لطلبك',
                        variant: 'warning',
                    });
                    navigate("/");
                }

            });
        }

    }, [])

    return (
        <StyledUsersUsersPayout>
            <Container fluid className='p-0'>
                <Row>
                    <Col className='text-center mb-3'>
                        <span className='box me-2'>{userData.name}</span>
                        {
                            userType === 'broker' ? (
                                <span className='box'>{userData.area}&nbsp;/&nbsp;{userData.city}</span>
                            ) : ""
                        }
                    </Col>
                </Row>
                {userData === undefined || userData.id === undefined ? '' : (
                    <Section2 userData={userData} userType={userType} />
                )}
            </Container>
        </StyledUsersUsersPayout>
    );
}

export default UsersUsersPayoutPage;