import React from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { Outlet, Route, Routes } from 'react-router-dom';
import styled from 'styled-components';
import ContractsDraftPage from '../../pages/contracts-draft/ContractsDraft';
import DashboardPage from '../../pages/dashboard/Dashboard';

const StyledMainContent = styled.div`
    width: 100%;
    transition: all .5s ease-in-out;
    height: calc(100% - 5rem);
    overflow: hidden;
`;

const MainContent = () => {

    return (
        <StyledMainContent>
            <Scrollbars
                renderView={props => (
                    <div {...props} style={{
                        ...props.style,
                        marginLeft: props.style.marginRight,
                        marginRight: 0,
                    }} />
                )}
                renderTrackVertical={props => (
                    <div {...props} style={{
                        ...props.style,
                        left: 2,
                        bottom: 20,
                        top: 20,
                        right: 'auto',
                        borderRadius: 3,
                    }} />
                )}
                style={{ width: "100%", height: "100%" }}
            >
                <Outlet />
            </Scrollbars>
        </StyledMainContent>
    );
}

export default MainContent;