import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { AiOutlineEye } from 'react-icons/ai';
import Moment from 'react-moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import moment from 'moment';


const StyledSection1 = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    margin-bottom: 3rem;
    padding: 2rem 1.75rem 1rem;
    background-color: ${({ theme }) => theme.colors.background.elements};
    border-radius: 0.75rem;
    position: relative;
    border: 1px solid #ccc;

    p{
        margin: 0;
    }
    .infoTitle{
        width: 8rem;
    }
    .infoData{
        padding: .5rem 2rem;
        border-radius: 0.75rem;
        border: 1px solid #ccc;
    }

    .periodBox{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .infoData2{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 3rem;
            padding: 0.5rem 0;
            margin-top: .5rem;
            border-radius: 0.5rem;
            border: 1px solid #ccc;
            background-color: ${({ theme }) => theme.colors.background.elements};
        }
    }
    .infoCard{
        padding: 1rem;
        border-radius: 0.75rem;
        border: 1px solid #ccc;
        background-color: ${({ theme }) => theme.colors.background.elements};
        .period{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            min-width: 3rem;
            padding: 0.5rem 0;
            border-radius: 0.5rem;
            border: 1px solid #ccc;
            background-color: ${({ theme }) => theme.colors.background.body};
        }
        .primaryColor{
            color: ${({ theme }) => theme.colors.text.primary}
        }
    }
    .displayCard{
        padding: 1rem;
        border-radius: 0.75rem;
        border: 1px solid #ccc;
        background-color: ${({ theme }) => theme.colors.background.body};
    }
    .scroll-thumb{
        background-color: ${({ theme }) => theme.colors.primary_8};
    }
`;

const Section1 = ({ contractData }) => {

    return (
        <StyledSection1>
            <h2 className='sectionFloatTitle'>مدة العقد</h2>

            <Row >
                <Col sm="12" lg="6" className='mb-3'>
                    <p>
                        <span className='infoTitle'>تاريخ البدء :</span>
                        <span className='infoData numbers-font'>
                            {moment(contractData.createDate ?? "").format('ll')}
                        </span>
                    </p>
                </Col>
                <Col sm="12" lg="6" className='mb-2'>
                    <p>
                        <span className='infoTitle'>تاريخ الانتهاء :</span>
                        <span className='infoData numbers-font'>
                            {moment(contractData.createDate ?? "").add(contractData.contractPeriodInMonths, 'months').format('ll')}
                        </span>
                    </p>
                </Col>
            </Row>
            <Row className='mt-2 w-75'>
                <Col xs="12" className='mb-2'>
                    <p>
                        <span className='infoTitle'>فترة الايجار :</span>
                    </p>
                </Col>
                <Col xs="12" className='mb-2'>
                    <div className='displayCard row '>
                        <div className='col-6'>
                            <Row className='w-75 h-100'>
                                <div className='col-4 periodBox'>
                                    <p>سنوات</p>
                                    <p className='infoData2 numbers-font'>
                                        {
                                            Math.floor((contractData.contractPeriodInMonths ?? 0) / 12)
                                        }
                                    </p>
                                </div>
                                <div className='col-4 periodBox'>
                                    <p>شهور</p>
                                    <p className='infoData2 numbers-font'>
                                        {
                                            Math.floor((((contractData.contractPeriodInMonths ?? 0) / 12) - Math.floor((contractData.contractPeriodInMonths ?? 0) / 12)) * 12)
                                        }
                                    </p>
                                </div>
                                <div className='col-4 periodBox'>
                                    <p>ايام</p>
                                    <p className='infoData2 numbers-font'>0</p>
                                </div>
                            </Row>
                        </div>
                        <div className='col-6 infoCard row align-items-center'>
                            <div className='col-9'>
                                <h4>رسوم التسجيل في عاقد</h4>
                                <p className='primaryColor'>الرسوم السنوية يتم دفعها بواسطة مكتب الوساطة او المؤجر</p>
                            </div>
                            <div className='col-3'>
                                <div className='period'>
                                    <p className=' numbers-font'>
                                        {
                                            Math.floor((contractData.contractPeriodInMonths ?? 0) / 12)
                                        }
                                    </p>
                                    <p>سنوات</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </StyledSection1>
    );
}

export default Section1;