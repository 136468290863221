import Form from 'react-bootstrap/Form';

function SwitchValueInput(
    {
        titleText,
        inputName,
        available,
        setAvailable,
        value,
        setValue,
        inputType,
        inputText,
    }
) {

    const handleChangeSwitch = e => {
        setAvailable(!available);
    }

    const handleChange = e => {
        if (inputType === 'text') {
            setValue(e.target.value);
        } else {
            e.target.value = e.target.value.replace(/([^0-9])/g, '');
            setValue(e.target.value.replace(/([^0-9])/g, ''));
        }
    }

    return (
        <>
            <div className='data-line'>
                <span className='infoTitle'>
                    {titleText}
                </span>
                <span className='infoData'>
                    <Form.Check
                        className='px-0'
                        type="switch"
                        checked={available}
                        onChange={handleChangeSwitch}
                    />
                </span>
            </div>

            {
                available ? (
                    <div className='data-line'>
                        <span className='infoTitle'>
                            {inputText}
                        </span>
                        <span className='infoData value'>
                            <Form.Control
                                className='py-1 px-3 myInput'
                                type='text'
                                value={value}
                                name={inputName}
                                onChange={handleChange}
                            />
                        </span>
                    </div>
                ) : ""
            }
        </>
    );
}

export default SwitchValueInput;