import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import styled, { css } from "styled-components";
import Section1 from "./sections/Section-1";
import { Link } from "react-router-dom";
import Section2 from "./sections/Section-2";
import Section3 from "./sections/Section-3";

const StyledGeneralSettings = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    padding: 2.5rem 1.75rem;
    
`;

const GeneralSettingsPage = () => {


    const handleSave = () => {
    }

    return (
        <StyledGeneralSettings>
            <Container fluid>
                <Section1 />
                <Section2 />
                <Section3 />

                <Row className="mt-3">
                    <Col className='d-flex align-items-center justify-content-end py-2'>
                        <Link to={`/`} className="py-2 px-3 return-link me-2">
                            رجوع
                        </Link>
                        <span onClick={handleSave} className="py-2 px-3 save-link">
                            حفظ
                        </span>
                    </Col>
                </Row>
            </Container>
        </StyledGeneralSettings>
    )
}

export default GeneralSettingsPage;