import React, { useContext, useEffect, useRef, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';
import Select from 'react-select';
import { Calendar } from 'react-date-range';
import moment from 'moment';
import StaticSelect from '../../../../pages-parts/inputs/static-select-input/StaticSelect';
import TextInput from '../../../../pages-parts/inputs/text-input/TextInput';
import NumberInput from '../../../../pages-parts/inputs/number-input/NumberInput';
import DatePickerInput from '../../../../pages-parts/inputs/date-picker/DatePickerInput';
import { OwnerDetailsEditContext } from '../OwnerDetailsEditContext';
import EmailInput from '../../../../pages-parts/inputs/email-input/EmailInput';
import { BsUpload } from 'react-icons/bs';
import SectionInputs from './SectionInputs';

const StyledSection6 = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    margin-top: 3rem;
    padding: 1rem 1.75rem;
    background-color: ${({ theme }) => theme.colors.background.elements};
    border-radius: 0.75rem;
    position: relative;
    border: 1px solid #ccc;

    
    p{
        margin-bottom: 0;
    }
    
    
    .infoData{
        width: 12rem;
        position: relative;
        
    }
    .displayCard{
        padding: 1rem;
        border-radius: 0.75rem;
        border: 1px solid #ccc;
        background-color: ${({ theme }) => theme.colors.background.body};

        &.import{
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            transition: all .125s ease-in-out;

            h4{
                margin-bottom: 0;
            }
            .icon{
                font-size: 1.5rem;
                font-weight: 700;
            }

            &:hover{
                color: ${({ theme }) => theme.colors.secondary};
            }
        }
    }
    span img{
        width: 6rem;
        height: 6rem;
        border-radius: 0.75rem;
    }
`;

const Section6 = () => {
    const {
        selectedOwnerTypeOption,
        // نوع المنشأة
        representativeTypeOptions,
        handleChangeRepresentativeType,
        selectedRepresentativeTypeOption,
        // رقم الوثيقة
        representativeDocumentNumber,
        setRepresentativeDocumentNumber,
    } = useContext(OwnerDetailsEditContext);

    return (
        <>
            {
                selectedOwnerTypeOption.value === "single-representative" ||
                    selectedOwnerTypeOption.value === "establishment-representative" ? (
                    <StyledSection6>
                        <h2 className='sectionFloatTitle'>تفاصيل الممثل</h2>
                        <Row className='mt-4 mb-3'>
                            <Col>
                                <StaticSelect
                                    titleText='نوع الوثيقة'
                                    selectedOption={selectedRepresentativeTypeOption}
                                    options={representativeTypeOptions}
                                    customStylesProp={{
                                        has: false,
                                        style: {}
                                    }}
                                    handleChangeSelect={handleChangeRepresentativeType}
                                />
                                <TextInput
                                    titleText='رقم الوثيقة'
                                    inputName='document-number'
                                    value={representativeDocumentNumber}
                                    setValue={setRepresentativeDocumentNumber}
                                />
                                {
                                    selectedRepresentativeTypeOption.value !== "electronic-legal-agency" ? (
                                        <SectionInputs />
                                    ) : ""
                                }

                            </Col>
                        </Row>
                        <label htmlFor='upload-image' className='displayCard import'>
                            <h4>صورة الوثيقة القانونية</h4>
                            <BsUpload className='icon' />
                            <input
                                type="file"
                                className='d-none'
                                id="upload-image"
                            />
                        </label>
                    </StyledSection6>
                ) : ""
            }
        </>
    );
}

export default Section6;