import React, { useContext, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useSearchParams, Link, useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';
import TabsFilter from '../TabsFilter';
import { ContractDetailsEditContext } from '../ContractDetailsEditContext';
import TenantEdit from './TenantDetailsEdit';
import OwnerDetailsEdit from './OwnerDetailsEdit';
import EscortDetails from '../../tenant/escort/Section-1';




const StyledContractParties = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    padding: 0;
    

`;

const ContractParties = () => {
    const {
        currentPageNum,
        setCurrentPageNum
    } = useContext(ContractDetailsEditContext);
    const tabs = [
        {
            id: 1,
            value: 4,
            name: "بيانات المؤجر",
        },
        {
            id: 2,
            value: 5,
            name: "بيانات المستأجر",
        },
        {
            id: 3,
            value: 6,
            name: "بيانات المرافق",
        },
    ];

    const getCurrentPage = () => {
        switch (currentPageNum) {
            case 4:
                return <OwnerDetailsEdit />;
            case 5:
                return <TenantEdit />;
            case 6:
                return <EscortDetails />;
            default:
                return "Error";
        }
    }

    return (
        <StyledContractParties>
            <Container fluid>
                <Row className='mb-4'>
                    <Col md="6">
                        <TabsFilter
                            activeTab={currentPageNum}
                            setActiveTab={setCurrentPageNum}
                            tabs={tabs}
                        />
                    </Col>
                </Row>
                {
                    getCurrentPage()
                }

            </Container>
        </StyledContractParties>
    );
}

export default ContractParties;