import React, { useContext } from 'react';
import styled from 'styled-components';
import SidebarToggle from '../sidebar/SidebarToggle';
import { BsSearch, BsChatLeftText } from 'react-icons/bs';
import { BiBell } from 'react-icons/bi';
import profileImage1 from '../../../images/pic1.png';
import profileImage2 from '../../../images/1.png';
import { Link, useLocation } from 'react-router-dom';
import { UserContext } from '../../../context/UserContext';
import { TopbarContext } from '../../../context/TopbarContext';

const StyledTopbar = styled.div`
    width: 100%;
    height: 5rem;
    background-color: ${({ theme }) => theme.colors.background.elements};
    position: relative;
    padding: 0 6rem 0 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const StyledTitle = styled.h2`
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    font-weight: 700;
    color: #000;
    text-transform: capitalize;
`



const StyledHeaderIcons = styled.ul`
    display: flex;
    align-items: center;
    height: 100%;
    margin: 0;
    
    li{
        display: flex;
        height: 100%;
        align-items: center;
        position: relative;
        font-size: 1.5rem;
        color: ${({ theme }) => theme.colors.secondary};
        position: relative;

        p{
            padding: 0.625rem;
            position: relative;
            border-radius: 0.75rem;
            line-height: 0.1;
            cursor: pointer;

            span{
                position: absolute;
                height: 1.25rem;
                width: 1.25rem;
                font-size: 14px;
                border-radius: 0.5rem !important;
                right: -0.2rem;
                top: -0.2rem;
                font-weight: 400;
                padding: 0.3125rem;
                background-color: ${({ theme }) => theme.colors.secondary};
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    li.profile img{
        width: 2.4375rem;
        height: 2.4375rem;
        border-radius: 0.75rem;
        cursor: pointer;
    }

    li:not(:first-child){
        margin-left: 1.25rem;
    }
`

const Topbar = () => {
    const location = useLocation();
    const userContext = useContext(UserContext);
    const { topbarTitle } = useContext(TopbarContext);
    return (
        <StyledTopbar>
            <SidebarToggle />

            <StyledTitle>
                {
                    topbarTitle
                }
            </StyledTitle>

            <StyledHeaderIcons>
                <li className='notification-bell'>
                    <p className='mb-0'>
                        <BiBell />
                        <span>5</span>
                    </p>
                </li>

                <li className='notification-chat'>
                    <p className='mb-0'>
                        <BsChatLeftText />
                        <span>12</span>
                    </p>
                </li>

                <li className='profile'>
                    <Link to='/profile'>
                        <img src={userContext.userData?.rule === 'admin' ? profileImage1 : profileImage2} alt="Profile" />
                    </Link>
                </li>
            </StyledHeaderIcons>
        </StyledTopbar>
    );
}

export default Topbar;