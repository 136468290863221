import { FaRegEnvelope } from 'react-icons/fa';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';

const EmailInput = () => {
    return (
        <InputGroup className="mb-3">
            <InputGroup.Text id="email" className='py-2 px-3 border-end-0'>
                <FaRegEnvelope />
            </InputGroup.Text>
            <Form.Control
                placeholder="البريد الإلكتروني"
                aria-label="email"
                aria-describedby="email"
                className='py-2 px-3 border-start-0'
                type='email'
                name='email'
            />
        </InputGroup>
    )
}

export default EmailInput;