import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { FaBed, FaBath } from 'react-icons/fa';
import { MdOutlineLiving } from 'react-icons/md';
import Moment from 'react-moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import moment from 'moment';
import CreateNewLink from '../../../../pages-parts/table/CreateNewLink';
import AddNewButton from '../../../../pages-parts/inputs/add-new-btn/AddNewButton';


const StyledSection3 = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    margin-bottom: 3rem;
    padding: 1rem 1.75rem;
    background-color: ${({ theme }) => theme.colors.background.elements};
    border-radius: 0.75rem;
    position: relative;
    border: 1px solid #ccc;

    

    &:last-child{
        margin-bottom: 0;
    }
    .headContainer{
        p{
            color: ${({ theme }) => theme.colors.text.primary};
        }

        h4 {
            display: flex;
            align-items: center;
            p{
                color: ${({ theme }) => theme.colors.text.secondary};
                margin-left: .75rem;
            }
            .status{
                display: inline-block;
                padding: 0.25rem 1rem;
                font-size: 14px;
                border-radius: .75rem;
                background-color: ${({ theme }) => theme.colors.secondary_2};
            }
        }
    }
    p{
        margin: 0;
    }
    
    .infoData{
        padding: .75rem 3rem;
        border-radius: 0.75rem;
        border: 1px solid #ccc;
    }

    
    .secondRow{
        justify-content: right;
        .periodBox{
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: auto;

            div{
                text-align: center;
            }
            h4, h6{
                margin-bottom: 0;
                color: ${({ theme }) => theme.colors.text.secondary};
            }
            .infoData2{
                padding: 0;
                margin-top: 0.5rem;
            }
        }
    }
    .amenitiesBox{
        display: flex;
        .amenities{
            padding: .25rem .75rem;
            border-radius: 0.5rem;
            border: 1px solid ${({ theme }) => theme.colors.secondary};
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            margin-left: 1rem;
            .icon{
                font-size: 1.5rem;
                color: ${({ theme }) => theme.colors.secondary};
                margin-left: .5rem;
            }
            span{
    
            }
        }
    }
`;
const Section3 = ({ realEstateData }) => {


    return (
        <StyledSection3>
            <h2 className='sectionFloatTitle'>تفاصيل العقار</h2>
            <Row className='my-3 headContainer'>

                <Col className='mb-0 text-end'>
                    <AddNewButton
                        title="تعديل"
                        url={`/real-estate-details-edit?real_id=${realEstateData.id}`}
                        isLink={true}
                        hasIcon={false}
                    />
                </Col>
            </Row>
            <Row className='my-3 secondRow'>
                <div className='periodBox align-items-start'>
                    <div>
                        <h6>نوع العقار</h6>
                        <p className='infoData2'>
                            تجاري
                        </p>
                    </div>
                </div>
                <div className='periodBox align-items-center'>
                    <div>
                        <h6>رقم العقار</h6>
                        <p className='infoData2'>
                            <span className="numbers-font">#4655418</span>
                        </p>
                    </div>
                </div>
                <div className='periodBox align-items-center'>
                    <div>
                        <h6>استخدام العقار</h6>
                        <p className='infoData2'>
                            مجمع تجاري مفتوح
                        </p>
                    </div>
                </div>
                <div className='periodBox align-items-center'>
                    <div>
                        <h6>تاريخ البناء</h6>
                        <p className='infoData2 numbers-font'>
                            {moment().subtract(6, "years").format('ll')}
                        </p>
                    </div>
                </div>
                <div className='periodBox align-items-center'>
                    <div>
                        <h6>اجمالي عدد الطوابق</h6>
                        <p className='infoData2 numbers-font'>
                            5
                        </p>
                    </div>
                </div>
                <div className='periodBox align-items-center'>
                    <div>
                        <h6>اجمالي الوحدات في كل طابق</h6>
                        <p className='infoData2 numbers-font'>
                            12
                        </p>
                    </div>
                </div>
                <div className='periodBox align-items-end'>
                    <div>
                        <h6>الاسم</h6>
                        <p className='infoData2'>
                            --
                        </p>
                    </div>
                </div>
            </Row>
        </StyledSection3>
    );
}

export default Section3;