import React, { useContext, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useSearchParams, Link, useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';
import PayoutSchedule from '../../contract-finance-records/edit/section-6/Section-6';
import { ContractDetailsEditContext } from '../ContractDetailsEditContext';




const StyledPayoutsEdit = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    padding: 0;
    

    & > *:last-child{
        margin-bottom: 0;
    }

`;

const PayoutsScheduleTableEdit = () => {
    const {
        duration,
    } = useContext(ContractDetailsEditContext)

    return (
        <StyledPayoutsEdit>
            <PayoutSchedule />
        </StyledPayoutsEdit>
    );
}

export default PayoutsScheduleTableEdit;