import React, { useContext, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { SidebarContext } from '../../../../../context/SidebarContext';
import ClosedSubMenuItem from './ClosedSublistItem';

const StyledSubMenu = styled.ul`
    position: absolute;
    top: 0;
    left: -12.5rem;
    width: 12rem;
    padding: 1.5rem;
    overflow: hidden;
    border-radius: 0.25rem;
    z-index: 2;
    ${({ sidebarstate, listItemId, subMenuOffset }) => {
        if (
            !sidebarstate.isOpen &&
            listItemId === sidebarstate.hoverOnClosedSubMenuHead.id
        ) {
            if (sidebarstate.hoverOnClosedSubMenuHead.postion.y <= 80) {
                return css`
                    top: 0;
                `;
            } else if (sidebarstate.hoverOnClosedSubMenuHead.postion.y >= (window.innerHeight - subMenuOffset.getBoundingClientRect().height)) {
                return css`
                    top: auto;
                    bottom: 0;
                `;
            } else {
                return css`
                    top: ${sidebarstate.hoverOnClosedSubMenuHead.postion.y - 80}px;
                `;
            }
        }
    }}
    ${({ theme, sidebarstate, listItemId, subMenuOffset, mousePosition }) => {
        if (
            !sidebarstate.isOpen &&
            listItemId === sidebarstate.hoverOnClosedSubMenuHead.id &&
            (
                sidebarstate.hoverClosedMenu ||
                (
                    subMenuOffset.getBoundingClientRect().x <= mousePosition.x &&
                    subMenuOffset.getBoundingClientRect().y <= mousePosition.y &&
                    (subMenuOffset.getBoundingClientRect().y + subMenuOffset.getBoundingClientRect().height) >= mousePosition.y
                )
            )
        ) {
            return css`
                background-color: ${theme.colors.background.elements};
            `;
        } else {
            return css`
                /* display: none; */
                opacity: 0;
                z-index: -1;
            `;
        }
    }}


`;

const ClosedSubMenuBody = ({ listItemProp }) => {
    const sidebarState = useContext(SidebarContext);
    const subMenuRef = useRef();
    const [subMenuOffset, setSubMenuOffset] = useState();
    const [mousePosition, setMousePosition] = useState({});

    useEffect(() => {
        setSubMenuOffset(subMenuRef.current);

        window.addEventListener('mousemove', handleWindowMouseMove);

        return () => {
            window.removeEventListener('mousemove', handleWindowMouseMove);
        }
    }, []);

    const handleWindowMouseMove = event => {
        // console.log(subMenuRef.current.getBoundingClientRect(), mousePosition.x, mousePosition.y);
        // if(
        //     !(
        //         sidebarState.hoverClosedMenu ||
        //         (
        //             subMenuRef.current.getBoundingClientRect().x <= mousePosition.x &&
        //             subMenuRef.current.getBoundingClientRect().y <= mousePosition.y &&
        //             (subMenuRef.current.getBoundingClientRect().y + subMenuRef.current.getBoundingClientRect().height) >= mousePosition.y
        //         )
        //     )
        // ){
        //     sidebarState.toggleSubMenuClosed(true, null, {});
        // }
        setMousePosition({
            x: event.clientX,
            y: event.clientY,
        });
    };

    return (
        <StyledSubMenu
            sidebarstate={sidebarState}
            listItemId={listItemProp.id}
            subMenuOffset={subMenuOffset}
            mousePosition={mousePosition}
            ref={subMenuRef}
        >
            {listItemProp.subMenu.map((subMenuItem) => (
                <ClosedSubMenuItem
                    key={subMenuItem.id}
                    listItemProp={listItemProp}
                    subListItemProp={subMenuItem}
                />
            ))}
        </StyledSubMenu>
    )
}

export default ClosedSubMenuBody;