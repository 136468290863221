import React from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import AddNewButton from '../../../../pages-parts/inputs/add-new-btn/AddNewButton';


const StyledSection2 = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    margin-bottom: 3rem;
    padding: 2rem 1.75rem 1rem;
    background-color: ${({ theme }) => theme.colors.background.elements};
    border-radius: 0.75rem;
    position: relative;
    border: 1px solid #ccc;

    .headContainer{
        p{
            color: ${({ theme }) => theme.colors.text.secondary};
        }

        h4 {
            display: flex;
            align-items: center;
            p{
                color: ${({ theme }) => theme.colors.text.secondary};
                margin-left: .75rem;
            }
            .status{
                display: inline-block;
                padding: 0.25rem 1rem;
                font-size: 14px;
                border-radius: .75rem;
                background-color: ${({ theme }) => theme.colors.secondary_2};
            }
        }
    }

    
    .infoTitle{
        width: 8rem;
    }
    .ownerName{
        color: ${({ theme }) => theme.colors.text.secondary};
    }
    .scroll-thumb{
        background-color: ${({ theme }) => theme.colors.primary_8};
    }
`;

const Section2 = ({ realEstateData }) => {
    const [params, setParams] = useSearchParams();
    const changeActiveOwner = (e) => {
        if (e.target.checked) {
            document.getElementById(e.target.dataset['value']).classList.add('opacity-100');
            document.getElementById(e.target.dataset['value']).classList.remove('opacity-0');
        } else {
            document.getElementById(e.target.dataset['value']).classList.add('opacity-0');
            document.getElementById(e.target.dataset['value']).classList.remove('opacity-100');
        }
    }
    return (
        <StyledSection2>
            <h2 className='sectionFloatTitle'>ملاك العقار</h2>
            <Row className='my-0 headContainer'>
                <Col className='mb-0'>
                    <p className='m-0'>
                        معلومات عن ملاك العقار سواء كانوا أفراد أو مؤسسات
                    </p>
                </Col>
            </Row>
            <Row className='mt-2'>
                <Col md="6" className='mb-2'>
                    <label htmlFor='owner-1' className='clearfix d-inline-block mb-2'>
                        <span htmlFor='owner-1' className='infoTitle'>
                            اسم المالك (1)
                        </span>
                        <span className='d-inline-block'>
                            <Form.Check
                                type="switch"
                                name='owners'
                                id='owner-1'
                                label=""
                                defaultChecked={true}
                                data-value="123456"
                                onChange={changeActiveOwner}
                            />
                        </span>
                    </label>
                    <div id='123456' className={true ? 'opacity-100' : 'opacity-0'}>
                        <span className='ownerName'>
                            ياسر فاروق هارون عبدالخالق
                        </span>
                    </div>
                </Col>
                <Col md="6" className='mb-2'>
                    <label htmlFor='owner-2' className='clearfix d-inline-block mb-2'>
                        <span className='infoTitle'>
                            اسم المالك (2)
                        </span>
                        <span className='d-inline-block'>
                            <Form.Check
                                type="switch"
                                name='owners'
                                id='owner-2'
                                label=""
                                defaultChecked={false}
                                data-value="654321"
                                onChange={changeActiveOwner}
                            />
                        </span>
                    </label>
                    <div id='654321' className={false ? 'opacity-100' : 'opacity-0'}>
                        <span className='ownerName'>
                            علاء محمود هاني فادي
                        </span>
                    </div>
                </Col>
            </Row>
            <AddNewButton
                title="إضافة مالك"
                url={`/owner-new?real_estate_id=${params.get('id')}`}
                isLink={true}
            />
        </StyledSection2>
    );
}

export default Section2;