import React, { useContext, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Container';
import { useSearchParams, Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import api from '../../../api/areas';
import { AlertContext } from '../../../context/AlertContext';
import { TopbarContext } from '../../../context/TopbarContext';
import AddNewButton from '../../pages-parts/inputs/add-new-btn/AddNewButton';
import Section1 from './sections/Section1';
import Section2 from './sections/Section2';
import Section3 from './sections/Section3';
import Section4 from './sections/Section4';
import Section5 from './sections/Section5';
import Section6 from './sections/Section6';
import Section1_1 from './sections/trading/Section1-1';
import Section2_1 from './sections/trading/Section2_1';


const StyledContractFinanceRecord = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    padding: 2.5rem 1.75rem;

    .container-fluid > *:nth-last-child(2){
        margin-bottom: 0;
    }
`;
const ContractFinanceRecord = () => {
    const [params, setParams] = useSearchParams();
    const [contractData, setContractData] = useState({});
    const navigate = useNavigate();
    const alertContext = useContext(AlertContext);
    const { toggleTopbarTitle } = useContext(TopbarContext);

    useEffect(() => {
        api.get(`/items?id=${params.get('id')}`).then(result => {
            setContractData(result.data[0]);
            if (result.data.length === 0) {
                alertContext.toggleAlert(true);
                alertContext.toggleAlertData({
                    heading: 'عقد مجهول',
                    message: 'لم يتم العثور علي العقد المطلوب',
                    variant: 'warning',
                });
                navigate("/");
            } else {
                toggleTopbarTitle(`البيانات المالية لعقد ${result.data[0].contractType}`)
            }
        });
    }, [])
    return (
        <StyledContractFinanceRecord>
            <Container fluid>
                <div className='tableHead'>
                    <AddNewButton
                        title="تعديل"
                        url={`/contract-finance-record-edit?id=${params.get('id')}`}
                        isLink={true}
                        hasIcon={false}
                    />
                </div>

                <Section1 />
                {contractData.contractType === "سكني" ? "" : <Section1_1 />}
                <Section2 />
                {contractData.contractType === "سكني" ? "" : <Section2_1 />}
                <Section3 contractId={params.get('id')} />
                <Section4 />
                <Section5 />
                <Section6 />

                <Row className='mt-3 p-0'>
                    <Col className='d-flex align-items-center justify-content-end p-0'>
                        <Link
                            to={`/contracts-view?id=${params.get('id')}`}
                            className="py-2 px-3 return-link"
                        >
                            رجوع
                        </Link>
                    </Col>
                </Row>
            </Container>
        </StyledContractFinanceRecord>
    );
}

export default ContractFinanceRecord;