import React, { useContext, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useSearchParams, Link, useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { AlertContext } from '../../../../context/AlertContext';
import Section1 from './section-1/Section-1';
import Section2 from './section-2/Section-2';
import Section3 from './section-3/Section-3';
import Section4 from './section-4/Section-4';
import Section5 from './section-5/Section-5';
import Section6 from './section-6/Section-6';
import Section7 from './section-7/Section-7';
import Section8 from './section-8/Section-8';
import Section9 from './section-9/Section-9';
import { TopbarContext } from '../../../../context/TopbarContext';
import { ContractFinanceRecordEditContext, ContractFinanceRecordEditContextProvider } from './ContractFinanceRecordEditContext';



const StyledContractFinanceRecordEdit = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    padding: 2.5rem 1.75rem;
    
`;
const ContractFinanceRecordEditContent = () => {
    const [params, setParams] = useSearchParams();
    const alertContext = useContext(AlertContext);
    const { toggleTopbarTitle } = useContext(TopbarContext);
    const navigate = useNavigate();
    const {
        getFinanceRecordData,
        contractTypeHousing,
        setContractTypeHousing,
    } = useContext(ContractFinanceRecordEditContext);

    useEffect(() => {

        getFinanceRecordData(params.get('id'));

        toggleTopbarTitle(`تعديل البيانات المالية لعقد ${contractTypeHousing ? "سكني" : "تجاري"}`)
    }, []);


    const handleSave = () => {
        console.log('hi');
    }
    return (
        <Container fluid>
            <Section1 />
            <Section2 />
            {contractTypeHousing ? "" : <Section3 />}
            {contractTypeHousing ? "" : <Section4 />}
            <Section5 />
            <Section6 />
            <Section7 />
            <Section8 />
            <Section9 />

            <Row className="mt-3">
                <Col className='d-flex align-items-center justify-content-end py-0'>
                    <Link
                        to={`/contract-finance-record?id=${params.get('id')}`}
                        className="py-2 px-3 return-link me-2"
                    >
                        رجوع
                    </Link>
                    <span onClick={handleSave} className="py-2 px-3 save-link">
                        حفظ
                    </span>
                </Col>
            </Row>
        </Container>
    );
}
const ContractFinanceRecordEdit = () => {
    const [params, setParams] = useSearchParams();

    return (
        <ContractFinanceRecordEditContextProvider>
            <StyledContractFinanceRecordEdit>
                <ContractFinanceRecordEditContent />
            </StyledContractFinanceRecordEdit>
        </ContractFinanceRecordEditContextProvider>
    );
}

export default ContractFinanceRecordEdit;