import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import { SidebarContext } from '../../../../../context/SidebarContext';
import SubMenuItem from './SublistItem';

const StyledSubMenu = styled.ul`
    ${({ sidebarstate, listItemId }) => {
        if (sidebarstate.isOpen) {
            return css`
                width: 100%;
                padding: 0.25rem 0;
                transition: all .5s ease-in-out;
                overflow: hidden;
                max-height: ${listItemId === sidebarstate.activeLink[0] ? "32rem" : "0px"};
            `;
        } else {
            return css`
                display: none;
            `;
        }
    }}
`;


const SubMenuBody = ({ listItemProp }) => {
    const sidebarState = useContext(SidebarContext);

    return (
        <StyledSubMenu
            sidebarstate={sidebarState}
            listItemId={listItemProp.id}
        >
            {listItemProp.subMenu.map((subMenuItem) => (
                <SubMenuItem
                    key={subMenuItem.id}
                    listItemProp={listItemProp}
                    subListItemProp={subMenuItem}
                />
            ))}
        </StyledSubMenu>
    )
}

export default SubMenuBody;