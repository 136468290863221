import Container from "react-bootstrap/Container";
import styled, { css } from "styled-components";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AlertContext } from "../../../../context/AlertContext";
import { useContext, useEffect, useState } from "react";
import api from '../../../../api/areas'
import Section1 from "./sections/Section1";
import Section2 from "./sections/Section2";
import Section3 from "./sections/Section3";
import Section4 from "./sections/Section4";
import Section5 from "./sections/Section5";
import Section6 from "./sections/Section6";


const StyledRealEstateView = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    padding: 2.5rem 1.75rem;

    ${({ theme }) => {
        return css`
            .edit-link{
                color: ${theme.colors.secondary};
                background-color: ${theme.colors.secondary_1};
                border: 1px solid ${theme.colors.secondary_2};
                border-radius: 0.5rem;
            }
        `;
    }}
    
`;

const RealEstateView = () => {
    const [params, setParams] = useSearchParams();
    const [realEstateData, setRealEstateData] = useState({});
    const navigate = useNavigate();
    const alertContext = useContext(AlertContext);

    useEffect(() => {
        api.get(`/realEstates?id=${params.get('id')}`).then(result => {

            if (result.data.length === 0) {
                alertContext.toggleAlert(true);
                alertContext.toggleAlertData({
                    heading: 'عقار مجهول',
                    message: 'لم يتم العثور علي العقار المطلوب',
                    variant: 'warning',
                });
                navigate("/");
            } else {
                setRealEstateData(result.data[0]);
            }
        });
    }, [])

    return (
        <StyledRealEstateView>
            <Container fluid>
                <Section1 realEstateData={realEstateData} />
                <Section2 realEstateData={realEstateData} />
                <Section3 realEstateData={realEstateData} />
                <Section4 realEstateData={realEstateData} />
                <Section5 realEstateData={realEstateData} />
                <Section6 realEstateData={realEstateData} />
            </Container>
        </StyledRealEstateView>
    )
}

export default RealEstateView;