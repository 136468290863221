import React, { useContext, useEffect, useRef, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';
import Select from 'react-select';
import { Calendar } from 'react-date-range';
import moment from 'moment';
import StaticSelect from '../../../../pages-parts/inputs/static-select-input/StaticSelect';
import DatePickerInput from '../../../../pages-parts/inputs/date-picker/DatePickerInput';
import NumberInput from '../../../../pages-parts/inputs/number-input/NumberInput';
import TextInput from '../../../../pages-parts/inputs/text-input/TextInput';
import CustomFieldModal from '../../../../pages-parts/inputs/custom-field/CustomFieldModal';
import CustomFieldInput from '../../../../pages-parts/inputs/custom-field/CustomFieldInput';
import Consistant from './multi-payment/Consistent';
import Flexible from './multi-payment/Flexible';
import { ContractFinanceRecordEditContext } from '../ContractFinanceRecordEditContext';

const StyledSection5 = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    margin-bottom: 3rem;
    padding: 1rem 1.75rem;
    background-color: ${({ theme }) => theme.colors.background.elements};
    border-radius: 0.75rem;
    position: relative;
    border: 1px solid #ccc;

    
    
    .infoData{
        width: 12rem;

    }

    .infoDataSwitch{
        display: flex;
        width: auto !important;
    }

    .customFieldRemoveIcon{
        margin-right: 1rem;

        .icon{
            cursor: pointer;
            &:hover{
                color: ${({ theme }) => theme.colors.delete};
            }
        }
    }
    span img{
        width: 6rem;
        height: 6rem;
        border-radius: 0.75rem;
    }
`;

const Section5 = () => {
    const {
        paymentType,
        setPaymentType,
    } = useContext(ContractFinanceRecordEditContext);

    const handleChangeType = (e) => {
        setPaymentType(e.target.value)
    }
    return (
        <StyledSection5>
            <h2 className='sectionFloatTitle'>الدفعات</h2>
            <div className='data-line'>
                <span className='infoDataSwitch mt-4'>
                    <Form.Check
                        className='me-5 mb-0'
                        type='radio'
                        id='single'
                        value='single'
                        checked={paymentType === 'single'}
                        name='payments-type'
                        label='دفعة واحدة'
                        onChange={handleChangeType}
                    />
                    <Form.Check
                        className='me-5 mb-0'
                        type='radio'
                        id='consistant'
                        value='consistant'
                        checked={paymentType === 'consistant'}
                        name='payments-type'
                        label='دفعات متكررة'
                        onChange={handleChangeType}
                    />
                    <Form.Check
                        className='me-5 mb-0'
                        type='radio'
                        id='flexible'
                        value='flexible'
                        checked={paymentType === 'flexible'}
                        name='payments-type'
                        label='دفعات مرنة'
                        onChange={handleChangeType}
                    />
                </span>
            </div>
            <Row>
                <Col md="9">
                    {paymentType === "consistant" ? <Consistant /> : ""}
                    {paymentType === "flexible" ? <Flexible /> : ""}
                </Col>
            </Row>
        </StyledSection5>
    );
}

export default Section5;