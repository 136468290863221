import { useContext } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import styled from 'styled-components';
import { AlertContext } from '../../../context/AlertContext';

const StyledFlashMessage = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .1);
    z-index: 4;
    display: flex;
    padding-top: 3rem;
    align-items: flex-start;
    justify-content: center;

    & > .alert{
        position: sticky;
        top: 2rem;
    }
`;

const FlashMessage = ({ conformation }) => {
    const {
        toggleAlert,
        toggleAlertData,
        alertData,
    } = useContext(AlertContext);

    const handleClose = () => {
        toggleAlert(false);
        
        toggleAlertData({
            heading: '',
            message: '',
            variant: '',
            typeConfirm: alertData.typeConfirm,
        });
        
    }
    
    return (
        <StyledFlashMessage>
            <Alert variant={alertData.variant} onClose={handleClose} dismissible>
                <Alert.Heading>{alertData.heading}</Alert.Heading>
                <p>
                    {alertData.message}
                </p>
                {
                    alertData.typeConfirm ? (
                        <div className="d-flex justify-content-start">
                            <Button onClick={conformation} variant="danger">
                                confirm delete
                            </Button>
                        </div>
                    ) : ""
                }
            </Alert>
        </StyledFlashMessage>
    )
}

export default FlashMessage;