import { BsPhone } from 'react-icons/bs';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import { useRef } from 'react';

const PhoneInput = () => {
    const phoneInput = useRef();

    const handleChange = () => {
        phoneInput.current.value = phoneInput.current.value.replace(/([^0-9])/g, '');
    }

    return (
        <InputGroup className="mb-3">
            <InputGroup.Text id="phone" className='py-2 px-3 border-end-0'>
                <BsPhone />
            </InputGroup.Text>
            <Form.Control
                placeholder="رقم الهاتف"
                aria-label="phone"
                aria-describedby="phone"
                className='py-2 px-3 border-start-0'
                type='text'
                onChange={handleChange}
                ref={phoneInput}
                name='phone'
            />
        </InputGroup>
    )
}

export default PhoneInput;