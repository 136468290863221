import React, { useState, createContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { availablePages } from '../components/pages/pages-list/PagesList';

export const TopbarContext = createContext();

export const TopbarContextProvider = ({ children }) => {
    const location = useLocation();
    const [topbarTitle, setTopbarTitle] = useState('');

    useEffect(() => {
        setTopbarTitle(prev => {
            let title;
            if(
                location.pathname === "/contracts-view" ||
                location.pathname === "/users/view" ||
                location.pathname === "/users/edit" ||
                location.pathname === "/users/create" ||
                location.pathname === "/contract-finance-record" ||
                location.pathname === "/contract-finance-record-edit" ||
                location.pathname === "/owner-view" ||
                location.pathname === "/owner-real-estates" ||
                location.pathname === "/owner-edit" ||
                location.pathname === "/zone-view" 
            ){
                title = prev
            }else{
                title = availablePages.find(item => item.url === location.pathname).translate
            }
            return title;
        })
    }, [location.pathname]);

    const toggleTopbarTitle = (title) => {
        setTopbarTitle(title)
    }

    const values = {
        topbarTitle,
        toggleTopbarTitle
    };

    return (
        <TopbarContext.Provider value={values}>
            {children}
        </TopbarContext.Provider>
    )
}

