import React, { useContext, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import { useSearchParams, Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import api from '../../../api/areas';
import { AlertContext } from '../../../context/AlertContext';
import { TopbarContext } from '../../../context/TopbarContext';
import AddNewButton from '../../pages-parts/inputs/add-new-btn/AddNewButton';
import Section1 from './section-1/Section-1';
import Section2 from './section-2/Section-2';
import Section3 from './section-3/Section-3';
import Section4 from './section-4/Section-4';
import Section5 from './section-5/Section-5';
import Section6 from './section-6/Section-6';

const StyledViewContract = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    padding: 2.5rem 1.75rem;
    color: ${({ theme }) => theme.colors.text.secondary};

    .row{
        margin: 0;
    }
`;

const ViewContractPage = () => {
    const [params, setParams] = useSearchParams();
    const [contractData, setContractData] = useState({});
    const navigate = useNavigate();
    const alertContext = useContext(AlertContext);
    const { toggleTopbarTitle } = useContext(TopbarContext);

    useEffect(() => {
        api.get(`/items?id=${params.get('id')}`).then(result => {
            setContractData(result.data[0]);
            if (result.data.length === 0) {
                alertContext.toggleAlert(true);
                alertContext.toggleAlertData({
                    heading: 'عقد مجهول',
                    message: 'لم يتم العثور علي العقد المطلوب',
                    variant: 'warning',
                });
                navigate("/");
            } else {
                toggleTopbarTitle(`عرض بيانات عقد ${result.data[0].contractType}`)
            }
        });
    }, [])
    return (
        <StyledViewContract>
            <Container fluid>
                {contractData.contractState === "مكتمل" ? "" : (
                    <div className='tableHead'>
                        <AddNewButton
                            title="تعديل العقد"
                            url={`/contracts-edit?id=${params.get('id')}`}
                            isLink={true}
                            hasIcon={false}
                        />
                    </div>
                )}
                <Section1 contractData={contractData} />
                <Section2 contractData={contractData} />
                <Section3 contractData={contractData} />
                <Section4 contractData={contractData} />
                <Section5 contractData={contractData} />
                <Section6 contractData={contractData} />
            </Container>
        </StyledViewContract>
    );
}

export default ViewContractPage;