import Container from "react-bootstrap/Container";
import styled, { css } from "styled-components";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AlertContext } from "../../../../context/AlertContext";
import { useContext, useEffect, useState } from "react";
import api from '../../../../api/areas'
import RealEstateCard from "./sections/RealEstateCard";
import { TopbarContext } from "../../../../context/TopbarContext";
import moment from "moment";

const StyledOwnerRealEstates = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    padding: 2.5rem 1.75rem;

    ${({ theme }) => {
        return css`
            .edit-link{
                color: ${theme.colors.secondary};
                background-color: ${theme.colors.secondary_1};
                border: 1px solid ${theme.colors.secondary_2};
                border-radius: 0.5rem;
            }
        `;
    }}
    
`;

const OwnerRealEstates = () => {
    const [params, setParams] = useSearchParams();
    const [ownerData, setOwnerData] = useState({});
    const navigate = useNavigate();
    const alertContext = useContext(AlertContext);
    const { toggleTopbarTitle } = useContext(TopbarContext);

    useEffect(() => {
        api.get(`/owners?id=${params.get('id')}`).then(result => {

            if (result.data.length === 0) {
                alertContext.toggleAlert(true);
                alertContext.toggleAlertData({
                    heading: 'مالك / ممثل مجهول',
                    message: 'لم يتم العثور علي المالك / الممثل المطلوب',
                    variant: 'warning',
                });
                navigate("/");
            } else {
                setOwnerData(result.data[0]);
                toggleTopbarTitle(`عرض عقارات ال${result.data[0].userType}`)
            }
        });
    }, [])

    return (
        <StyledOwnerRealEstates>
            <Container fluid>
                {
                    [...Array(ownerData.realEstatesNum)].map((e, i) => (
                        <RealEstateCard key={i} data={{
                            id: `real-estate-${moment().format()}-${i}`
                        }} />
                    ))
                }
            </Container>
        </StyledOwnerRealEstates>
    )
}

export default OwnerRealEstates;