import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FaParking } from 'react-icons/fa';
import { MdFitnessCenter, MdElevator, MdOutlineLocalGroceryStore } from 'react-icons/md';
import { GiSecurityGate } from 'react-icons/gi';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AddNewButton from '../../../../pages-parts/inputs/add-new-btn/AddNewButton';


const StyledSection5 = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    margin-bottom: 3rem;
    padding: 1rem 1.75rem;
    background-color: ${({ theme }) => theme.colors.background.elements};
    border-radius: 0.75rem;
    position: relative;
    border: 1px solid #ccc;

    

    &:last-child{
        margin-bottom: 0;
    }
    .headContainer{
        p{
            color: ${({ theme }) => theme.colors.text.primary};
        }

        h4 {
            display: flex;
            align-items: center;
            p{
                color: ${({ theme }) => theme.colors.text.secondary};
                margin-left: .75rem;
            }
            .status{
                display: inline-block;
                padding: 0.25rem 1rem;
                font-size: 14px;
                border-radius: .75rem;
                background-color: ${({ theme }) => theme.colors.secondary_2};
            }
        }
    }
    p{
        margin: 0;
    }
    
    .infoData{
        padding: .75rem 3rem;
        border-radius: 0.75rem;
        border: 1px solid #ccc;
    }

    
    .secondRow{
        justify-content: right;
        .periodBox{
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: auto;

            div{
                text-align: center;
            }
            h4, h6{
                margin-bottom: 0;
                color: ${({ theme }) => theme.colors.text.secondary};
            }
            .infoData2{
                padding: 0;
                margin-top: 0.5rem;
            }
        }
    }
    .amenitiesBox{
        display: flex;
        flex-wrap: wrap;
        .amenities{
            padding: .25rem .75rem;
            border-radius: 0.5rem;
            border: 1px solid ${({ theme }) => theme.colors.secondary};
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            margin-left: 1rem;
            margin-bottom: .5rem;
            .icon{
                font-size: 1.5rem;
                color: ${({ theme }) => theme.colors.secondary};
                margin-left: .5rem;
            }
            span{
    
            }
        }
    }
`;

const Section5 = ({ realEstateData }) => {


    return (
        <StyledSection5>
            <h2 className='sectionFloatTitle'>المرافق المشتركة للعقار</h2>
            <Row className='mt-3 headContainer'>

                <Col className='mb-0 text-end'>
                    <AddNewButton
                        title="تعديل"
                        url={`/real-estate-shared-facilities-edit?real_id=${realEstateData.id}`}
                        isLink={true}
                        hasIcon={false}
                    />
                </Col>
            </Row>
            <div className='mt-2 mb-3 amenitiesBox'>
                <div className='amenities'>
                    <FaParking className='icon' />
                    <span>15 مواقف سيارات</span>
                </div>
                <div className='amenities'>
                    <MdElevator className='icon' />
                    <span>3 مصاعد</span>
                </div>
                <div className='amenities'>
                    <MdFitnessCenter className='icon' />
                    <span>1 مركز لياقة بدنية</span>
                </div>
                <div className='amenities'>
                    <GiSecurityGate className='icon' />
                    <span>3 مداخل امن</span>
                </div>
                <div className='amenities'>
                    <MdOutlineLocalGroceryStore className='icon' />
                    <span>2 محلات بقالة</span>
                </div>
            </div>
        </StyledSection5>
    );
}

export default Section5;