import Form from 'react-bootstrap/Form';

function NumberInput({ titleText, inputName, value, setValue }) {

    const handleChange = e => {
        setValue(e.target.value.replace(/([^0-9])/g, ''));
    }

    return (
        <div className='data-line'>
            <span className='infoTitle'>
                {titleText}
            </span>
            <span className='infoData'>
                <Form.Control
                    className='py-1 px-3 myInput'
                    type='text'
                    value={value}
                    name={inputName}
                    onChange={handleChange}
                />
            </span>
        </div>
    );
}

export default NumberInput;