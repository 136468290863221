import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import RentValue from '../../contract-finance-records/edit/section-1/Section-1';
import Services from '../../contract-finance-records/edit/section-2/Section-2';
import AnnualUnitServicesFees from '../../contract-finance-records/edit/section-4/Section-4';
import Payouts from '../../contract-finance-records/edit/section-5/Section-5';
import { ContractDetailsEditContext } from '../ContractDetailsEditContext';




const StyledRentValueEdit = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    padding: 0;
    

    & > *:last-child{
        margin-bottom: 0;
    }

`;
const RentValueEdit = () => {
    const {
        duration,
    } = useContext(ContractDetailsEditContext)

    return (
        <StyledRentValueEdit>
            <RentValue />
            <Services />
            <Payouts />
            {duration.contractTypeHousing ? "" : <AnnualUnitServicesFees />}
        </StyledRentValueEdit>
    );
}

export default RentValueEdit;