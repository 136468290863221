import axios from "axios";


const base = axios.create({
    // baseURL: 'http://192.168.1.6:3333'
    baseURL: 'https://aqedbackend.bakkasoft.net/api/data'
});

base.defaults.headers.common['Accept'] = "application/json"
base.defaults.headers.common["Content-Type"] = "application/json"

export default base;