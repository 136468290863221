import React, { useContext, useEffect, useRef, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';
import Select from 'react-select';
import { Calendar } from 'react-date-range';
import moment from 'moment';
import StaticSelect from '../../../../pages-parts/inputs/static-select-input/StaticSelect';
import TextInput from '../../../../pages-parts/inputs/text-input/TextInput';
import NumberInput from '../../../../pages-parts/inputs/number-input/NumberInput';
import DatePickerInput from '../../../../pages-parts/inputs/date-picker/DatePickerInput';
import { OwnerDetailsEditContext } from '../OwnerDetailsEditContext';
import EmailInput from '../../../../pages-parts/inputs/email-input/EmailInput';

const StyledSection2 = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    margin-bottom: 3rem;
    padding: 1rem 1.75rem;
    background-color: ${({ theme }) => theme.colors.background.elements};
    border-radius: 0.75rem;
    position: relative;
    border: 1px solid #ccc;

    
    p{
        margin-bottom: 0;
    }
    .data-line{
        display: flex;
        align-items: center;
        margin-bottom: .5rem;
        &:first-child{
            margin-top: 0!important;
        }
        &:last-child{
            margin-bottom: 0;
        }
    }
    
    .infoData{
        width: 12rem;
        position: relative;
        
    }
    span img{
        width: 6rem;
        height: 6rem;
        border-radius: 0.75rem;
    }
`;

const Section2 = () => {
    const {
        selectedOwnerTypeOption,
        establishmentTypeOptions,
        handleChangeEstablishmentType,
        selectedEstablishmentTypeOption,
        establishmentId,
        setEstablishmentId,
        taxNumber,
        setTaxNumber,
    } = useContext(OwnerDetailsEditContext);

    const establishmentIdLabel = () => {
        if (selectedEstablishmentTypeOption.value === "commercial") {
            return 'رقم المؤسسة التجارية';
        } else if (selectedEstablishmentTypeOption.value === "non-prophet") {
            return 'رقم المؤسسة';
        } else {
            return 'رقم الرخصة';
        }
    }
    return (
        <>
            {
                selectedOwnerTypeOption.value === "establishment-owner" ||
                    selectedOwnerTypeOption.value === "establishment-representative" ? (
                    <StyledSection2>
                        <h2 className='sectionFloatTitle'>بيانات المؤسسة</h2>
                        <Row className='mt-3'>
                            <Col>
                                <StaticSelect
                                    titleText='نوع المؤسسة'
                                    selectedOption={selectedEstablishmentTypeOption}
                                    options={establishmentTypeOptions}
                                    customStylesProp={{
                                        has: false,
                                        style: {}
                                    }}
                                    handleChangeSelect={handleChangeEstablishmentType}
                                />
                                <TextInput
                                    titleText={establishmentIdLabel()}
                                    inputName='establishmentId'
                                    value={establishmentId}
                                    setValue={setEstablishmentId}
                                />
                                <TextInput
                                    titleText='الرقم الضريبي'
                                    inputName='tax-number'
                                    value={taxNumber}
                                    setValue={setTaxNumber}
                                />
                            </Col>
                        </Row>
                    </StyledSection2>
                ) : ""
            }
        </>
    );
}

export default Section2;