import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { AiOutlineEye } from 'react-icons/ai';
import { FaClipboardList, FaFileContract } from 'react-icons/fa';
import { CiDollar } from 'react-icons/ci';
import { FaRegEdit } from 'react-icons/fa';
import TableComponent from '../../pages-parts/table/TableComponent';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import SearchBarFilter from '../../pages-parts/table/SearchFilter';
import Entries from '../../pages-parts/table/Entries';
import profileImage from '../../../images/1.png';
import SelectFilter from '../../pages-parts/table/SelectFilter';
import AddNewButton from '../../pages-parts/inputs/add-new-btn/AddNewButton';


const StyledBrokerAssestants = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    padding: 2.5rem 1.75rem;
    .scroll-thumb{
        background-color: ${({ theme }) => theme.colors.primary_8};
    }

    .unitsNum{
        display: flex;
        align-items: center;
        justify-content: center;

        span{
            width: 2.5rem;
            text-align: center;
            padding: 0.125rem 0;
            border: 1px solid #ccc;
        }
        .filled{
            color: ${({ theme }) => theme.colors.primary};
            border-right: none;
        }
    }
`;

const BrokerAssestantsPage = () => {
    const location = useLocation();
    const [columns, setColumns] = useState([
        {
            id: 1,
            Header: "#",
            Cell: e => {
                return <span className='numbers-font'>{e.value}</span>
            },
            accessor: "number",
            sortable: true,
        },
        {
            id: 2,
            Header: "مساعد الوسيط",
            accessor: "name",
            sortable: true,
            Cell: e => {
                return (
                    <Link to={`/users/view?id=${e.row.cells.find(cell => cell.column.Header === "الاجراءات").value}`} className='d-flex align-items-center image-heading-text-link'>
                        <img src={profileImage} alt="Profile" />
                        <span>
                            <h4>
                                {e.value}
                            </h4>
                            <span className="numbers-font"><span>#</span> {e.row.cells.find(cell => cell.column.Header === "الاجراءات").value.replace(/(-.*)/g, "")}...</span>
                        </span>
                    </Link>
                )
            }
        },
        {
            id: 3,
            Header: "عدد العقود السكنية",
            accessor: "housingUnitsNum",
            Cell: e => {
                return <span className='numbers-font'>{e.value}</span>
            },
            sortable: true,

        },
        {
            id: 4,
            Header: "عدد العقود التجارية",
            accessor: "tradingUnitsNum",
            Cell: e => {
                return <span className='numbers-font'>{e.value}</span>
            },
            sortable: true
        },
        {
            id: 5,
            Header: "المنطقة",
            accessor: "area",
            sortable: true
        },
        {
            id: 6,
            Header: "المدينة",
            accessor: "city",
            sortable: true
        },
        {
            id: 7,
            Header: "الاجراءات",
            accessor: "id",
            sortable: false,
            Cell: e => {
                return (
                    <>
                        <OverlayTrigger
                            key="view"
                            placement="bottom"
                            overlay={
                                <Tooltip id={`tooltip-view`}>
                                    عرض بيانات المساعد الوسيط
                                </Tooltip>
                            }
                        >
                            <Link to={`/users/view?id=${e.value}`}><AiOutlineEye /></Link>
                        </OverlayTrigger>
                        <OverlayTrigger
                            key="edit"
                            placement="bottom"
                            overlay={
                                <Tooltip id={`tooltip-edit`}>
                                    تعديل بيانات المساعد الوسيط
                                </Tooltip>
                            }
                        >
                            <Link to={`/users/edit?id=${e.value}`}><FaRegEdit /></Link>
                        </OverlayTrigger>
                        <OverlayTrigger
                            key="financeRecord"
                            placement="bottom"
                            overlay={
                                <Tooltip id={`tooltip-financeRecord`}>
                                    السجل المالي
                                </Tooltip>
                            }
                        >
                            <Link to={`/broker-assistant-financial-records?id=${e.value}`}><FaClipboardList /></Link>
                        </OverlayTrigger>
                        <OverlayTrigger
                            key="paymentRecord"
                            placement="bottom"
                            overlay={
                                <Tooltip id={`tooltip-paymentRecord`}>
                                    سجل المدفوعات
                                </Tooltip>
                            }
                        >
                            <Link to={`/broker-assistant-payouts?id=${e.value}`}><CiDollar /></Link>
                        </OverlayTrigger>
                        <OverlayTrigger
                            key="contracts"
                            placement="bottom"
                            overlay={
                                <Tooltip id={`tooltip-contracts`}>
                                    سجل العقود
                                </Tooltip>
                            }
                        >
                            <Link to={`/users/contracts?id=${e.value}`}><FaFileContract /></Link>
                        </OverlayTrigger>
                    </>
                )
            }
        },
    ]);
    const [searchTable, setSearchTable] = useState(() => {
        const filterStorage = JSON.parse(localStorage.getItem(location.pathname + '-filters')) ?? null;
        if (filterStorage !== null && filterStorage.search !== undefined) {
            return filterStorage.search;
        } else {
            return '';
        }
    });
    const [sortTable, setSortTable] = useState(() => {
        const filterStorage = JSON.parse(localStorage.getItem(location.pathname + '-filters')) ?? null;
        if (filterStorage !== null && filterStorage.sortFilter !== undefined) {
            return filterStorage.sortFilter;
        } else {
            return {
                column: '',
                direction: ''
            };
        }
    });
    const [pageNum, setPageNum] = useState(() => {
        return JSON.parse(localStorage.getItem(location.pathname + '-page-num')) ?? 1;
    });
    const [areaSelectFilter, setAreaSelectFilter] = useState(() => {
        const filterStorage = JSON.parse(localStorage.getItem(location.pathname + '-filters')) ?? null;
        if (
            filterStorage !== null &&
            filterStorage.areaSelectFilter !== undefined &&
            filterStorage.areaSelectFilter?.value
        ) {
            return {
                type: 'api',
                url: '/areas?',
                value: filterStorage.areaSelectFilter,
                column: 'area',
                name: 'areaSelectFilter',
            };
        } else {
            return {
                type: 'api',
                url: '/areas?',
                value: null,
                column: 'area',
                name: 'areaSelectFilter',
            };
        }
    });
    const [citiesSelectFilter, setCitiesSelectFilter] = useState(() => {
        const filterStorage = JSON.parse(localStorage.getItem(location.pathname + '-filters')) ?? null;
        if (
            filterStorage !== null &&
            filterStorage.citiesSelectFilter !== undefined &&
            filterStorage.requestStatusSelectFilter?.value
        ) {
            return {
                type: 'api',
                url: '/cities?area_id=' + (areaSelectFilter.value?.value ?? '') + '&',
                value: filterStorage.citiesSelectFilter,
                column: 'city',
                name: 'citiesSelectFilter',
            };
        } else {
            return {
                type: 'api',
                url: '/cities?',
                value: null,
                column: 'city',
                name: 'citiesSelectFilter',
            };
        }
    });
    const [entries, setEntries] = useState(() => {
        return JSON.parse(localStorage.getItem('entries-per-page')) ?? 10;
    })
    const [tableDataUrl, setTableDataUrl] = useState(() => {
        // sort filter url part
        let sortFilter;
        if (sortTable.column !== "" && sortTable.column !== undefined) {
            sortFilter = `_sort=${sortTable.column}&_order=${sortTable.direction}&`;
        } else {
            sortFilter = "";
        }

        // select filter url part
        // area
        let areaSelectFilterText;
        if (
            areaSelectFilter.value !== {} &&
            areaSelectFilter.value?.label !== '' &&
            areaSelectFilter.value?.label !== 'الكل' &&
            areaSelectFilter.value?.label !== undefined &&
            areaSelectFilter.value?.value !== 1
        ) {
            areaSelectFilterText = `${areaSelectFilter.column}=${areaSelectFilter.value?.label}&`;
        } else {
            areaSelectFilterText = "";
        }
        // city
        let citySelectFilterText;
        if (
            citiesSelectFilter.value !== {} &&
            citiesSelectFilter.value?.label !== '' &&
            citiesSelectFilter.value?.label !== 'الكل' &&
            citiesSelectFilter.value?.label !== undefined &&
            citiesSelectFilter.value?.value !== 1
        ) {
            citySelectFilterText = `${citiesSelectFilter.column}=${citiesSelectFilter.value?.label}&`;
        } else {
            citySelectFilterText = "";
        }
        let selectFilterText = areaSelectFilterText + citySelectFilterText;

        const url = `/users?rule=مساعد وسيط&${selectFilterText}${sortFilter}q=${searchTable}&_page=${pageNum}&_limit=${entries}`;

        return url;
    })

    useEffect(() => {
        // sort filter url part
        let sortFilter;
        if (sortTable.column !== "" && sortTable.column !== undefined) {
            sortFilter = `_sort=${sortTable.column}&_order=${sortTable.direction}&`;
        } else {
            sortFilter = "";
        }

        // select filter url part
        // area
        let areaSelectFilterText;
        if (
            areaSelectFilter.value !== {} &&
            areaSelectFilter.value?.label !== '' &&
            areaSelectFilter.value?.label !== 'الكل' &&
            areaSelectFilter.value?.value !== undefined
        ) {
            areaSelectFilterText = `${areaSelectFilter.column}=${areaSelectFilter.value?.label}&`;
        } else {
            areaSelectFilterText = "";
        }
        // city
        let citySelectFilterText;
        if (
            citiesSelectFilter.value !== {} &&
            citiesSelectFilter.value?.label !== '' &&
            citiesSelectFilter.value?.label !== 'الكل' &&
            citiesSelectFilter.value?.value !== undefined
        ) {
            citySelectFilterText = `${citiesSelectFilter.column}=${citiesSelectFilter.value?.label}&`;
        } else {
            citySelectFilterText = "";
        }
        let selectFilterText = areaSelectFilterText + citySelectFilterText;

        const url = `/users?rule=مساعد وسيط&${selectFilterText}${sortFilter}q=${searchTable}&_page=${pageNum}&_limit=${entries}`;
        setTableDataUrl(url);
    },
        [
            searchTable,
            sortTable,
            pageNum,
            entries,
            areaSelectFilter.value,
            citiesSelectFilter.value
        ]);

    useEffect(() => {
        setCitiesSelectFilter(prev => {
            return {
                type: 'api',
                url: '/cities?area_id=' + areaSelectFilter.value?.value + '&',
                value: null,
                column: 'city',
                name: 'citiesSelectFilter'
            };
        })
    }, [areaSelectFilter.value])

    useEffect(() => {
        const localFilter = {
            search: searchTable,
            sortFilter: sortTable,
            areaSelectFilter: areaSelectFilter.value,
            citiesSelectFilter: citiesSelectFilter.value,
        }
        localStorage.setItem(location.pathname + '-filters', JSON.stringify(localFilter));
        localStorage.setItem(location.pathname + '-page-num', pageNum);
        localStorage.setItem('entries-per-page', entries);
    }, [tableDataUrl]);

    return (
        <StyledBrokerAssestants>
            <Container fluid>
                <Row className='mb-3 flex-lg-row-reverse flex-md-row justify-content-between'>
                    <Col
                        lg={5}
                        md={12}
                        className='d-flex justify-content-end align-items-center mb-2'
                    >
                        <Entries
                            entries={entries}
                            setEntries={setEntries}
                        />
                        <SearchBarFilter
                            setSearchTable={setSearchTable}
                            searchTable={searchTable}
                            setPageNum={setPageNum}
                        />
                    </Col>
                    <Col lg={7} md={12} className='d-flex align-items-center'>
                        <AddNewButton
                            title='إضافة مساعد وسيط'
                            url='/users/create?type=broker-assistant'
                            isLink={true}
                        />
                        <SelectFilter
                            selectFilter={areaSelectFilter}
                            setSelectFilter={setAreaSelectFilter}
                            label="المنطقة"
                        />
                        <SelectFilter
                            selectFilter={citiesSelectFilter}
                            setSelectFilter={setCitiesSelectFilter}
                            label="المدينة"
                        />
                    </Col>
                </Row>
                <TableComponent
                    columns={columns}
                    tableDataUrl={tableDataUrl}
                    setSortTable={setSortTable}
                    tableWidth={800}
                    setPageNum={setPageNum}
                    pageNum={pageNum}
                    tableRowsPerPage={entries}
                />
            </Container>
        </StyledBrokerAssestants>
    );
}

export default BrokerAssestantsPage;