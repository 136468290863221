import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useState } from "react";
import AddNewButton from "../add-new-btn/AddNewButton";


const CustomFieldModal = ({ text, modleContent, handleSave }) => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleSaveButton = () => {
        handleSave();
        setShow(false);
    };

    return (
        <>
            <AddNewButton
                onClickFunction={handleShow}
                title={text}
            />
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{modleContent.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {modleContent.inputs.map(item => (
                        <Form.Control
                            key={item.inputName}
                            className='py-2 px-3 mb-2 text-start'
                            type={item.inputType}
                            name={item.inputName}
                            placeholder={item.inputPlaceholder}
                        />
                    ))}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        اغلاق
                    </Button>
                    <Button variant="primary" onClick={handleSaveButton}>
                        حفظ
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default CustomFieldModal;