import React, { useContext, useEffect, useRef, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';
import Select from 'react-select';
import { Calendar } from 'react-date-range';
import moment from 'moment';
import DatePickerInput from '../../../pages-parts/inputs/date-picker/DatePickerInput';
import { ContractDetailsEditContext } from '../ContractDetailsEditContext';
import SectionCommercialPart from '../../contract-finance-records/edit/section-1/increasing-rent';


const StyledContractDurationPage = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    margin-bottom: 0;
    padding: 1rem 1.75rem;
    background-color: ${({ theme }) => theme.colors.background.elements};
    border-radius: 0.75rem;
    position: relative;
    border: 1px solid #ccc;
    
    p{
        margin-bottom: 0;
    }
    
    .infoTitle{
        width: 10rem;
    }
    .infoData{
        width: 10rem;
        position: relative;
        
        &:has(input[name=yearly-rent])::after{
            display: flex;
            content: "SAR";
            position: absolute;
            height: 100%;
            align-items: center;
            right: 0;
            top: 0;
            font-weight: 900;
            width: 50px;
            justify-content: center;
            border-left: 1px solid #ccc;
        }

        input[name=yearly-rent].myInput{
            padding-right: 55px!important;
        }
        
    }
    .periodBox{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h3{
            font-size: 14px;
            color: ${({ theme }) => theme.colors.text.secondary};
            margin-bottom: 0;
            margin-top: .25rem;
        }
        .infoData2{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 3rem;
            padding: 0.5rem 0;
            margin-top: .5rem;
            border-radius: 0.5rem;
            border: 1px solid #ccc;
            background-color: ${({ theme }) => theme.colors.background.elements};
        }
    }
    .infoCard{
        padding: 1rem;
        border-radius: 0.75rem;
        border: 1px solid #ccc;
        background-color: ${({ theme }) => theme.colors.background.elements};
        .period{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            min-width: 3rem;
            padding: 0.5rem 0;
            border-radius: 0.5rem;
            border: 1px solid #ccc;
            background-color: ${({ theme }) => theme.colors.background.body};
        }
        .primaryColor{
            color: ${({ theme }) => theme.colors.text.primary}
        }
    }
    .displayCard{
        padding: 1rem;
        border-radius: 0.75rem;
        border: 1px solid #ccc;
        background-color: ${({ theme }) => theme.colors.background.body};
    }
    span img{
        width: 6rem;
        height: 6rem;
        border-radius: 0.75rem;
    }

    .contractTypeCheck{
        border: 1px solid #ccc;
        border-radius: 0.75rem;
        &:has(input:checked){
            border-color: ${({ theme }) => theme.colors.secondary};
            color: ${({ theme }) => theme.colors.secondary};
        }
    }

    input[name=contract-type]{
        &:checked{
            background-color: ${({ theme }) => theme.colors.secondary};
            border-color: ${({ theme }) => theme.colors.secondary};
        }
        
        &:focus{
            box-shadow: 0 0 0 0.25rem ${({ theme }) => theme.colors.secondary_2};
        }
    }
`;

const ContractDurationPage = () => {
    const { duration } = useContext(ContractDetailsEditContext);

    const yearsDuration = moment(duration.contractEndDate).diff(duration.contractStartDate, "Y");
    const monthsTotal = moment(duration.contractEndDate).diff(duration.contractStartDate, "M");
    const monthsDuration = monthsTotal - (yearsDuration * 12);
    let daysDuration = moment(duration.contractEndDate).format('D') - moment(duration.contractStartDate).format("D");

    if (daysDuration < 0) {
        daysDuration *= -1;
    }
    return (
        <Container fluid>
            <StyledContractDurationPage>

                <div className='mt-2 infoTitle'>
                    نوع العقد
                </div>
                <div className='mb-3 ps-5 d-flex'>
                    <label className='me-5 mb-0 px-5 py-1 contractTypeCheck' htmlFor='housing'>
                        <Form.Check
                            type='radio'
                            id='housing'
                            checked={duration.contractTypeHousing}
                            name='contract-type'
                            label='سكني'
                            onChange={() => duration.setContractTypeHousing(true)}
                        />
                    </label>
                    <label className='me-5 mb-0 px-5 py-1 contractTypeCheck' htmlFor='commercial'>
                        <Form.Check
                            type='radio'
                            id='commercial'
                            checked={!duration.contractTypeHousing}
                            name='contract-type'
                            label='تجاري'
                            onChange={() => duration.setContractTypeHousing(false)}
                        />
                    </label>
                </div>
                <Row>
                    <Col className='mb-2'>
                        <DatePickerInput
                            titleText='تاريخ البدء'
                            date={duration.contractStartDate}
                            setDate={duration.setContractStartDate}
                        />
                    </Col>
                    <Col>
                        <DatePickerInput
                            titleText='تاريخ الانتهاء'
                            date={duration.contractEndDate}
                            setDate={duration.setContractEndDate}
                        />
                    </Col>
                </Row>

                <Row className='mt-2'>
                    <Col xs="12" className='mb-2'>
                        <p>
                            <span className='infoTitle'>فترة الايجار :</span>
                        </p>
                    </Col>
                    <Col xs="12" className='mb-2'>
                        <div className='displayCard row '>
                            <div className='col-6'>
                                <Row className='h-100'>
                                    <div className='col-4 periodBox'>
                                        <p>سنوات</p>
                                        <p className='infoData2'>
                                            {
                                                yearsDuration
                                            }
                                        </p>
                                    </div>
                                    <div className='col-4 periodBox'>
                                        <p>شهور</p>
                                        <p className='infoData2'>
                                            {
                                                monthsDuration
                                            }
                                        </p>
                                    </div>
                                    <div className='col-4 periodBox'>
                                        <p>ايام</p>
                                        <p className='infoData2'>
                                            {
                                                daysDuration
                                            }
                                        </p>
                                    </div>
                                </Row>
                            </div>
                            <div className='col-6 infoCard row align-items-center'>
                                <div className='col-9'>
                                    <h4>رسوم التسجيل في عاقد</h4>
                                    <p className='primaryColor'>الرسوم السنوية يتم دفعها بواسطة مكتب الوساطة او المؤجر</p>
                                </div>
                                <div className='col-3'>
                                    <div className='period'>
                                        <p>
                                            {
                                                yearsDuration
                                            }
                                        </p>
                                        <p>سنوات</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>

                {duration.contractTypeHousing ? "" : <SectionCommercialPart />}
            </StyledContractDurationPage>
        </Container>
    );
}

export default ContractDurationPage;