import React, { useContext, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useSearchParams, Link, useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { ContractDetailsEditContextProvider } from './ContractDetailsEditContext';
import { OwnerDetailsEditContextProvider } from '../owners/edit/OwnerDetailsEditContext';
import { AlertContext } from '../../../context/AlertContext';
import ContractDetailsEdit from './ContractDetailsEdit';
import { OwnershipDocEditContextProvider } from '../real-estates/ownership-document-edit/OwnershipDocEditContext';
import { RealEstateDetailsEditContextProvider } from '../real-estates/real-estate-details-edit/RealEstateDetailsEditContext';
import { RealEstateSharedFacilitiesEditContextProvider } from '../real-estates/real-estate-shared-facilities-edit/RealEstateSharedFacilitiesEditContext';
import { UnitEditContextProvider } from '../real-estates/units/unit-edit/UnitEditContext';
import { TenantDetailsEditContextProvider } from '../tenant/edit/TenantDetailsEditContext';
import { ContractFinanceRecordEditContextProvider } from '../contract-finance-records/edit/ContractFinanceRecordEditContext';





const ContractDetailsEditContainer = () => {
    return (
        // ownership doc
        <OwnershipDocEditContextProvider>
            {/* real estates and units */}
            <RealEstateDetailsEditContextProvider>
                <RealEstateSharedFacilitiesEditContextProvider>
                    <UnitEditContextProvider>
                        {/* parties */}
                        <OwnerDetailsEditContextProvider>
                            <TenantDetailsEditContextProvider>
                                {/* finance records */}
                                <ContractFinanceRecordEditContextProvider>
                                    <ContractDetailsEditContextProvider>
                                        <ContractDetailsEdit />
                                    </ContractDetailsEditContextProvider>
                                </ContractFinanceRecordEditContextProvider>
                            </TenantDetailsEditContextProvider>
                        </OwnerDetailsEditContextProvider>
                    </UnitEditContextProvider>
                </RealEstateSharedFacilitiesEditContextProvider>
            </RealEstateDetailsEditContextProvider>
        </OwnershipDocEditContextProvider>
    );
}

export default ContractDetailsEditContainer;