import Form from 'react-bootstrap/Form';

function SwitchInput({ titleText, inputName, value, setValue }) {

    const handleChange = e => {
        setValue(prev => !prev);
    }

    return (
        <div className='data-line'>
            <span className='infoTitle'>
                {titleText}
            </span>
            <span className='infoData'>
                <Form.Check
                    className='px-0'
                    type="switch"
                    label=''
                    defaultChecked={value}
                    name={inputName}
                    onChange={handleChange}
                />
            </span>
        </div>
    );
}

export default SwitchInput;