import React, { useContext, useEffect, useRef, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';
import Select from 'react-select';
import { Calendar } from 'react-date-range';
import moment from 'moment';
import StaticSelect from '../../../../pages-parts/inputs/static-select-input/StaticSelect';
import TextInput from '../../../../pages-parts/inputs/text-input/TextInput';
import NumberInput from '../../../../pages-parts/inputs/number-input/NumberInput';
import DatePickerInput from '../../../../pages-parts/inputs/date-picker/DatePickerInput';
import { TenantDetailsEditContext } from '../TenantDetailsEditContext';
import EmailInput from '../../../../pages-parts/inputs/email-input/EmailInput';

const StyledSectionInputs = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    position: relative;
    padding: 1rem;
    border-radius: 0.75rem;
    border: 1px solid #ccc;
    background-color: ${({ theme }) => theme.colors.background.body};

    p{
        margin-bottom: 0;
    }
    
    
    .infoData{
        width: 12rem;
        position: relative;
        
        
    }
    
    span img{
        width: 6rem;
        height: 6rem;
        border-radius: 0.75rem;
    }
`;

const SectionInputs = () => {
    const {
        selectedRepresentativeTypeOption,
        representativeDocumentReleaseDate,
        setRepresentativeDocumentReleaseDate,
        representativeDocumentExpireDate,
        setRepresentativeDocumentExpireDate,
        representativeDocumentReleasedBy,
        setRepresentativeDocumentReleasedBy,
        representativeDocumentReleasedIn,
        setRepresentativeDocumentReleasedIn,
        representativeLegalDocumentName,
        setRepresentativeLegalDocumentName,
    } = useContext(TenantDetailsEditContext);

    return (
        <StyledSectionInputs>
            <Row>
                <Col>
                    <DatePickerInput
                        titleText='تاريخ الاصدار'
                        inputName='release-date'
                        date={representativeDocumentReleaseDate}
                        setDate={setRepresentativeDocumentReleaseDate}
                    />
                    <DatePickerInput
                        titleText='تاريخ الانتهاء'
                        inputName='expire-date'
                        date={representativeDocumentExpireDate}
                        setDate={setRepresentativeDocumentExpireDate}
                    />
                    <TextInput
                        titleText='تم الانشاء بواسطة'
                        inputName='released-by'
                        value={representativeDocumentReleasedBy}
                        setValue={setRepresentativeDocumentReleasedBy}
                    />
                    <TextInput
                        titleText='تم الانشاء في'
                        inputName='released-in'
                        value={representativeDocumentReleasedIn}
                        setValue={setRepresentativeDocumentReleasedIn}
                    />
                    {
                        selectedRepresentativeTypeOption.value === "others" ? (
                            <TextInput
                                titleText='اسم نوع الوثيقة القانونية'
                                inputName='legal-document-name'
                                value={representativeLegalDocumentName}
                                setValue={setRepresentativeLegalDocumentName}
                            />
                        ) : ""
                    }
                </Col>
            </Row>
        </StyledSectionInputs>
    );
}

export default SectionInputs;