import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { AiOutlineEye } from 'react-icons/ai';
import { FaRegEdit } from 'react-icons/fa';
import TableComponent from '../../pages-parts/table/TableComponent';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Entries from '../../pages-parts/table/Entries';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import CreateNewModal from '../../pages-parts/table/CreateNewModal';
import SearchBarFilter from '../../pages-parts/table/SearchFilter';
import api from '../../../api/areas'
import { TopbarContext } from '../../../context/TopbarContext';
import { AlertContext } from '../../../context/AlertContext';


const StyledZoneView = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    padding: 2.5rem 1.75rem;
    .scroll-thumb{
        background-color: ${({ theme }) => theme.colors.primary_8};
    }
`;

const ZoneViewPage = () => {
    const [params, setParams] = useSearchParams();
    const location = useLocation();
    const { toggleTopbarTitle } = useContext(TopbarContext);
    const { alertContext } = useContext(AlertContext);
    const navigate = useNavigate();
    const [zoneDate, setZoneDate] = useState({});
    const [showEditModal, setShowEditModal] = useState(false);
    const [editModalValue, setEditModalValue] = useState({
        id: "",
        cityName: ""
    });
    const [columns, setColumns] = useState([
        {
            id: 1,
            Header: "المدينة",
            accessor: "city",
            sortable: true,
            Cell: e => {
                return (
                    <div id={e.row.cells.find(cell => cell.column.Header === "الاجراءات").value}>
                        {e.value}
                    </div>
                )
            }
        },
        {
            id: 3,
            Header: "الاجراءات",
            accessor: "id",
            sortable: false,
            Cell: e => {
                return (
                    <>
                        <OverlayTrigger
                            key="edit"
                            placement="bottom"
                            overlay={
                                <Tooltip id={`tooltip-edit`}>
                                    تعديل المدينة
                                </Tooltip>
                            }
                        >
                            <span className='edit-modal' onClick={() => handleEditCity(e.value)}><FaRegEdit /></span>
                        </OverlayTrigger>
                    </>
                )
            }
        },
    ]);
    const [searchTable, setSearchTable] = useState(() => {
        const filterStorage = JSON.parse(localStorage.getItem(location.pathname + '-filters')) ?? null;
        if (filterStorage !== null && filterStorage.search !== undefined) {
            return filterStorage.search;
        } else {
            return '';
        }
    });
    const [sortTable, setSortTable] = useState(() => {
        const filterStorage = JSON.parse(localStorage.getItem(location.pathname + '-filters')) ?? null;
        if (filterStorage !== null && filterStorage.sortFilter !== undefined) {
            return filterStorage.sortFilter;
        } else {
            return {
                column: '',
                direction: ''
            };
        }
    });
    const [pageNum, setPageNum] = useState(() => {
        return JSON.parse(localStorage.getItem(location.pathname + '-page-num')) ?? 1;
    });
    const [entries, setEntries] = useState(() => {
        return JSON.parse(localStorage.getItem('entries-per-page')) ?? 10;
    })
    const [tableDataUrl, setTableDataUrl] = useState(() => {

        // sort filter url part
        let sortFilter;
        if (sortTable.column !== "" && sortTable.column !== undefined) {
            sortFilter = `_sort=${sortTable.column}&_order=${sortTable.direction}&`;
        } else {
            sortFilter = "";
        }

        const url = `/cities?area_id=${params.get('id')}&${sortFilter}q=${searchTable}&_page=${pageNum}&_limit=${entries}`;

        return url;
    })

    useEffect(() => {
        api.get(`/areas?id=${params.get('id')}`).then(result => {
            setZoneDate(result.data[0]);
            if (result.data.length === 0) {
                alertContext.toggleAlert(true);
                alertContext.toggleAlertData({
                    heading: 'منطقة مجهول',
                    message: 'لم يتم العثور علي المنطقة المطلوبة',
                    variant: 'warning',
                });
                navigate("/");
            } else {
                toggleTopbarTitle(`عرض بيانات منطقة ${result.data[0].area}`)
            }
        });
    }, [])

    useEffect(() => {

        // sort filter url part
        let sortFilter;
        if (sortTable.column !== "" && sortTable.column !== undefined) {
            sortFilter = `_sort=${sortTable.column}&_order=${sortTable.direction}&`;
        } else {
            sortFilter = "";
        }

        const url = `/cities?area_id=${params.get('id')}&${sortFilter}q=${searchTable}&_page=${pageNum}&_limit=${entries}`;
        setTableDataUrl(url);
    },
        [
            searchTable,
            sortTable,
            pageNum,
            entries,
        ]
    );

    useEffect(() => {
        const localFilter = {
            sortFilter: sortTable,
            search: searchTable,
        }
        localStorage.setItem(location.pathname + '-page-num', pageNum);
        localStorage.setItem('entries-per-page', entries);
        localStorage.setItem(location.pathname + '-filters', JSON.stringify(localFilter));

    }, [tableDataUrl]);

    const handleEditCity = (id) => {
        setShowEditModal(true);
        const city = document.getElementById(id).innerText;
        setEditModalValue({
            id,
            cityName: city
        })
    }
    const handleClose = () => {
        setShowEditModal(false);
    }
    const handleSave = () => {
        const city = document.getElementById(editModalValue.id);
        city.innerText = editModalValue.cityName;
        setShowEditModal(false);
    }
    return (
        <StyledZoneView>
            <Container fluid>
                <Row className='mb-3 flex-lg-row-reverse flex-md-row justify-content-between'>
                    <Col
                        md={5}
                        sm={12}
                        className='d-flex justify-content-end align-items-center mb-2'
                    >
                        <Entries
                            entries={entries}
                            setEntries={setEntries}
                        />
                        <SearchBarFilter
                            setSearchTable={setSearchTable}
                            searchTable={searchTable}
                            setPageNum={setPageNum}
                        />
                    </Col>
                    <Col md={5} sm={12}>
                        <CreateNewModal
                            text='إضافة مدينة'
                            modleContent={{
                                title: 'إضافة مدينة',
                                inputName: 'zone',
                                inputPlaceholder: 'اسم المدينة'
                            }}
                        />
                    </Col>
                </Row>
                <TableComponent
                    columns={columns}
                    tableDataUrl={tableDataUrl}
                    setSortTable={setSortTable}
                    tableWidth={800}
                    setPageNum={setPageNum}
                    pageNum={pageNum}
                    tableRowsPerPage={entries}
                />
                <Modal show={showEditModal} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>تعديل المدينة</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Control
                            className='py-2 px-3'
                            type='text'
                            name="edit-city"
                            placeholder="اسم المدينة"
                            value={editModalValue.cityName}
                            onChange={(e) => setEditModalValue(prev => {
                                return {
                                    ...prev,
                                    cityName: e.target.value
                                }
                            })}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            اغلاق
                        </Button>
                        <Button variant="primary" onClick={handleSave}>
                            حفظ
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Container>
        </StyledZoneView>
    );
}

export default ZoneViewPage;