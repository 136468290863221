import React, { useContext, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useSearchParams, Link, useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';
import TabsFilter from '../TabsFilter';
import { ContractDetailsEditContext } from '../ContractDetailsEditContext';
import RealEstateEdit from './RealEstate';
import UnitsEdit from './Units';




const StyledRealEstatesAndUnits = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    padding: 0;
    

`;
const RealEstatesAndUnits = () => {
    const {
        currentPageNum,
        setCurrentPageNum
    } = useContext(ContractDetailsEditContext);
    const tabs = [
        {
            id: 1,
            value: 2,
            name: "العقارات",
        },
        {
            id: 2,
            value: 3,
            name: "الوحدات",
        },
    ];


    return (
        <StyledRealEstatesAndUnits>
            <Container fluid>
                <Row className='mb-4'>
                    <Col md="6">
                        <TabsFilter
                            activeTab={currentPageNum}
                            setActiveTab={setCurrentPageNum}
                            tabs={tabs}
                        />
                    </Col>
                </Row>
                {
                    currentPageNum === 2 ? <RealEstateEdit /> : <UnitsEdit />
                }

            </Container>
        </StyledRealEstatesAndUnits>
    );
}

export default RealEstatesAndUnits;