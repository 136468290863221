import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { BsDownload } from 'react-icons/bs';
import Moment from 'react-moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import moment from 'moment';
import PlainText from '../../../pages-parts/display-info/standard/PlainText';


const StyledSection3 = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    margin-bottom: 3rem;
    padding: 1rem 1.75rem;
    background-color: ${({ theme }) => theme.colors.background.elements};
    border-radius: 0.75rem;
    position: relative;
    border: 1px solid #ccc;

    
    
    
    .infoDataBorder{
        display: inline-block;
        padding: .25rem 3rem;
        border-radius: 0.75rem;
        border: 1px solid #ccc;
    }
    .infoData{
        color: ${({ theme }) => theme.colors.text.primary};
    }

    .displayCard{
        padding: 1rem;
        border-radius: 0.75rem;
        border: 1px solid #ccc;
        background-color: ${({ theme }) => theme.colors.background.body};

        &.export{
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            transition: all .125s ease-in-out;

            h4{
                margin-bottom: 0;
            }
            .icon{
                font-size: 1.5rem;
                font-weight: 700;
            }

            &:hover{
                color: ${({ theme }) => theme.colors.secondary};
            }
        }
    }
    .scroll-thumb{
        background-color: ${({ theme }) => theme.colors.primary_8};
    }
`;

const Section3 = ({ ownerData }) => {

    const [displayData, setDisplayData] = useState([]);

    useEffect(() => {

        setDisplayData([
            {
                title: 'تاريخ الاصدار',
                value: moment(new Date("7-23-2018")).format('ll'),
                hasNumber: true
            },
            {
                title: 'تاريخ انتهاء الصلاحية',
                value: moment(new Date("7-22-2026")).format('ll'),
                hasNumber: true
            },
            {
                title: 'تم الاصدار بواسطة',
                value: '--',
                hasNumber: false
            },
            {
                title: 'تم الاصدار في',
                value: '--',
                hasNumber: false
            },
        ]);
    }, [ownerData]);

    return (
        <StyledSection3>
            <h2 className='sectionFloatTitle'>تفاصيل الممثل</h2>

            <Row className='mt-2'>
                <Col md="6" className='mb-3'>
                    <PlainText
                        title='نوع الوثيقة'
                        value='وكالة شرعية'
                        hasNumber={false}
                    />
                </Col>
            </Row>

            <div className='displayCard mb-3'>
                <Row>
                    <Col md="6">
                        {displayData.map((item, i) => (
                            <PlainText
                                key={i}
                                title={item.title}
                                value={item.value}
                                hasNumber={item.hasNumber}
                            />
                        ))}
                    </Col>
                </Row>
            </div>

            <div className='displayCard export'>
                <h4>صورة الوثيقة القانونية</h4>
                <BsDownload className='icon' />
            </div>
        </StyledSection3>
    );
}

export default Section3;