import React, { useContext, useRef } from 'react';
import styled from 'styled-components';
import { SidebarContext } from '../../../context/SidebarContext';
import ListItem from './menu-list-item/ListItem';

const StyledSidebarMenu = styled.ul`
    width: 100%;
    padding: 0;
`;



const SidebarMenu = () => {
    const sidebarState = useContext(SidebarContext);
    const menuRef = useRef()

    const handleMouseEnter = () => {
        sidebarState.toggleHoverClosedMenu(true);
    }

    const handleMouseLeave = () => {
        sidebarState.toggleHoverClosedMenu(false);
    }

    return (
        <StyledSidebarMenu
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            ref={menuRef}
        >
            {
                sidebarState.menuList.map((listItem) => (
                    <ListItem listItemProp={listItem} key={listItem.id} />
                ))
            }
        </StyledSidebarMenu>
    );
}

export default SidebarMenu;