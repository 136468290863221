import React, { useState, createContext, useEffect, useContext } from 'react';
import { AlertContext } from "../../../../context/AlertContext";
import api from '../../../../api/areas';
import { useNavigate } from "react-router-dom";
export const RealEstateDetailsEditContext = createContext();

export const RealEstateDetailsEditContextProvider = ({ children }) => {
    //realEstateData place holder
    const [realEstateData, setRealEstateData] = useState({});
    const alertContext = useContext(AlertContext);
    const navigate = useNavigate();

    // section 1 real estate details --------------------------
    // نوع العقار
    const [realEstateTypeOptions, setRealEstateTypeOptions] = useState([
        {
            value: 'building',
            label: 'مبني',
        },
        {
            value: 'villa',
            label: 'فيلا',
        },
        {
            value: 'open-commercial-complex',
            label: 'مجمع تجاري مفتوح',
        },
        {
            value: 'ground',
            label: 'ارض',
        },
        {
            value: 'closed-commercial-complex',
            label: 'مجمع تجاري مغلق',
        },
        {
            value: 'tower',
            label: 'برج',
        },
        {
            value: 'another',
            label: 'اخر',
        },
    ]);
    const [realEstateTypeOption, setRealEstateTypeOption] = useState({
        value: 'building',
        label: 'مبني',
    });
    const handleChangeRealEstateTypeOption = (selectOption) => {
        setRealEstateTypeOption(selectOption)
    }
    // استخدام العقار
    const [realEstateUseOptions, setRealEstateUseOptions] = useState([
        {
            value: 'family-housing',
            label: 'سكن عائلي',
        },
        {
            value: 'individual-housing',
            label: 'سكن فردي',
        },
        {
            value: 'commercial',
            label: 'تجاري',
        },
        {
            value: 'housing-commercial',
            label: 'سكني / تجاري',
        },
    ]);
    const [realEstateUseOption, setRealEstateUseOption] = useState({
        value: 'family-housing',
        label: 'سكن عائلي',
    });
    const handleChangeRealEstateUseOption = (selectOption) => {
        setRealEstateUseOption(selectOption)
    }
    // رقم العقار
    const [realEstateNumber, setRealEstateNumber] = useState('');
    // تاريخ الانشاء
    const [constructionDate, setConstructionDate] = useState(new Date());
    // عدد الادوار
    const [floorsNumber, setFloorsNumber] = useState('');
    // عدد الوحدات لكل دور
    const [unitsBerFloorNumber, setUnitsBerFloorNumber] = useState('');
    // اسم العقار
    const [realEstateName, setRealEstateName] = useState('');

    // section 2 address --------------------------
    // المنطقة
    const [areaDBUrl, setAreaDBUrl] = useState('areas?');
    const [selectedArea, setSelectedArea] = useState({
        value: 'no-value',
        label: 'غير محدد',
    });
    const handleChangeSelectedAreaOption = (selectOption) => {
        setSelectedArea(selectOption)
    }
    // المدينة
    const [cityDBUrl, setCityDBUrl] = useState(`cities?area_id=${selectedArea.value}&`);
    const [selectedCity, setSelectedCity] = useState({
        value: 'no-value',
        label: 'غير محدد',
    });
    const handleChangeSelectedCityOption = (selectOption) => {
        setSelectedCity(selectOption)
    }
    // الحي
    const [neighborhoodOptions, setNeighborhoodOptions] = useState([
        {
            value: 'no-value',
            label: 'غير محدد',
        },
        {
            value: 1,
            label: 'لبن',
        },
        {
            value: 2,
            label: 'درّة الرياض',
        },
        {
            value: 3,
            label: 'حي الموسى',
        },
        {
            value: 4,
            label: 'النظيم',
        },
        {
            value: 5,
            label: 'تلال الرياض',
        },
    ]);
    const [selectedNeighborhood, setSelectedNeighborhood] = useState({
        value: 'no-value',
        label: 'غير محدد',
    });
    const handleChangeSelectedNeighborhood = (selectOption) => {
        setSelectedNeighborhood(selectOption)
    }
    // الرمز البريدى
    const [zipCode, setZipCode] = useState('');
    // اسم الشارع
    const [streetName, setStreetName] = useState('');
    // رقم المبني
    const [buildingNumber, setBuildingNumber] = useState('');
    // رقم اضافي
    const [additionalNumber, setAdditionalNumber] = useState('');
    useEffect(() => {
        setCityDBUrl(`cities?area_id=${selectedArea.value}&`)
        setSelectedCity({
            value: 'no-value',
            label: 'غير محدد',
        })
    }, [selectedArea])
    useEffect(() => {
        setSelectedNeighborhood({
            value: 'no-value',
            label: 'غير محدد',
        })
    }, [selectedCity])

    const getRealEstateData = (realEstateId) => {
        api.get(`/realEstates?id=${realEstateId}`).then(result => {
            if (result.data.length === 0) {
                alertContext.toggleAlert(true);
                alertContext.toggleAlertData({
                    heading: 'عقار مجهول',
                    message: 'لم يتم العثور علي العقار المطلوب',
                    variant: 'warning',
                });
                navigate("/");
            } else {
                setRealEstateData(result.data[0]);
            }
        });
    }

    const values = {
        getRealEstateData,
        realEstateTypeOptions,
        realEstateTypeOption,
        handleChangeRealEstateTypeOption,
        realEstateUseOptions,
        realEstateUseOption,
        handleChangeRealEstateUseOption,
        realEstateNumber,
        setRealEstateNumber,
        constructionDate,
        setConstructionDate,
        floorsNumber,
        setFloorsNumber,
        unitsBerFloorNumber,
        setUnitsBerFloorNumber,
        realEstateName,
        setRealEstateName,

        areaDBUrl,
        selectedArea,
        handleChangeSelectedAreaOption,
        cityDBUrl,
        selectedCity,
        handleChangeSelectedCityOption,
        neighborhoodOptions,
        selectedNeighborhood,
        handleChangeSelectedNeighborhood,
        zipCode,
        setZipCode,
        streetName,
        setStreetName,
        buildingNumber,
        setBuildingNumber,
        additionalNumber,
        setAdditionalNumber,
    };

    return (
        <RealEstateDetailsEditContext.Provider value={values}>
            {children}
        </RealEstateDetailsEditContext.Provider>
    )
}

