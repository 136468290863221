import React, { useContext, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import { useSearchParams, Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import api from '../../../api/areas';
import { AlertContext } from '../../../context/AlertContext';
import { TopbarContext } from '../../../context/TopbarContext';
import AddNewButton from '../../pages-parts/inputs/add-new-btn/AddNewButton';
import Section1 from './section-1/Section-1';
import Section2 from './section-2/Section-2';
import Section3 from './section-3/Section-3';
import Section4 from './section-4/Section-4';
import Section5 from './section-5/Section-5';

const StyledViewOwner = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    padding: 2.5rem 1.75rem;
    color: ${({ theme }) => theme.colors.text.secondary};

    .row{
        margin: 0;
    }
`;

const ViewOwnerPage = () => {
    const [params, setParams] = useSearchParams();
    const [ownerData, setOwnerData] = useState({});
    const [isOwner, setIsOwner] = useState(true);
    const navigate = useNavigate();
    const alertContext = useContext(AlertContext);
    const { toggleTopbarTitle } = useContext(TopbarContext);

    useEffect(() => {
        api.get(`/owners?id=${params.get('id')}`).then(result => {
            setOwnerData(result.data[0]);
            if (result.data.length === 0) {
                alertContext.toggleAlert(true);
                alertContext.toggleAlertData({
                    heading: 'شخص مجهول',
                    message: 'لم يتم العثور علي اي مالك او ممثل مطابق لطلبك',
                    variant: 'warning',
                });
                navigate("/");
            } else {
                toggleTopbarTitle(`عرض بيانات ${result.data[0].userType}`)
                setIsOwner(result.data[0].userType !== "ممثل")
            }
        });
    }, [])
    return (
        <StyledViewOwner>
            <Container fluid>
                <div className='tableHead'>
                    <AddNewButton
                        title={`تعديل ال${ownerData.userType}`}
                        url={`/owner-edit?id=${params.get('id')}`}
                        isLink={true}
                        hasIcon={false}
                    />
                </div>
                <Section1 ownerData={ownerData} />
                <Section2 ownerData={ownerData} />
                {isOwner ? '' : <Section3 ownerData={ownerData} />}
                <Section4 ownerData={ownerData} />
                <Section5 ownerData={ownerData} />
            </Container>
        </StyledViewOwner>
    );
}

export default ViewOwnerPage;