import React, { useContext, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';
import { Calendar } from 'react-date-range';
import moment from 'moment';
import NewFlexibleField from './NewFlexibleField';
import { ContractFinanceRecordEditContext } from '../../ContractFinanceRecordEditContext';
import AddNewButton from '../../../../../pages-parts/inputs/add-new-btn/AddNewButton';


const StyledFlexible = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    padding: 1rem 1.75rem;
    background-color: ${({ theme }) => theme.colors.background.body};
    border-radius: 0.75rem;
    position: relative;
    border: 1px solid #ccc;

    .box{
        padding: .5rem;
        border: 1px solid #ccc;
        margin-bottom: 1rem;

        &:last-child{
            margin-bottom: 0;
        }

        &.checked{
            border: 2px solid #787878;
            background-color: ${({ theme }) => theme.colors.background.elements};
        }
    }
    
    .data-line{
        margin-bottom: 1rem;

        .calender-input{
            position: relative;
            p{
                height: 32px;
                padding: 0.25rem 1rem;
                border: 1px solid #ced4da;
                border-radius: .375rem;
                color: ${({ theme }) => theme.colors.text.secondary};
                line-height: 1.5;
                cursor: pointer;
            }

            .datepicker-container{
                position: absolute;
                top: 100%;
                right: 0;
                background-color: #fff;
                z-index: 3;
                direction: ltr;
            }
        }
    }
    
    .infoData{
        width: 12rem !important;
        position: relative;

        &::after{
            display: flex;
            content: "SAR";
            position: absolute;
            height: 100%;
            align-items: center;
            right: 0;
            top: 0;
            font-weight: 900;
            width: 50px;
            justify-content: center;
            border-left: 1px solid #ccc;
        }

        .myInput{
            padding-right: 55px!important;
        }

    }
    .infoDataSwitch{
    }
    span img{
        width: 6rem;
        height: 6rem;
        border-radius: 0.75rem;
    }
`;

const Flexible = () => {
    const {
        firstAppointmentDate,
        setFirstAppointmentDate,
        firstValue,
        setFirstValue,
        additionalAppointment,
        setAdditionalAppointment,
    } = useContext(ContractFinanceRecordEditContext);


    const handleClickWindow = () => {
        const eles = document.getElementsByClassName('datepicker-container')
        Array.prototype.forEach.call(eles, ele => {
            ele.classList.add("d-none");
        })
    }
    const toggleCalender = e => {
        e.stopPropagation();
        const eles = document.getElementsByClassName('datepicker-container')
        Array.prototype.forEach.call(eles, ele => {
            ele.classList.add("d-none");
        })
        document.getElementById("first-appointment-date").classList.toggle("d-none");

    }
    const handleChangingDate = e => {
        e.stopPropagation();
    }
    useEffect(() => {
        const eles = document.getElementsByClassName('datepicker-container')
        Array.prototype.forEach.call(eles, ele => {
            ele.classList.add("d-none");
        })
    }, [firstAppointmentDate]);
    useEffect(() => {
        window.addEventListener('click', handleClickWindow);

        return function () {
            window.removeEventListener('click', handleClickWindow)
        }
    }, []);

    const handleChangefirstAppointmentValue = e => {
        setFirstValue(prev => {
            e.target.value = e.target.value.replace(/([^0-9])/g, '');
            return (e.target.value.replace(/([^0-9])/g, ''));
        })
    }

    // add new appointment


    // inputName, value, setValue, date, setDate, removeField
    const addNewService = () => {
        setAdditionalAppointment(prev => {
            return [
                ...prev,
                {
                    inputName: `new-appointment-${moment().format()}-${prev.length}`,
                    value: '',
                    date: new Date(),
                }
            ]
        })
    }
    const setNewAppointmentValue = (value, inputName) => {
        setAdditionalAppointment(prev => {
            return prev.filter(item => {
                if (item.inputName === inputName) {
                    item.value = value
                    return item;
                } else {
                    return item;
                }
            })
        })
    }
    const setNewAppointmentDate = (date, inputName) => {
        setAdditionalAppointment(prev => {
            return prev.filter(item => {
                if (item.inputName === inputName) {
                    item.date = date
                    return item;
                } else {
                    return item;
                }
            })
        })
    }
    const removeField = (fieldName) => {
        setAdditionalAppointment(prev => {
            return prev.filter(item => item.inputName !== fieldName)
        })
    }

    return (
        <StyledFlexible>
            <div className='data-line'>
                <span className='infoTitle calender-input numbers-font'>
                    <p className="mb-0" onClick={toggleCalender}>{moment(firstAppointmentDate).format('ll')}</p>
                    <div
                        className='datepicker-container'
                        onClick={handleChangingDate}
                        id="first-appointment-date"
                    >
                        <Calendar
                            onChange={item => setFirstAppointmentDate(item)}
                            date={firstAppointmentDate}
                        />
                    </div>
                </span>
                <span className='infoData'>
                    <Form.Control
                        className='py-1 px-3 myInput'
                        type='text'
                        value={firstValue}
                        name='first-appointment'
                        onChange={handleChangefirstAppointmentValue}
                    />
                </span>
            </div>

            {additionalAppointment.map(item => (
                <NewFlexibleField
                    key={item.inputName}
                    inputName={item.inputName}
                    value={item.value}
                    setValue={(value) => setNewAppointmentValue(value, item.inputName)}
                    date={item.date}
                    setDate={(date) => setNewAppointmentDate(date, item.inputName)}
                    removeField={removeField}
                />
            ))}

            <AddNewButton
                onClickFunction={addNewService}
                title="إضافة بند أخر"
            />
        </StyledFlexible>
    );
}

export default Flexible;