import styled from "styled-components"
import { TfiAngleDown } from 'react-icons/tfi';
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { DateRange } from 'react-date-range';
import Moment from "react-moment";
import moment from "moment";

const StyledDatePicker = styled.div`
    width: 100%;
    min-width: 15rem;
    max-width: 15rem;
    border: 2px solid ${({ theme }) => theme.colors.secondary};
    border-radius: 0.75rem;
    background-color: ${({ theme }) => theme.colors.secondary};
    margin-right: .5rem;
    position: relative;
    display: flex;
    padding: 0.3125rem .5rem;
    align-items: center;

    .options-container{
        position: absolute;
        background-color: ${({ theme }) => theme.colors.background.elements};
        top: calc(100% + .75rem);
        border: 2px solid #ddd;
        z-index: 3;
        font-size: 15px;
        line-height: normal;
        color: ${({ theme }) => theme.colors.text.primary};

        &.static-ranges{
            width: 8.75rem;
            &.left-zero{
                left: 0;
            }
            &.right-zero{
                right: 0;
            }
        }
        &.dynamic-ranges{
            width: auto;
            
            direction: ltr;

            &.left-zero{
                left: 8.75rem;
            }
            &.right-zero{
                right: 8.75rem;
            }
            .rdrDateDisplay{
                margin: 0.25em 0.833em;
            }
            .rdrMonthAndYearWrapper{
                height: auto;
                padding-top: 0;
            }
            .rdrMonth{
                padding: 0 0.833em;
            }
            .rdrMonthName{
                padding: 0 0.833em;
            }
        }
        
        

        .option{
            padding: 0.5rem .75rem;

            &:hover{
                background-color: #eee;
            }
            &.active{
                background-color: #08c;
                color: #fff;
            }
        }
    }
    .display-text{
        color: #fff;
        text-align: center;
        font-size: 14px;
        width: 100%;
    }
    .icon{
        color: #fff;
        position: absolute;
        left: 5%;
        top: calc(50% - .5rem);
        font-size: 14px;
    }
`
// {dateFilter, setDateFilter, setPageNum}
const DateFilter = ({ setPageNum, dateType, setDateType, onLeft = true, setDateFilter, dateFilter }) => {
    const [showOptions, setShowOptions] = useState(false);
    const [showCustomRangePicker, setShowCustomRangePicker] = useState(false);
    const [displayText, setDisplayText] = useState('');
    const [customDateValue, setCustomDateValue] = useState([
        {
            startDate: new Date(),
            endDate: new Date(moment().subtract(10, "days")),
            key: 'selection'
        }
    ]);

    const options = [
        {
            id: 1,
            title: 'الكل',
            value: "all",
        },
        {
            id: 2,
            title: 'اليوم',
            value: "today",
        },
        {
            id: 3,
            title: 'الأمس',
            value: "yesterday",
        },
        {
            id: 4,
            title: 'اخر 7 أيام',
            value: "last-7-days",
        },
        {
            id: 5,
            title: 'اخر 30 يوم',
            value: "last-30-days",
        },
        {
            id: 6,
            title: 'هذا الشهر',
            value: "this-month",
        },
        {
            id: 7,
            title: 'الشهر الماضى',
            value: "last-month",
        },
        {
            id: 8,
            title: 'أختر الفترة',
            value: "custom",
        },
    ]

    useEffect(() => {
        window.addEventListener('click', handleClickWindow);

        return function () {
            window.removeEventListener('click', handleClickWindow)
        }
    }, []);

    useEffect(() => {
        setDisplayText(() => {
            switch (dateType) {
                case 'all':
                    return 'الكل'
                    break;
                case 'today':
                    return (<span className="numbers-font">{moment().format('ll')}</span>)
                    break;
                case 'yesterday':
                    return (<span className="numbers-font">{moment().subtract(1, 'days').format('ll')}</span>)
                    break;
                case 'last-7-days':
                    return (
                        <span className="numbers-font">
                            {moment().subtract(7, 'days').format('ll')} - {moment().format('ll')}
                        </span>)
                    break;
                case 'last-30-days':
                    return (<span className="numbers-font">
                        {moment().subtract(30, 'days').format('ll')} - {moment().format('ll')}
                    </span>)
                    return
                    break;
                case 'this-month':
                    return (
                        <span className="numbers-font">
                            {moment().endOf('month').format('ll')} - {moment().startOf('month').format('ll')}
                        </span>)
                    return
                    break;
                case 'last-month':
                    return (
                        <span className="numbers-font">
                            {
                                moment(moment().subtract(1, 'month')).endOf('month').format('ll')
                            } - {
                                moment(moment().subtract(1, 'month')).startOf('month').format('ll')
                            }
                        </span>
                    )
                    break;
                case 'custom':

                    return (
                        <span className="numbers-font">
                            {moment(customDateValue[0].endDate).format('ll')} - {moment(customDateValue[0].startDate).format('ll')}
                        </span>
                    )
                    break;
                default:
                    return 'الكل'
            }

        });
    }, [dateType, dateFilter])

    const handleClickWindow = e => {
        setShowOptions(false);
        setShowCustomRangePicker(false);
    }
    const handleClickSelect = e => {
        e.stopPropagation();
        setShowOptions(prev => {

            setShowCustomRangePicker(!prev && dateType === "custom");

            return !prev
        });
    }

    const handleChangeDataType = e => {
        e.stopPropagation();
        const optionValue = e.target.dataset['value'];
        localStorage.setItem('date-type-filter', JSON.stringify(optionValue));
        setDateType(prev => {
            if (prev !== optionValue) {
                setPageNum(1);
            }
            return optionValue
        });
        setShowCustomRangePicker(optionValue === "custom");
        if (optionValue !== "custom") {
            setShowOptions(false);
        }
    }

    const handleChangingCustomRange = (e) => {
        e.stopPropagation();
        setDateFilter({
            start: moment(customDateValue[0].startDate).format(),
            end: moment(customDateValue[0].endDate).format()
        });
        setShowCustomRangePicker(false);
        setShowOptions(false);
    }
    return (
        <StyledDatePicker onClick={handleClickSelect}>
            <div className={
                `
                    options-container
                    static-ranges
                    ${onLeft ? 'left-zero' : 'right-zero'}
                    ${showOptions ? 'd-block' : 'd-none'}
                    ${showCustomRangePicker && onLeft ? 'border-start-0' : ''}
                    ${showCustomRangePicker && !onLeft ? 'border-end-0' : ''}
                `
            }>
                {
                    options.map(item => (
                        <div
                            className={`option ${item.value === dateType ? 'active' : ''}`}
                            data-value={item.value}
                            key={item.id}
                            onClick={handleChangeDataType}
                        >
                            {item.title}
                        </div>
                    ))
                }
            </div>
            <div
                className={
                    `
                    options-container
                    dynamic-ranges
                    ${onLeft ? 'left-zero' : 'right-zero'}
                    ${showCustomRangePicker ? 'd-block' : 'd-none'}
                `
                }
                onClick={e => e.stopPropagation()}
            >
                <DateRange
                    editableDateInputs={true}
                    onChange={item => setCustomDateValue([item.selection])}
                    moveRangeOnFirstSelection={false}
                    months={1}
                    direction="horizontal"
                    rangeColors={["#135846"]}
                    ranges={customDateValue}
                />
                <Row className="justify-content-center">
                    <Col sm="6">
                        <div className="btn btn-success w-100 my-2" onClick={handleChangingCustomRange}>
                            ارسال
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="display-text">{displayText}</div>
            <TfiAngleDown className="icon" />
        </StyledDatePicker>
    )
}

export default DateFilter;