import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { AiOutlineEye } from 'react-icons/ai';
import { BsFillHouseDoorFill, BsUpload } from 'react-icons/bs';
import { IoPricetagOutline } from 'react-icons/io5';
import { FaRegBuilding, FaRegEdit } from 'react-icons/fa';
import Moment from 'react-moment';
import TableComponent from '../../pages-parts/table/TableComponent';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import TabsFilter from '../../pages-parts/table/TabsFilter';
import SearchBarFilter from '../../pages-parts/table/SearchFilter';
import DateFilter from '../../pages-parts/table/DateFilter';
import moment from 'moment';
import Entries from '../../pages-parts/table/Entries';
import SelectFilter from '../../pages-parts/table/SelectFilter';


const StyledContractsExpireSoon = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    padding: 2.5rem 1.75rem;
    .scroll-thumb{
        background-color: ${({ theme }) => theme.colors.primary_8};
    }
`;

const ContractsExpireSoonPage = () => {
    const location = useLocation();
    const [columns, setColumns] = useState([
        {
            id: 1,
            Header: "#",
            Cell: e => {
                return <span className='numbers-font'>{e.value}</span>
            },
            accessor: "number",
            sortable: true,
        },
        {
            id: 2,
            Header: "العقود",
            accessor: "contractName",
            sortable: true,
            Cell: e => {
                if (e.row.cells.find(cell => cell.column.Header === "نوع العقد").value === "سكني") {
                    return (
                        <Link to={`/contracts-view?id=${e.row.cells.find(cell => cell.column.Header === "الاجراءات").value}`} className='d-flex align-items-center icon-header-text-link'>
                            <BsFillHouseDoorFill className='icon' />
                            <span>
                                <h4>
                                    {e.value}
                                </h4>
                                <span className="numbers-font"><span>#</span> {e.row.cells.find(cell => cell.column.Header === "الاجراءات").value.replace(/(-.*)/g, "")}...</span>
                            </span>
                        </Link>
                    )
                } else {
                    return (
                        <Link to={`/contracts-view?id=${e.row.cells.find(cell => cell.column.Header === "الاجراءات").value}`} className='d-flex align-items-center icon-header-text-link'>
                            <FaRegBuilding className='icon' />
                            <span>
                                <h4>
                                    {e.value}
                                </h4>
                                <span className="numbers-font"><span>#</span> {e.row.cells.find(cell => cell.column.Header === "الاجراءات").value.replace(/(-.*)/g, "")}...</span>
                            </span>
                        </Link>
                    )
                }
            }
        },
        {
            id: 3,
            Header: "تاريخ الانشاء",
            accessor: "createDate",
            sortable: true,
            Cell: e => {
                const date = new Date(e.value);
                return (
                    <span className='numbers-font'>
                        <Moment date={date} format="YYYY/MM/DD" />
                    </span>
                )
            }
        },
        {
            id: 4,
            Header: "المالك",
            accessor: "owner",
            sortable: true
        },
        {
            id: 5,
            Header: "المستأجر",
            accessor: "Tenant",
            sortable: true
        },
        {
            id: 6,
            Header: "نوع العقد",
            accessor: "contractType",
            sortable: true
        },
        {
            id: 7,
            Header: "حالة العقد",
            accessor: "contractState",
            sortable: true
        },
        {
            id: 8,
            Header: "حالة الدفع",
            accessor: "paymentState",
            sortable: true
        },
        {
            id: 9,
            Header: "الاجراءات",
            accessor: "id",
            sortable: false,
            Cell: e => {
                return (
                    <>
                        <OverlayTrigger
                            key="view"
                            placement="bottom"
                            overlay={
                                <Tooltip id={`tooltip-view`}>
                                    عرض بيانات العقد
                                </Tooltip>
                            }
                        >
                            <Link to={`/contracts-view?id=${e.value}`}><AiOutlineEye /></Link>
                        </OverlayTrigger>
                        <OverlayTrigger
                            key="export"
                            placement="bottom"
                            overlay={
                                <Tooltip id={`tooltip-export`}>
                                    تصدير بيانات العقد
                                </Tooltip>
                            }
                        >
                            <Link to={`/contracts-export?id=${e.value}`}><BsUpload /></Link>
                        </OverlayTrigger>
                    </>
                )
            }
        },
    ]);
    const [searchTable, setSearchTable] = useState(() => {
        const filterStorage = JSON.parse(localStorage.getItem(location.pathname + '-filters')) ?? null;
        if (filterStorage !== null && filterStorage.search !== undefined) {
            return filterStorage.search;
        } else {
            return '';
        }
    });
    const [sortTable, setSortTable] = useState(() => {
        const filterStorage = JSON.parse(localStorage.getItem(location.pathname + '-filters')) ?? null;
        if (filterStorage !== null && filterStorage.sortFilter !== undefined) {
            return filterStorage.sortFilter;
        } else {
            return {
                column: '',
                direction: ''
            };
        }
    });
    const [tabFilter, setTabFilter] = useState(() => {
        const filterStorage = JSON.parse(localStorage.getItem(location.pathname + '-filters')) ?? null;
        if (filterStorage !== null && filterStorage.tabFilter !== undefined) {
            return filterStorage.tabFilter;
        } else {
            return {
                column: '',
                value: ''
            };
        }
    });
    const [ownerSelectFilter, setOwnerSelectFilter] = useState(() => {
        const filterStorage = JSON.parse(localStorage.getItem(location.pathname + '-filters')) ?? null;
        if (
            filterStorage !== null &&
            filterStorage.ownerSelectFilter !== undefined &&
            filterStorage.ownerSelectFilter?.value
        ) {
            return {
                type: 'api',
                url: '/owners?',
                value: filterStorage.ownerSelectFilter,
                column: 'ownerOrRepresentative',
                name: 'ownerSelectFilter',
            };
        } else {
            return {
                type: 'api',
                url: '/owners?',
                value: null,
                column: 'ownerOrRepresentative',
                name: 'ownerSelectFilter',
            };
        }
    });
    const [tenantsSelectFilter, setTenantsSelectFilter] = useState(() => {
        const filterStorage = JSON.parse(localStorage.getItem(location.pathname + '-filters')) ?? null;
        if (
            filterStorage !== null &&
            filterStorage.tenantsSelectFilter !== undefined &&
            filterStorage.tenantsSelectFilter?.value
        ) {
            return {
                type: 'api',
                url: '/tenants?',
                value: filterStorage.tenantsSelectFilter,
                column: 'tenantOrRepresentative',
                name: 'tenantsSelectFilter',
            };
        } else {
            return {
                type: 'api',
                url: '/tenants?',
                value: null,
                column: 'tenantOrRepresentative',
                name: 'tenantsSelectFilter',
            };
        }
    });
    const [pageNum, setPageNum] = useState(() => {
        return JSON.parse(localStorage.getItem(location.pathname + '-page-num')) ?? 1;
    });
    const [dateType, setDateType] = useState(() => {
        return JSON.parse(localStorage.getItem('date-type-filter')) ?? 'all';
    });
    const [dateFilter, setDateFilter] = useState(() => {
        const filterStorage = JSON.parse(localStorage.getItem(location.pathname + '-filters')) ?? null;
        if (filterStorage !== null && filterStorage.dateFilter !== undefined) {
            return filterStorage.dateFilter;
        } else {
            return {
                start: moment().subtract(1, 'months').startOf('month').format(),
                end: moment().format()
            };
        }
    });

    const getDateTypeUrlText = type => {
        let dateFilterText = '';
        switch (type) {
            case 'all':
                dateFilterText = '';
                break;
            case 'today':
                dateFilterText = `createDate_gte=${moment().startOf('day').format()}&createDate_lte=${moment().endOf('day').format()}&`;
                break;
            case 'yesterday':
                dateFilterText = `createDate_gte=${moment().subtract(1, 'days').startOf('day').format()}&createDate_lte=${moment().subtract(1, 'days').endOf('day').format()}&`;
                break;
            case 'last-7-days':
                dateFilterText = `createDate_gte=${moment().subtract(7, 'days').startOf('day').format()}&createDate_lte=${moment().endOf('day').format()}&`;
                break;
            case 'last-30-days':
                dateFilterText = `createDate_gte=${moment().subtract(30, 'days').startOf('day').format()}&createDate_lte=${moment().endOf('day').format()}&`;
                break;
            case 'this-month':
                dateFilterText = `createDate_gte=${moment().startOf('month').format()}&createDate_lte=${moment().endOf('month').format()}&`;
                break;
            case 'last-month':
                dateFilterText = `createDate_gte=${moment().subtract(1, 'months').startOf('month').format()}&createDate_lte=${moment().subtract(1, 'months').endOf('month').format()}&`;
                break;
            case 'custom':
                dateFilterText = dateFilter.start !== undefined && dateFilter.end !== undefined ? `createDate_gte=${dateFilter.start}&createDate_lte=${dateFilter.end}&` : "";
                break;
            default:
                dateFilterText = '';
                break;
        }

        return dateFilterText;
    }

    const [entries, setEntries] = useState(() => {
        return JSON.parse(localStorage.getItem('entries-per-page')) ?? 10;
    })
    const [tableDataUrl, setTableDataUrl] = useState(() => {
        // tab filter url part
        let tabFilterText;
        if (tabFilter.value !== "" && tabFilter.value !== undefined) {
            tabFilterText = `${tabFilter.column}=${tabFilter.value}&`;
        } else {
            tabFilterText = "";
        }

        // sort filter url part
        let sortFilter;
        if (sortTable.column !== "" && sortTable.column !== undefined) {
            sortFilter = `_sort=${sortTable.column}&_order=${sortTable.direction}&`;
        } else {
            sortFilter = "";
        }

        const url = `/items?contractState=مكتمل&${tabFilterText}${sortFilter}${getDateTypeUrlText(dateType)}q=${searchTable}&_page=${pageNum}&_limit=${entries}`;

        return url;
    })

    useEffect(() => {
        // tab filter url part
        let tabFilterText;
        if (tabFilter.value !== "" && tabFilter.value !== undefined) {
            tabFilterText = `${tabFilter.column}=${tabFilter.value}&`;
        } else {
            tabFilterText = "";
        }

        // sort filter url part
        let sortFilter;
        if (sortTable.column !== "" && sortTable.column !== undefined) {
            sortFilter = `_sort=${sortTable.column}&_order=${sortTable.direction}&`;
        } else {
            sortFilter = "";
        }

        const url = `/items?contractState=مكتمل&${tabFilterText}${sortFilter}${getDateTypeUrlText(dateType)}q=${searchTable}&_page=${pageNum}&_limit=${entries}`;
        setTableDataUrl(url);

    }, [searchTable, sortTable, tabFilter, dateFilter, pageNum, dateType, entries]);


    useEffect(() => {
        const localFilter = {
            tabFilter: tabFilter,
            sortFilter: sortTable,
            search: searchTable,
            dateFilter: dateFilter,
        }
        localStorage.setItem(location.pathname + '-page-num', pageNum);
        localStorage.setItem('entries-per-page', entries);
        localStorage.setItem(location.pathname + '-filters', JSON.stringify(localFilter));
    }, [tableDataUrl]);
    return (
        <StyledContractsExpireSoon>
            <Container fluid>
                <Row className='mb-2 flex-lg-row-reverse flex-md-row'>
                    <Col
                        lg={8}
                        md={12}
                        className='d-flex justify-content-end align-items-center mb-2'
                    >
                        <Entries
                            entries={entries}
                            setEntries={setEntries}
                        />
                        <SelectFilter
                            selectFilter={ownerSelectFilter}
                            setSelectFilter={setOwnerSelectFilter}
                            label="المالك"
                        />
                        <SelectFilter
                            selectFilter={tenantsSelectFilter}
                            setSelectFilter={setTenantsSelectFilter}
                            label="المستأجر"
                        />
                        <SearchBarFilter
                            setSearchTable={setSearchTable}
                            searchTable={searchTable}
                            setPageNum={setPageNum}
                        />
                        <DateFilter
                            dateFilter={dateFilter}
                            setDateFilter={setDateFilter}
                            dateType={dateType}
                            setDateType={setDateType}
                            setPageNum={setPageNum}
                        />
                    </Col>
                    <Col
                        lg={4}
                        md={12}
                        className='d-flex justify-content-start align-items-center'
                    >
                        <TabsFilter
                            tabFilters={
                                {
                                    columnName: "contractType",
                                    tabs: [
                                        {
                                            id: 1,
                                            name: 'جميع العقود',
                                            value: '',
                                        },
                                        {
                                            id: 2,
                                            name: 'سكني',
                                            value: 'سكني',
                                        },
                                        {
                                            id: 3,
                                            name: 'تجاري',
                                            value: 'تجاري',
                                        },
                                    ]
                                }
                            }
                            setTabFilter={setTabFilter}
                            setPageNum={setPageNum}
                        />
                    </Col>
                </Row>
                <TableComponent
                    columns={columns}
                    tableDataUrl={tableDataUrl}
                    setSortTable={setSortTable}
                    tableWidth={1000}
                    setPageNum={setPageNum}
                    pageNum={pageNum}
                    tableRowsPerPage={entries}
                />
            </Container>
        </StyledContractsExpireSoon>
    );
}

export default ContractsExpireSoonPage;