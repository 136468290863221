import React, { useContext, useEffect } from 'react';
import { json, Outlet, useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { AlertContext } from '../../../context/AlertContext';
import { TenantDetailsEditContext, TenantDetailsEditContextProvider } from './edit/TenantDetailsEditContext';
import Topbar from './TenantHeader';

const StyledTenantPagesContainer = styled.div`
    position: relative;
    width: 100vw;
    height: 100vh;
    background-color: ${({ theme }) => theme.colors.background.body};
`;

const TenantPagesContent = () => {
    const [params, setParams] = useSearchParams();
    const navigate = useNavigate();
    const alertContext = useContext(AlertContext);
    const { getTenantData } = useContext(TenantDetailsEditContext);

    useEffect(() => {
        const tenantAccess = JSON.parse(sessionStorage.getItem('tenant-access'));

        if (tenantAccess === undefined || tenantAccess === null) {
            alertContext.toggleAlert(true);
            alertContext.toggleAlertData({
                heading: 'لم يتم ادخال كود التعريف',
                message: 'من فضلك قم بأدخال كود التعريف الذي تم أرسالة الي جوالك أولا',
                variant: 'warning',
            });
            navigate('/login');
        }else{
            getTenantData(params.get('id'));
        }
    }, []);

    return(
        <Outlet />
    )
}

const TenantPagesContainer = () => {

    return (
        <TenantDetailsEditContextProvider>
            <StyledTenantPagesContainer>
                <Topbar />
                <TenantPagesContent />
            </StyledTenantPagesContainer>
        </TenantDetailsEditContextProvider>
    );
}

export default TenantPagesContainer;