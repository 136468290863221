import React, { useContext, useEffect, useRef, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';
import Select from 'react-select';
import { Calendar } from 'react-date-range';
import moment from 'moment';
import StaticSelect from '../../../../pages-parts/inputs/static-select-input/StaticSelect';
import DatePickerInput from '../../../../pages-parts/inputs/date-picker/DatePickerInput';
import NumberInput from '../../../../pages-parts/inputs/number-input/NumberInput';
import TextInput from '../../../../pages-parts/inputs/text-input/TextInput';
import CustomFieldModal from '../../../../pages-parts/inputs/custom-field/CustomFieldModal';
import CustomFieldInput from '../../../../pages-parts/inputs/custom-field/CustomFieldInput';
import { ContractFinanceRecordEditContext } from '../ContractFinanceRecordEditContext';

const StyledSection4 = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    margin-bottom: 3rem;
    padding: 1rem 1.75rem;
    background-color: ${({ theme }) => theme.colors.background.elements};
    border-radius: 0.75rem;
    position: relative;
    border: 1px solid #ccc;

    
    
    .infoData{
        width: 12rem;
    }

    .customFieldRemoveIcon{
        margin-right: 1rem;

        .icon{
            cursor: pointer;
            &:hover{
                color: ${({ theme }) => theme.colors.delete};
            }
        }
    }
    span img{
        width: 6rem;
        height: 6rem;
        border-radius: 0.75rem;
    }
`;

const Section4 = () => {
    const {
        parkingFee,
        setParkingFee,
        sewageFee,
        setSewageFee,
        maintenanceFee,
        setMaintenanceFee,
        cleaningFee,
        setCleaningFee,
        securityFee,
        setSecurityFee,
        additionalFee,
        setAdditionalFee,
    } = useContext(ContractFinanceRecordEditContext);

    const addNewService = () => {
        setAdditionalFee(prev => {
            return [
                ...prev,
                {
                    title: document.querySelector('input[name=new-service-name]').value,
                    name: `new-service-${moment().format()}`,
                    value: document.querySelector('input[name=new-service-value]').value,
                }
            ]
        })
    }
    const setCustomServiceValue = (value, inputName) => {
        setAdditionalFee(prev => {
            return prev.filter(item => {
                if (item.name === inputName) {
                    item.value = value
                    return item;
                } else {
                    return item;
                }
            })
        })
    }
    const removeField = (fieldName) => {
        setAdditionalFee(prev => {
            return prev.filter(item => item.name !== fieldName)
        })
    }

    return (
        <StyledSection4>
            <h2 className='sectionFloatTitle'>رسوم خدمات الوحدة السنوية</h2>
            <Row className='mt-4'>
                <Col>
                    <>
                        <NumberInput
                            titleText='رسوم المواقف'
                            inputName='parking-fee'
                            value={parkingFee}
                            setValue={setParkingFee}
                        />
                        <NumberInput
                            titleText='رسوم الصرف الصحي'
                            inputName='sewage-fee'
                            value={sewageFee}
                            setValue={setSewageFee}
                        />
                        <NumberInput
                            titleText='رسوم الصيانة'
                            inputName='maintenance-fee'
                            value={maintenanceFee}
                            setValue={setMaintenanceFee}
                        />
                        <NumberInput
                            titleText='رسوم النظافة'
                            inputName='cleaning-fee'
                            value={cleaningFee}
                            setValue={setCleaningFee}
                        />
                        <NumberInput
                            titleText='رسوم الأمن'
                            inputName='security-fee'
                            value={securityFee}
                            setValue={setSecurityFee}
                        />
                    </>

                    {additionalFee.map(item => (
                        <CustomFieldInput
                            key={item.name}
                            titleText={item.title}
                            inputName={item.name}
                            value={item.value}
                            setValue={(value) => setCustomServiceValue(value, item.name)}
                            inputType="number"
                            removeField={() => removeField(item.name)}
                        />
                    ))}

                    <CustomFieldModal
                        text='إضافة خدمة أضافية'
                        modleContent={{
                            title: 'إضافة خدمة أضافية',
                            inputs: [
                                {
                                    inputType: 'text',
                                    inputName: 'new-service-name',
                                    inputPlaceholder: 'اسم الخدمة'
                                },
                                {
                                    inputType: 'number',
                                    inputName: 'new-service-value',
                                    inputPlaceholder: 'رسوم الخدمة'
                                },
                            ]
                        }}
                        handleSave={addNewService}
                    />
                </Col>
            </Row>
        </StyledSection4>
    );
}

export default Section4;