import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import { SidebarContext } from '../../../context/SidebarContext';

const StyledSidebarToggle = styled.div`
    position: absolute;
    height: 2.5rem;
    width: 1.625rem;
    top: ${(1.25 - (0.1875 / 2)) + "rem"};
    right: ${(3 - (1.625 / 2)) + "rem"};
    display: flex;
    align-items: center;
    cursor: pointer;

    span{
        display: inline-block;
        position: relative;
        background-color: ${({ theme, sidebarstate }) => sidebarstate.isOpen ? "#000" : theme.colors.primary};
        width: 1.625rem;
        height: 0.1875rem;
        transition: all .25s ease-in-out;
        border-radius: 0.25rem;

        &::before{
            display: block;
            content: '';
            position: absolute;
            transition: all .25s ease-in-out;
            border-radius: 0.1875rem;
            ${({ theme, sidebarstate }) => {
        if (sidebarstate.isOpen) {
            return css`
                            background-color: #000;
                            width: 1rem;
                            height: 0.1875rem;
                            bottom: -.5rem;
                            left: calc(100% - 1rem);
                        `
        } else {
            return css`
                            background-color: ${theme.colors.primary};
                            width: 0.8125rem;
                            height: 0.09375rem;
                            bottom: -0.1875rem;
                            left: -0.1875rem;
                            transform: rotate(45deg);
                        `
        }
    }
    }
        }
        &::after{
            display: block;
            content: '';
            position: absolute;
            transition: all .25s ease-in-out;
            border-radius: 0.1875rem;
            ${({ theme, sidebarstate }) => {
        if (sidebarstate.isOpen) {
            return css`
                            background-color: #000;
                            width: 1.625rem;
                            height: 0.1875rem;
                            top: -.5rem;
                            left: 0;
                        `
        } else {
            return css`
                            background-color: ${theme.colors.primary};
                            width: 0.8125rem;
                            height: 0.09375rem;
                            top: -0.2495rem;
                            left: -0.1875rem;
                            transform: rotate(-45deg);
                        `
        }
    }
    }
        }
    }

    &:hover span{
        &::before{
            ${({ sidebarstate }) => {
        if (sidebarstate.isOpen) {
            return css`
                            width: 1.625rem;
                            left: calc(100% - 1.625rem);
                        `
        }
    }
    }
        }
    }
`;

const SidebarToggle = () => {
    const sidebarState = useContext(SidebarContext);

    return (
        <StyledSidebarToggle sidebarstate={sidebarState} onClick={() => sidebarState.toggleSidebar()}>
            <span></span>
        </StyledSidebarToggle>
    );
}

export default SidebarToggle;