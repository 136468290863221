import React, { useContext } from 'react';
import styled from 'styled-components';
import { BsSearch, BsChatLeftText } from 'react-icons/bs';
import { BiBell } from 'react-icons/bi';
import { Link, useLocation } from 'react-router-dom';
import { TopbarContext } from '../../../context/TopbarContext';
import Logo from '../../../images/logo.png';

const StyledTopbar = styled.div`
    width: 100%;
    height: 5rem;
    background-color: ${({ theme }) => theme.colors.background.elements};
    
    .content{
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: calc(1024px + 5rem);
        padding: 0 2.5rem;
        margin: 0 auto;
    }
`;

const StyledTitle = styled.h2`
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    font-weight: 700;
    color: #000;
    text-transform: capitalize;
    margin-bottom: 0;
`



const StyledHeaderLogo = styled.div`
    width: 5rem;
    text-align: left;
    img{
        width: 4rem;
    }
`

const Topbar = () => {
    const location = useLocation();
    const { topbarTitle } = useContext(TopbarContext);
    return (
        <StyledTopbar>
            <div className='content'>
                <StyledTitle>
                    {
                        topbarTitle
                    }
                </StyledTitle>

                <StyledHeaderLogo>
                    <img src={Logo} className="logo" alt="logo" />
                </StyledHeaderLogo>
            </div>
        </StyledTopbar>
    );
}

export default Topbar;