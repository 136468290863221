import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { AiOutlineEye } from 'react-icons/ai';
import { FaClipboardList, FaFileContract } from 'react-icons/fa';
import { CiDollar } from 'react-icons/ci';
import { FaRegEdit } from 'react-icons/fa';
import TableComponent from '../../pages-parts/table/TableComponent';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import SearchBarFilter from '../../pages-parts/table/SearchFilter';
import Entries from '../../pages-parts/table/Entries';
import profileImage from '../../../images/1.png';
import SelectFilter from '../../pages-parts/table/SelectFilter';
import DateFilter from '../../pages-parts/table/DateFilter';
import moment from 'moment';
import Moment from 'react-moment';


const StyledBrokerAssestantsRequests = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    padding: 2.5rem 1.75rem;
    .scroll-thumb{
        background-color: ${({ theme }) => theme.colors.primary_8};
    }

    .unitsNum{
        display: flex;
        align-items: center;
        justify-content: center;

        span{
            width: 2.5rem;
            text-align: center;
            padding: 0.125rem 0;
            border: 1px solid #ccc;
        }
        .filled{
            color: ${({ theme }) => theme.colors.primary};
            border-right: none;
        }
    }
`;

const BrokerAssestantsRequestsPage = () => {
    const location = useLocation();
    const [columns, setColumns] = useState([
        {
            id: 1,
            Header: "#",
            Cell: e => {
                return <span className='numbers-font'>{e.value}</span>
            },
            accessor: "number",
            sortable: true,
        },
        {
            id: 2,
            Header: "مساعد الوسيط",
            accessor: "name",
            sortable: true,
            Cell: e => {
                return (
                    <Link to={`/broker-assistant-request-view?id=${e.row.cells.find(cell => cell.column.Header === "الاجراءات").value}`} className='d-flex align-items-center image-heading-text-link'>
                        <img src={profileImage} alt="Profile" />
                        <span>
                            <h4>
                                {e.value}
                            </h4>
                            <span className="numbers-font"><span>#</span> {e.row.cells.find(cell => cell.column.Header === "الاجراءات").value.replace(/(-.*)/g, "")}...</span>
                        </span>
                    </Link>
                )
            }
        },
        {
            id: 3,
            Header: "تم الارسال في",
            accessor: "sentAt",
            sortable: true,
            Cell: e => {
                const date = new Date(e.value);
                return <span className='numbers-font'><Moment date={date} format="YYYY/MM/DD MM:HH" /></span>
            }
        },
        {
            id: 4,
            Header: "المنطقة",
            accessor: "area",
            sortable: true
        },
        {
            id: 5,
            Header: "المدينة",
            accessor: "city",
            sortable: true
        },
        {
            id: 6,
            Header: "الحالة",
            accessor: "requestStatus",
            sortable: true
        },
        {
            id: 7,
            Header: "تم العرض في",
            accessor: "sentAt",
            sortable: true,
            Cell: e => {
                const date = new Date(e.value);
                return <span className='numbers-font'><Moment date={date} format="YYYY/MM/DD MM:HH" /></span>
            }
        },
        {
            id: 8,
            Header: "الاجراءات",
            accessor: "id",
            sortable: false,
            Cell: e => {
                return (
                    <>
                        <OverlayTrigger
                            key="view"
                            placement="bottom"
                            overlay={
                                <Tooltip id={`tooltip-view`}>
                                    عرض بيانات الطلب
                                </Tooltip>
                            }
                        >
                            <Link to={`/broker-assistant-request-view?id=${e.value}`}><AiOutlineEye /></Link>
                        </OverlayTrigger>
                    </>
                )
            }
        },
    ]);
    const [searchTable, setSearchTable] = useState(() => {
        const filterStorage = JSON.parse(localStorage.getItem(location.pathname + '-filters')) ?? null;
        if (filterStorage !== null && filterStorage.search !== undefined) {
            return filterStorage.search;
        } else {
            return '';
        }
    });
    const [sortTable, setSortTable] = useState(() => {
        const filterStorage = JSON.parse(localStorage.getItem(location.pathname + '-filters')) ?? null;
        if (filterStorage !== null && filterStorage.sortFilter !== undefined) {
            return filterStorage.sortFilter;
        } else {
            return {
                column: '',
                direction: ''
            };
        }
    });
    const [pageNum, setPageNum] = useState(() => {
        return JSON.parse(localStorage.getItem(location.pathname + '-page-num')) ?? 1;
    });
    const [requestStatusSelectFilter, setRequestStatusSelectFilter] = useState(() => {
        const filterStorage = JSON.parse(localStorage.getItem(location.pathname + '-filters')) ?? null;
        if (
            filterStorage !== null &&
            filterStorage.requestStatusSelectFilter !== undefined &&
            filterStorage.requestStatusSelectFilter?.value !== undefined
        ) {
            return {
                type: 'static',
                url: '',
                value: filterStorage.requestStatusSelectFilter,
                column: 'requestStatus',
                name: 'requestStatusSelectFilter',
                options: [
                    {
                        value: 'no-filter',
                        label: 'حالة الطلب',
                    },
                    {
                        value: 1,
                        label: "تحت المراجعة",
                    },
                    {
                        value: 2,
                        label: "موافقة",
                    },
                ],
            };
        } else {
            return {
                type: 'static',
                url: '',
                value: null,
                column: 'requestStatus',
                name: 'requestStatusSelectFilter',
                options: [
                    {
                        value: 'no-filter',
                        label: 'حالة الطلب',
                    },
                    {
                        value: 1,
                        label: "تحت المراجعة",
                    },
                    {
                        value: 2,
                        label: "موافقة",
                    },
                ],
            };
        }
    });
    const [paymentStatusSelectFilter, setPaymentStatusSelectFilter] = useState(() => {
        const filterStorage = JSON.parse(localStorage.getItem(location.pathname + '-filters')) ?? null;
        if (
            filterStorage !== null &&
            filterStorage.paymentStatusSelectFilter !== undefined &&
            filterStorage.paymentStatusSelectFilter?.label !== undefined &&
            filterStorage.paymentStatusSelectFilter?.value !== undefined
        ) {
            return {
                type: 'static',
                url: '',
                value: filterStorage.paymentStatusSelectFilter,
                column: 'paymentState',
                name: 'paymentStatusSelectFilter',
                options: [
                    {
                        value: 'no-filter',
                        label: 'حالة العرض',
                    },
                    {
                        value: 1,
                        label: "تم العرض",
                    },
                    {
                        value: 2,
                        label: "لم يتم العرض",
                    },
                ],
            };
        } else {
            return {
                type: 'static',
                url: '',
                value: null,
                column: 'paymentState',
                name: 'paymentStatusSelectFilter',
                options: [
                    {
                        value: 'no-filter',
                        label: 'حالة الدفع',
                    },
                    {
                        value: 1,
                        label: "تم الدفع",
                    },
                    {
                        value: 2,
                        label: "لم يتم الدفع",
                    },
                ],
            };
        }
    });
    const [areaSelectFilter, setAreaSelectFilter] = useState(() => {
        const filterStorage = JSON.parse(localStorage.getItem(location.pathname + '-filters')) ?? null;
        if (
            filterStorage !== null &&
            filterStorage.areaSelectFilter !== undefined &&
            filterStorage.areaSelectFilter?.value !== undefined
        ) {
            return {
                type: 'api',
                url: '/areas?',
                value: filterStorage.areaSelectFilter,
                column: 'area',
                name: 'areaSelectFilter',
            };
        } else {
            return {
                type: 'api',
                url: '/areas?',
                value: null,
                column: 'area',
                name: 'areaSelectFilter',
            };
        }
    });
    const [citiesSelectFilter, setCitiesSelectFilter] = useState(() => {
        const filterStorage = JSON.parse(localStorage.getItem(location.pathname + '-filters')) ?? null;
        if (
            filterStorage !== null &&
            filterStorage.citiesSelectFilter !== undefined &&
            filterStorage.citiesSelectFilter?.value !== undefined
        ) {
            return {
                type: 'api',
                url: '/cities?area_id=' + (areaSelectFilter.value?.value ?? '') + '&',
                value: filterStorage.citiesSelectFilter,
                column: 'city',
                name: 'citiesSelectFilter',
            };
        } else {
            return {
                type: 'api',
                url: '/cities?',
                value: null,
                column: 'city',
                name: 'citiesSelectFilter',
            };
        }
    });
    const [entries, setEntries] = useState(() => {
        return JSON.parse(localStorage.getItem('entries-per-page')) ?? 10;
    })
    const [dateType, setDateType] = useState(() => {
        return JSON.parse(localStorage.getItem('date-type-filter')) ?? 'all';
    });
    const [dateFilter, setDateFilter] = useState(() => {
        const filterStorage = JSON.parse(localStorage.getItem(location.pathname + '-filters')) ?? null;
        if (filterStorage !== null && filterStorage.dateFilter !== undefined) {
            return filterStorage.dateFilter;
        } else {
            return {
                start: moment().subtract(1, 'months').startOf('month').format(),
                end: moment().format()
            };
        }
    });
    const getDateTypeUrlText = type => {
        let dateFilterText = '';
        switch (type) {
            case 'all':
                dateFilterText = '';
                break;
            case 'today':
                dateFilterText = `sentAt_gte=${moment().startOf('day').format()}&sentAt_lte=${moment().endOf('day').format()}&`;
                break;
            case 'yesterday':
                dateFilterText = `sentAt_gte=${moment().subtract(1, 'days').startOf('day').format()}&sentAt_lte=${moment().subtract(1, 'days').endOf('day').format()}&`;
                break;
            case 'last-7-days':
                dateFilterText = `sentAt_gte=${moment().subtract(7, 'days').startOf('day').format()}&sentAt_lte=${moment().endOf('day').format()}&`;
                break;
            case 'last-30-days':
                dateFilterText = `sentAt_gte=${moment().subtract(30, 'days').startOf('day').format()}&sentAt_lte=${moment().endOf('day').format()}&`;
                break;
            case 'this-month':
                dateFilterText = `sentAt_gte=${moment().startOf('month').format()}&sentAt_lte=${moment().endOf('month').format()}&`;
                break;
            case 'last-month':
                dateFilterText = `sentAt_gte=${moment().subtract(1, 'months').startOf('month').format()}&sentAt_lte=${moment().subtract(1, 'months').endOf('month').format()}&`;
                break;
            case 'custom':
                dateFilterText = dateFilter.start !== undefined && dateFilter.end !== undefined ? `sentAt_gte=${dateFilter.start}&sentAt_lte=${dateFilter.end}&` : "";
                break;
            default:
                dateFilterText = '';
                break;
        }

        return dateFilterText;
    }

    const [tableDataUrl, setTableDataUrl] = useState(() => {
        // sort filter url part
        let sortFilter;
        if (sortTable.column !== "" && sortTable.column !== undefined) {
            sortFilter = `_sort=${sortTable.column}&_order=${sortTable.direction}&`;
        } else {
            sortFilter = "";
        }

        // select filter url part
        // request status
        let requestStatusSelectFilterText;
        if (
            requestStatusSelectFilter.value !== {} &&
            requestStatusSelectFilter.value?.label !== '' &&
            requestStatusSelectFilter.value?.label !== undefined &&
            requestStatusSelectFilter.value?.label !== 'الكل' &&
            requestStatusSelectFilter.value?.value !== 'no-filter'
        ) {
            requestStatusSelectFilterText = `${requestStatusSelectFilter.column}=${requestStatusSelectFilter.value?.label}&`;
        } else {
            requestStatusSelectFilterText = "";
        }
        // payment status
        let paymentStatusSelectFilterText;
        if (
            paymentStatusSelectFilter.value !== {} &&
            paymentStatusSelectFilter.value?.label !== '' &&
            paymentStatusSelectFilter.value?.label !== undefined &&
            paymentStatusSelectFilter.value?.label !== 'الكل' &&
            paymentStatusSelectFilter.value?.value !== 'no-filter'
        ) {
            paymentStatusSelectFilterText = `${paymentStatusSelectFilter.column}=${paymentStatusSelectFilter.value?.label}&`;
        } else {
            paymentStatusSelectFilterText = "";
        }
        // area
        let areaSelectFilterText;
        if (
            areaSelectFilter.value !== {} &&
            areaSelectFilter.value?.label !== '' &&
            areaSelectFilter.value?.label !== 'الكل' &&
            areaSelectFilter.value?.value !== undefined
        ) {
            areaSelectFilterText = `${areaSelectFilter.column}=${areaSelectFilter.value?.label}&`;
        } else {
            areaSelectFilterText = "";
        }
        // city
        let citySelectFilterText;
        if (
            citiesSelectFilter.value !== {} &&
            citiesSelectFilter.value?.label !== '' &&
            citiesSelectFilter.value?.label !== 'الكل' &&
            citiesSelectFilter.value?.value !== undefined
        ) {
            citySelectFilterText = `${citiesSelectFilter.column}=${citiesSelectFilter.value?.label}&`;
        } else {
            citySelectFilterText = "";
        }
        let selectFilterText = requestStatusSelectFilterText + paymentStatusSelectFilterText + areaSelectFilterText + citySelectFilterText;

        const url = `/brokersRequests?${selectFilterText}${sortFilter}${getDateTypeUrlText(dateType)}q=${searchTable}&_page=${pageNum}&_limit=${entries}`;

        return url;
    })

    useEffect(() => {
        // sort filter url part
        let sortFilter;
        if (sortTable.column !== "" && sortTable.column !== undefined) {
            sortFilter = `_sort=${sortTable.column}&_order=${sortTable.direction}&`;
        } else {
            sortFilter = "";
        }

        // select filter url part
        // request status
        let requestStatusSelectFilterText;
        if (
            requestStatusSelectFilter.value !== {} &&
            requestStatusSelectFilter.value?.label !== '' &&
            requestStatusSelectFilter.value?.label !== 'الكل' &&
            requestStatusSelectFilter.value?.value !== 'no-filter' &&
            requestStatusSelectFilter.value?.value !== undefined
        ) {
            requestStatusSelectFilterText = `${requestStatusSelectFilter.column}=${requestStatusSelectFilter.value?.label}&`;
        } else {
            requestStatusSelectFilterText = "";
        }
        // payment status
        let paymentStatusSelectFilterText;
        if (
            paymentStatusSelectFilter.value !== {} &&
            paymentStatusSelectFilter.value?.label !== '' &&
            paymentStatusSelectFilter.value?.label !== 'الكل' &&
            paymentStatusSelectFilter.value?.value !== 'no-filter' &&
            paymentStatusSelectFilter.value?.value !== undefined
        ) {
            paymentStatusSelectFilterText = `${paymentStatusSelectFilter.column}=${paymentStatusSelectFilter.value?.label}&`;
        } else {
            paymentStatusSelectFilterText = "";
        }
        // area
        let areaSelectFilterText;
        if (
            areaSelectFilter.value !== {} &&
            areaSelectFilter.value?.label !== '' &&
            areaSelectFilter.value?.label !== 'الكل' &&
            areaSelectFilter.value?.value !== 'no-filter' &&
            areaSelectFilter.value?.value !== undefined
        ) {
            areaSelectFilterText = `${areaSelectFilter.column}=${areaSelectFilter.value?.label}&`;
        } else {
            areaSelectFilterText = "";
        }
        // city
        let citySelectFilterText;
        if (
            citiesSelectFilter.value !== {} &&
            citiesSelectFilter.value?.label !== '' &&
            citiesSelectFilter.value?.label !== 'الكل' &&
            citiesSelectFilter.value?.value !== 'no-filter' &&
            citiesSelectFilter.value?.value !== undefined
        ) {
            citySelectFilterText = `${citiesSelectFilter.column}=${citiesSelectFilter.value?.label}&`;
        } else {
            citySelectFilterText = "";
        }
        let selectFilterText = requestStatusSelectFilterText + paymentStatusSelectFilterText + areaSelectFilterText + citySelectFilterText;

        const url = `/brokersRequests?${selectFilterText}${sortFilter}${getDateTypeUrlText(dateType)}q=${searchTable}&_page=${pageNum}&_limit=${entries}`;
        setTableDataUrl(url);
    },
        [
            searchTable,
            sortTable,
            pageNum,
            entries,
            requestStatusSelectFilter.value,
            paymentStatusSelectFilter.value,
            areaSelectFilter.value,
            citiesSelectFilter.value,
            dateFilter,
            dateType
        ]);

    useEffect(() => {
        setCitiesSelectFilter(prev => {
            return {
                type: 'api',
                url: '/cities?area_id=' + areaSelectFilter.value?.value + '&',
                value: null,
                column: 'city',
                name: 'citiesSelectFilter'
            };
        })
    }, [areaSelectFilter.value])

    useEffect(() => {
        const localFilter = {
            search: searchTable,
            sortFilter: sortTable,
            requestStatusSelectFilter: requestStatusSelectFilter.value,
            paymentStatusSelectFilter: paymentStatusSelectFilter.value,
            areaSelectFilter: areaSelectFilter.value,
            citiesSelectFilter: citiesSelectFilter.value,
            dateFilter: dateFilter,
        }
        localStorage.setItem(location.pathname + '-filters', JSON.stringify(localFilter));
        localStorage.setItem(location.pathname + '-page-num', pageNum);
        localStorage.setItem('entries-per-page', entries);
    }, [tableDataUrl]);


    return (
        <StyledBrokerAssestantsRequests>
            <Container fluid>
                <Row className='mb-3 flex-lg-row-reverse flex-md-row justify-content-between'>
                    <Col
                        lg={6}
                        md={12}
                        className='d-flex justify-content-end align-items-center mb-2'
                    >
                        <Entries
                            entries={entries}
                            setEntries={setEntries}
                        />
                        <SearchBarFilter
                            setSearchTable={setSearchTable}
                            searchTable={searchTable}
                            setPageNum={setPageNum}
                        />
                        <DateFilter
                            dateFilter={dateFilter}
                            setDateFilter={setDateFilter}
                            dateType={dateType}
                            setDateType={setDateType}
                            setPageNum={setPageNum}
                        />
                    </Col>
                    <Col lg={6} md={12} className='d-flex'>
                        <SelectFilter
                            label="حالة الطلب"
                            selectFilter={requestStatusSelectFilter}
                            setSelectFilter={setRequestStatusSelectFilter}
                        />
                        <SelectFilter
                            label="حالة العرض"
                            selectFilter={paymentStatusSelectFilter}
                            setSelectFilter={setPaymentStatusSelectFilter}
                        />
                        <SelectFilter
                            label="المنطقة"
                            selectFilter={areaSelectFilter}
                            setSelectFilter={setAreaSelectFilter}
                        />
                        <SelectFilter
                            label="المدينة"
                            selectFilter={citiesSelectFilter}
                            setSelectFilter={setCitiesSelectFilter}
                        />
                    </Col>
                </Row>
                <TableComponent
                    columns={columns}
                    tableDataUrl={tableDataUrl}
                    setSortTable={setSortTable}
                    tableWidth={800}
                    setPageNum={setPageNum}
                    pageNum={pageNum}
                    tableRowsPerPage={entries}
                />
            </Container>
        </StyledBrokerAssestantsRequests>
    );
}

export default BrokerAssestantsRequestsPage;