import React, { useContext, useEffect, useRef, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';
import Select from 'react-select';
import { Calendar } from 'react-date-range';
import moment from 'moment';
import NumberInput from '../../../../../pages-parts/inputs/number-input/NumberInput';
import { ContractFinanceRecordEditContext } from '../../ContractFinanceRecordEditContext';


const StyledPercent = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    padding: 1rem 1.75rem;
    background-color: ${({ theme }) => theme.colors.background.body};
    border-radius: 0.75rem;
    position: relative;
    border: 1px solid #ccc;

    .box{
        padding: .5rem;
        border: 1px solid #ccc;
        margin-bottom: 1rem;

        &:last-child{
            margin-bottom: 0;
        }

        &.checked{
            border: 2px solid #787878;
            background-color: ${({ theme }) => theme.colors.background.elements};
        }
    }
    
    
    
    .infoData{
        width: 12rem !important;
        position: relative;

        &::after{
            display: flex;
            content: "%";
            position: absolute;
            height: 100%;
            align-items: center;
            right: 0;
            top: 0;
            font-weight: 900;
            width: 50px;
            justify-content: center;
            border-left: 1px solid #ccc;
        }
        .myInput{
            padding-right: 55px!important;
        }

    }
    span img{
        width: 6rem;
        height: 6rem;
        border-radius: 0.75rem;
    }
`;

const Percent = () => {
    const {
        percentTypeIncrease,
        setPercentTypeIncrease,
        increasePercent,
        setIncreasePercent,
        decreasePercent,
        setDecreasePercent,
    } = useContext(ContractFinanceRecordEditContext);

    const percentTypeIncreaseRef = useRef();

    const handleChange = (type) => {
        if (type === "increase") {
            setPercentTypeIncrease(true);
        } else {
            setPercentTypeIncrease(false);
        }
    }
    return (
        <StyledPercent>
            <div
                className={`box ${percentTypeIncrease ? 'checked' : ''}`}
                onClick={() => handleChange('increase')}
            >
                <Form.Check
                    className='me-5 mb-0'
                    type='radio'
                    id='increase'
                    checked={percentTypeIncrease}
                    name='increase-decrease'
                    label='نسبة الزيادة'
                    onChange={() => { }}
                    ref={percentTypeIncreaseRef}
                />

                <NumberInput
                    titleText='نسبة ذيادة السنة 2'
                    inputName='yearly-rent'
                    value={increasePercent[0]}
                    setValue={(e) => {
                        setIncreasePercent(prev => {
                            if (e() > 100) {
                                prev[0] = '100';
                            } else {
                                prev[0] = e();
                            }
                            return [
                                ...prev
                            ]
                        })
                    }}
                />
                <NumberInput
                    titleText='نسبة ذيادة السنة 3'
                    inputName='yearly-rent'
                    value={increasePercent[1]}
                    setValue={(e) => {
                        setIncreasePercent(prev => {
                            if (e() > 100) {
                                prev[1] = '100';
                            } else {
                                prev[1] = e();
                            }
                            return [
                                ...prev
                            ]
                        })
                    }}
                />
            </div>

            <div
                className={`box ${!percentTypeIncrease ? 'checked' : ''}`}
                onClick={() => handleChange('decrease')}
            >
                <Form.Check
                    className='me-5 mb-0'
                    type='radio'
                    id='decrease'
                    checked={!percentTypeIncrease}
                    onChange={() => { }}
                    name='increase-decrease'
                    label='نسبة النقصان'
                />

                <NumberInput
                    titleText='نسبة نقصان السنة 2'
                    inputName='yearly-rent'
                    value={decreasePercent[0]}
                    setValue={(e) => {
                        setDecreasePercent(prev => {
                            if (e() > 100) {
                                prev[0] = '100';
                            } else {
                                prev[0] = e();
                            }
                            return [
                                ...prev
                            ]
                        })
                    }}
                />
                <NumberInput
                    titleText='نسبة نقصان السنة 3'
                    inputName='yearly-rent'
                    value={decreasePercent[1]}
                    setValue={(e) => {
                        setDecreasePercent(prev => {
                            if (e() > 100) {
                                prev[1] = '100';
                            } else {
                                prev[1] = e();
                            }
                            return [
                                ...prev
                            ]
                        })
                    }}
                />
            </div>
        </StyledPercent>
    );
}

export default Percent;