import React, { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import Section1 from './section-1/Section-1';
import Scrollbars from 'react-custom-scrollbars-2';
import Section2 from './section-2/Section-2';




const StyledTenantDetailsView = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    padding: 2.5rem 1.75rem;

    .container-fluid > *:last-child {
        margin-bottom: 0;
    }

`;
const TenantDetailsView = () => {
    const [params, setParams] = useSearchParams();

    useEffect(() => {

    }, [])

    const handleSave = () => {
        console.log('hi');
    }
    return (
        <Scrollbars
            renderView={props => (
                <div {...props} style={{
                    ...props.style,
                    marginLeft: props.style.marginRight,
                    marginRight: 0,
                }} />
            )}
            renderTrackVertical={props => (
                <div {...props} style={{
                    ...props.style,
                    left: 2,
                    bottom: 20,
                    top: 20,
                    right: 'auto',
                    borderRadius: 3,
                }} />
            )}
            style={{ width: "100%", height: "calc(100vh - 5rem)" }}
        >
            <StyledTenantDetailsView>
                <Container fluid>
                    <Section1 />
                    <Section2 />
                </Container>
            </StyledTenantDetailsView>
        </Scrollbars>
    );
}

export default TenantDetailsView;