import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { SidebarContext } from '../../../../../context/SidebarContext';

const StyledSublistItem = styled.li`
    ${({ theme, sidebarstate, subMenuItemId }) => {
        if (!sidebarstate.isOpen) {
            return css`
                width: 100%;
                padding-top: 0.5rem;
                padding-bottom: 0.5rem;
                position: relative;
                font-size: 14px;

                & > a{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    color: ${sidebarstate.activeLink[1] === subMenuItemId ? theme.colors.primary : theme.colors.text.primary};
                }

                &:hover a{
                    color: ${theme.colors.primary};
                }
            `
        } else {
            return css`

            `;
        }
    }}
`;

const ClosedSubMenuItem = ({ listItemProp, subListItemProp }) => {
    const sidebarState = useContext(SidebarContext);

    return (
        <StyledSublistItem
            key={subListItemProp.id}
            onClick={() => sidebarState.toggleActiveLink(listItemProp, subListItemProp)}
            sidebarstate={sidebarState}
            subMenuItemId={subListItemProp.id}
        >
            <Link
                to={subListItemProp.link}
            >
                <span className='link-text'>
                    {subListItemProp.translate}
                </span>
            </Link>
        </StyledSublistItem>
    )
}

export default ClosedSubMenuItem;