import { Link } from "react-router-dom";
import styled from "styled-components";
import { AiOutlinePlusCircle } from 'react-icons/ai';

const StyledCreateLink = styled(Link)`
    padding: 0.25rem .5rem;
    border-radius: 0.5rem;
    white-space: nowrap;
    display: inline-flex;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.secondary};
    color: #fff;
    cursor: pointer;

    .icon{
        margin-left: .25rem;
    }
    span{
        white-space: nowrap;
    }
    &:hover{
        background-color: ${({ theme }) => theme.colors.secondary};
        filter: brightness(1.2);
    }
`

const CreateNewLink = ({ text, url }) => {

    return (
        <StyledCreateLink to={url}>
            <AiOutlinePlusCircle className="icon" />
            <span>
                {text}
            </span>
        </StyledCreateLink>
    )
}

export default CreateNewLink;