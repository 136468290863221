import React, { useContext, useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import styled from 'styled-components';
import { FiUpload } from 'react-icons/fi';
import { Link, useNavigate } from 'react-router-dom';
import Logo from '../../../images/logo.png';
import LogoText from '../../../images/logo-text.png';
import { AlertContext } from '../../../context/AlertContext';
import Loading from '../../pages-parts/loading/Loading';
import FlashMessage from '../../pages-parts/flash-message/FlashMessage';
import DynamicSelect from '../../pages-parts/inputs/dynamic-select-input/DynamicSelect';
import TextInput from '../../pages-parts/inputs/text-input/TextInput';
import EmailInput from '../../pages-parts/inputs/email-input/EmailInput';
import NumberInput from '../../pages-parts/inputs/number-input/NumberInput';
import DatePickerInput from '../../pages-parts/inputs/date-picker/DatePickerInput';

const StyledSendBrokerAssistantRequest = styled(Row)`
    position: relative;
    --bs-gutter-x: 0;
    width: 100vw;
    height: 100vh;
    overflow: auto;
    align-items: center;
    justify-content: center;

    .alert{
        position: fixed;
    }
    .content-container{
        margin: 1rem 0;

        .logoContainer{
            text-align: center;
            color: ${({ theme }) => theme.colors.primary};
            margin-bottom: 1rem;

            .logo, .logoText{
                width: 7rem;
            }
        }
        .login-form-card{
            border: none;

            h2{
                text-align: center;
                color: ${({ theme }) => theme.colors.text.secondary};
                font-size: 1.5rem;
                font-weight: 700;
            }

            button[type=submit]{
                background-color: ${({ theme }) => theme.colors.primary};
                color: #fff;
                font-size: 14px;
            }
            a{
                font-size: 14px;
                color: ${({ theme }) => theme.colors.text.secondary};
                &:hover{
                    color: ${({ theme }) => theme.colors.primary};
                }
            }
        }
    }

    .data-line{
        margin-top: 0!important;
    }
    .infoTitle{
        display: inline-block;
        min-width: 6rem;
        margin: 0.5rem 0 .25rem;
        font-size: 14px;
        color: ${({ theme }) => theme.colors.text.secondary};
    }
    .infoData{
        display: block;

    }
    .upload{
        margin-top: 0.5rem;
        label{
            cursor: pointer;
    
            &:hover{
                color: ${({ theme }) => theme.colors.primary}
            }
        }
    }
`;

const SendBrokerAssistantRequest = () => {
    const [isLoading, setIsLoading] = useState(false);
    const alertContext = useContext(AlertContext);
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [birthDate, setBirthDate] = useState(new Date());

    // المنطقة
    const [areaDBUrl, setAreaDBUrl] = useState('areas?');
    const [selectedArea, setSelectedArea] = useState({
        value: 'no-value',
        label: 'غير محدد',
    });
    const handleChangeSelectedAreaOption = (selectOption) => {
        setSelectedArea(selectOption)
    }

    // المدينة
    const [cityDBUrl, setCityDBUrl] = useState(`cities?area_id=${selectedArea.value}&`);
    const [selectedCity, setSelectedCity] = useState({
        value: 'no-value',
        label: 'غير محدد',
    });
    const handleChangeSelectedCityOption = (selectOption) => {
        setSelectedCity(selectOption)
    }

    useEffect(() => {
        setCityDBUrl(`cities?area_id=${selectedArea.value}&`)
        setSelectedCity({
            value: 'no-value',
            label: 'غير محدد',
        })
    }, [selectedArea])

    const handelSubmint = (e) => {
        e.preventDefault();

        if (
            fullName === '' ||
            email === '' ||
            phone === '' ||
            birthDate === '' ||
            selectedArea.value === 'no-value' ||
            selectedCity.value === 'no-value'
        ) {
            alertContext.toggleAlert(true);
            alertContext.toggleAlertData({
                heading: 'فشل الارسال',
                message: 'من فضلك تأكد من ادخال جميع البيانات المطلوبة',
                variant: 'danger',
            });
        } else {
            alertContext.toggleAlert(true);
            alertContext.toggleAlertData({
                heading: 'شكرا للطلب',
                message: 'سيتم التواصل معك في اقرب وقت',
                variant: 'success',
            });
        }
        setTimeout(() => setIsLoading(false), 1000);
    }

    return (
        <StyledSendBrokerAssistantRequest>
            {isLoading ? <Loading className="loading" /> : ''}
            {alertContext.showAlert ? <FlashMessage /> : ''}

            <Col xs='12' sm='9' md='6' lg='4' className='content-container'>
                <div className='logoContainer'>
                    <img src={Logo} className="logo" alt="logo" />
                    <img src={LogoText} className="logoText" alt="logo" />
                </div>
                <Card className='login-form-card'>
                    <Card.Body>
                        <h2>أرسال طلب مساعد وسيط</h2>
                        <Form onSubmit={handelSubmint}>
                            <TextInput
                                titleText='الاسم بالكامل'
                                inputName='full-name'
                                value={fullName}
                                setValue={setFullName}
                            />
                            <EmailInput
                                titleText='البريد الالكتروني'
                                inputName='email'
                                value={email}
                                setValue={setEmail}
                            />
                            <NumberInput
                                titleText='رقم الهاتف'
                                inputName='phone'
                                value={phone}
                                setValue={setPhone}
                            />
                            <DatePickerInput
                                titleText='تاريخ الميلاد'
                                date={birthDate}
                                setDate={setBirthDate}
                            />
                            <DynamicSelect
                                titleText='المنطقة'
                                selectedOption={selectedArea}
                                url={areaDBUrl}
                                column='area'
                                customStylesProp={{
                                    has: false,
                                    style: {}
                                }}
                                handleChangeSelect={handleChangeSelectedAreaOption}
                            />
                            <DynamicSelect
                                titleText='المدينة'
                                selectedOption={selectedCity}
                                url={cityDBUrl}
                                column='city'
                                customStylesProp={{
                                    has: false,
                                    style: {}
                                }}
                                handleChangeSelect={handleChangeSelectedCityOption}
                            />
                            <div className='upload'>
                                <label htmlFor="upload-image">
                                    <FiUpload /> الصورة الشخصية
                                </label>
                                <input
                                    type="file"
                                    className='d-none'
                                    id="upload-image"
                                />
                            </div>
                            <div className='upload'>
                                <label htmlFor="upload-education-qualification">
                                    <FiUpload /> المؤهل الدراسي
                                </label>
                                <input
                                    type="file"
                                    className='d-none'
                                    id="upload-education-qualification"
                                />
                            </div>
                            <Row className='mt-2'>
                                <Col md='12'>
                                    <Button variant="primary" type="submit" className='border-0'>
                                        أرسال
                                    </Button>
                                </Col>
                                <Col md='12' className='text-center my-0'>
                                    <Link to='/login'>
                                        تسجيل الدخول من جديد
                                    </Link>
                                </Col>
                            </Row>
                        </Form>
                    </Card.Body>
                </Card>
            </Col>
        </StyledSendBrokerAssistantRequest>
    );
}

export default SendBrokerAssistantRequest;