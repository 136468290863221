import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import styled, { css } from "styled-components";
import Section1 from "./sections/Section-1";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { AlertContext } from "../../../../../context/AlertContext";
import Section2 from "./sections/Section-2";
import Section3 from "./sections/Section-3";
import { UnitEditContext, UnitEditContextProvider } from "./UnitEditContext";

const StyledUnitEditPage = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    padding: 2.5rem 1.75rem;

`;

const UnitEditPageContent = () => {
    const [params, setParams] = useSearchParams();
    const {
        getUnitData
    } = useContext(UnitEditContext);

    useEffect(() => {
       getUnitData(params.get('unit_id'));
    }, [])

    const handleSave = () => {
        console.log('hi');
    }
    
    return(
        <StyledUnitEditPage>
            <Container fluid>
                <Section1 />
                <Section2 />
                <Section3 />

                <Row className="mt-3">
                    <Col className='d-flex align-items-center justify-content-end py-2'>
                        <Link to={`/unit-view?unit_id=${params.get('unit_id')}`} className="py-2 px-3 return-link me-2">
                            رجوع
                        </Link>
                        <span onClick={handleSave} className="py-2 px-3 save-link">
                            حفظ
                        </span>
                    </Col>
                </Row>
            </Container>
        </StyledUnitEditPage>
    )
}

const UnitEditPage = () => {
    return (
        <UnitEditContextProvider>
            <UnitEditPageContent />
        </UnitEditContextProvider>
    )
}

export default UnitEditPage;