import React, { useContext, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';
import moment from 'moment';
import StaticSelect from '../../../../../pages-parts/inputs/static-select-input/StaticSelect';
import DatePickerInput from '../../../../../pages-parts/inputs/date-picker/DatePickerInput';
import NumberInput from '../../../../../pages-parts/inputs/number-input/NumberInput';
import TextInput from '../../../../../pages-parts/inputs/text-input/TextInput';
import SwitchInput from '../../../../../pages-parts/inputs/switch-input/SwitchInput';
import { UnitEditContext } from '../UnitEditContext';

const StyledSection3 = styled.div`
    position: relative;
    width: 100%;
    margin-top: 3rem;
    transition: all .5s ease-in-out;
    padding: 1rem 1.75rem;
    background-color: ${({ theme }) => theme.colors.background.elements};
    border-radius: 0.75rem;
    position: relative;
    border: 1px solid #ccc;

    .form-check-input{
        margin-right: 0;
    }
    
    
    .infoData{
        width: 12rem;

    }

    span img{
        width: 6rem;
        height: 6rem;
        border-radius: 0.75rem;
    }
`;

const Section3 = () => {
    const {
        hasServices,
        setHasServices,
        waterMeter,
        setWaterMeter,
        gasMeter,
        setGasMeter,
        electricityMeter,
        setElectricityMeter,
    } = useContext(UnitEditContext);

    return (
        <StyledSection3>
            <h2 className='sectionFloatTitle'>الخدمات</h2>
            <Row>
                <Col className='text-end'>
                    <Form.Check
                        className='px-0'
                        type="switch"
                        defaultChecked={hasServices}
                        name='has-facilities'
                        onChange={() => setHasServices(prev => !prev)}
                    />
                </Col>
            </Row>
            <Row className={hasServices ? '' : 'd-none'}>
                <Col>
                    <NumberInput
                        titleText='عداد مياة'
                        inputName='water-meter'
                        value={waterMeter}
                        setValue={setWaterMeter}
                    />
                    <NumberInput
                        titleText='عداد غاز'
                        inputName='gas-meter'
                        value={gasMeter}
                        setValue={setGasMeter}
                    />
                    <NumberInput
                        titleText='عداد كهرباء'
                        inputName='electricity-meter'
                        value={electricityMeter}
                        setValue={setElectricityMeter}
                    />
                </Col>
            </Row>
        </StyledSection3>
    );
}

export default Section3;