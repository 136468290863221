import React, { useContext, useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from 'styled-components';
import moment from 'moment';
import StaticSelect from '../../../../pages-parts/inputs/static-select-input/StaticSelect';
import DatePickerInput from '../../../../pages-parts/inputs/date-picker/DatePickerInput';
import NumberInput from '../../../../pages-parts/inputs/number-input/NumberInput';
import TextInput from '../../../../pages-parts/inputs/text-input/TextInput';
import DynamicSelect from '../../../../pages-parts/inputs/dynamic-select-input/DynamicSelect';
import { OwnerDetailsEditContext } from '../OwnerDetailsEditContext';

const StyledSection3 = styled.div`
    position: relative;
    width: 100%;
    margin-top: 3rem;
    transition: all .5s ease-in-out;
    margin-bottom: 3rem;
    padding: 1rem 1.75rem;
    background-color: ${({ theme }) => theme.colors.background.elements};
    border-radius: 0.75rem;
    position: relative;
    border: 1px solid #ccc;

    
    
    .infoData{
        width: 12rem;

    }

    span img{
        width: 6rem;
        height: 6rem;
        border-radius: 0.75rem;
    }
`;

const Section3 = ({ realEstateData }) => {

    const {
        areaDBUrl,
        handleChangeSelectedAreaOption,
        selectedArea,
        cityDBUrl,
        handleChangeSelectedCityOption,
        selectedCity,
        neighborhoodOptions,
        handleChangeSelectedNeighborhood,
        selectedNeighborhood,
        zipCode,
        setZipCode,
        streetName,
        setStreetName,
        buildingNumber,
        setBuildingNumber,
        additionalNumber,
        setAdditionalNumber,
    } = useContext(OwnerDetailsEditContext);

    return (
        <StyledSection3>
            <h2 className='sectionFloatTitle'>العنوان في المملكة العربية السعودية</h2>
            <Row className='mt-2'>
                <Col md="6">
                    <DynamicSelect
                        titleText='المنطقة'
                        selectedOption={selectedArea}
                        url={areaDBUrl}
                        column='area'
                        customStylesProp={{
                            has: false,
                            style: {}
                        }}
                        handleChangeSelect={handleChangeSelectedAreaOption}
                    />
                    <StaticSelect
                        titleText='الحي'
                        selectedOption={selectedNeighborhood}
                        options={neighborhoodOptions}
                        customStylesProp={{
                            has: false,
                            style: {}
                        }}
                        handleChangeSelect={handleChangeSelectedNeighborhood}
                    />
                </Col>
                <Col md="6">
                    <DynamicSelect
                        titleText='المدينة'
                        selectedOption={selectedCity}
                        url={cityDBUrl}
                        column='city'
                        customStylesProp={{
                            has: false,
                            style: {}
                        }}
                        handleChangeSelect={handleChangeSelectedCityOption}
                    />
                    <NumberInput
                        titleText='الرمز البريدي'
                        inputName='zip-code'
                        value={zipCode}
                        setValue={setZipCode}
                    />
                </Col>
            </Row>
            <Row className='mt-2'>
                <Col md="6" className="mb-2">
                    <TextInput
                        titleText='اسم الشارع'
                        inputName='street-name'
                        value={streetName}
                        setValue={setStreetName}
                    />
                </Col>
                <Col md="6" className="mb-2">
                    <NumberInput
                        titleText='رقم المبني'
                        inputName='building-number'
                        value={buildingNumber}
                        setValue={setBuildingNumber}
                    />
                </Col>
                <Col md="6" className="mb-2">
                    <NumberInput
                        titleText='رقم اضافي'
                        inputName='additional-number'
                        value={additionalNumber}
                        setValue={setAdditionalNumber}
                    />
                </Col>
            </Row>
        </StyledSection3>
    );
}

export default Section3;