import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { FaBed, FaBath } from 'react-icons/fa';
import { MdOutlineLiving } from 'react-icons/md';
import Moment from 'react-moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import moment from 'moment';
import AddNewButton from '../../../pages-parts/inputs/add-new-btn/AddNewButton';


const StyledSection3 = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    margin-bottom: 3rem;
    padding: 1rem 1.75rem;
    background-color: ${({ theme }) => theme.colors.background.elements};
    border-radius: 0.75rem;
    position: relative;
    border: 1px solid #ccc;

    

    .headContainer{
        
    }
    p{
        margin: 0;
    }
    
    .infoData{
        padding: .75rem 3rem;
        border-radius: 0.75rem;
        border: 1px solid #ccc;
    }

    .secondRow{
        width: 75%;
        .periodBox{
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: auto;

            div{
                text-align: center;
            }
            h4, h5{
                margin-bottom: 0;
            }
            .infoData2{
                padding: 0;
                margin-top: 0.5rem;
            }
        }
    }
    .amenitiesBox{
        display: flex;
        .amenities{
            padding: .25rem .75rem;
            border-radius: 0.5rem;
            border: 1px solid ${({ theme }) => theme.colors.secondary};
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            margin-left: .5rem;
            .icon{
                font-size: 1.5rem;
                color: ${({ theme }) => theme.colors.secondary};
                margin-left: .5rem;
            }
            span{
    
            }
        }
    }
`;

const Section3 = ({ contractData }) => {
    const createAt = moment(contractData.createDate).subtract(2, "years").format('ll');

    console.log(createAt.replace(/[٠١٢٣٤٥٦٧٨٩]/g, function (d) {
        return d.charCodeAt(0) - 1632;                
    }).replace(/[۰۱۲۳۴۵۶۷۸۹]/g, function (d) {
        return d.charCodeAt(0) - 1776;
    }))
    return (
        <StyledSection3>
            <h2 className='sectionFloatTitle'>تفاصيل الوحدة</h2>

            <Row className='my-3 headContainer'>
                <Col className='mb-2'>
                    <h4 className='mb-0'>اسم الوحدة</h4>
                </Col>
                <Col className='mb-2 text-end'>
                    <AddNewButton
                        title="تفاصيل"
                        url={`/unit-view?unit_id=${contractData.unitId}`}
                        isLink={true}
                        hasIcon={false}
                    />
                </Col>
            </Row>
            <Row className='my-3 secondRow'>
                <Col className='periodBox align-items-start'>
                    <div>
                        <h5>رقم الطابق</h5>
                        <p className='infoData2 numbers-font'>
                            6
                        </p>
                    </div>
                </Col>
                <Col className='periodBox align-items-center'>
                    <div>
                        <h5>نوع الوحدة</h5>
                        <p className='infoData2'>
                            شقة
                        </p>
                    </div>
                </Col>
                <Col className='periodBox align-items-center'>
                    <div>
                        <h5>تاريخ الانشاء</h5>
                        <p className='infoData2'>
                            {
                                createAt.replace(/[٠١٢٣٤٥٦٧٨٩]/g, function (d) {
                                    return d.charCodeAt(0) - 1632;                
                                }).replace(/[۰۱۲۳۴۵۶۷۸۹]/g, function (d) {
                                    return d.charCodeAt(0) - 1776;
                                })
                            }
                        </p>
                    </div>
                </Col>
                <Col className='periodBox align-items-end'>
                    <div>
                        <h5>حالة الاتفاقية</h5>
                        <p className='badge warning mt-2'>
                            مشغول
                        </p>
                    </div>
                </Col>
            </Row>
        </StyledSection3>
    );
}

export default Section3;