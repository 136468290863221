import React, { useContext, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from 'styled-components';
import moment from 'moment';
import StaticSelect from '../../../../pages-parts/inputs/static-select-input/StaticSelect';
import DatePickerInput from '../../../../pages-parts/inputs/date-picker/DatePickerInput';
import NumberInput from '../../../../pages-parts/inputs/number-input/NumberInput';
import TextInput from '../../../../pages-parts/inputs/text-input/TextInput';
import SwitchInput from '../../../../pages-parts/inputs/switch-input/SwitchInput';
import { RealEstateSharedFacilitiesEditContext } from '../RealEstateSharedFacilitiesEditContext';

const StyledSection1 = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    padding: 2rem 1.75rem 1rem;
    background-color: ${({ theme }) => theme.colors.background.elements};
    border-radius: 0.75rem;
    position: relative;
    border: 1px solid #ccc;

    
    
    .infoData{
        width: 12rem;

    }

    span img{
        width: 6rem;
        height: 6rem;
        border-radius: 0.75rem;
    }
`;

const Section1 = () => {
    const {
        parkingNumber,
        setParkingNumber,
        elevatorNumber,
        setElevatorNumber,
        securityEntrancesNumber,
        setSecurityEntrancesNumber,
        fitnessCenters,
        setFitnessCenters,
        securityService,
        setSecurityService,
        transferService,
        setTransferService,
        laundryService,
        setLaundryService,
        snackbarShop,
        setSnackbarShop,
        groceryShop,
        setGroceryShop,
        babyDayCare,
        setBabyDayCare,
        childrenPlayground,
        setChildrenPlayground,
        gamesHall,
        setGamesHall,
        swimmingPool,
        setSwimmingPool,
        footballStadium,
        setFootballStadium,
        basketballStadium,
        setBasketballStadium,
        volleyballStadium,
        setVolleyballStadium,
        banquetHall,
        setBanquetHall,
        inComplex,
        setInComplex,
        complexName,
        setComplexName,
    } = useContext(RealEstateSharedFacilitiesEditContext);

    return (
        <StyledSection1>
            <h2 className='sectionFloatTitle'>مرافق العقار</h2>
            <Row>
                <Col>
                    <NumberInput
                        titleText='مواقف السيارات'
                        inputName='parking-number'
                        value={parkingNumber}
                        setValue={setParkingNumber}
                    />
                    <NumberInput
                        titleText='عدد المصاعد'
                        inputName='elevator-number'
                        value={elevatorNumber}
                        setValue={setElevatorNumber}
                    />
                    <NumberInput
                        titleText='مداخل الامن'
                        inputName='security-entrances'
                        value={securityEntrancesNumber}
                        setValue={setSecurityEntrancesNumber}
                    />
                    <NumberInput
                        titleText='مراكز اللياقة البدنية'
                        inputName='fitness-centers'
                        value={fitnessCenters}
                        setValue={setFitnessCenters}
                    />
                    <TextInput
                        titleText='خدمة الحراسة'
                        inputName='security-service'
                        value={securityService}
                        setValue={setSecurityService}
                    />
                    <TextInput
                        titleText='خدمة نقل'
                        inputName='transfer-service'
                        value={transferService}
                        setValue={setTransferService}
                    />
                    <TextInput
                        titleText='مغسل ملابس'
                        inputName='laundry-service'
                        value={laundryService}
                        setValue={setLaundryService}
                    />
                    <TextInput
                        titleText='مطعم وجبات خفيفة'
                        inputName='snackbar-shop'
                        value={snackbarShop}
                        setValue={setSnackbarShop}
                    />
                    <TextInput
                        titleText='محل بقالة'
                        inputName='grocery-shop'
                        value={groceryShop}
                        setValue={setGroceryShop}
                    />
                    <TextInput
                        titleText='حضانة اطفال'
                        inputName='baby-day-care'
                        value={babyDayCare}
                        setValue={setBabyDayCare}
                    />
                    <TextInput
                        titleText='ملعب اطفال'
                        inputName='children-playground'
                        value={childrenPlayground}
                        setValue={setChildrenPlayground}
                    />
                    <TextInput
                        titleText='صالة العاب'
                        inputName='games-hall'
                        value={gamesHall}
                        setValue={setGamesHall}
                    />
                    <TextInput
                        titleText='حمام سباحة'
                        inputName='swimming-pool'
                        value={swimmingPool}
                        setValue={setSwimmingPool}
                    />
                    <TextInput
                        titleText='ملعب كرة قدم'
                        inputName='football-stadium'
                        value={footballStadium}
                        setValue={setFootballStadium}
                    />
                    <TextInput
                        titleText='ملعب كرة سلة'
                        inputName='basketball-stadium'
                        value={basketballStadium}
                        setValue={setBasketballStadium}
                    />
                    <TextInput
                        titleText='ملعب كرة طائرة'
                        inputName='volleyball-stadium'
                        value={volleyballStadium}
                        setValue={setVolleyballStadium}
                    />
                    <TextInput
                        titleText='قاعة مناسبات'
                        inputName='banquet-hall'
                        value={banquetHall}
                        setValue={setBanquetHall}
                    />
                    <SwitchInput
                        titleText='العقار داخل مجمع'
                        inputName='in-complex'
                        value={inComplex}
                        setValue={setInComplex}
                    />
                    {
                        inComplex ? (
                            <TextInput
                                titleText='اسم المجمع'
                                inputName='complex-name'
                                value={complexName}
                                setValue={setComplexName}
                            />
                        ) : ""
                    }
                </Col>
            </Row>
        </StyledSection1>
    );
}

export default Section1;