import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { AiOutlineAppstore } from 'react-icons/ai';
import { MdOutlineElevator } from 'react-icons/md';
import { TfiAngleUp } from 'react-icons/tfi';
import { GiSecurityGate } from 'react-icons/gi';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import moment from 'moment';
import UnitCard from '../../../real-estates/units/UnitCard';
import Accordion from 'react-bootstrap/Accordion';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import { AccordionContext } from 'react-bootstrap';
import AddNewButton from '../../../../pages-parts/inputs/add-new-btn/AddNewButton';


const StyledRealEstateCard = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    margin-bottom: 1rem;
    padding: 1rem 1.75rem;
    background-color: ${({ theme }) => theme.colors.background.elements};
    border-radius: 0.75rem;
    position: relative;
    border: 1px solid #ccc;

    &:last-child{
        margin-bottom: 0;
    }
    .headContainer{
        cursor: pointer;

        &.closed{
            margin-bottom: 0;
            padding-bottom: 0;
            border: none;
            p.unit-id{
                display: none;
            }
            .icon{
                transform: rotate(180deg);
            }
        }
        .icon{
            font-size: 1.5rem;
            transition: all .5s ease-in-out;
        }
        p{
            color: ${({ theme }) => theme.colors.text.primary};
        }
        h4 {
            display: flex;
            align-items: center;
            p{
                color: ${({ theme }) => theme.colors.text.secondary};
                margin-left: .75rem;
            }
            .status{
                display: inline-block;
                padding: 0.25rem 1rem;
                font-size: 14px;
                border-radius: .75rem;
                background-color: ${({ theme }) => theme.colors.secondary_2};
            }
        }

        &:hover{
            color: ${({ theme }) => theme.colors.secondary};
        }
    }
    p{
        margin: 0;
    }
    
    .infoData{
        padding: .75rem 3rem;
        border-radius: 0.75rem;
        border: 1px solid #ccc;
    }

    
    .secondRow{
        justify-content: right;
        .periodBox{
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: auto;

            div{
                text-align: center;
            }
            h4, h5{
                margin-bottom: 0;
            }
            .infoData2{
                padding: 0;
                margin-top: 0.5rem;
            }
        }
    }
    .amenitiesBox{
        display: flex;
        .amenities{
            padding: .25rem .75rem;
            border-radius: 0.5rem;
            border: 1px solid ${({ theme }) => theme.colors.secondary};
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            margin-left: 1rem;
            .icon{
                font-size: 1.5rem;
                color: ${({ theme }) => theme.colors.secondary};
                margin-left: .5rem;
            }
            span{
    
            }
        }
    }

    .contentContainer{
        overflow: hidden;
        transform-origin: top;
        
    }

`;

function CardHead({ children, data, callback }) {
    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(
        data.id,
        () => callback && callback(data.id),
    );

    const isCurrentEventKey = activeEventKey === data.id;

    return (
        <Row className={`m-0 headContainer ${isCurrentEventKey ? '' : 'closed'}`} onClick={decoratedOnClick}>
            <>
                <Col className='mb-2'>
                    <h4 className='mb-0'><p>اسم العقار</p></h4>
                    <p className='mt-2 unit-id'>
                        سند ملكية - {123456}
                    </p>
                </Col>
                <Col className='mb-2 d-flex justify-content-end align-items-center'>
                    <AddNewButton
                        title="تفاصيل"
                        url={`/real-estate-view?id=9e0af4be-e398-470a-9efa-834f91117c85`}
                        isLink={true}
                        hasIcon={false}
                    />
                    <TfiAngleUp className='icon ms-3' />
                </Col>
            </>
        </Row>
    );
}
const RealEstateCard = ({ data }) => {

    const openCard = (e, id) => {
        if (e.currentTarget.classList.contains('closed')) {
            e.currentTarget.classList.remove('closed');
            document.getElementById(id).classList.remove('closed');
        } else {
            e.currentTarget.classList.add('closed');
            document.getElementById(id).classList.add('closed');
        }
    }

    return (
        <StyledRealEstateCard>
            <Accordion defaultActiveKey="0">
                <CardHead data={data} />
                <Row className='my-3 secondRow' >
                    <div className='periodBox align-items-start'>
                        <div>
                            <h5>نوع العقار</h5>
                            <p className='infoData2'>
                                مجمع تجاري مفتوح
                            </p>
                        </div>
                    </div>
                    <div className='periodBox align-items-center'>
                        <div>
                            <h5>استخدام العقار</h5>
                            <p className='infoData2'>
                                تجاري
                            </p>
                        </div>
                    </div>
                    <div className='periodBox align-items-end'>
                        <div>
                            <h5>تاريخ الانشاء</h5>
                            <p className='infoData2 numbers-font'>
                                {moment().subtract(6, "years").format('ll')}
                            </p>
                        </div>
                    </div>
                </Row>
                <div className='mb-3 amenitiesBox'>
                    <div className='amenities'>
                        <MdOutlineElevator className='icon' />
                        <span>3 المصاعد</span>
                    </div>
                    <div className='amenities'>
                        <GiSecurityGate className='icon' />
                        <span>2 بوابات امن</span>
                    </div>
                    <div className='amenities'>
                        <AiOutlineAppstore className='icon' />
                        <span>1 الوحدات</span>
                    </div>

                </div>
                <Accordion.Collapse className='contentContainer' eventKey={data.id}>
                    <>
                        {
                            [...Array(3)].map((e, i) => (
                                <UnitCard key={i} data={{
                                    id: `unit-${moment().format()}-${i}`
                                }} />
                            ))
                        }
                    </>
                </Accordion.Collapse>
            </Accordion>

        </StyledRealEstateCard>
    );
}

export default RealEstateCard;