import styled from "styled-components"

const StyledEntries = styled.div`
    margin-left: .25rem;
    margin-right: .25rem;
    display: inline-flex;
    flex-direction: row-reverse;

    span{
        margin-right: .25rem;
        line-height: 1.5;
    }
`

const Entries = ({ entries, setEntries }) => {
    const options = [
        10,
        25,
        50,
        100
    ]
    const handleChange = e => {
        setEntries(e.target.value);
    }
    return (
        <StyledEntries>
            <span>Show</span>
            <select className="numbers-font" value={entries} onChange={handleChange}>
                {
                    options.map(item => (
                        <option value={item} key={item}>{item}</option>
                    ))
                }
            </select>
        </StyledEntries>
    )
}

export default Entries;