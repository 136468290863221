import { createGlobalStyle, css } from "styled-components";
import islamic from './islamic.ttf';

const GlobalStyles = createGlobalStyle`
    @font-face {
        font-family: 'islamic';
        src: url(${islamic});
    }
    
    *{
        box-sizing: border-box;
        margin: 0;
        outline: none;
        padding: 0;
        &::after {
            margin: 0;
            padding: 0;
        }
        &::before {
            margin: 0;
            padding: 0;
        }
    }

    body{
        background-color: ${({theme}) => theme.colors.background.body} !important;
        margin: 0;
        padding: 0;
        font-family: 'islamic';
        font-size: 14px;
        color: ${({theme}) => theme.colors.text.primary};
        overflow: hidden;
        line-height: 1;
    }

    ul,li{
        list-style: none;
    }

    
    a{
        text-decoration: none;
        color: ${({theme}) => theme.colors.light_gray};
    }

    .badge{
        padding: .5rem .75rem;
        &.done{
            color: #0BB783;
            background-color: #0BB78366;
        }
        &.warning{
            color: #fff;
            background-color: #FFA800;
        }
        &.cancelled{
            color: #FFA800;
            background-color: #FFA80066;
        }
    }

    .data-line{
        display: flex;
        align-items: center;
        margin-bottom: .5rem;
        &:last-child{
            margin-bottom: 0;
        }

        .infoData{
            // ------------
            &.calender-input{
                position: relative;
                z-index: 3;
                p{
                    height: 32px;
                    padding: 0.25rem 1rem;
                    border: 1px solid #ced4da;
                    border-radius: .375rem;
                    color: ${({ theme }) => theme.colors.text.secondary};
                    line-height: 1.5;
                    cursor: pointer;
                }

                .datepicker-container{
                    position: absolute;
                    top: 100%;
                    right: 0;
                    background-color: #fff;
                    direction: ltr;
                }
            }
        }
    }

    .infoTitle{
        display: inline-block;
        width: 12rem;
        margin-left: .5rem;
        font-size: 14px;
        color: ${({ theme }) => theme.colors.text.secondary};
    }

    ${({ theme }) => {
        return css`
            .save-link, a.save-link{
                color: ${theme.colors.primary};
                background-color: ${theme.colors.primary_1};
                border: 1px solid ${theme.colors.primary_2};
                border-radius: 0.5rem;
                cursor: pointer;

                &:hover{
                    color: ${theme.colors.secondary};
                    background-color: ${theme.colors.secondary_1};
                    border: 1px solid ${theme.colors.secondary_2};
                }
            }
            .return-link, a.return-link{
                color: ${theme.colors.delete};
                background-color: ${theme.colors.delete_1};
                border: 1px solid ${theme.colors.delete_2};
                border-radius: 0.5rem;
                cursor: pointer;

                &:hover{
                    filter: brightness(.75);
                }
            }
        `;
    }}

    .form-control{
        max-height: 2rem;
        min-height: 2rem;
        font-size: 14px;
    }
    .form-check {
        display: block;
        min-height: auto;
        padding-right: 1.5em;
        margin-bottom: 0;
        &.form-switch .form-check-input{
            margin-right: 0;
        }
        .form-check-input{
            margin-top: 0;
        }
    }
    
    .tableHead{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-bottom: 1rem;
    }

    h2.sectionFloatTitle{
        margin: 0;
        font-size: 14px;
        position: absolute;
        top: -1.25rem;
        right: 2rem;
        height: 2.5rem;
        line-height: 2.5rem;
        padding: 0 1rem;
        background-color: ${({ theme }) => theme.colors.background.elements};
        border-radius: 0.75rem;
        border: 1px solid #ccc;
        color: ${({ theme }) => theme.colors.text.secondary};
    }
`;

export default GlobalStyles;

export const customSelectStyles = {
    control: (provided, state) => ({
        ...provided,
        minHeight: "2rem",
        maxHeight: "2rem",
    }),
    container: (provided, state) => ({
        ...provided,
        width: "100%",
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        padding: "0px 1rem",
    }),
    multiValue: (provided, state) => ({
        ...provided,
        padding: "2px 8px",
    }),
    clearIndicator: (provided, state) => ({
        ...provided,
        padding: "0px 8px",
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        padding: 0,
    }),
}