
// primary: "#e23428",
// primary_1: "rgba(226, 52, 40, 0.1)",
// primary_2: "rgba(226, 52, 40, 0.2)",
// primary_8: "rgba(226, 52, 40, 0.8)",
// rgba(0, 171, 132, 0.1)
// secondary: "#135846",
// secondary_1: "rgba(19, 88, 70, 0.1)",
// secondary_2: "rgba(19, 88, 70, 0.2)",
// secondary_8: "rgba(19, 88, 70, 0.8)",
const theme = {
    colors: {
        primary: "rgba(0, 171, 132, 1)",
        primary_1: "rgba(0, 171, 132, 0.1)",
        primary_2: "rgba(0, 171, 132, 0.2)",
        primary_8: "rgba(0, 171, 132, 0.8)",
        light_gray: "#898a8a",
        dark_gray: "#55565c",
        secondary: "rgb(0,89,79)",
        secondary_1: "rgba(0, 89, 79, 0.1)",
        secondary_2: "rgba(0, 89, 79, 0.2)",
        secondary_8: "rgba(0, 89, 79, 0.8)",
        delete: "#C6352D",
        delete_1: "rgba(198, 53, 45, 0.1)",
        delete_2: "rgba(198, 53, 45, 0.2)",
        delete_8: "rgba(198, 53, 45, 0.8)",
        text: {
            primary: "#6E6E6E",
            secondary: "#000",
        },
        background: {
            body: "#f0f0f0",
            elements: "#FFFFFF",
        }
    },
    sidebar_width: {
        open: "15.5rem",
        close: "6rem"
    }
}

export default theme;