import React, { useContext, useEffect, useRef, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import styled, { css } from 'styled-components';
import Select from 'react-select';
import { Calendar } from 'react-date-range';
import moment from 'moment';
import DatePickerInput from '../../../pages-parts/inputs/date-picker/DatePickerInput';
import { ContractDetailsEditContext } from '../ContractDetailsEditContext';
import Section1 from './housing-sections/Section-1';
import Section2 from './housing-sections/Section-2';
import Section3 from './housing-sections/Section-3';
import Section4 from './housing-sections/Section-4';
import CommercialSection1 from './commercial-section/Section-1';


const StyledTermsAndConditionsPage = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    padding: 0;
    
    .infoTitle{
        width: 20rem;
    }
`;

const TermsAndConditionsPage = () => {
    const { duration } = useContext(ContractDetailsEditContext);

    return (
        <StyledTermsAndConditionsPage>
            <Container fluid>
                {duration.contractTypeHousing ? (
                    <>
                        <Section1 />
                        <Section2 />
                        <Section3 />
                        <Section4 />
                    </>
                )
                    : (
                        <>
                            <CommercialSection1 />
                        </>
                    )}
            </Container>
        </StyledTermsAndConditionsPage>
    );
}

export default TermsAndConditionsPage;