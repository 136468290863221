import { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import AsyncSelect from 'react-select/async';
import api from '../../../../api/areas'

function PhoneNumber({ titleText, inputName, phoneNum, setPhoneNum, handleChangeSelect, selectedOption }) {
    const [defaultOptions, setDefaultValues] = useState([]);

    useEffect(() => {
        getDefaultOptions()
    }, [])

    const getDefaultOptions = async () => {
        try {
            const respond = await api.get(`countries?_page=1`).then(res => {
                let values = [];
                res.data.forEach(item => {
                    const option = {
                        value: item.name,
                        label: item.phoneCode,
                    }
                    values.push(option);
                });
                handleChangeSelect(values[0]);
                setDefaultValues(values)
            }).catch(err => {
                console.log(err)
            });
        } catch (err) {
            console.log(err)
        }

    }

    const handleChange = e => {
        setPhoneNum(prev => {
            e.target.value = e.target.value.replace(/([^0-9])/g, '');
            return (e.target.value.replace(/([^0-9])/g, ''));
        })
    }

    const loadOptions = async (searchValue, callback) => {
        try {
            const respond = await api.get(`countries?q=${searchValue}&_page=1`).then(res => {
                let values = [{
                    value: 'no-value',
                    label: 'غير محدد',
                }];
                res.data.forEach(item => {
                    const option = {
                        value: item.name,
                        label: item.phoneCode,
                    }
                    values.push(option);
                });
                callback(values)
            }).catch(err => {
                console.log(err)
            });
        } catch (err) {
            console.log(err)
        }

    }

    const customStyles = {
        control: (baseStyles, state) => ({
            ...baseStyles,
            minHeight: "2rem",
            maxHeight: "2rem",
            borderRadius: ".375rem 0 0 .375rem",
            // maxHeight: '30px',
        }),
        container: (provided, state) => ({
            ...provided,
            width: "auto",
            display: "inline-block",
            minWidth: "5rem",
            top: "-1px"
            // lineHeight: 1,
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            padding: "0",
            // minHeight: 'auto',
            // maxHeight: '30px',
            // lineHeight: 1,
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            padding: 0,
            // minHeight: 'auto',
            // maxHeight: '30px',
            // lineHeight: '1',
        }),
    }

    return (
        <div className='data-line'>
            <span className='infoTitle'>
                {titleText}
            </span>
            <span className='infoData'>
                <Form.Control
                    className='py-1 px-3 phoneNumber'
                    type='text'
                    value={phoneNum}
                    name={inputName}
                    onChange={handleChange}
                />
                <AsyncSelect
                    value={selectedOption}
                    loadOptions={loadOptions}
                    defaultOptions={defaultOptions}
                    styles={customStyles}
                    onChange={handleChangeSelect}
                />
            </span>
        </div>
    );
}

export default PhoneNumber;