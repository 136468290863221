import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import Card from 'react-bootstrap/Card';
import styled from 'styled-components';

const StyledSection1Card = styled(Card)`
    border: none;
    box-shadow: 0rem 0.3125rem 0.3125rem 0rem rgb(82 63 105 / 5%);
    transition: all .5s ease-in-out;
    border-radius: .75rem;
    background-color: ${({ theme }) => theme.colors.background.elements};
    display: flex;
    align-items: center;
    flex-direction: row;
    height: 100%;
    padding: 0;

    span{
        height: 4rem;
        min-width: 4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${({ theme }) => theme.colors.primary_1};
        color: ${({ theme }) => theme.colors.primary};
        font-size: 2rem;
        border-radius: 0.5rem;
        transition: all .5s ease-in-out;
    }
    h2{
        font-weight: 700;
        font-size: 1.5rem;
        text-align: center;
    }
    p{
        text-transform: capitalize;
        color: ${({ theme }) => theme.colors.text.primary};
    }

    &:hover{
        box-shadow: 0 1.188rem 1.188rem rgb(0 0 0 / 16%) !important;

        span{
            color: #fff;
            background-color: ${({ theme }) => theme.colors.primary};
        }
    }
`;

const Section1Card = ({ carddata }) => {

    return (
        <StyledSection1Card >
            <Card.Body className='d-flex align-items-center p-3 justify-content-center'>
                <div className='ms-2'>
                    <h2 className='mb-0'>
                        {carddata.value}
                    </h2>
                    <p className='mb-0'>
                        {carddata.title}
                    </p>
                </div>
            </Card.Body>
        </StyledSection1Card>
    );
}

export default Section1Card;