import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from 'styled-components';
import { RiDraftLine } from 'react-icons/ri';
import { AiOutlineFileDone } from 'react-icons/ai';
import { IoEnterOutline } from 'react-icons/io5';
import Section1Card from './Cards';
import moment from 'moment';

const StyledSection1 = styled.div`
    width: 100%;
    margin-bottom: 3rem;
`;

const Section1 = () => {
    const [cardList, setCardList] = useState([]);

    useEffect(() => {
        setCardList([
            {
                id: 1,
                title: 'عدد العقود الاجمالي',
                countUrl: '/items?_page=2',
                icon: <RiDraftLine />,
                reverse: false,
            },
            {
                id: 2,
                title: 'عقود تنتهي قريبا',
                countUrl: '/items?contractState=مكتمل&_page=2',
                icon: <AiOutlineFileDone />,
                reverse: false,
            },
            {
                id: 3,
                title: 'عدد مسودة العقود',
                countUrl: '/items?contractState=تحت المراجعة&_page=2',
                icon: <RiDraftLine />,
                reverse: false,
            },
            {
                id: 4,
                title: 'العقود المكتملة هذا الشهر',
                countUrl: `/items?contractState=مكتمل&createDate_gte=${moment().startOf('month').format()}&createDate_lte=${moment().endOf('month').format()}&_page=2`,
                icon: <AiOutlineFileDone />,
                reverse: true,
            },
        ]);
    }, []);

    return (
        <StyledSection1>
            <Row>
                {
                    cardList.map(item => (
                        <Col md="3" sm="6" xs="12" className='mb-3' key={item.id}>
                            <Section1Card carddata={item} />
                        </Col>
                    ))
                }
            </Row>
        </StyledSection1>
    );
}

export default Section1;