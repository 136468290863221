import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { AiOutlineEye } from 'react-icons/ai';
import { FaClipboardList } from 'react-icons/fa';
import { CiDollar } from 'react-icons/ci';
import { FaRegEdit } from 'react-icons/fa';
import TableComponent from '../../pages-parts/table/TableComponent';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import SearchBarFilter from '../../pages-parts/table/SearchFilter';
import Entries from '../../pages-parts/table/Entries';
import profileImage from '../../../images/1.png';
import SelectFilter from '../../pages-parts/table/SelectFilter';
import AddNewButton from '../../pages-parts/inputs/add-new-btn/AddNewButton';


const StyledUsers = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    padding: 2.5rem 1.75rem;
    .scroll-thumb{
        background-color: ${({ theme }) => theme.colors.primary_8};
    }

    .unitsNum{
        display: flex;
        align-items: center;
        justify-content: center;

        span{
            width: 2.5rem;
            text-align: center;
            padding: 0.125rem 0;
            border: 1px solid #ccc;
        }
        .filled{
            color: ${({ theme }) => theme.colors.primary};
            border-right: none;
        }
    }
`;

const UsersPage = () => {

    const rulesWithMoreActions = [
        "مساعد وسيط",
        "مروج",
        "خدمة عملاء",
    ]
    const location = useLocation();
    const [columns, setColumns] = useState([
        {
            id: 1,
            Header: "#",
            Cell: e => {
                return <span className='numbers-font'>{e.value}</span>
            },
            accessor: "number",
            sortable: true,
        },
        {
            id: 2,
            Header: "المستخدمون",
            accessor: "name",
            sortable: true,
            Cell: e => {
                return (
                    <Link to={`/users/view?id=${e.row.cells.find(cell => cell.column.Header === "الاجراءات").value}`} className='d-flex align-items-center image-heading-text-link'>
                        <img src={profileImage} alt="Profile" />
                        <span>
                            <h4>
                                {e.value}
                            </h4>
                            <span className="numbers-font"><span>#</span> {e.row.cells.find(cell => cell.column.Header === "الاجراءات").value.replace(/(-.*)/g, "")}...</span>
                        </span>
                    </Link>
                )
            }
        },
        {
            id: 3,
            Header: "رقم الجوال",
            accessor: "phoneNum",
            Cell: e => {
                return <span className='numbers-font'>{e.value}</span>
            },
            sortable: true,

        },
        {
            id: 4,
            Header: "الايميل",
            accessor: "email",
            sortable: true
        },
        {
            id: 5,
            Header: "الدور",
            accessor: "rule",
            sortable: true
        },
        {
            id: 6,
            Header: "الاجراءات",
            accessor: "id",
            sortable: false,
            Cell: e => {
                return (
                    <>
                        <OverlayTrigger
                            key="view"
                            placement="bottom"
                            overlay={
                                <Tooltip id={`tooltip-view`}>
                                    عرض المستخدم
                                </Tooltip>
                            }
                        >
                            <Link to={`/users/view?id=${e.value}`}><AiOutlineEye /></Link>
                        </OverlayTrigger>
                        <OverlayTrigger
                            key="edit"
                            placement="bottom"
                            overlay={
                                <Tooltip id={`tooltip-edit`}>
                                    تعديل المستخدم
                                </Tooltip>
                            }
                        >
                            <Link to={`/users/edit?id=${e.value}`}><FaRegEdit /></Link>
                        </OverlayTrigger>
                        {rulesWithMoreActions.includes(e.row.cells.find(cell => cell.column.Header === "الدور").value) ? (
                            <>
                                <OverlayTrigger
                                    key="financeRecord"
                                    placement="bottom"
                                    overlay={
                                        <Tooltip id={`tooltip-financeRecord`}>
                                            السجل المالي
                                        </Tooltip>
                                    }
                                >
                                    <Link to={
                                        e.row.cells.find(cell => cell.column.Header === "الدور").value === "مساعد وسيط" ?
                                        `/broker-assistant-financial-records?id=${e.value}` :
                                            e.row.cells.find(cell => cell.column.Header === "الدور").value === "خدمة عملاء" ?
                                            `/customer-service-financial-records?id=${e.value}` :
                                            `/promoter-financial-records?id=${e.value}`
                                    }><FaClipboardList /></Link>
                                </OverlayTrigger>
                                <OverlayTrigger
                                    key="paymentRecord"
                                    placement="bottom"
                                    overlay={
                                        <Tooltip id={`tooltip-paymentRecord`}>
                                            سجل المدفوعات
                                        </Tooltip>
                                    }
                                >
                                    <Link to={
                                        e.row.cells.find(cell => cell.column.Header === "الدور").value === "مساعد وسيط" ?
                                        `/broker-assistant-payouts?id=${e.value}` :
                                            e.row.cells.find(cell => cell.column.Header === "الدور").value === "خدمة عملاء" ?
                                            `/customer-service-payouts?id=${e.value}` :
                                            `/promoter-payouts?id=${e.value}`
                                    }><CiDollar /></Link>
                                </OverlayTrigger>
                            </>
                        ) : (
                            <>

                            </>
                        )}
                    </>
                )
            }
        },
    ]);
    const [searchTable, setSearchTable] = useState(() => {
        const filterStorage = JSON.parse(localStorage.getItem(location.pathname + '-filters')) ?? null;
        if (filterStorage !== null && filterStorage.search !== undefined) {
            return filterStorage.search;
        } else {
            return '';
        }
    });
    const [sortTable, setSortTable] = useState(() => {
        const filterStorage = JSON.parse(localStorage.getItem(location.pathname + '-filters')) ?? null;
        if (filterStorage !== null && filterStorage.sortFilter !== undefined) {
            return filterStorage.sortFilter;
        } else {
            return {
                column: '',
                direction: ''
            };
        }
    });
    const [pageNum, setPageNum] = useState(() => {
        return JSON.parse(localStorage.getItem(location.pathname + '-page-num')) ?? 1;
    });
    const [userTypeSelectFilter, setUserTypeSelectFilter] = useState(() => {
        const filterStorage = JSON.parse(localStorage.getItem(location.pathname + '-filters')) ?? null;
        if (
            filterStorage !== null &&
            filterStorage.userTypeSelectFilter !== undefined &&
            filterStorage.userTypeSelectFilter?.value !== undefined
        ) {
            return {
                type: 'static',
                url: '',
                value: filterStorage.userTypeSelectFilter,
                column: 'rule',
                name: 'userTypeSelectFilter',
                options: [
                    {
                        value: 'no-filter',
                        label: 'كل الادواد',
                    },
                    {
                        value: 1,
                        label: "مشرف",
                    },
                    {
                        value: 2,
                        label: "مساعد وسيط",
                    },
                    {
                        value: 3,
                        label: "مروج",
                    },
                    {
                        value: 4,
                        label: "خدمة عملاء",
                    },
                ],
            };
        } else {
            return {
                type: 'static',
                url: '',
                value: null,
                column: 'rule',
                name: 'userTypeSelectFilter',
                options: [
                    {
                        value: 'no-filter',
                        label: 'كل الادواد',
                    },
                    {
                        value: 1,
                        label: "مشرف",
                    },
                    {
                        value: 2,
                        label: "مساعد وسيط",
                    },
                    {
                        value: 3,
                        label: "مروج",
                    },
                    {
                        value: 4,
                        label: "خدمة عملاء",
                    },
                ],
            };
        }
    });
    const [entries, setEntries] = useState(() => {
        return JSON.parse(localStorage.getItem('entries-per-page')) ?? 10;
    })
    const [tableDataUrl, setTableDataUrl] = useState(() => {
        // sort filter url part
        let sortFilter;
        if (sortTable.column !== "" && sortTable.column !== undefined) {
            sortFilter = `_sort=${sortTable.column}&_order=${sortTable.direction}&`;
        } else {
            sortFilter = "";
        }

        // select filter url part
        // user type
        let userTypeSelectFilterText;
        if (
            userTypeSelectFilter.value !== {} &&
            userTypeSelectFilter.value?.label !== '' &&
            userTypeSelectFilter.value?.label !== undefined &&
            userTypeSelectFilter.value?.label !== 'الكل' &&
            userTypeSelectFilter.value?.value !== 'no-filter'
        ) {
            userTypeSelectFilterText = `${userTypeSelectFilter.column}=${userTypeSelectFilter.value?.label}&`;
        } else {
            userTypeSelectFilterText = "";
        }
        let selectFilterText = userTypeSelectFilterText;

        const url = `/users?${selectFilterText}${sortFilter}q=${searchTable}&_page=${pageNum}&_limit=${entries}`;

        return url;
    })

    useEffect(() => {
        // sort filter url part
        let sortFilter;
        if (sortTable.column !== "" && sortTable.column !== undefined) {
            sortFilter = `_sort=${sortTable.column}&_order=${sortTable.direction}&`;
        } else {
            sortFilter = "";
        }

        // select filter url part
        // user type
        let userTypeSelectFilterText;
        if (
            userTypeSelectFilter.value !== {} &&
            userTypeSelectFilter.value?.label !== '' &&
            userTypeSelectFilter.value?.label !== undefined &&
            userTypeSelectFilter.value?.label !== 'الكل' &&
            userTypeSelectFilter.value?.value !== 'no-filter'
        ) {
            userTypeSelectFilterText = `${userTypeSelectFilter.column}=${userTypeSelectFilter.value?.label}&`;
        } else {
            userTypeSelectFilterText = "";
        }
        let selectFilterText = userTypeSelectFilterText;

        const url = `/users?${selectFilterText}${sortFilter}q=${searchTable}&_page=${pageNum}&_limit=${entries}`;
        setTableDataUrl(url);
    },
        [
            searchTable,
            sortTable,
            pageNum,
            entries,
            userTypeSelectFilter.value,
        ]);

    useEffect(() => {
        const localFilter = {
            search: searchTable,
            sortFilter: sortTable,
            userTypeSelectFilter: userTypeSelectFilter.value
        }
        localStorage.setItem(location.pathname + '-filters', JSON.stringify(localFilter));
        localStorage.setItem(location.pathname + '-page-num', pageNum);
        localStorage.setItem('entries-per-page', entries);
    }, [tableDataUrl]);

    return (
        <StyledUsers>
            <Container fluid>
                <Row className='mb-3 flex-lg-row-reverse flex-md-row justify-content-between'>
                    <Col
                        lg={5}
                        md={12}
                        className='d-flex justify-content-end align-items-center mb-2'
                    >
                        <Entries
                            entries={entries}
                            setEntries={setEntries}
                        />
                        <SearchBarFilter
                            setSearchTable={setSearchTable}
                            searchTable={searchTable}
                            setPageNum={setPageNum}
                        />
                    </Col>
                    <Col lg={7} md={12} className='d-flex align-items-center'>
                        <div>
                            <AddNewButton
                                title='إضافة مستخدم'
                                url='/users/create'
                                isLink={true}
                            />
                        </div>
                        <SelectFilter
                            label='الدور'
                            selectFilter={userTypeSelectFilter}
                            setSelectFilter={setUserTypeSelectFilter}
                        />
                    </Col>
                </Row>
                <TableComponent
                    columns={columns}
                    tableDataUrl={tableDataUrl}
                    setSortTable={setSortTable}
                    tableWidth={800}
                    setPageNum={setPageNum}
                    pageNum={pageNum}
                    tableRowsPerPage={entries}
                />
            </Container>
        </StyledUsers>
    );
}

export default UsersPage;