import React, { useEffect, useRef, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import styled from 'styled-components';
import { FaFileContract } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import EmailInput from './inputs/EmailInput';
import PhoneInput from './inputs/PhoneInput';
import Loading from '../../pages-parts/loading/Loading';
import Logo from '../../../images/logo.png';
import LogoText from '../../../images/logo-text.png';

const StyledLogin = styled(Row)`
    position: relative;
    --bs-gutter-x: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    align-items: center;
    justify-content: center;

    .content-container{
        margin-top: -2.5rem;

        .logoContainer{
            text-align: center;
            color: ${({ theme }) => theme.colors.primary};
            margin-bottom: 1rem;

            .logo, .logoText{
                width: 7rem;
            }
        }
        .login-form-card{
            border: none;

            h2{
                text-align: center;
                color: ${({ theme }) => theme.colors.text.secondary};
                font-size: 1.5rem;
                font-weight: 700;
            }

            form{
                .input-group-text{
                    background-color: ${({ theme }) => theme.colors.primary_2};
                    color: ${({ theme }) => theme.colors.primary};
                    font-size: 1.5rem;
                    border-color: ${({ theme }) => theme.colors.primary_2};
                }
                input{
                    background-color: ${({ theme }) => theme.colors.background.body};
                    border-color: ${({ theme }) => theme.colors.primary_2};
                    transition: none;
                    text-align: right;
                    &:focus{
                        box-shadow: 0 0 0 0.25rem ${({ theme }) => theme.colors.primary_2};
                        border: none;
                    }
                    &:checked{
                        box-shadow: 0 0 0 0.25rem ${({ theme }) => theme.colors.primary_2};
                        background-color: ${({ theme }) => theme.colors.primary};
                        border: none;
                    }
                }
                button{
                    background-color: ${({ theme }) => theme.colors.primary};
                    color: #fff;
                    font-size: 14px;
                }
                a{
                    font-size: 1.1875rem;
                    color: ${({ theme }) => theme.colors.text.secondary};
                    &:hover{
                        color: ${({ theme }) => theme.colors.primary};
                    }
                }
            }
        }
    }
`;

const ForgotPassword = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [methodEmail, setMethodEmail] = useState();
    const byEmailRadioRef = useRef();

    useEffect(() => {
        if (byEmailRadioRef.current.checked) {
            setMethodEmail(true);
        }
    }, []);

    const handelSubmint = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);

        setIsLoading(true);

        setTimeout(() => setIsLoading(false), 1000);
    }

    const handleChange = () => {
        if (byEmailRadioRef.current.checked) {
            setMethodEmail(true);
        } else {
            setMethodEmail(false);
        }
    }
    return (
        <StyledLogin>
            {isLoading ? <Loading className="loading" /> : ''}
            <Col xs='12' sm='9' md='6' lg='4' className='content-container'>
                <div className='logoContainer'>
                    <img src={Logo} className="logo" alt="logo" />
                    <img src={LogoText} className="logoText" alt="logo" />
                </div>
                <Card className='login-form-card'>
                    <Card.Body>
                        <h2>استعادة كلمة المرور</h2>
                        <Form onSubmit={handelSubmint}>
                            {methodEmail ? <EmailInput /> : <PhoneInput />}
                            <Row className='mt-4'>
                                <Col md='6' className='d-flex align-items-center'>
                                    <Form.Check
                                        type='radio'
                                        id='by-email'
                                        name='send-to'
                                        defaultChecked={true}
                                        label='البريد الإلكتروني'
                                        ref={byEmailRadioRef}
                                        onChange={handleChange}
                                        value='0'
                                        reverse
                                    />
                                </Col>
                                <Col md='6' className='d-flex align-items-center justify-content-end'>
                                    <Form.Check
                                        type='radio'
                                        id='by-phone'
                                        name='send-to'
                                        label='رقم الهاتف'
                                        onChange={handleChange}
                                        value='1'
                                        reverse
                                    />
                                </Col>
                                <Col md='12' className='text-center mt-3'>
                                    <Button variant="primary" type="submit" className='border-0'>
                                        أرسل رابط ألاستعادة
                                    </Button>
                                </Col>
                                <Col md='12' className='text-center mt-3'>
                                    <Link to='/login'>
                                        تسجيل الدخول من جديد
                                    </Link>
                                </Col>
                            </Row>
                        </Form>
                    </Card.Body>
                </Card>
            </Col>
        </StyledLogin>
    );
}

export default ForgotPassword;