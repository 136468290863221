import React, { useState, createContext, useEffect, useContext } from 'react';
import { AlertContext } from "../../../../context/AlertContext";
import api from '../../../../api/areas';
import { useNavigate } from "react-router-dom";
export const RealEstateSharedFacilitiesEditContext = createContext();

export const RealEstateSharedFacilitiesEditContextProvider = ({ children }) => {
    //realEstateData place holder
    const [realEstateData, setRealEstateData] = useState({});
    const alertContext = useContext(AlertContext);
    const navigate = useNavigate();

    // real estate shared facilities --------------------------
    // مواقف السيارات
    const [parkingNumber, setParkingNumber] = useState('');

    // عدد المصاعد
    const [elevatorNumber, setElevatorNumber] = useState('');

    // مداخل الامن
    const [securityEntrancesNumber, setSecurityEntrancesNumber] = useState('');

    // مراكز اللياقة البدنية
    const [fitnessCenters, setFitnessCenters] = useState('');

    // خدمة الحراسة
    const [securityService, setSecurityService] = useState('');

    // خدمة نقل
    const [transferService, setTransferService] = useState('');

    // مغسل ملابس
    const [laundryService, setLaundryService] = useState('');

    // مطعم وجبات خفيفة
    const [snackbarShop, setSnackbarShop] = useState('');

    // محل بقالة
    const [groceryShop, setGroceryShop] = useState('');

    // حضانة اطفال
    const [babyDayCare, setBabyDayCare] = useState('');

    // ملعب اطفال
    const [childrenPlayground, setChildrenPlayground] = useState('');

    // مداخل الامن
    const [gamesHall, setGamesHall] = useState('');

    // حمام سباحة
    const [swimmingPool, setSwimmingPool] = useState('');

    // ملعب كرة قدم
    const [footballStadium, setFootballStadium] = useState('');

    // ملعب كرة سلة
    const [basketballStadium, setBasketballStadium] = useState('');

    // ملعب كرة طائرة
    const [volleyballStadium, setVolleyballStadium] = useState('');

    // قاعة مناسبات
    const [banquetHall, setBanquetHall] = useState('');

    // العقار داخل مجمع
    const [inComplex, setInComplex] = useState(false);

    // اسم المجمع
    const [complexName, setComplexName] = useState('');

    const getRealEstateFacilitiesData = (realEstateId) => {
        api.get(`/realEstates?id=${realEstateId}`).then(result => {
            if (result.data.length === 0) {
                alertContext.toggleAlert(true);
                alertContext.toggleAlertData({
                    heading: 'عقار مجهول',
                    message: 'لم يتم العثور علي العقار المطلوب',
                    variant: 'warning',
                });
                navigate("/");
            } else {
                setRealEstateData(result.data[0]);
            }
        });
    }

    const values = {
        getRealEstateFacilitiesData,
        parkingNumber,
        setParkingNumber,
        elevatorNumber,
        setElevatorNumber,
        securityEntrancesNumber,
        setSecurityEntrancesNumber,
        fitnessCenters,
        setFitnessCenters,
        securityService,
        setSecurityService,
        transferService,
        setTransferService,
        laundryService,
        setLaundryService,
        snackbarShop,
        setSnackbarShop,
        groceryShop,
        setGroceryShop,
        babyDayCare,
        setBabyDayCare,
        childrenPlayground,
        setChildrenPlayground,
        gamesHall,
        setGamesHall,
        swimmingPool,
        setSwimmingPool,
        footballStadium,
        setFootballStadium,
        basketballStadium,
        setBasketballStadium,
        volleyballStadium,
        setVolleyballStadium,
        banquetHall,
        setBanquetHall,
        inComplex,
        setInComplex,
        complexName,
        setComplexName,
    };

    return (
        <RealEstateSharedFacilitiesEditContext.Provider value={values}>
            {children}
        </RealEstateSharedFacilitiesEditContext.Provider>
    )
}

