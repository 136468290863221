import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import { SidebarContext } from '../../../context/SidebarContext';
import Logo from '../../../images/logo.png';
import LogoText from '../../../images/logo-text.png';
import Scrollbars from 'react-custom-scrollbars-2';
import SidebarMenu from './SidebarMenu';
import ClosedSubMenuBody from './menu-list-item/closed-menu-sub-menu/ClosedSubMenuBody';

const StyledSidebar = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    width: ${(props) => props.sidebarstate.isOpen ? props.theme.sidebar_width.open : props.theme.sidebar_width.close};
    transition: all .5s ease-in-out;
    background-color: ${({ theme }) => theme.colors.background.elements};
    height: 100%;
`;

const StyledSidebarHeader = styled.div`
    height: 5rem;
    width: 100%;
    .logo{
        width: 3.1875rem;
        padding-left: 0.75rem;
    }
    .logos-container{
        display: flex;
        height: 100%;
        width: 100%;
        align-items: center;
        ${({ sidebarstate }) => {
        if (sidebarstate.isOpen) {
            return css`
                    padding: 0 1rem;
                    justify-content: flex-start;

                    .logo-text{
                        width: 3.1875rem;
                    }
                `
        } else {
            return css`
                    padding: 0;
                    justify-content: center;
                    
                    .logo{
                        padding: 0;
                    }
                    .logo-text{
                        display: none;
                    }

                `
        }
    }}
    }
`;

const StyledSidebarContent = styled.div`
    height: calc(100% - 5.5rem);
    margin-top: .5rem;
    position: relative;
`
const Sidebar = () => {

    const sidebarState = useContext(SidebarContext);

    return (
        <StyledSidebar sidebarstate={sidebarState}>
            <StyledSidebarHeader sidebarstate={sidebarState}>
                <div className='logos-container'>
                    <img src={Logo} className="logo" alt="logo" />
                    <img src={LogoText} className="logo-text mb-0" alt="logo's text" />
                </div>
            </StyledSidebarHeader>

            <StyledSidebarContent sidebarstate={sidebarState}>
                <Scrollbars
                    renderView={props => (
                        <div {...props} style={{
                            ...props.style,
                            marginLeft: props.style.marginRight,
                            marginRight: 0,
                        }} />
                    )}
                    renderTrackVertical={props => (
                        <div {...props} style={{
                            ...props.style,
                            left: 2,
                            bottom: 20,
                            top: 20,
                            right: 'auto',
                            borderRadius: 3,
                        }} />
                    )}
                    style={{ width: "100%", height: "100%" }}
                >
                    <SidebarMenu />
                </Scrollbars>

                {sidebarState.menuList.map(item => {
                    if (item.haveSubMenu) {
                        return (
                            <ClosedSubMenuBody
                                key={item.id}
                                listItemProp={item}
                            />
                        )
                    }
                })}
            </StyledSidebarContent>
        </StyledSidebar>
    );
}

export default Sidebar;