import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import styled, { css } from "styled-components";
import Section1 from "./sections/Section-1";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { AlertContext } from "../../../../context/AlertContext";
import { useContext, useEffect, useState } from "react";
import api from '../../../../api/areas'
import SupervisorSection2 from "./sections/supervisor/SupervisorSection-2";
import PromoterSection2 from "./sections/promoter/PromoterSection-2";
import BrokerAssistantSection2 from "./sections/broker-assistant/BrokerAssistantSection-2";
import Loading from "../../../pages-parts/loading/Loading";
import { TopbarContext } from "../../../../context/TopbarContext";

const StyledEditUser = styled.div`
    position: relative;
    width: 100%;
    transition: all .5s ease-in-out;
    padding: 2.5rem 1.75rem;

    & .container-fluid > *:nth-last-child(2){
        margin-bottom: 0!important;
    }
`;

const EditUser = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [params, setParams] = useSearchParams();
    const [userData, setUserData] = useState({});
    const [userType, setUserType] = useState({});
    const navigate = useNavigate();
    const location = useLocation();
    const alertContext = useContext(AlertContext);
    const { toggleTopbarTitle } = useContext(TopbarContext);

    useEffect(() => {
        if(location.pathname === "/users/edit"){
            api.get(`/users?id=${params.get('id')}`).then(result => {
    
                if (result.data.length === 0) {
                    alertContext.toggleAlert(true);
                    alertContext.toggleAlertData({
                        heading: 'مستخدم مجهول',
                        message: 'لم يتم العثور علي المستخدم المطلوب',
                        variant: 'warning',
                    });
                    navigate("/");
                } else {
                    setUserData(result.data[0]);
                    setUserType(() => {
                        switch (result.data[0].rule) {
                            case 'مشرف':
                                return {
                                    value: 'supervisor',
                                    label: 'مشرف',
                                };
                            case 'مساعد وسيط':
                                return {
                                    value: 'broker-assistant',
                                    label: 'مساعد وسيط',
                                };
                            case 'خدمة عملاء':
                                return {
                                    value: 'customer-service',
                                    label: 'خدمة عملاء',
                                };
                            case 'مروج':
                                return {
                                    value: 'promoter',
                                    label: 'مروج',
                                };
    
                        }
                    });
    
                    toggleTopbarTitle(`تعديل بيانات ${result.data[0].rule}`)
                }
            });
        }else{
            const types = [
                {
                    value: 'supervisor',
                    label: 'مشرف',
                },
                {
                    value: 'broker-assistant',
                    label: 'مساعد وسيط',
                },
                {
                    value: 'promoter',
                    label: 'مروج',
                },
                {
                    value: 'customer-service',
                    label: 'خدمة عملاء',
                },
            ];
    
            if (params.get('type') !== undefined && params.get('type') !== null) {
                const newUserType = types.find(item => item.value === params.get('type'));
                toggleTopbarTitle(`إضافة ${newUserType.label}`);
                setUserType(newUserType);
            } else {
                toggleTopbarTitle(`إضافة مستخدم`);
            }
        }
    }, [])

    const getSection2 = () => {
        switch (userType.label) {
            case null:
                return '';
                break;
            case undefined:
                return '';
                break;
            case "مشرف":
                return <SupervisorSection2 />
                break;
            case "مساعد وسيط":
                return <BrokerAssistantSection2 />;
                break;
            case "مروج":
                return <PromoterSection2 />;
                break;
            case "خدمة عملاء":
                return '';
                break;
            default:
                return '';
        }
    }

    const handleSave = () => {
        setIsLoading(true);
        if(location.pathname === "/users/edit"){
            setTimeout(() => navigate(`/users/view?id=${params.get('id')}`), 1500);
        }else{
            setTimeout(() => navigate(`/users`), 1500);
        }
    }

    return (
        <StyledEditUser>
            {isLoading ? <Loading className="loading" /> : ''}
            <Container fluid>
                <Section1 userData={userData} userType={userType} setUserType={setUserType} />

                {getSection2()}
                <Row className="mt-3">
                    <Col className='d-flex align-items-center justify-content-start py-2'>
                        <Link
                            to={
                                location.pathname === "/users/edit" ?
                                `/users/view?id=${params.get('id')}` :
                                `/${
                                    params.get('type') !== null ?
                                        params.get('type') === "customer-service" ?
                                            params.get('type') :
                                            params.get('type') + 's'
                                        :
                                        "users"
                                }`
                            }
                            className="py-2 px-3 return-link me-2"
                        >
                            {
                                location.pathname === "/users/edit" ?
                                `رجوع` :
                                `الغاء`
                            }
                        </Link>
                        <span onClick={handleSave} className="py-2 px-3 save-link">
                            حفظ
                        </span>
                    </Col>
                </Row>
            </Container>
        </StyledEditUser>
    )
}

export default EditUser;